import { phoneGradeImg } from 'common/constants/imageUrls';

export const phoneDetailImgData = {
  A: {
    1: [phoneGradeImg.a1_1],
    2: [phoneGradeImg.a2_1, phoneGradeImg.a2_2],
  },
  B: {
    1: [phoneGradeImg.b1_1],
    2: [phoneGradeImg.b2_1, phoneGradeImg.b2_2],
    3: [phoneGradeImg.b3_1],
  },
  C: {
    1: [phoneGradeImg.c1_1, phoneGradeImg.c1_2],
    2: [phoneGradeImg.c2_1, phoneGradeImg.c2_2],
    3: [phoneGradeImg.c3_1],
  },
  D: {
    1: [phoneGradeImg.d1_1, phoneGradeImg.d1_2],
    2: [phoneGradeImg.d2_1],
    3: [phoneGradeImg.d3_1, phoneGradeImg.d3_2],
  },
};
