import React from 'react';
import styled, { css } from 'styled-components';
import { closedIcon } from 'common/constants/imageUrls';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';
import { gradeImgUrls } from 'components/PriceDetail/utils';
import { Grade } from 'components/Modal/GradingModal';
import { phoneDetailImgData } from 'components/Modal/GradingModal/DetailModal/data';
import Button from 'components/Button';

interface DetailModalProps {
  onClose: () => void;
  title: string;
  grade: Grade;
  detail?: string;
  questionIdx?: number;
}

const DetailModal: React.FunctionComponent<DetailModalProps> = ({
  onClose,
  title,
  grade,
  detail,
  questionIdx,
}) => {
  // 등급 아이콘 랜더 함수
  const renderGradeIcon = (grade: Grade) => {
    let iconSrc;
    switch (grade) {
      case 'A':
        iconSrc = gradeImgUrls.price_a;
        break;
      case 'B':
        iconSrc = gradeImgUrls.price_b;
        break;
      case 'C':
        iconSrc = gradeImgUrls.price_c;
        break;
      case 'D':
        iconSrc = gradeImgUrls.price_d;
        break;
      default:
        break;
    }
    return <DetailModalStyle.GradeImg src={iconSrc} />;
  };
  // 등급 이미지 랜더 함수
  const renderGradeImg = (grade: Grade, questionIdx: string) => {
    const srcObj = phoneDetailImgData[`${grade}`];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const srcList = srcObj[`${questionIdx}`];
    return (
      <DetailModalStyle.OverFlow length={srcList.length}>
        {srcList.map((imgSrc: string) => (
          <DetailModalStyle.Slider key={imgSrc}>
            <DetailModalStyle.SliderImg src={imgSrc} length={srcList.length} />
          </DetailModalStyle.Slider>
        ))}
      </DetailModalStyle.OverFlow>
    );
  };

  return (
    <DetailModalStyle.Wrapper>
      <DetailModalStyle.CloseBtn src={closedIcon} onClick={onClose} />
      {/* 잔상 예시 사진 */}
      <DetailModalStyle.Title>{title} 예시 사진</DetailModalStyle.Title>
      {/* 사진 + *등급 */}
      <DetailModalStyle.GradeContainer>
        {renderGradeIcon(grade)}
        <DetailModalStyle.Grade>{grade} 등급</DetailModalStyle.Grade>
      </DetailModalStyle.GradeContainer>
      {/* lcd 급 잔상 ~ lcd 파손된 경우 */}
      <DetailModalStyle.Status>{detail}</DetailModalStyle.Status>
      <DetailModalStyle.SliderContainer>
        {/* <DetailModalStyle.PrevBtn>{'<'}</DetailModalStyle.PrevBtn> */}
        {questionIdx && renderGradeImg(grade, questionIdx.toString())}
        {/* <DetailModalStyle.NextBtn>{'>'}</DetailModalStyle.NextBtn> */}
      </DetailModalStyle.SliderContainer>
      <DetailModalStyle.ConfirmBtn onClick={onClose}>
        확인
      </DetailModalStyle.ConfirmBtn>
    </DetailModalStyle.Wrapper>
  );
};

export default DetailModal;

const DetailModalStyle = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 20px;
    position: relative;
  `,
  CloseBtn: styled.img`
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  `,
  Title: styled.div`
    margin: 25px 0;
    font-size: min(20px, 6vw);
    font-weight: 500;
    color: #0f0f0f;
  `,
  GradeContainer: styled.div`
    height: 24px;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-end;
    ${mediaQueries(MOBILE)} {
      height: min(16px, 10vw);
    }
  `,
  GradeImg: styled.img`
    height: 100%;
    margin-right: 5px;
    ${mediaQueries(MOBILE)} {
      margin-right: 4px;
      height: 100%;
    }
  `,
  Grade: styled.div`
    color: #707070;
    font-size: min(14px, 3.5vw);
    line-height: 24px;
    ${mediaQueries(MOBILE)} {
      line-height: min(14px, 3.5vw);
    }
  `,
  Status: styled.div`
    color: #0f0f0f;
    font-size: min(14px, 3.5vw);
    margin-bottom: 20px;
  `,
  SliderContainer: styled.div`
    position: relative;
    margin: 15px auto 30px;
    width: 100%;
  `,
  OverFlow: styled.div<{ length: number }>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow: scroll;
    & > div:first-of-type {
      ${({ length }) =>
        length === 1
          ? css`
              margin: 0;
            `
          : css`
              margin-right: min(5px, 1vw);
            `}
    }
    & > div:last-of-type {
      ${({ length }) =>
        length === 1
          ? css`
              margin: 0;
            `
          : css`
              margin-left: min(5px, 1vw);
            `}
    }
  `,
  Slider: styled.div`
    width: 300%;
  `,
  SliderImg: styled.img<{ length: number }>`
    ${({ length }) =>
      length === 1
        ? css`
            width: 100%;
          `
        : css`
            width: 320px;
          `}
    height: 340px;
    ${mediaQueries(MOBILE)} {
      ${({ length }) =>
        length === 1
          ? css`
              width: 100%;
            `
          : css`
              width: min(240px, 61vw);
            `}
      height: min(250px, 65vw);
    }
  `,
  // PrevBtn: styled.button`
  //   position: absolute;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   left: 10px;
  //   width: 20px;
  //   height: 20px;
  //   background-color: red;
  //   border-radius: 50%;
  //   color: #fff;
  //   z-index: 99;
  // `,
  // NextBtn: styled.button`
  //   position: absolute;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   right: 10px;
  //   width: 20px;
  //   height: 20px;
  //   background-color: red;
  //   border-radius: 50%;
  //   color: #fff;
  //   z-index: 99;
  // `,
  ConfirmBtn: styled(Button)`
    width: 80%;
    padding: 10px 0;
    border-radius: 20px;
    background-color: #33ccbd;
    color: #fff;
    font-size: min(14px, 4vw);
    align-self: center;
  `,
};
