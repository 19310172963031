import React, { useState } from 'react';
import * as S from './styles';

const EndModal = () => {
  const [isVisible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (e.target.id === 'modalBox') {
      handleClose();
    }
  };

  return (
    <S.EndModalBox
      onClick={handleBackgroundClick}
      id="modalBox"
      isVisible={isVisible}
    >
      <S.EndModalLayout>
        <S.CloseButton onClick={handleClose} />
        <h1>이벤트가 종료되었습니다!</h1>
        <p>다음에 더욱 풍성한 이벤트로 찾아뵙겠습니다.</p>
      </S.EndModalLayout>
    </S.EndModalBox>
  );
};

export default EndModal;
