import { FunctionComponent, HTMLProps } from 'react';
import { MainContentLayout, MainContentLayoutProps } from './styles';

interface MainContentProps
  extends HTMLProps<HTMLElement>,
    MainContentLayoutProps {}

const MainContent: FunctionComponent<MainContentProps> = ({
  children,
  maxWidth,
}) => {
  return <MainContentLayout maxWidth={maxWidth}>{children}</MainContentLayout>;
};

export default MainContent;
