import styled, { css } from 'styled-components';
import { mainIcon } from 'common/constants/imageUrls';
import { DetailDesc, DetailTitle } from 'components/main/Styles';
import Button from 'components/Button';
import ScrollAnimation from 'components/ScrollAnimation';
import Footer from 'components/partials/Footer';
import {
  mediaQueries,
  MOBILE,
  SCROLL_BREAK,
  TABLET,
} from 'styles/mediaQueries';
import {
  backgroundCss,
  flexCenterCss,
  flexColumnCss,
} from 'styles/commonStyle';

export const MainPageStyle = {
  Container: styled.div`
    ${({ theme }) => css`
      min-width: 1220px;
      overflow-x: scroll;
      height: 100%;
      background-color: ${theme.common.colors.white};

      @keyframes motion {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(10px);
        }
      }

      @keyframes opacityAnimation {
        0% {
          opacity: 0.8;
        }
        100% {
          opacity: 1;
        }
      }

      ${mediaQueries(MOBILE)} {
        min-width: auto;
      }
    `};
  `,
  // 메인 화면
  MainWrapper: styled.div<{ pathname: string }>`
    ${({ theme, pathname }) => css`
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      text-align: center;
      background-image: url(${mainIcon.mainBanner});
      ${backgroundCss};
      width: 100vw;
      height: calc(100% - ${theme.header.height});
      color: ${theme.common.colors.white};
      padding-top: 16.909em;

      ${mediaQueries(TABLET)} {
        width: 100%;
        font-size: 0.7vw;
        mobile(11)
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 8.5px;
      }

      ${mediaQueries(MOBILE)} {
        background-image: url(${mainIcon.mainBannerMobile});
        margin-top: ${pathname === '/' ? theme.header.mobileHeight : 0};
        height: ${
          pathname === '/'
            ? `calc(100% - ${theme.header.mobileHeight})`
            : '100%'
        };
      }
    `};
  `,
  BtnWrapper: styled.div`
    margin-bottom: 4.545em;

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      margin-bottom: 24px;
    }
  `,
  Down: styled.div`
    ${({ theme }) => css`
      ${theme.typo.body16R};
      line-height: 2.5em;
      color: ${theme.common.colors.white};
      background-color: rgba(0, 0, 0, 0.3);
      width: 136px;
      margin: 0 auto 1.625em;
      border-radius: 200px;

      ${mediaQueries(TABLET)} {
        font-size: 1vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 12px;
      }

      ${mediaQueries(MOBILE)} {
        display: none;
      }
    `};
  `,
  DownIcon: styled.img`
    animation: motion 0.4s linear 0s infinite alternate,
      opacityAnimation 0.4s linear 0s infinite alternate;
  `,
  // Header
  HeaderWrapper: styled.div<{ isFixed: boolean }>`
    ${({ theme, isFixed }) => css`
      width: 100vw;
      background-color: ${theme.common.colors.white};
      z-index: 100;
      top: 0;

      ${isFixed
        ? css`
            position: fixed;
          `
        : css`
            position: static;
          `};

      ${mediaQueries(SCROLL_BREAK)} {
        width: 1220px;
      }

      ${mediaQueries(MOBILE)} {
        width: 100%;
        position: fixed !important;
        top: 0 !important;
      }
    `};
  `,
  // 룰렛 이벤트 배너
  FullSizeContainer: styled.div`
    ${({ theme }) => css`
      width: 100vw;
      background-color: ${theme.common.colors.alabaster};

      & > div {
        background-color: ${theme.common.colors.alabaster};
      }
    `}
  `,
  // Footer
  FooterContainer: styled(Footer)`
    ${({ theme }) => css`
      background-color: ${theme.common.colors.white};
    `}
  `,
  HowToAdditionalBanner: styled.div`
    ${({ theme }) => css`
      ${flexColumnCss};
      flex-wrap: wrap;
      width: 100%;
      height: 42.818em;
      color: ${theme.common.colors.white};
      padding: 5.273em 5.455em;
      border-radius: 16px;
      background-color: ${theme.common.colors.turquoise};
      margin-bottom: 3.636em;

      ${mediaQueries(TABLET)} {
        font-size: 0.7vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 8.5px;
      }

      ${mediaQueries(MOBILE)} {
        height: 100%;
        ${theme.typo.body14R};
        padding: 30px 24px;
        margin: 24px 0;
      }
    `}
  `,
  HowToAdditionalBannerTitle: styled.div`
    ${({ theme }) => css`
      ${theme.typo.body40T};
      text-align: left;
      color: ${theme.common.colors.mainText};

      span {
        font-weight: ${theme.fontWeights.bold};
      }

      ${mediaQueries(TABLET)} {
        font-size: 2.7vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 32.94px;
      }

      ${mediaQueries(MOBILE)} {
        ${theme.typo.body20T};
        margin-bottom: 20px;
        order: 1;
      }
    `}
  `,
  HowToAdditionalBannerDesc: styled(DetailDesc)`
    ${({ theme }) => css`
      color: ${theme.common.colors.white};
      margin: 0.667em 0 2.5em;
      font-weight: ${theme.fontWeights.normal};

      ${mediaQueries(MOBILE)} {
        ${theme.typo.body14R};
        margin-top: 0;
        margin-bottom: 44px;
        order: 2;
      }
    `}
  `,
  HowToAdditionalBannerBtn: styled(Button)`
    ${({ theme }) => css`
      width: 12.917em;
      height: 3.458em;
      ${theme.typo.head24};
      background-color: ${theme.common.colors.white};

      ${mediaQueries(TABLET)} {
        font-size: 1.5vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 18.3px;
      }

      ${mediaQueries(MOBILE)} {
        ${flexCenterCss};
        width: 100%;
        height: 52px;
        ${theme.typo.head14};
        order: 4;
        margin-top: 40px;
      }
    `}
  `,
  HowToAdditionalBannerImg: styled.img`
    height: 34.818em;
    margin-right: 10em;
    align-self: end;

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      width: 130px;
      height: 143px;
      align-self: center;
      order: 3;
      margin: 0;
    }
  `,

  HowToAdditionalBottomWrapper: styled(ScrollAnimation)<{ section?: boolean }>`
    ${({ theme, section }) => css`
      width: 100%;
      padding: 3.636em 2.909em;
      text-align: left;
      border-radius: 16px;
      background-color: ${section
        ? theme.common.colors.white
        : theme.common.colors.wildSand};
      border: ${section ? `1px solid ${theme.common.colors.mercury}` : 'none'};

      ${mediaQueries(TABLET)} {
        font-size: 0.7vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 8.5px;
      }

      ${mediaQueries(MOBILE)} {
        padding: 20px;
        margin-bottom: 0;
      }
    `}
  `,
  HowToAdditionalBottomTitleWrapper: styled.div`
    display: flex;
    align-items: center;

    ${mediaQueries(MOBILE)} {
      align-items: start;
    }
  `,
  HowToAdditionalBottomTitleImg: styled.img`
    width: 2.182em;
    height: 2.182em;
    margin-right: 0.727em;

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      width: 20px;
      height: 20px;
    }
  `,
  HowToAdditionalBottomTitle: styled(DetailTitle)`
    ${({ theme }) => css`
      color: ${theme.common.colors.mainText};
      margin-left: 0.333em;
    `}
  `,
  HowToAdditionalBottomDescList: styled.ul`
    margin-left: 2.909em;
    margin-top: 2.182em;
    word-break: keep-all;

    ${mediaQueries(MOBILE)} {
      text-align: left;
      margin-top: 16px;
    }
  `,
  HowToAdditionalBottomDesc: styled.li`
    ${({ theme }) => css`
      list-style: disc;
      ${theme.typo.body16R};
      color: ${theme.common.colors.mainText};

      :not(:last-child) {
        margin-bottom: 0.313em;
      }

      ${mediaQueries(TABLET)} {
        font-size: 1vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 12.2px;
      }

      ${mediaQueries(MOBILE)} {
        ${theme.typo.body12R};
        word-break: keep-all;
      }
    `}
  `,
};
