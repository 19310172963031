import headerLogoImg from 'assets/images/icon/headerLogo.svg';
import faviconIconImg from 'assets/images/icon/favicon.png';
import headerLogoMobileImg from 'assets/images/icon/header-logo-Mobile.svg';
import headerLogoMobileWhiteImg from 'assets/images/icon/header-logo-Mobile-white.svg';
import headerMoreImg from 'assets/images/icon/header-more.svg';
import footerLogoImg from 'assets/images/icon/footerLogo.svg';
import youtubeIconImg from 'assets/images/icon/sns/youtube.svg';
import facebookIconImg from 'assets/images/icon/sns/facebook.svg';
import instagramIconImg from 'assets/images/icon/sns/instagram.svg';
import closedIconImg from 'assets/images/icon/closed.svg';
import rightArrowImg from 'assets/images/icon/right-arrow.svg';
import upArrowImg from 'assets/images/icon/arrow-up-black.svg';
import AImg from 'assets/images/icon/grade/A.svg';
import BImg from 'assets/images/icon/grade/B.svg';
import CImg from 'assets/images/icon/grade/C.svg';
import DImg from 'assets/images/icon/grade/D.svg';
import SamsungLogoIcon from 'assets/images/icon/icon-samsung-logo.svg';
import AppleLogoIcon from 'assets/images/icon/icon-apple-logo.svg';
import LgLogoIcon from 'assets/images/icon/icon-lg-logo.svg';
import HpLogoIcon from 'assets/images/icon/icon-hp-logo.svg';
import MsLogoIcon from 'assets/images/icon/icon-ms-logo.svg';
import DellLogoIcon from 'assets/images/icon/icon-dell-logo.svg';
import LenovoLogoIcon from 'assets/images/icon/icon-lenovo-logo.svg';
import LaptopDetailBackground from 'assets/images/background/img-laptop-background.png';
import WatchDetailBackground from 'assets/images/background/img-watch-background.png';
import ImgEventHomeBanner01 from 'assets/images/background/eventHome/event-home-banner-1.jpg';
import ImgEventHomeBanner01Mobile from 'assets/images/background/eventHome/event-home-banner-1-mobile.png';
import ImgEventHomeBanner02 from 'assets/images/background/eventHome/event-home-banner-2.jpg';
import ImgEventHomeBanner02Mobile from 'assets/images/background/eventHome/event-home-banner-2-mobile.png';
import ImgEventHomeBanner03 from 'assets/images/background/eventHome/event-home-banner-3.jpg';
import ImgEventHomeBanner03Mobile from 'assets/images/background/eventHome/event-home-banner-3-mobile.png';
import ImgEventHomeBanner04 from 'assets/images/background/eventHome/event-home-banner-4.png';
import ImgEventHomeBanner04Mobile from 'assets/images/background/eventHome/event-home-banner-4-mobile.png';
import ImgEventHomeBanner05 from 'assets/images/background/eventHome/event-home-banner-5.png';
import ImgEventHomeBanner05Mobile from 'assets/images/background/eventHome/event-home-banner-5-mobile.png';
import ImgEventHomeBanner06 from 'assets/images/background/eventHome/event-home-banner-6.jpg';
import ImgEventHomeBanner06Mobile from 'assets/images/background/eventHome/event-home-banner-6-mobile.jpg';
import ImgEventHomeBanner07 from 'assets/images/background/eventHome/event-home-banner-7.jpg';
import ImgEventHomeBanner07Mobile from 'assets/images/background/eventHome/event-home-banner-7-mobile.jpg';
import ImgEventHomeBanner08Mobile from 'assets/images/background/eventHome/event-home-banner-8-mobile.jpg';

// brand logo
import SamsungLogoImg from 'assets/images/icon/brand/Samsung.svg';
import AppleLogoImg from 'assets/images/icon/brand/Apple.svg';
import LGLogoImg from 'assets/images/icon/brand/LG.svg';
import EtcImg from 'assets/images/icon/brand/Etc.svg';
import PantechLogoImg from 'assets/images/icon/brand/Pantech.svg';
import BlackberryLogoImg from 'assets/images/icon/brand/Blackberry.svg';
import SonyLogoImg from 'assets/images/icon/brand/Sony.svg';
import TGLogoImg from 'assets/images/icon/brand/Tgco.svg';
import HuawelLogoImg from 'assets/images/icon/brand/Huawel.svg';
import XiaomiLogoImg from 'assets/images/icon/brand/Xiaomi.svg';
import AlcatelLogoImg from 'assets/images/icon/brand/Alcatel.svg';
import BluebirdLogoImg from 'assets/images/icon/brand/Bluebird.svg';
import CasioLogoImg from 'assets/images/icon/brand/Casio.svg';
import DellLogoImg from 'assets/images/icon/brand/Dell.svg';
import ThcLogoImg from 'assets/images/icon/brand/Thc.svg';
import MotorolaLogoImg from 'assets/images/icon/brand/Motorola.svg';
import NokiaLogoImg from 'assets/images/icon/brand/Nokia.svg';
import TakeLogoImg from 'assets/images/icon/brand/Take.svg';
import ZteLogoImg from 'assets/images/icon/brand/Zte.svg';
import SharpLogoImg from 'assets/images/icon/brand/Sharp.svg';
// device img
import SamsungDeviceImg from 'assets/images/background/deviceImg/Samsung.png';
import AppleDeviceImg from 'assets/images/background/deviceImg/Apple.png';
import LGDeviceImg from 'assets/images/background/deviceImg/LG.png';
import PantechDeviceImg from 'assets/images/background/deviceImg/Pantech.png';
import BlackberryDeviceImg from 'assets/images/background/deviceImg/Blackberry.png';
import SonyDeviceImg from 'assets/images/background/deviceImg/Sony.png';
import TGDeviceImg from 'assets/images/background/deviceImg/TGCO.png';
import HuawelDeviceImg from 'assets/images/background/deviceImg/Huawel.png';
import XiaomiDeviceImg from 'assets/images/background/deviceImg/Xiaomi.png';
import AlcatelDeviceImg from 'assets/images/background/deviceImg/Alcatel.png';
import BluebirdDeviceImg from 'assets/images/background/deviceImg/Bluebird.png';
import CasioDeviceImg from 'assets/images/background/deviceImg/Casio.png';
import DellDeviceImg from 'assets/images/background/deviceImg/Dell.png';
import HtcDeviceImg from 'assets/images/background/deviceImg/Htc.png';
import MotorolaDeviceImg from 'assets/images/background/deviceImg/Motorola.png';
import NokiaDeviceImg from 'assets/images/background/deviceImg/Nokia.png';
import TakeDeviceImg from 'assets/images/background/deviceImg/Take.png';
import ZteDeviceImg from 'assets/images/background/deviceImg/ZTE.png';
import SharpDeviceImg from 'assets/images/background/deviceImg/Sharp.png';
// phone grade img
import A_1_1 from 'assets/images/background/phoneGradeImg/ratings-standards-example-a-1-1.png';
import A_2_1 from 'assets/images/background/phoneGradeImg/ratings-standards-example-a-2-1.png';
import A_2_2 from 'assets/images/background/phoneGradeImg/ratings-standards-example-a-2-2.png';
import B_1_1 from 'assets/images/background/phoneGradeImg/ratings-standards-example-b-1-1.png';
import B_2_1 from 'assets/images/background/phoneGradeImg/ratings-standards-example-b-2-1.png';
import B_2_2 from 'assets/images/background/phoneGradeImg/ratings-standards-example-b-2-2.png';
import B_3_1 from 'assets/images/background/phoneGradeImg/ratings-standards-example-b-3-1.png';
import C_1_1 from 'assets/images/background/phoneGradeImg/ratings-standards-example-c-1-1.png';
import C_1_2 from 'assets/images/background/phoneGradeImg/ratings-standards-example-c-1-2.png';
import C_2_1 from 'assets/images/background/phoneGradeImg/ratings-standards-example-c-2-1.png';
import C_2_2 from 'assets/images/background/phoneGradeImg/ratings-standards-example-c-2-2.png';
import C_3_1 from 'assets/images/background/phoneGradeImg/ratings-standards-example-c-3-1.png';
import D_1_1 from 'assets/images/background/phoneGradeImg/ratings-standards-example-d-1-1.png';
import D_1_2 from 'assets/images/background/phoneGradeImg/ratings-standards-example-d-1-2.png';
import D_2_1 from 'assets/images/background/phoneGradeImg/ratings-standards-example-d-2-1.png';
import D_3_1 from 'assets/images/background/phoneGradeImg/ratings-standards-example-d-3-1.png';
import D_3_2 from 'assets/images/background/phoneGradeImg/ratings-standards-example-d-3-2.png';
// connector
import Android1 from 'assets/images/background/connector/Android1.png';
import Android2 from 'assets/images/background/connector/Android2.png';
import Android3 from 'assets/images/background/connector/Android3.png';
import Android4 from 'assets/images/background/connector/Android4.png';
import Android5 from 'assets/images/background/connector/Android5.png';
import Android6 from 'assets/images/background/connector/Android6.png';
import IOS1 from 'assets/images/background/connector/IOS1.png';
import IOS2 from 'assets/images/background/connector/IOS2.png';
import IOS3 from 'assets/images/background/connector/IOS3.png';
import IOS4 from 'assets/images/background/connector/IOS4.png';
import IOS5 from 'assets/images/background/connector/IOS5.png';
import IOS6 from 'assets/images/background/connector/IOS6.png';
import IOS7 from 'assets/images/background/connector/IOS7.png';

// etc
import mainBannerImg from 'assets/images/background/home-banner.png';
import subBannerImg from 'assets/images/background/sub-banner.png';
import subBannerMobileImg from 'assets/images/background/sub-banner-mobile.jpg';
import luckyBoxImg from 'assets/images/background/lucky-box.svg';
import evtPhoneImg from 'assets/images/background/evt-phone.svg';
import warningIconImg from 'assets/images/icon/exclamation-mark.svg';
import testImg from 'assets/images/background/s22-mock.svg';
import rewardPhoneImg from 'assets/images/background/main-reward.svg';
import downArrowImg from 'assets/images/icon/arrow-down-white.svg';
import downArrowMobileImg from 'assets/images/icon/mobile-down-arrow.svg';
import MyLocationImg from 'assets/images/icon/map/Target.svg';
import UnSKImg from 'assets/images/icon/map/unSK.svg';
import UnSamsungImg from 'assets/images/icon/map/unSamsung.svg';
import UnEmartImg from 'assets/images/icon/map/unEmart.svg';
import UnHomeplusImg from 'assets/images/icon/map/unHomeplus.svg';
import UnLotteImg from 'assets/images/icon/map/unLotte.svg';
import UnHimartImg from 'assets/images/icon/map/unHimart.svg';
import UnPostImg from 'assets/images/icon/map/unPost.svg';
import UnLGImg from 'assets/images/icon/map/unLG.svg';
import UnKTImg from 'assets/images/icon/map/unKT.svg';
import UnElecImg from 'assets/images/icon/map/unElec.svg';
import UnMintitImg from 'assets/images/icon/map/unMintit.svg';
import UnStsSKImg from 'assets/images/icon/map/unStsSk.svg';
import UnStsSamsungImg from 'assets/images/icon/map/unStsSamsung.svg';
import UnStsEmartImg from 'assets/images/icon/map/unStsEmart.svg';
import UnStsHomeplusImg from 'assets/images/icon/map/unStsHomplus.svg';
import UnStsLotteImg from 'assets/images/icon/map/unStsLotte.svg';
import UnStsHimartImg from 'assets/images/icon/map/unStsHimart.svg';
import UnStsPostImg from 'assets/images/icon/map/unStsPost.svg';
import UnStsLGImg from 'assets/images/icon/map/unStsLg.svg';
import UnStsKTImg from 'assets/images/icon/map/unStsKt.svg';
import UnStsElecImg from 'assets/images/icon/map/unStsElec.svg';
import UnStsMintitImg from 'assets/images/icon/map/unStsMintit.svg';
import bgCluster1Img from 'assets/images/icon/map/bg_cluster_1.png';
import bgCluster2Img from 'assets/images/icon/map/bg_cluster_2.png';
import bgCluster3Img from 'assets/images/icon/map/bg_cluster_3.png';
import bgCluster4Img from 'assets/images/icon/map/bg_cluster_4.png';
import SKImg from 'assets/images/icon/map/sk.svg';
import SamsungImg from 'assets/images/icon/map/samsung.svg';
import EmartImg from 'assets/images/icon/map/emart.svg';
import HomeplusImg from 'assets/images/icon/map/homeplus.svg';
import LotteImg from 'assets/images/icon/map/lotte.svg';
import HimartImg from 'assets/images/icon/map/himart.svg';
import PostImg from 'assets/images/icon/map/post.svg';
import LGImg from 'assets/images/icon/map/lg.svg';
import KTImg from 'assets/images/icon/map/kt.svg';
import ElecImg from 'assets/images/icon/map/elec.svg';
import MintitImg from 'assets/images/icon/map/mintit.svg';
import StsSKImg from 'assets/images/icon/map/stsSk.svg';
import StsSamsungImg from 'assets/images/icon/map/stsSamsung.svg';
import StsEmartImg from 'assets/images/icon/map/stsEmart.svg';
import StsHomeplusImg from 'assets/images/icon/map/stsHomplus.svg';
import StsLotteImg from 'assets/images/icon/map/stsLotte.svg';
import StsHimartImg from 'assets/images/icon/map/stsHimart.svg';
import StsPostImg from 'assets/images/icon/map/stsPost.svg';
import StsLGImg from 'assets/images/icon/map/stsLg.svg';
import StsKTImg from 'assets/images/icon/map/stsKt.svg';
import StsElecImg from 'assets/images/icon/map/stsElec.svg';
import StsMintitImg from 'assets/images/icon/map/stsMintit.svg';
import SearchImg from 'assets/images/icon/map/search.svg';
import LocateImg from 'assets/images/icon/map/location.svg';
import ClockImg from 'assets/images/icon/map/clock.svg';
import AtmGuideVedioImg from 'assets/images/background/video-guide.png';
import RightArrowWhiteImg from 'assets/images/icon/arrow-right-white.svg';
import phoneSettings1Img from 'assets/images/background/phone-settings1.png';
import phoneSettings2Img from 'assets/images/background/phone-settings2.png';
import phoneSettings3Img from 'assets/images/background/phone-settings3.png';
import visualInspection1Img from 'assets/images/background/visual-inspection1.png';
import visualInspection2Img from 'assets/images/background/visual-inspection2.png';
import visualInspection3Img from 'assets/images/background/visual-inspection3.png';
import phoneSettings1Icon from 'assets/images/icon/guide/phone-settings1.svg';
import phoneSettings2Icon from 'assets/images/icon/guide/phone-settings2.svg';
import phoneSettings3Icon from 'assets/images/icon/guide/phone-settings3.svg';
import visualInspection1Icon from 'assets/images/icon/guide/visual-inspection1.svg';
import visualInspection2Icon from 'assets/images/icon/guide/visual-inspection2.svg';
import visualInspection3Icon from 'assets/images/icon/guide/visual-inspection3.svg';
import HimartLogoImg from 'assets/images/icon/main/HimartLogo.svg';
import MainSamsungLogoImg from 'assets/images/icon/main/SamsungLogo.svg';
import SKLogoImg from 'assets/images/icon/main/SKLogo.png';
import KTLogoImg from 'assets/images/icon/main/KTLogo.png';
import ElectronicLandImg from 'assets/images/icon/main/ElectronicLand.svg';
import MainLGLogoImg from 'assets/images/icon/main/LGLogo.png';
import BellIcon from 'assets/images/icon/main/Bell.svg';
import MintitAtmIcon from 'assets/images/icon/main/MintitIcon.svg';
import SaleIcon from 'assets/images/icon/main/Sale.svg';
import WithDrawIcon from 'assets/images/icon/main/WithDraw.svg';
import ChartIcon from 'assets/images/icon/main/chart.svg';
import ArrowImg from 'assets/images/icon/arrow-right-blck.svg';
import RouletteImg from 'assets/images/icon/roulette.png';
import MobileHomeImg from 'assets/images/background/home-banner-mobile.png';
import MainArrowImg from 'assets/images/icon/main/main-arrow.svg';
import headerMoreWhiteImg from 'assets/images/icon/header-more-white.svg';
import rouletteBackground from 'assets/images/background/roulette_bg.jpg';
import rouletteArrow from 'assets/images/background/roulette_arrow.png';
import rouletteButton from 'assets/images/background/roulette_button.png';
import rouletteBoard from 'assets/images/background/roulette_board.png';
import rouletteCheckIcon from 'assets/images/icon/roulette/check.svg';
import rouletteUnCheckIcon from 'assets/images/icon/roulette/unCheck.svg';
import GalaxyS22Img from 'assets/images/background/rouletteReward/1tr_Galaxy_S22.svg';
import GalaxyWatchImg from 'assets/images/background/rouletteReward/2tr_Galaxy_Watch.svg';
import GalaxyBudImg from 'assets/images/background/rouletteReward/3tr_galaxy_bud_2.svg';
import StarbucksAmericanoImg from 'assets/images/background/rouletteReward/4tr_starbucks_Americano.svg';
import BaskinRobbinsImg from 'assets/images/background/rouletteReward/5tr_Baskin-Robbins.svg';
import BananaMilkImg from 'assets/images/background/rouletteReward/6tr_banana_milk.svg';
import RewardNoneImg from 'assets/images/background/rouletteReward/non_boom.png';
import AffiliateNoticePc from 'assets/images/background/affiliate-notice.jpg';
import AffiliateNoticeMobile from 'assets/images/background/affiliate-notice-mobile.jpg';
import ModelsReward from 'assets/images/background/detail-model.jpg';

import appQrCodeImage from 'assets/images/app-qr.png';
import QuizEventPc from 'assets/images/background/quiz-event.jpg';
import QuizEventMobile from 'assets/images/background/quiz-event-mobile.jpg';
import QuizTitleImg from 'assets/images/background/quiz-title.png';
import QuizImg from 'assets/images/background/quiz-banner.png';
import CheckIcon from 'assets/images/icon/icon-check.svg';
import CheckedIcon from 'assets/images/icon/icon-checked.svg';

import PriceGradeA from 'assets/images/icon/icon-grade-A.svg';
import PriceGradeB from 'assets/images/icon/icon-grade-B.svg';
import PriceGradeC from 'assets/images/icon/icon-grade-C.svg';
import PriceGradeD from 'assets/images/icon/icon-grade-D.svg';

import HowToApply1 from 'assets/images/icon/priceInquiry/icon-how-to-apply-1.png';
import HowToApplyTablet1 from 'assets/images/icon/priceInquiry/icon-how-to-apply-1-tablet.png';
import HowToApplyWatch1 from 'assets/images/icon/priceInquiry/icon-how-to-apply-1-watch.png';
import HowToApply2 from 'assets/images/icon/priceInquiry/icon-how-to-apply-2.png';
import HowToApply3 from 'assets/images/icon/priceInquiry/icon-how-to-apply-3.png';
import HowToApply4 from 'assets/images/icon/priceInquiry/icon-how-to-apply-4.png';
import HowToApply5 from 'assets/images/icon/priceInquiry/icon-how-to-apply-5.png';
import myLocationImage from 'assets/images/icon/map/icon-my-location.svg';
import LogoSamsungXMintit from 'assets/images/icon/logo_samsungXmintit.svg';

import ImgWatchStep01 from 'assets/images/background/img_watch_step_01.png';
import ImgWatchStep02 from 'assets/images/background/img_watch_step_02.png';
import ImgWatchStep03 from 'assets/images/background/img_watch_step_03.png';
import ImgWatchStep04 from 'assets/images/background/img_watch_step_04.png';
import ImgWatchStep05 from 'assets/images/background/img_watch_step_05.png';

import IconQna from 'assets/images/icon/icon_qna.svg';
import ArrowDown from 'assets/images/icon/icon_arrow.svg';
import ImgWatchBackground01 from 'assets/images/background/img_watch_bg01.png';
import ImgWatchBackground02 from 'assets/images/background/img_watch_bg02.png';

import IconCheckCircle from 'assets/images/icon/icon-check-circle.svg';
import IconCheckedCircle from 'assets/images/icon/icon-checked-circle.svg';
import IconSecurity from 'assets/images/icon/icon-security.svg';
import IconChevronDown from 'assets/images/icon/icon-chevron-down.svg';
import IconRadioChecked from 'assets/images/icon/icon-raido-checked.svg';
import IconRadioUnChecked from 'assets/images/icon/icon-raido-unchecked.svg';
import ImgEventDetail01 from 'assets/images/background/event/event-1.jpg';
import ImgEventDetail02 from 'assets/images/background/event/event-2.jpg';
import ImgEventDetail03 from 'assets/images/background/event/event-3.jpg';
import ImgEventDetail04 from 'assets/images/background/event/event-4.jpg';
import ImgEventDetail05 from 'assets/images/background/event/event-5.jpg';

// favicon icon
export const faviconIcon = faviconIconImg;

// header icon
export const headerLogo = headerLogoImg;
export const headerLogoMobile = headerLogoMobileImg;
export const headerLogoMobileWhite = headerLogoMobileWhiteImg;
export const headerMore = headerMoreImg;
export const headerMoreWhite = headerMoreWhiteImg;

// footer icon
export const footerLogo = footerLogoImg;
export const youtubeIcon = youtubeIconImg;
export const facebookIcon = facebookIconImg;
export const instagramIcon = instagramIconImg;

// icon
export const closedIcon = closedIconImg;
export const rightArrow = rightArrowImg;
export const rightArrowWhite = RightArrowWhiteImg;
export const upArrow = upArrowImg;
export const priceGradeA = PriceGradeA;
export const priceGradeB = PriceGradeB;
export const priceGradeC = PriceGradeC;
export const priceGradeD = PriceGradeD;

// event
export const QuizTitle = QuizTitleImg;
export const Quiz = QuizImg;
export const Roulette = RouletteImg;
export const RewardImg = {
  1: GalaxyS22Img,
  2: GalaxyWatchImg,
  3: GalaxyBudImg,
  4: StarbucksAmericanoImg,
  5: BaskinRobbinsImg,
  6: BananaMilkImg,
  none: RewardNoneImg,
};

// 다른 기종 확인하기
export const modelsReward = ModelsReward;

// grade icon
export const gradeIcon = {
  A: AImg,
  B: BImg,
  C: CImg,
  D: DImg,
};

// connector img
export const connectorImg = {
  android1: Android1,
  android2: Android2,
  android3: Android3,
  android4: Android4,
  android5: Android5,
  android6: Android6,
  ios1: IOS1,
  ios2: IOS2,
  ios3: IOS3,
  ios4: IOS4,
  ios5: IOS5,
  ios6: IOS6,
  ios7: IOS7,
};

// manufacturer img
export const manufacturerImg = {
  SamsungLogo: SamsungLogoImg,
  AppleLogo: AppleLogoImg,
  LGLogo: LGLogoImg,
  Etc: EtcImg,
  PantechLogo: PantechLogoImg,
  BlackberryLogo: BlackberryLogoImg,
  SonyLogo: SonyLogoImg,
  TGLogo: TGLogoImg,
  HuawelLogo: HuawelLogoImg,
  XiaomiLogo: XiaomiLogoImg,
  AlcatelLogo: AlcatelLogoImg,
  BluebirdLogo: BluebirdLogoImg,
  CasioLogo: CasioLogoImg,
  DellLogo: DellLogoImg,
  ThcLogo: ThcLogoImg,
  MotorolaLogo: MotorolaLogoImg,
  NokiaLogo: NokiaLogoImg,
  TakeLogo: TakeLogoImg,
  ZteLogo: ZteLogoImg,
  SharpLogo: SharpLogoImg,
};

// empty device img
export const deviceImg = {
  SamsungDevice: SamsungDeviceImg,
  AppleDevice: AppleDeviceImg,
  LGDevice: LGDeviceImg,
  PantechDevice: PantechDeviceImg,
  BlackberryDevice: BlackberryDeviceImg,
  SonyDevice: SonyDeviceImg,
  TGDevice: TGDeviceImg,
  HuawelDevice: HuawelDeviceImg,
  XiaomiDevice: XiaomiDeviceImg,
  AlcatelDevice: AlcatelDeviceImg,
  BluebirdDevice: BluebirdDeviceImg,
  CasioDevice: CasioDeviceImg,
  DellDevice: DellDeviceImg,
  HtcDevice: HtcDeviceImg,
  MotorolaDevice: MotorolaDeviceImg,
  NokiaDevice: NokiaDeviceImg,
  TakeDevice: TakeDeviceImg,
  ZteDevice: ZteDeviceImg,
  SharpDevice: SharpDeviceImg,
};

// phone grade img
export const phoneGradeImg = {
  a1_1: A_1_1,
  a2_1: A_2_1,
  a2_2: A_2_2,

  b1_1: B_1_1,
  b2_1: B_2_1,
  b2_2: B_2_2,
  b3_1: B_3_1,

  c1_1: C_1_1,
  c1_2: C_1_2,
  c2_1: C_2_1,
  c2_2: C_2_2,
  c3_1: C_3_1,

  d1_1: D_1_1,
  d1_2: D_1_2,
  d2_1: D_2_1,
  d3_1: D_3_1,
  d3_2: D_3_2,
};
// map icon
export const mapIcon = {
  MyLocation: MyLocationImg,
  UnSK: UnSKImg,
  UnSamsung: UnSamsungImg,
  UnEmart: UnEmartImg,
  UnHomeplus: UnHomeplusImg,
  UnLotte: UnLotteImg,
  UnHimart: UnHimartImg,
  UnPost: UnPostImg,
  UnLG: UnLGImg,
  UnKT: UnKTImg,
  UnElec: UnElecImg,
  UnMintit: UnMintitImg,
  bg_cluster_1: bgCluster1Img,
  bg_cluster_2: bgCluster2Img,
  bg_cluster_3: bgCluster3Img,
  bg_cluster_4: bgCluster4Img,
  SK: SKImg,
  Samsung: SamsungImg,
  Emart: EmartImg,
  Homeplus: HomeplusImg,
  Lotte: LotteImg,
  Himart: HimartImg,
  Post: PostImg,
  LG: LGImg,
  KT: KTImg,
  Elec: ElecImg,
  Mintit: MintitImg,
  Search: SearchImg,
  Locate: LocateImg,
  Clock: ClockImg,
  Arrow: ArrowImg,
  StsSK: StsSKImg,
  StsSamsung: StsSamsungImg,
  StsEmart: StsEmartImg,
  StsHomeplus: StsHomeplusImg,
  StsLotte: StsLotteImg,
  StsHimart: StsHimartImg,
  StsPost: StsPostImg,
  StsLG: StsLGImg,
  StsKT: StsKTImg,
  StsElec: StsElecImg,
  StsMintit: StsMintitImg,
  UnStsSK: UnStsSKImg,
  UnStsSamsung: UnStsSamsungImg,
  UnStsEmart: UnStsEmartImg,
  UnStsHomeplus: UnStsHomeplusImg,
  UnStsLotte: UnStsLotteImg,
  UnStsHimart: UnStsHimartImg,
  UnStsPost: UnStsPostImg,
  UnStsLG: UnStsLGImg,
  UnStsKT: UnStsKTImg,
  UnStsElec: UnStsElecImg,
  UnStsMintit: UnStsMintitImg,
  myLocationImage,
};

// main page
export const mainIcon = {
  mainBannerMobile: MobileHomeImg,
  mainBanner: mainBannerImg,
  subBanner: subBannerImg,
  subBannerMobile: subBannerMobileImg,
  luckyBox: luckyBoxImg,
  evtPhone: evtPhoneImg,
  warningIcon: warningIconImg,
  test: testImg,
  rewardPhone: rewardPhoneImg,
  downArrow: downArrowImg,
  downArrowMobile: downArrowMobileImg,
  HimartLogo: HimartLogoImg,
  MainSamsungLogo: MainSamsungLogoImg,
  SKLogo: SKLogoImg,
  KTLogo: KTLogoImg,
  ElectronicLandLogo: ElectronicLandImg,
  MainLGLogo: MainLGLogoImg,
  Bell: BellIcon,
  MintitAtm: MintitAtmIcon,
  Sale: SaleIcon,
  WithDraw: WithDrawIcon,
  Chart: ChartIcon,
  MainArrow: MainArrowImg,
};

// atm guide page
export const atmGuideVideo = AtmGuideVedioImg;
export const atmGuideImages: { [index: string]: string } = {
  phoneSettings1: phoneSettings1Img,
  phoneSettings2: phoneSettings2Img,
  phoneSettings3: phoneSettings3Img,
  visualInspection1: visualInspection1Img,
  visualInspection2: visualInspection2Img,
  visualInspection3: visualInspection3Img,
  appQrCodeImage,
};
export const atmGuideIcons: { [index: string]: string } = {
  phoneSettings1: phoneSettings1Icon,
  phoneSettings2: phoneSettings2Icon,
  phoneSettings3: phoneSettings3Icon,
  visualInspection1: visualInspection1Icon,
  visualInspection2: visualInspection2Icon,
  visualInspection3: visualInspection3Icon,
};

// 룰렛 이벤트
export const RouletteImages = {
  background: rouletteBackground,
  arrow: rouletteArrow,
  button: rouletteButton,
  board: rouletteBoard,
  rouletteCheck: rouletteCheckIcon,
  rouletteUnCheck: rouletteUnCheckIcon,
};

export const AffiliateNoticeImages = {
  pc: AffiliateNoticePc,
  mobile: AffiliateNoticeMobile,
};

export const QuizEventImages = {
  pc: QuizEventPc,
  mobile: QuizEventMobile,
};

export const PriceInquiryImages = {
  howToApply1: HowToApply1,
  howToApplyTablet1: HowToApplyTablet1,
  howToApplyWatch1: HowToApplyWatch1,
  howToApply2: HowToApply2,
  howToApply3: HowToApply3,
  howToApply4: HowToApply4,
  howToApply5: HowToApply5,
};

// 타임라인 icon
export const checkIcon = CheckIcon;
export const checkedIcon = CheckedIcon;

// 브랜드 로고 아이콘
export const samsungLogoIcon = SamsungLogoIcon;
export const appleLogoIcon = AppleLogoIcon;
export const lgLogoIcon = LgLogoIcon;
export const hpLogoIcon = HpLogoIcon;
export const msLogoIcon = MsLogoIcon;
export const dellLogoIcon = DellLogoIcon;
export const lenovoLogoIcon = LenovoLogoIcon;

// 노트북 상세 샘플이미지 배경
export const laptopDetailBackground = LaptopDetailBackground;
// 태블릿 상세 샘플이미지 배경
export const watchDetailBackground = WatchDetailBackground;

// 이벤트 홈
export const imgEventHomeBanner01 = ImgEventHomeBanner01;
export const imgEventHomeBanner01Mobile = ImgEventHomeBanner01Mobile;
export const imgEventHomeBanner02 = ImgEventHomeBanner02;
export const imgEventHomeBanner02Mobile = ImgEventHomeBanner02Mobile;
export const imgEventHomeBanner03 = ImgEventHomeBanner03;
export const imgEventHomeBanner03Mobile = ImgEventHomeBanner03Mobile;
export const imgEventHomeBanner04 = ImgEventHomeBanner04;
export const imgEventHomeBanner04Mobile = ImgEventHomeBanner04Mobile;
export const imgEventHomeBanner05 = ImgEventHomeBanner05;
export const imgEventHomeBanner05Mobile = ImgEventHomeBanner05Mobile;
export const imgEventHomeBanner06 = ImgEventHomeBanner06;
export const imgEventHomeBanner06Mobile = ImgEventHomeBanner06Mobile;
export const imgEventHomeBanner07 = ImgEventHomeBanner07;
export const imgEventHomeBanner07Mobile = ImgEventHomeBanner07Mobile;
export const imgEventHomeBanner08Mobile = ImgEventHomeBanner08Mobile;

export const imgEventDetail01 = ImgEventDetail01;
export const imgEventDetail02 = ImgEventDetail02;
export const imgEventDetail03 = ImgEventDetail03;
export const imgEventDetail04 = ImgEventDetail04;
export const imgEventDetail05 = ImgEventDetail05;

export const logoSamsungXMintit = LogoSamsungXMintit;
export const imgWatchStep01 = ImgWatchStep01;
export const imgWatchStep02 = ImgWatchStep02;
export const imgWatchStep03 = ImgWatchStep03;
export const imgWatchStep04 = ImgWatchStep04;
export const imgWatchStep05 = ImgWatchStep05;
export const iconQna = IconQna;
export const arrowDown = ArrowDown;
export const watchBackground01 = ImgWatchBackground01;
export const watchBackground02 = ImgWatchBackground02;
export const iconCheckCircle = IconCheckCircle;
export const iconCheckedCircle = IconCheckedCircle;
export const iconSecurity = IconSecurity;
export const iconChevronDown = IconChevronDown;
export const iconRadioChecked = IconRadioChecked;
export const iconRadioUnchecked = IconRadioUnChecked;
