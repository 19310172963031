import { HttpMethod, request } from '../../common/network';
import { IPriceInquiryItem } from '../priceInquiryLaptop';
import { getBrandImg } from '../../common/utils';

const baseUrl = `/rest/api/etc/product/tablet`;

export const getPriceInquiryTablet = (
  url: string,
  category: string,
  code: string,
  modelNo?: string,
): Promise<IPriceInquiryItem[]> => {
  let apiUrl = `${baseUrl}/category`;

  switch (category) {
    case 'manufacturer':
      apiUrl += '/cate02/';
      break;
    case 'series':
      apiUrl += '/cate03/';
      break;
    case 'model':
      apiUrl += '/cate03/';
      break;
    default:
      apiUrl = '/cate02/';
      break;
  }

  return request(HttpMethod.GET, apiUrl + code).then((res) => {
    const filterRes = res.data.dataset;

    let filterModel = [];

    switch (category) {
      case 'manufacturer':
        return filterRes.map((item: any) => ({
          name: item.cate02_name,
          code: item.cate02_num,
          img: getBrandImg(item.cate02_num, 'tablet'),
        }));
      case 'series':
        return filterRes.map((item: any) => ({
          name: item.model_no,
          code,
        }));
      case 'model':
        filterModel = filterRes.filter((item: any) => {
          if (modelNo && item.model_no === modelNo) {
            return true;
          }

          return false;
        });

        if (!filterModel.length) {
          filterModel[0] = { children: [] };
        }

        return filterModel[0].children.map((item: any) => ({
          name: item.cate03_name,
          code: item.cate03_num,
        }));
      default:
        return [];
    }

    return res;
  });
};

export interface IPriceInquiryTabletDetail {
  btob_price: number;
  buy_price_a: string;
  buy_price_b: string;
  buy_price_c: string;
  cate01_name: string;
  cate02_num: number;
  cate02_name: string;
  cate03_name: string;
  cate04_name: string;
  ex_price: string;
  fcart_num: number;
  future_standard_price: string;
  mem_num: number;
  pro_num: number;
  pro_number: string;
  reg_date: string;
  standard_price: string;
  model_no: string;
}

export const getPriceInquiryTabletDetail = (url: string, code: string) => {
  const apiUrl = `${baseUrl}/detail/`;

  return request(HttpMethod.GET, apiUrl + code).then((res) => {
    if (res.data === '') {
      throw new Error('404');
    }

    return res.data.dataset;
  });
};
