import { useEffect, useRef, useState } from 'react';

const useScroll = () => {
  const wrapperRef = useRef<HTMLElement | null>(null);

  const [state, setState] = useState({
    x: 0, // x와 y의 초기값을 0으로 지정
    y: 0,
  });
  const onScroll = () => {
    if (wrapperRef?.current) {
      wrapperRef.current?.getClientRects();
      const y = wrapperRef.current?.scrollTop;
      const x = wrapperRef.current?.scrollLeft;

      setState({ x, y });
    } else {
      setState({ x: window.scrollX, y: window.scrollY });
    }
  };
  useEffect(() => {
    wrapperRef.current = document.getElementById('wrapper');

    const target = wrapperRef.current || window;

    target.addEventListener('scroll', onScroll); // scorll할 때 onScroll 이벤트 핸들러 지정
    return () => target.removeEventListener('scroll', onScroll); // clean up
  }, []);
  return state;
};

export default useScroll;
