import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import persistStore from 'redux-persist/es/persistStore';
import persistReducer from 'redux/reducer';

const middleware: any[] = [];

export const store = createStore(
  persistReducer,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default persistStore(store);
