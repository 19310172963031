import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import {
  facebookIcon,
  footerLogo,
  instagramIcon,
  youtubeIcon,
} from 'common/constants/imageUrls';
import { mediaQueries, MOBILE, SCROLL_BREAK } from 'styles/mediaQueries';
import {
  PRIVACY_MINTIT,
  TERMS_MINTIT,
  YOUTUBE_SNS,
  FACEBOOK_SNS,
  INSTAR_SNS,
} from 'common/constants/pathUrls';

interface FooterProps extends HTMLAttributes<HTMLDivElement> {
  isHide?: boolean;
}

const Footer: React.FunctionComponent<FooterProps> = ({ isHide, ...rest }) => {
  return (
    <FooterStyle.Base isHide={isHide} {...rest}>
      <FooterStyle.Wrapper>
        <FooterStyle.TitleContainer>
          <FooterStyle.Logo src={footerLogo} />
          <FooterStyle.UseTermsList>
            <a href={TERMS_MINTIT} target="_blank" rel="noreferrer">
              이용약관
            </a>
            <a href={PRIVACY_MINTIT} target="_blank" rel="noreferrer">
              개인정보처리방침
            </a>
          </FooterStyle.UseTermsList>
          <FooterStyle.SNSContainer>
            <a href={YOUTUBE_SNS} target="_blank" rel="noreferrer">
              <FooterStyle.SNSAtr src={youtubeIcon} />
            </a>
            <a href={FACEBOOK_SNS} target="_blank" rel="noreferrer">
              <FooterStyle.SNSAtr src={facebookIcon} />
            </a>
            <a href={INSTAR_SNS} target="_blank" rel="noreferrer">
              <FooterStyle.SNSAtr src={instagramIcon} />
            </a>
          </FooterStyle.SNSContainer>
        </FooterStyle.TitleContainer>
        <FooterStyle.ContentContainer>
          <FooterStyle.ContentTitle>민팃(주)</FooterStyle.ContentTitle>
          <FooterStyle.ContentDesc>
            <FooterStyle.ContentPhoneNumber>
              고객센터 1877-5446 (일반 민팃) · 1877-2660 (SKT 민팃) · 1877-3943
              (LGU+ 민팃) · 1877-4515 (KT 민팃) 평일 09:00 ~ 18:00 (공휴일 /
              주말 휴무)
            </FooterStyle.ContentPhoneNumber>
            <FooterStyle.Info>
              대표자 하성문 | 사업자 번호 137-81-49755 | 통신판매번호 제
              2005-51호 | 개인정보관리책임자 성미라 | 서울특별시 마포구 성암로
              189 중소기업DMC타워 16층
            </FooterStyle.Info>
          </FooterStyle.ContentDesc>
        </FooterStyle.ContentContainer>
        <FooterStyle.CopyRightContainer>
          &copy; MINTIT. All rights reserved.
        </FooterStyle.CopyRightContainer>
      </FooterStyle.Wrapper>
    </FooterStyle.Base>
  );
};

export default Footer;

const FooterStyle = {
  Base: styled.footer<{ isHide?: boolean }>`
    display: ${({ isHide }) => (isHide ? 'none' : 'block')};
    border-top: 1px solid #cacaca;
    background-color: #f5f5f5;
    ${mediaQueries(SCROLL_BREAK)} {
      min-width: 1220px;
    }
    ${mediaQueries(MOBILE)} {
      min-width: auto;
      position: relative;
      padding-bottom: 96px;
    }
  `,
  Wrapper: styled.div`
    max-width: 1440px;
    margin: 0 auto;
    padding: 60px 80px;
    height: 354px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;

    ${mediaQueries(MOBILE)} {
      height: auto;
      padding: 26px 42px 0;
    }
  `,
  TitleContainer: styled.div`
    display: flex;

    ${mediaQueries(MOBILE)} {
      flex-direction: column;
    }
  `,
  Logo: styled.img`
    width: 103px;
    height: 25px;

    ${mediaQueries(MOBILE)} {
      margin-bottom: 20px;
    }
  `,
  UseTermsList: styled.div`
    margin-left: 70px;
    display: flex;
    justify-content: space-between;
    width: 188px;

    & > a {
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      color: #1f1f1f;
    }

    ${mediaQueries(MOBILE)} {
      margin-left: 0;
      margin-bottom: 26px;
    }
  `,
  SNSContainer: styled.li`
    margin: 0 48px 0 auto;
    width: 104px;
    display: flex;
    justify-content: space-between;

    ${mediaQueries(MOBILE)} {
      position: absolute;
      bottom: 40px;
      margin: 0;
    }
  `,
  SNSAtr: styled.img`
    width: 24px;
    height: 24px;
  `,
  ContentContainer: styled.div`
    ${mediaQueries(MOBILE)} {
      margin-bottom: 16px;
    }
  `,
  ContentTitle: styled.div`
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    margin-bottom: 12px;

    ${mediaQueries(MOBILE)} {
      margin-bottom: 16px;
    }
  `,
  ContentDesc: styled.div`
    font-size: 12px;
    <<<<<<< Updated upstream & > * {
      color: #4b4b4b;
      margin: 4px 0;
      word-break: keep-all;
    }

    & > li {
      display: flex;

      & > ul {
        position: relative;
        margin-right: 10px;
      }

      & > ul::after {
        content: '';
        width: 1px;
        height: 100%;
        background-color: #4b4b4b;
        position: absolute;
        right: -5px;
      }

      & > ul:last-of-type::after {
        display: none;
      }
    }

    ${mediaQueries(MOBILE)} {
      & > * {
        word-break: keep-all;
        margin-bottom: 8px;
        line-height: 1.5em;
      }
    }
  `,
  ContentPhoneNumber: styled.div`
    font-size: 12px;
    line-height: 16px;
    margin: 6px 0;
    word-break: keep-all;
  `,
  Info: styled.div``,
  CopyRightContainer: styled.div`
    font-size: 12px;
    line-height: 17px;
    font-weight: bold;
  `,
};
