import { DefaultTheme } from 'styled-components';

const getTypo = (
  bold: 'thin' | 'light' | 'normal' | 'medium' | 'bold',
  size: number,
  lineHeight = '1.5',
): string => {
  let fontWeight;
  switch (bold) {
    case 'bold':
      fontWeight = '700';
      break;
    case 'medium':
      fontWeight = '500';
      break;
    case 'normal':
      fontWeight = '400';
      break;
    case 'light':
      fontWeight = '300';
      break;
    default:
      fontWeight = '200';
  }
  return `font-weight : ${fontWeight}; font-size : ${size}px; line-height: ${lineHeight}`;
};

const getButton = (
  type: 'primary' | 'ghost' | 'secondary' | 'info' | 'select',
  opacity = 1,
): string => {
  let bgColor;
  let textColor;
  let border;
  switch (type) {
    case 'primary':
      bgColor = '#33CCBD';
      textColor = '#ffffff';
      border = '1px solid #33CCBD';
      break;
    case 'ghost':
      bgColor = 'transparent';
      textColor = '#33CCBD';
      border = '1px solid #33CCBD';
      break;
    case 'secondary':
      bgColor = '#eeeeee';
      textColor = '#4b4b4b';
      border = 'none';
      break;
    case 'info':
      bgColor = '#f5f5f5';
      textColor = '#4b4b4b';
      border = 'none';
      break;
    case 'select':
      bgColor = '#d6f5f2';
      textColor = '#1f7a71';
      border = '2px solid #33CCBD';
      break;
    default:
      bgColor = '#eeeeee';
      textColor = '#4b4b4b';
      border = '1px solid #33CCBD';
  }
  return `background-color : ${bgColor}; color : ${textColor}; border : ${border}; opacity : ${opacity};`;
};

const getInput = (
  type: 'primary' | 'error',
  padding = '0 16px',
  borderRadius = '8px',
): string => {
  let border;
  switch (type) {
    case 'primary':
      border = '1px solid #8e8e8e';
      break;
    case 'error':
      border = '2px solid #FF6262';
      break;
    default:
      border = '1px solid #8e8e8e';
  }
  return `border : ${border} ; border-radius : ${borderRadius} ; padding : ${padding} ;`;
};

const theme: DefaultTheme = {
  common: {
    colors: {
      mainText: '#1f1f1f',
      subText: '#8e8e8e',
      turquoise: '#33ccbd',
      alabaster: '#fafafa',
      wildSand: '#f5f5f5',
      silver: '#cacaca',
      darkSilver: '#c4c4c4',
      mercury: '#e1e1e1',
      darkGray: '#3a3a3c',
      neutralGray: '#969696',
      white: '#FFF',
      black: '#000',
      error: '#ff6262',
    },
  },
  header: {
    mobileHeight: '64px',
    height: '80px',
  },
  input: {
    primary: getInput('primary'),
    error: getInput('error'),
    primaryMobile: getInput('primary', '12px 8px'),
    errorMobile: getInput('error', '12px 8px'),
  },
  button: {
    primary: getButton('primary'),
    primaryDisabled: getButton('primary', 0.3),
    ghost: getButton('ghost'),
    ghostDisabled: getButton('ghost', 0.3),
    secondary: getButton('secondary'),
    secondaryDisabled: getButton('secondary', 0.3),
    info: getButton('info'),
    infoSelect: getButton('select'),
    colors: {
      // gray
      bgGray: '#eeeeee',
      textGray: '#4b4b4b',
      // primary
      borderPrimary: '#33CCBD',
      bgPrimary: '#33CCBD',
      textPrimary: '#ffffff',
      // ghost + Primary
      ghostBorder: '#33CCBD',
      ghostBg: 'transparent',
      ghostText: '#33CCBD',
      // ghost + disabled
      ghostBorderDisabled: '#33CCBD',
      ghostBgDisabled: '#33CCBD',
      ghostTextDisabled: '#ffffff',
      // hover
      hover: '#d6f5f2',
    },
  },
  typo: {
    head52: getTypo('bold', 52),
    head48: getTypo('bold', 48),
    head40: getTypo('bold', 40),
    head32: getTypo('bold', 32),
    head26: getTypo('bold', 26),
    head24: getTypo('bold', 24),
    head20: getTypo('bold', 20),
    head18: getTypo('bold', 18),
    head16: getTypo('bold', 16),
    head14: getTypo('bold', 14),
    head12: getTypo('bold', 12),
    body32R: getTypo('normal', 32),
    body28R: getTypo('normal', 28),
    body24R: getTypo('normal', 24),
    body20R: getTypo('normal', 20),
    body18R: getTypo('normal', 18),
    body16R: getTypo('normal', 16),
    body14R: getTypo('normal', 14),
    body12R: getTypo('normal', 12),
    body10R: getTypo('normal', 10),
    body24L: getTypo('light', 24),
    body14L: getTypo('light', 14),
    body40T: getTypo('thin', 40),
    body20T: getTypo('thin', 20),
  },
  fontWeights: {
    thin: '200',
    light: '300',
    normal: '400',
    medium: '500',
    bold: '700',
  },
  contentWidth: {
    mainMaxWidth: '1440',
  },
};

const customMediaQuery = (maxWidth: number): string =>
  `@media (max-width: ${maxWidth}px)`;

export const media = {
  custom: customMediaQuery,
  pc: customMediaQuery(1440),
  tablet: customMediaQuery(1220),
  mobile: customMediaQuery(720),
};

export default theme;
