import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { RootState } from '../../redux/reducer';
import { setIsDeployment } from '../../redux/modules/deployState';

const useIsDeploy = () => {
  const isDeployment = useSelector<RootState, boolean>(
    (state) => state.deployState.isDeployment,
  );

  const dispath = useDispatch();

  const setIsDeplymentHandler = useCallback(
    (isDeployment: boolean) => {
      dispath(setIsDeployment(isDeployment));
    },
    [dispath],
  );

  return {
    isDeployment,
    setIsDeplymentHandler,
  };
};

export default useIsDeploy;
