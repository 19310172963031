import styled, { css } from 'styled-components';
import { BottomDesc } from 'components/main/Styles';
import Button from 'components/Button';
import ScrollAnimation from 'components/ScrollAnimation';
import {
  mediaQueries,
  MOBILE,
  SCROLL_BREAK,
  TABLET,
} from 'styles/mediaQueries';

export const AdditionalPartnerStyle = {
  BoxContainer: styled.div`
    ${({ theme }) => css`
      display: flex;
      justify-content: center;
      width: 100%;

      ${mediaQueries(TABLET)} {
        font-size: 0.7vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 8.5px;
      }

      ${mediaQueries(MOBILE)} {
        flex-direction: column;
        border: 1px solid ${theme.common.colors.mercury};
        height: 100%;
        border-radius: 16px;
        padding: 50px 0;
      }
    `};
  `,
  BoxWrapper: styled(ScrollAnimation)`
    width: 49%;

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      width: 100%;
    }
  `,
  BoxImgContainer: styled.div`
    ${({ theme }) => css`
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 26.636em;
      border: 1px solid ${theme.common.colors.mercury};
      border-radius: 16px;

      ${mediaQueries(TABLET)} {
        font-size: 0.7vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 8.5px;
      }

      ${mediaQueries(MOBILE)} {
        flex-wrap: wrap;
        font-size: 0.7vw;
        border: none;
        height: 100%;
      }
    `};
  `,
  BoxImg: styled.img`
    height: 8.273em;

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      height: 100%;
    }
  `,
  BrandBoxImg: styled.img<{ key: number }>`
    max-width: 13.182em;
    margin: 0 1.091em;

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      width: 40%;
      max-width: 100%;
      height: 100%;
      margin: 22px 10%;
    }
  `,
  BoxText: styled(BottomDesc)`
    margin-top: 1.455em;
    word-break: keep-all;

    ${mediaQueries(MOBILE)} {
      display: none;
    }
  `,
  Btn: styled(Button)`
    ${({ theme }) => css`
      width: 12.917em;
      height: 3.458em;
      ${theme.typo.head24};
      margin-top: 3.333em;

      ${mediaQueries(TABLET)} {
        font-size: 1.5vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 18.3px;
      }

      ${mediaQueries(MOBILE)} {
        width: 100%;
        max-width: 328px;
        padding: 16px 0;
        ${theme.typo.head14};
        margin-top: 40px;
      }
    `};
  `,
};
