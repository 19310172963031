import React from 'react';
import Layout from 'components/Layout';
import { modelsReward } from 'common/constants/imageUrls';
import styled from 'styled-components';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';
import Footer from 'components/partials/Footer';

const FindModel: React.FunctionComponent = () => {
  return (
    <Layout>
      <FindModelStyle.Container>
        <FindModelStyle.Wrapper>
          <FindModelStyle.BackgroundImage
            src={modelsReward}
            alt=""
            width="100%"
          />
        </FindModelStyle.Wrapper>
      </FindModelStyle.Container>
      <Footer />
    </Layout>
  );
};

export default FindModel;

const FindModelStyle = {
  Container: styled.main`
    width: 100%;
    max-width: 1440px;
    padding: 0 200px;
    margin: 0 auto 100px;

    ${mediaQueries(MOBILE)} {
      padding: 0;
      margin-bottom: 40px;
    }
  `,
  Wrapper: styled.section`
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
  `,
  BackgroundImage: styled.img``,
};
