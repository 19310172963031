import axios, { AxiosRequestConfig } from 'axios';
import { Cookies } from 'react-cookie';
import { getCsrf } from '../utils';
import { BASE_URL } from './apiUrl';
import { store } from '../../redux/store';
import { setIsDeployment } from '../../redux/modules/deployState';

export enum HttpMethod {
  GET = 'get',
  PUT = 'put',
  POST = 'post',
  DELETE = 'delete',
}

interface requestOptions {
  param?: Record<string, any>;
  body?: Record<string, any>;
  header?: Record<string, any>;
  notUsedAccept?: boolean;
}

export const request = (
  method: HttpMethod,
  url: string,
  options: requestOptions = {},
) => {
  const baseUrl = BASE_URL;

  const config: AxiosRequestConfig = {
    method,
    url: `${baseUrl}${url}`,
    withCredentials: true,
  };

  if (process.env.REACT_APP_ENV === 'local') {
    const csrf = getCsrf();
    new Cookies().set('CSRF_TOKEN', csrf, { path: '/' });

    options.header = {
      ...options.header,
      csrf,
    };
  }

  if (options) {
    if (options.header) {
      config.headers = {
        ...config.headers,
        ...options.header,
      };
    }

    if (!options.notUsedAccept) {
      config.headers = {
        ...config.headers,
        Accept: 'application/json',
      };
    }

    if (options.param) {
      config.params = options.param;
    }

    if (options.body) {
      config.data = options.body;
    }
  }

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (error) => {
      const errorCode = error.response.status;

      if (errorCode === 502) {
        store.dispatch(setIsDeployment(true));
      }

      return error;
    },
  );

  return axios.request(config);
};
