import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { HttpMethod, request } from 'common/network';
import { searchPriceDetail } from 'common/constants/urls';
import { FIND_MODEL } from 'common/constants/pathUrls';
import Section from 'components/main/Section';
import PriceAnimationTest from 'components/Animation/PriceAnimationTest';
import { BottomText, BottomTextWrap } from '../Styles';
import { AdditionalCompensationStyle } from './style';
import { ITypes } from '../types';

/* 추가보상금 */
const AdditionalCompensation: FunctionComponent<ITypes> = ({ pathname }) => {
  const [series, setSeries] = useState<string>('SAMSUNG');

  const [modelList, setModelList] = useState<
    { name: string; id: string; type: string }[]
  >([
    { type: 'Q', name: '퀀텀2', id: 'SM-A826' },
    { type: 'Q', name: '퀀텀1', id: 'SM-A716' },
    { type: 'Q', name: 'A80', id: 'SM-A805' },
    { type: 'Jump', name: 'Jump', id: 'SM-A326' },
    { type: 'Jump', name: 'Jean2', id: 'SM-A202' },
    { type: 'Jump', name: 'Jean1', id: 'SM-A605' },
  ]);

  const [model, setModel] = useState<{
    name: string;
    id: string;
    type: string;
  }>({
    name: '퀀텀2',
    id: 'SM-A826',
    type: 'Q',
  });

  const [priceData, setPriceData] = useState<{
    aPrice: number;
    addAmount: number;
    totalPrice: number;
  }>({ aPrice: 0, addAmount: 0, totalPrice: 0 });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isAnimation, setIsAnimation] = useState<boolean>(false);
  const seriesModel: {
    title: string;
    models: { name: string; id: string; type: string }[];
  }[] = [
    {
      title: 'SAMSUNG',
      models: [
        { type: 'Q', name: '퀀텀2', id: 'SM-A826' },
        { type: 'Q', name: '퀀텀1', id: 'SM-A716' },
        { type: 'Q', name: 'A80', id: 'SM-A805' },
        { type: 'Jump', name: 'Jump', id: 'SM-A326' },
        { type: 'Jump', name: 'Jean2', id: 'SM-A202' },
        { type: 'Jump', name: 'Jean1', id: 'SM-A605' },
      ],
    },
    {
      title: 'LG',
      models: [
        { type: 'all', name: '윙', id: 'LM-F100' },
        { type: 'all', name: '벨벳', id: 'LM-G910' },
        { type: 'all', name: 'V50', id: 'LM-V510' },
        { type: 'all', name: 'A91', id: 'LM-Q920' },
        { type: 'all', name: 'Q51', id: 'LM-Q510' },
        { type: 'all', name: 'X4', id: 'LM-X415' },
      ],
    },
  ];

  const callPriceData = async (petname: string) => {
    setIsAnimation(false);
    const res = await request(HttpMethod.POST, searchPriceDetail, {
      body: { petname },
    });
    const data = res.data.dataset;
    setPriceData({
      aPrice: data !== null ? data.price_a : 0,
      addAmount: 20000,
      totalPrice: data !== null ? data.price_a + 20000 : 0,
    });

    setIsAnimation(true);
  };

  useEffect(() => {
    const call = async () => {
      await callPriceData(model.id);
    };
    call().then(() => null);
  }, []);

  const selectValue = async (value: string) => {
    setSeries(value);
    const findModel = seriesModel.find((item) => item.title === value);
    if (findModel) {
      setModelList(findModel.models);
      setModel(findModel.models[0]);
      await callPriceData(findModel.models[0].id);
    }
    setIsTotalShow(false);
  };

  const selectModel = async (value: {
    name: string;
    id: string;
    type: string;
  }) => {
    setModel(value);
    await callPriceData(value.id);
    setIsTotalShow(false);
  };

  // ㅡㅡㅡㅡ 가격 애니메이션 ㅡㅡㅡㅡ //
  const [isTotalShow, setIsTotalShow] = useState<boolean>(false);
  const [isPriceAnimationStart, setIsPriceAnimationStart] =
    useState<boolean>(false);
  const priceRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((v) => {
          if (v.intersectionRatio > 0.5) {
            setIsPriceAnimationStart(true);
          }
        });
      },
      {
        threshold: 0.5,
      },
    );

    if (priceRef.current) {
      observer.observe(priceRef.current);
    }
  }, []);

  useEffect(() => {
    const Timer = setTimeout(() => {
      setIsTotalShow(isPriceAnimationStart);
    }, 2500);
    return () => clearInterval(Timer);
  }, [priceData, isPriceAnimationStart]);
  // ㅡㅡㅡㅡ 가격 애니메이션 ㅡㅡㅡㅡ //

  const getAdditionalTarget = (type: string) => {
    let result = '퀀텀3, 점프2';

    if (type === 'Q') {
      result = '퀀텀3';
    }

    if (type === 'Jump') {
      result = '점프2';
    }

    return result;
  };

  return (
    <Section
      title="사용중인 휴대폰을 선택하고 <br /> 추가 보상금을 확인해보세요"
      sectionLabel="추가 보상금 안내"
      subtitle={
        window.innerWidth <= 720
          ? `용량/시리즈가 달라도, 상태가 A급이 아니어도 <br /> &nbsp;모델별 추가 보상금은 동일하게!`
          : `용량/시리즈가 달라도, 상태가 A급이 아니어도 모델별 추가 보상금은 동일하게!`
      }
    >
      {window.innerWidth < 720 ? (
        <AdditionalCompensationStyle.Container>
          <AdditionalCompensationStyle.PriceContentWrapper>
            <AdditionalCompensationStyle.DetailTitle>
              {model.name}
            </AdditionalCompensationStyle.DetailTitle>
            <AdditionalCompensationStyle.SeriesContainer>
              <AdditionalCompensationStyle.Series
                select={series === 'SAMSUNG'}
                onClick={() => selectValue('SAMSUNG')}
              >
                SAMSUNG
              </AdditionalCompensationStyle.Series>
              <AdditionalCompensationStyle.Series
                select={series === 'LG'}
                onClick={() => selectValue('LG')}
              >
                LG
              </AdditionalCompensationStyle.Series>
            </AdditionalCompensationStyle.SeriesContainer>
            <AdditionalCompensationStyle.DetailBtnContainer>
              {modelList.map((item, i) => (
                <AdditionalCompensationStyle.DetailBtn
                  onClick={() => selectModel(item)}
                  key={i}
                  type={model.name === item.name ? 'infoSelect' : 'info'}
                >
                  {item.name}
                </AdditionalCompensationStyle.DetailBtn>
              ))}
            </AdditionalCompensationStyle.DetailBtnContainer>
            <AdditionalCompensationStyle.Img
              isAnimation
              src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/images/models/${model.id}.png`}
              alt=""
            />
            <AdditionalCompensationStyle.AdditionalTargetContainer>
              <span />
              <p>{getAdditionalTarget(model.type)} 개통 고객 대상</p>
            </AdditionalCompensationStyle.AdditionalTargetContainer>
            <AdditionalCompensationStyle.PaymentContainer>
              <AdditionalCompensationStyle.PaymentWrapper alwaysShown>
                <AdditionalCompensationStyle.PaymentLabel design="normal">
                  민팃 보상금 (A급 기준)
                </AdditionalCompensationStyle.PaymentLabel>
                <AdditionalCompensationStyle.Payment
                  design="normal"
                  alwaysShown
                >
                  <PriceAnimationTest
                    price={priceData.aPrice}
                    width="min(0.85vw, 14px)"
                    mobileWidth="8px"
                    delay={0}
                    key={model.id}
                    propsAnimationStart={isPriceAnimationStart}
                  />{' '}
                  원
                </AdditionalCompensationStyle.Payment>
              </AdditionalCompensationStyle.PaymentWrapper>
              <AdditionalCompensationStyle.PaymentWrapper
                alwaysShown
                ref={priceRef}
              >
                <AdditionalCompensationStyle.PaymentLabel design="plus">
                  추가 보상금
                </AdditionalCompensationStyle.PaymentLabel>
                <AdditionalCompensationStyle.Payment design="plus" alwaysShown>
                  +{' '}
                  <PriceAnimationTest
                    price={priceData.addAmount}
                    width="min(0.85vw, 14px)"
                    mobileWidth="8px"
                    delay={0}
                    key={model.id}
                    propsAnimationStart={isPriceAnimationStart}
                  />{' '}
                  원
                </AdditionalCompensationStyle.Payment>
              </AdditionalCompensationStyle.PaymentWrapper>
              <AdditionalCompensationStyle.PaymentSplit />
              <AdditionalCompensationStyle.PaymentWrapper>
                <AdditionalCompensationStyle.PaymentLabel design="total">
                  합계
                </AdditionalCompensationStyle.PaymentLabel>
                <AdditionalCompensationStyle.Payment
                  design="total"
                  isTotalShow={isPriceAnimationStart && isTotalShow}
                >
                  {priceData.totalPrice.toLocaleString('ko-KR')}원
                </AdditionalCompensationStyle.Payment>
              </AdditionalCompensationStyle.PaymentWrapper>
            </AdditionalCompensationStyle.PaymentContainer>
          </AdditionalCompensationStyle.PriceContentWrapper>
        </AdditionalCompensationStyle.Container>
      ) : (
        <AdditionalCompensationStyle.Container>
          <AdditionalCompensationStyle.Img
            isAnimation
            src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/images/models/${model.id}.png`}
            alt=""
          />
          <AdditionalCompensationStyle.PriceContentWrapper>
            <AdditionalCompensationStyle.DetailTitle>
              {model.name}
            </AdditionalCompensationStyle.DetailTitle>
            <AdditionalCompensationStyle.SeriesContainer>
              <AdditionalCompensationStyle.Series
                select={series === 'SAMSUNG'}
                onClick={() => selectValue('SAMSUNG')}
              >
                SAMSUNG
              </AdditionalCompensationStyle.Series>
              <AdditionalCompensationStyle.Series
                select={series === 'LG'}
                onClick={() => selectValue('LG')}
              >
                LG
              </AdditionalCompensationStyle.Series>
            </AdditionalCompensationStyle.SeriesContainer>
            <AdditionalCompensationStyle.DetailBtnContainer>
              {modelList.map((item, i) => (
                <AdditionalCompensationStyle.DetailBtn
                  onClick={() => selectModel(item)}
                  key={i}
                  type={model.name === item.name ? 'infoSelect' : 'info'}
                >
                  {item.name}
                </AdditionalCompensationStyle.DetailBtn>
              ))}
            </AdditionalCompensationStyle.DetailBtnContainer>
            <AdditionalCompensationStyle.AdditionalTargetContainer>
              <span />
              <p>{getAdditionalTarget(model.type)} 개통 고객 대상</p>
            </AdditionalCompensationStyle.AdditionalTargetContainer>
            <AdditionalCompensationStyle.PaymentContainer>
              <AdditionalCompensationStyle.PaymentWrapper alwaysShown>
                <AdditionalCompensationStyle.PaymentLabel design="normal">
                  민팃 보상금 (A급 기준)
                </AdditionalCompensationStyle.PaymentLabel>
                <AdditionalCompensationStyle.Payment
                  design="normal"
                  alwaysShown
                >
                  <PriceAnimationTest
                    price={priceData.aPrice}
                    width="min(0.85vw, 14px)"
                    mobileWidth="8px"
                    delay={0}
                    key={model.id}
                    propsAnimationStart={isPriceAnimationStart}
                  />{' '}
                  원
                </AdditionalCompensationStyle.Payment>
              </AdditionalCompensationStyle.PaymentWrapper>
              <AdditionalCompensationStyle.PaymentWrapper
                alwaysShown
                ref={priceRef}
              >
                <AdditionalCompensationStyle.PaymentLabel design="plus">
                  추가 보상금
                </AdditionalCompensationStyle.PaymentLabel>
                <AdditionalCompensationStyle.Payment design="plus" alwaysShown>
                  +{' '}
                  <PriceAnimationTest
                    price={priceData.addAmount}
                    width="min(0.85vw, 14px)"
                    mobileWidth="8px"
                    delay={0}
                    key={model.id}
                    propsAnimationStart={isPriceAnimationStart}
                  />{' '}
                  원
                </AdditionalCompensationStyle.Payment>
              </AdditionalCompensationStyle.PaymentWrapper>
              <AdditionalCompensationStyle.PaymentSplit />
              <AdditionalCompensationStyle.PaymentWrapper>
                <AdditionalCompensationStyle.PaymentLabel design="total">
                  합계
                </AdditionalCompensationStyle.PaymentLabel>
                <AdditionalCompensationStyle.Payment
                  design="total"
                  isTotalShow={isPriceAnimationStart && isTotalShow}
                >
                  {priceData.totalPrice.toLocaleString('ko-KR')}원
                </AdditionalCompensationStyle.Payment>
              </AdditionalCompensationStyle.PaymentWrapper>
            </AdditionalCompensationStyle.PaymentContainer>
          </AdditionalCompensationStyle.PriceContentWrapper>
        </AdditionalCompensationStyle.Container>
      )}
      <AdditionalCompensationStyle.BtnContainer>
        <AdditionalCompensationStyle.ModelBtn
          to={pathname === '/' ? FIND_MODEL : undefined}
          href={
            pathname === '/'
              ? undefined
              : 'https://event.mintit.co.kr/findModel'
          }
          type={pathname === '/' ? 'ghost' : 'primary'}
        >
          전체 모델 확인하기
        </AdditionalCompensationStyle.ModelBtn>
        {pathname === '/' && (
          <AdditionalCompensationStyle.RewardBtn
            onClick={async () => {
              const result = await request(
                HttpMethod.GET,
                '/rest/api/component/getNicePassEncData',
              );
              if (result.data !== 'ERROR') {
                const encodeDataEl = document.getElementById(
                  'EncodeData',
                ) as HTMLInputElement;
                const formChkEl = document.getElementsByName(
                  'form_chk',
                )[0] as HTMLFormElement;
                if (encodeDataEl && formChkEl) {
                  encodeDataEl.value = result.data;
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  window.niceRes = async () => {
                    const res = await request(
                      HttpMethod.GET,
                      '/rest/api/component/getNicePassDecData',
                    );

                    console.log(res);
                  };
                  window.open(
                    '',
                    'popupChk',
                    'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no',
                  );
                  formChkEl.action =
                    'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb';
                  formChkEl.target = 'popupChk';
                  formChkEl.submit();
                }
              }
            }}
          >
            내 추가 보상금 확인하기
          </AdditionalCompensationStyle.RewardBtn>
        )}
      </AdditionalCompensationStyle.BtnContainer>
      <BottomTextWrap>
        <BottomText>
          <span />
          상기 예시는 A급 기준이며 휴대폰 반납시기 및
          <br className="mobile-only" />
          &nbsp;상태에 따라 금액은 달라질 수 있습니다.
        </BottomText>
      </BottomTextWrap>
      <div style={{ display: 'none' }}>
        <form name="form_chk" method="post">
          <input type="hidden" name="m" value="checkplusService" />
          <input type="hidden" id="EncodeData" name="EncodeData" value="" />
          <input type="hidden" name="recvMethodType" value="get" />
        </form>
      </div>
    </Section>
  );
};

export default AdditionalCompensation;
