import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import usePhoneDetail from 'hooks/usePhoneDetail';
import { useManufacturer } from 'hooks/usePriceInquiry';
import Layout from 'components/Layout';
import Modal from 'components/Modal';
import EtcManufacturerModal from 'components/Modal/EtcManufacturerModal';
import PriceInquiryLayout from 'components/PriceInquiry/PriceInquiryLayout';
import { ManufacturerType } from 'components/PriceInquiry/@types';
import RadioButton from 'components/Button/RadioButton';
import { modelToImg } from 'pages/PriceInquiry/SelectManufacturer/utils';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';

const PriceInquiryPage: React.FunctionComponent = () => {
  // Modal 오픈 상태 및 이벤트
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const navigate = useNavigate();

  // redux 상태 hook
  const {
    manufacName,
    setManufacUrl,
    setManufacHandler,
    clearPhoneInfoHandler,
  } = usePhoneDetail();

  // 제조사 fetch hook
  const { ManufacturerData } = useManufacturer();

  // 시리즈 페이지로 이동
  const handleSelectSeries = (manufacIdx: string) => {
    // 제조사 idx 저장
    setManufacUrl(manufacIdx);
    const atr = ManufacturerData?.find((idx) => idx.model === manufacIdx);
    if (atr) {
      setManufacHandler(atr.model_name);
      navigate(`/priceInquiry/${manufacIdx}`);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    clearPhoneInfoHandler();
  }, [window]);

  useLayoutEffect(() => {
    window.location.href = 'https://www.mintit.co.kr/galaxys23_event';
  }, []);

  return (
    <Layout>
      <Modal
        visible={isModalOpen}
        onClose={handleModalClose}
        width="620px"
        mobileWidth="min(90vw, 310px)"
        maxHeight="680px"
        mobileHeight="456px"
      >
        {ManufacturerData && (
          <EtcManufacturerModal
            onClose={handleModalClose}
            ManufacturerList={ManufacturerData.slice(3)}
          />
        )}
      </Modal>
      <PriceInquiryLayout currentSection={1}>
        <Manufacturer.Container>
          {ManufacturerData?.slice(0, 3).map((data: ManufacturerType) => (
            <Manufacturer.BoxWrapper key={data.model}>
              <RadioButton
                text={data.model_name}
                img={modelToImg(data.model_name)}
                size="lg"
                onClick={() => handleSelectSeries(data.model)}
                active={manufacName === data.model_name}
              />
            </Manufacturer.BoxWrapper>
          ))}
          <RadioButton
            text="기타"
            img={modelToImg('')}
            size="lg"
            onClick={handleModalOpen}
          />
        </Manufacturer.Container>
      </PriceInquiryLayout>
    </Layout>
  );
};

export default PriceInquiryPage;

const Manufacturer = {
  Container: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    width: auto;
    gap: 24px;
    margin-bottom: 160px;
    ${mediaQueries(MOBILE)} {
      gap: 1vw;
      margin: 0 auto;
    }
  `,
  BoxWrapper: styled.div``,
};
