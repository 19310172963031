import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';
import { flexCenterCss } from 'styles/commonStyle';
import theme from 'styles/theme';
import {
  fontSizeHandler,
  sizeHandler,
  maxSizeHandler,
  mobileFontSizeHandler,
  mobileSizeHandler,
} from './utils';

interface RadioButtonProps {
  // image url
  img?: string | undefined;
  // 텍스트
  text: string;
  // 사이즈
  size: 'sm' | 'md' | 'lg';
  // hover
  onClick?: MouseEventHandler<HTMLButtonElement>;
  active?: boolean;
}

const RadioButton: React.FunctionComponent<RadioButtonProps> = ({
  img,
  text,
  size,
  onClick,
  active,
}) => {
  const renderContent = (img: string | undefined) =>
    img ? (
      <RadioButtonStyle.ImgButtonContainer>
        <RadioButtonStyle.ImgLogo src={img} />
        <RadioButtonStyle.ImgTitle size={size}>
          {text}
        </RadioButtonStyle.ImgTitle>
      </RadioButtonStyle.ImgButtonContainer>
    ) : (
      <RadioButtonStyle.Title size={size}>{text}</RadioButtonStyle.Title>
    );

  return (
    <RadioButtonStyle.Wrapper size={size} onClick={onClick} active={active}>
      {renderContent(img)}
    </RadioButtonStyle.Wrapper>
  );
};

export default RadioButton;

const RadioButtonStyle = {
  Wrapper: styled.button<{ size: 'lg' | 'md' | 'sm'; active?: boolean }>`
    width: ${({ size }) => sizeHandler(size)};
    height: ${({ size }) => sizeHandler(size)};
    max-width: ${({ size }) => maxSizeHandler(size)};
    max-height: ${({ size }) => maxSizeHandler(size)};
    ${flexCenterCss};
    border-radius: 24px;
    transition: all 0.1s;
    padding: 10px;
    border: ${({ active }) =>
      active
        ? `1px solid ${theme.common.colors.turquoise}`
        : `1px solid ${theme.common.colors.mercury}`};
    background-color: ${({ active }) =>
      active ? `${theme.button.colors.hover}` : `${theme.common.colors.white}`};
    color: ${({ active }) =>
      active
        ? `${theme.button.colors.bgPrimary}`
        : `${theme.common.colors.darkGray}`};
    &:hover {
      background-color: ${theme.button.colors.hover};
      color: ${theme.common.colors.turquoise};
      border: 1px solid ${theme.common.colors.turquoise};
    }
    ${mediaQueries(MOBILE)} {
      width: ${({ size }) => mobileSizeHandler(size)};
      height: ${({ size }) => mobileSizeHandler(size)};
      padding: 0;
    }
  `,
  ImgButtonContainer: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    ${flexCenterCss};
  `,
  ImgLogo: styled.img`
    display: block;
    position: absolute;
    width: 90%;
    top: 40%;
    transform: translateY(-50%);
    ${mediaQueries(MOBILE)} {
      width: 90%;
      margin-bottom: 0;
    }
  `,
  ImgTitle: styled.div<{ size: 'lg' | 'md' | 'sm' }>`
    position: absolute;
    bottom: 20%;
    font-size: ${({ size }) => fontSizeHandler(size)};
    ${mediaQueries(MOBILE)} {
      bottom: 20%;
      font-size: ${({ size }) => mobileFontSizeHandler(size)};
    }
  `,
  Title: styled.div<{ size: 'lg' | 'md' | 'sm' }>`
    word-break: keep-all;
    line-height: 26px;
    width: 130px;
    padding: 12px;
    font-size: ${({ size }) => fontSizeHandler(size)};
    ${mediaQueries(MOBILE)} {
      font-size: ${({ size }) => mobileFontSizeHandler(size)};
      line-height: min(26px, 5vw);
      padding: 8px;
      width: auto;
    }
  `,
};
