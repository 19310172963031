import React from 'react';
import styled from 'styled-components';
import {
  atmGuideImages,
  closedIcon,
} from '../../../common/constants/imageUrls';
import Modal from '../index';
import { mediaQueries, MOBILE } from '../../../styles/mediaQueries';

interface ConnectQrModalProps {
  visible: boolean;
  onClose: () => void;
}

const ConnectQrModal = ({ visible, onClose }: ConnectQrModalProps) => {
  return (
    <ConnectQrModalStyle.QrModal visible={visible} onClose={onClose}>
      <ConnectQrModalStyle.QrModalCloseButton onClick={onClose}>
        닫기
      </ConnectQrModalStyle.QrModalCloseButton>
      <ConnectQrModalStyle.QrModalTitle>
        판매하실 휴대폰에 <br />
        QR코드 또는 URL을 통해 <br />
        민팃 커넥터를 설치해주세요
      </ConnectQrModalStyle.QrModalTitle>
      <ConnectQrModalStyle.QrContent>
        <p>app.mintit.co.kr</p>
        <ConnectQrModalStyle.QrImageWrapper>
          <img
            src={atmGuideImages.appQrCodeImage}
            alt={'"app.mintit.co.kr" QR 코드'}
          />
        </ConnectQrModalStyle.QrImageWrapper>
      </ConnectQrModalStyle.QrContent>
    </ConnectQrModalStyle.QrModal>
  );
};

const ConnectQrModalStyle = {
  QrModal: styled(Modal)`
    display: flex;
    min-width: 499px;
    flex-direction: column;
    align-items: center;
    padding: 45px 100px 40px;
    text-align: center;

    ${mediaQueries(MOBILE)} {
      min-width: auto;
      width: 90%;
      padding: 45px 30px 40px;
    }
  `,
  QrModalTitle: styled.div`
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 24px;
    text-align: center;
    line-height: 1.4em;

    ${mediaQueries(MOBILE)} {
      font-size: 20px;
    }

    @media screen and (max-width: 440px) {
      font-size: 5vw;
    }
  `,
  QrContent: styled.div`
    p {
      position: relative;
      display: flex;
      justify-content: center;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 25px;

      &::after {
        content: '';
        position: absolute;
        bottom: -30%;
        display: flex;
        width: 90%;
        height: 70%;
        z-index: -1;
        background: rgba(51, 204, 189, 0.4);
      }
    }
  `,
  QrImageWrapper: styled.div`
    width: 150px;

    img {
      width: 80%;
    }
  `,
  QrModalCloseButton: styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    background: url(${closedIcon}) no-repeat center center;
    background-size: contain;
  `,
};

export default ConnectQrModal;
