// 노트북, 태블릿 시세조회 제조사 코드(모델명 모름)
export const LAPTOP_CODE = Number(process.env.REACT_APP_LAPTOP_CODE);
export const TABLET_CODE = Number(process.env.REACT_APP_TABLET_CODE);
export const WATCH_CODE = Number(process.env.REACT_APP_WATCH_CODE);
export const BUDS_CODE = Number(process.env.REACT_APP_BUDS_CODE);
export const NONAME_CODE = Number(process.env.REACT_APP_NONAME_CODE);
export const NONAME_CODE_DETAIL = Number(
  process.env.REACT_APP_NONAME_CODE_DETAIL,
);
export const SAMSUNG_CODE_LAPTOP = Number(
  process.env.REACT_APP_SAMSUNG_CODE_LAPTOP,
);
export const APPLE_CODE_LAPTOP = Number(
  process.env.REACT_APP_APPLE_CODE_LAPTOP,
);
export const LG_CODE_LAPTOP = Number(process.env.REACT_APP_LG_CODE_LAPTOP);
export const HP_CODE_LAPTOP = Number(process.env.REACT_APP_HP_CODE_LAPTOP);
export const MS_CODE_LAPTOP = Number(process.env.REACT_APP_MS_CODE_LAPTOP);
export const DELL_CODE_LAPTOP = Number(process.env.REACT_APP_DELL_CODE_LAPTOP);
export const LENOVO_CODE_LAPTOP = Number(
  process.env.REACT_APP_LENOVO_CODE_LAPTOP,
);
export const SAMSUNG_CODE_TABLET = Number(
  process.env.REACT_APP_SAMSUNG_CODE_TABLET,
);
export const APPLE_CODE_TABLET = Number(
  process.env.REACT_APP_APPLE_CODE_TABLET,
);
export const SAMSUNG_CODE_WATCH = Number(
  process.env.REACT_APP_SAMSUNG_CODE_WATCH,
);
export const APPLE_CODE_WATCH = Number(process.env.REACT_APP_APPLE_CODE_WATCH);
export const SAMSUNG_CODE_BUDS = Number(
  process.env.REACT_APP_SAMSUNG_CODE_BUDS,
);
