// IOS
export const IOSGradeData = {
  A: [
    {
      id: 'd1',
      name: '액정',
      desc: '미세한 기스',
      isQuestion: true,
      detail: '액정에 미세한 기스가 있는 경우',
    },
    {
      id: 'd2',
      name: '뒷판 (측면)',
      desc: '미세한 기스',
      isQuestion: true,
      detail: '측면에 미세한 기스가 있는 경우',
    },
    { id: 'd3', name: '잔상', desc: '정상' },
    { id: 'd4', name: '지문/FACE ID', desc: '정상' },
    { id: 'd5', name: '나침반', desc: '정상' },
    { id: 'd6', name: 'LCD', desc: '정상' },
    { id: 'd7', name: '음성 녹음', desc: '정상' },
    { id: 'd8', name: '카메라', desc: '정상' },
    { id: 'd9', name: '와이파이', desc: '정상' },
    { id: 'd10', name: '전원', desc: '정상' },
  ],
  B: [
    {
      id: 'd1',
      name: '액정',
      desc: '흠집',
      isQuestion: true,
      detail: '액정에 기스가 있는 경우',
    },
    {
      id: 'd2',
      name: '뒷판 (측면)',
      desc: '흠집,찍힘',
      isQuestion: true,
      detail: '뒷판(측면)에 기스나 찍힘이 있는 경우',
    },
    {
      id: 'd3',
      name: '잔상',
      desc: '약잔상-중잔상',
      isQuestion: true,
      detail: '약잔상-중잔상이 있는 경우',
    },
    {
      id: 'd4',
      name: '지문/FACE ID',
      desc: '정상',
    },
    { id: 'd5', name: '나침반', desc: '정상' },
    { id: 'd6', name: 'LCD', desc: '정상' },
    { id: 'd7', name: '음성 녹음', desc: '정상' },
    { id: 'd8', name: '카메라', desc: '정상' },
    { id: 'd9', name: '와이파이', desc: '정상' },
    { id: 'd10', name: '전원', desc: '정상' },
  ],
  C: [
    {
      id: 'd1',
      name: '액정',
      desc: '액정 파손',
      isQuestion: true,
      detail: '액정이 파손된 경우',
    },
    {
      id: 'd2',
      name: '뒷판 (측면)',
      desc: '파손',
      isQuestion: true,
      detail: '뒷판이 파손된 경우',
    },
    {
      id: 'd3',
      name: '잔상',
      desc: '강잔상',
      isQuestion: true,
      detail: '강잔상이 있는 경우',
    },
    { id: 'd4', name: '지문/FACE ID', desc: '홈버튼 오류' },
    { id: 'd5', name: '나침반', desc: '나침반 장애(GPS)' },
    { id: 'd6', name: 'LCD', desc: '백화 1-2개' },
    { id: 'd7', name: '음성 녹음', desc: '녹음 어플 장애' },
    { id: 'd8', name: '카메라', desc: '카메라 불량' },
    { id: 'd9', name: '와이파이', desc: '인식 불가' },
    { id: 'd10', name: '전원', desc: '정상' },
  ],
  D: [
    {
      id: 'd1',
      name: '액정',
      desc: '액정 파손',
      isQuestion: true,
      detail: '심하게 액정이 파손된 경우',
    },
    {
      id: 'd2',
      name: '뒷판 (측면)',
      desc: '베젤 휨',
      isQuestion: true,
      detail: '베젤이 휜 경우',
    },
    {
      id: 'd3',
      name: '잔상',
      desc: 'LCD급잔상 ~ LCD파손',
      isQuestion: true,
      detail: 'LCD급잔상 ~ LCD파손된 경우',
    },
    { id: 'd4', name: '지문/FACE ID', desc: '인식 불가' },
    { id: 'd5', name: '나침반', desc: '나침반 장애(GPS)' },
    { id: 'd6', name: 'LCD', desc: '백화 3개 이상' },
    { id: 'd7', name: '음성 녹음', desc: '-' },
    { id: 'd8', name: '카메라', desc: '-' },
    { id: 'd9', name: '와이파이', desc: '인식 불가' },
    { id: 'd10', name: '전원', desc: '정상' },
  ],
};

// Android
export const AndroidGradeData = {
  A: [
    {
      id: 'd1',
      name: '액정',
      desc: '미세한 흠집',
      isQuestion: true,
      detail: '액정에 미세한 기스가 있는 경우',
    },
    {
      id: 'd2',
      name: '뒷판 (측면)',
      desc: '미세한 흠집',
      isQuestion: true,
      detail: '측면에 미세한 기스가 있는 경우',
    },
    { id: 'd3', name: '잔상', desc: '정상' },
    { id: 'd4', name: 'LCD', desc: '정상' },
    { id: 'd5', name: '전원', desc: '정상' },
    { id: 'd6', name: '옵션', desc: '정상' },
  ],
  B: [
    {
      id: 'd1',
      name: '액정',
      desc: '흠집',
      isQuestion: true,
      detail: '액정에 기스가 있는 경우',
    },
    {
      id: 'd2',
      name: '뒷판 (측면)',
      desc: '흠집,찍힘',
      isQuestion: true,
      detail: '뒷판(측면)에 기스나 찍힘이 있는 경우',
    },
    {
      id: 'd3',
      name: '잔상',
      desc: '약잔상-중잔상',
      isQuestion: true,
      detail: '약잔상-중잔상이 있는 경우',
    },
    { id: 'd4', name: 'LCD', desc: '정상' },
    { id: 'd5', name: '전원', desc: '정상' },
    { id: 'd6', name: '옵션', desc: '노트펜 분실' },
  ],
  C: [
    {
      id: 'd1',
      name: '액정',
      desc: '액정 파손/홈버튼 까짐',
      isQuestion: true,
      detail: '액정이 파손된 경우',
    },
    {
      id: 'd2',
      name: '뒷판 (측면)',
      desc: '파손',
      isQuestion: true,
      detail: '뒷판이 파손된 경우',
    },
    {
      id: 'd3',
      name: '잔상',
      desc: '강잔상 ~ 백화',
      isQuestion: true,
      detail: '강잔상이 있는 경우',
    },
    { id: 'd4', name: 'LCD', desc: 'LCD홍조' },
    { id: 'd5', name: '전원', desc: '정상' },
    { id: 'd6', name: '옵션', desc: '-' },
  ],
  D: [
    {
      id: 'd1',
      name: '액정',
      desc: 'LCD불량',
      isQuestion: true,
      detail: '심하게 액정이 파손된 경우',
    },
    {
      id: 'd2',
      name: '뒷판 (측면)',
      desc: '베젤 휨',
      isQuestion: true,
      detail: '베젤이 휜 경우',
    },
    {
      id: 'd3',
      name: '잔상',
      desc: 'LCD급잔상 ~ LCD파손',
      isQuestion: true,
      detail: 'LCD급잔상 ~ LCD파손된 경우',
    },
    { id: 'd4', name: 'LCD', desc: 'LCD홍조,흑점' },
    { id: 'd5', name: '전원', desc: '정상' },
    { id: 'd6', name: '옵션', desc: '-' },
  ],
};
