import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { PriceInquiry } from 'redux/@types';

const prefix = 'skn-mintit-web';
const M_URL = `${prefix}/M_URL` as const;
const SETMANUFAC = `${prefix}/SETMANUFACIDX` as const;
const SETSERIESIDX = `${prefix}/SETSERIESIDX` as const;
const SETMODELIDX = `${prefix}/SETMODELIDX` as const;
const CLEARPHONEINFO = `${prefix}/CLEARPHONEINFO` as const;

const initialState = {
  manufacturer_url: '',
  manufacturer: '',
  generation: '',
  petname: '',
};

export const setManufacturer = createAction(SETMANUFAC, (atr: string) => atr)();
export const setManufacturerUrl = createAction(M_URL, (atr: string) => atr)();
export const setSeries = createAction(SETSERIESIDX, (atr: string) => atr)();
export const setModel = createAction(SETMODELIDX, (atr: string) => atr)();
export const clearPhoneInfo = createAction(CLEARPHONEINFO)();

const setInfo = {
  setManufacturer,
  setManufacturerUrl,
  setSeries,
  setModel,
  clearPhoneInfo,
};
export type setAction = ActionType<typeof setInfo>;

const reducer = createReducer<PriceInquiry, setAction>(initialState, {
  [M_URL]: (state, { payload }) => ({
    ...state,
    manufacturer_url: payload,
  }),
  [SETMANUFAC]: (state, { payload }) => ({
    ...state,
    manufacturer: payload,
  }),
  [SETSERIESIDX]: (state, { payload }) => ({
    ...state,
    generation: payload,
  }),
  [SETMODELIDX]: (state, { payload }) => ({
    ...state,
    petname: payload,
  }),
  [CLEARPHONEINFO]: (state) => ({
    ...state,
    manufacturer_url: '',
    manufacturer: '',
    generation: '',
    petname: '',
  }),
});

export default reducer;
