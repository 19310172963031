import React from 'react';
import styled from 'styled-components';
import { flexCenterCss } from 'styles/commonStyle';

const Loading = () => {
  return (
    <LoadingStyle>
      <div className="rotate">
        <div className="circle" />
      </div>
    </LoadingStyle>
  );
};

const LoadingStyle = styled.div`
  ${flexCenterCss};
  position: fixed;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  background: rgba(140, 140, 140, 0.45);

  .rotate {
    color: #fff;
    text-align: center;
    position: relative;
  }

  .circle {
    width: 70px;
    height: 70px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    animation: spin 1s infinite;
  }

  @keyframes spin {
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

export default Loading;
