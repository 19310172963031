import styled, { css } from 'styled-components';
import { mediaQueries, MOBILE } from '../../styles/mediaQueries';

export const Header = styled.header`
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background: #fff;
`;
export const HeaderBox = styled.div<{ isFull: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  padding: 24px 80px;
  margin: 0 auto;

  ${({ isFull }) =>
    isFull &&
    css`
      max-width: initial;
    `}

  ${mediaQueries(MOBILE)} {
    padding: 22px 20px;
  }
`;
export const LogoBox = styled.div`
  cursor: pointer;
  width: 132px;

  ${mediaQueries(MOBILE)} {
    width: 70px;
  }
`;
export const LinkBox = styled.div``;
export const Link = styled.a`
  ${({ theme }) => css`
    cursor: pointer;
    color: ${theme.button.colors.textGray};
    background-color: ${theme.common.colors.wildSand};
    ${theme.typo.body12R};
    border-radius: 7px;
    padding: 10px 17px;

    &:hover {
      color: #444444;
      background-color: ${theme.button.colors.bgGray};
    }
  `}
`;
