import React, { forwardRef, FunctionComponent, Ref } from 'react';
import styled from 'styled-components';

type KakaoMapProps = {
  ref: Ref<HTMLDivElement>;
};

// eslint-disable-next-line react/display-name
const KakaoMap: FunctionComponent<KakaoMapProps> = forwardRef((props, ref) => {
  return (
    <KakaoMapStyle.Wrapper>
      <div ref={ref} style={{ width: '100%', height: '100%' }} />
    </KakaoMapStyle.Wrapper>
  );
});

const KakaoMapStyle = {
  Wrapper: styled.div`
    flex: 1;

    .event {
      background-color: #ffffff;
      position: absolute;
      left: 0;
      bottom: 40px;
      width: 314px;
      height: 350px;
      padding: 24px;
      margin-left: -144px;
      border-radius: 12px;

      &_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-title {
          max-width: 80%;
          font-size: 18px;
          font-weight: 700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-fix {
          width: max-content;
          background-color: #ffccd2;
          color: #f64c4c;
          padding: 4px 8px;
          border-radius: 100px;
          font-size: 12px;
        }

        &-sub {
          display: flex;
          font-size: 14px;
          line-height: 18px;
          margin: 14px 0;
          color: #969696;

          &-distance {
            display: flex;
            align-items: center;

            :after {
              content: '';
              display: inline-block;
              width: 1px;
              height: 12px;
              background-color: #969696;
              margin: 0 8px;
            }
          }
        }
      }

      &_info {
        &-title {
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 8px;
          margin-top: 16px;
        }

        &-info {
          overflow: hidden;
          width: 100%;
          display: flex;
          font-size: 14px;
          line-height: 18px;

          &-main {
            width: 240px;
            word-break: keep-all;
            color: #3a3a3c;
            margin-bottom: 4px;
            white-space: normal;
            word-break: keep-all;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          &-sub {
            color: #969696;
          }
        }
      }

      &_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 42px;
        background-color: #33ccbd;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        text-decoration: none;
        margin-top: 20px;
      }

      &_img {
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-right: 9px;
        margin-top: 2px;
      }

      @media all and (max-width: 720px) {
        display: none;
      }
    }
  `,
};

export default KakaoMap;
