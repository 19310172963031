export const TABLET = 'tablet';
export const SCROLL_BREAK = 'scrollBreak';
export const MOBILE = 'mobile';

// media query

export const breakpoints = {
  tablet: 1440,
  scrollBreak: 1220,
  mobile: 720,
};

export const mediaQueries = (key: keyof typeof breakpoints) => {
  return `@media(max-width: ${breakpoints[key]}px)`;
};
