import { FunctionComponent, useState } from 'react';
import { PriceInquiryLaptopModalLayout } from './styles';

export interface PriceInquiryLaptopModalProps {
  visible: boolean;
  onClose: () => void;
  datas: {
    name: string;
    description: string;
    content1: { name: string; text: string };
    content2: { name: string; text: string };
    content3: { name: string; text: string };
  }[];
}

const PriceInquiryGradeModal: FunctionComponent<
  PriceInquiryLaptopModalProps
> = ({ onClose, visible, datas }) => {
  const [selectedItem, setSelectedItem] = useState(datas[0].name);

  return (
    <PriceInquiryLaptopModalLayout visible={visible} onClose={onClose}>
      <header className="header">
        <h1 className="header__title">등급기준</h1>
        <button type="button" onClick={onClose} className="header__close">
          닫기
        </button>
      </header>
      <div className="body">
        <nav className="nav">
          <ul className="nav__list">
            {datas.map((item: any, idx) => (
              <li
                key={idx}
                className={`nav__item ${
                  item.name === selectedItem ? ' nav__item--active' : ''
                }`}
              >
                <button
                  onClick={() => setSelectedItem(item.name)}
                  type="button"
                >
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        {datas
          .filter((item: any) => item.name === selectedItem)
          .map((item: any, idx) => {
            return (
              <div key={idx}>
                {item.description !== '' ? (
                  <div className="body__description">
                    <p>{item.description}</p>
                  </div>
                ) : null}
                <ul className="body__list">
                  <li className="body__item">
                    <p>{item.content1.name}</p>
                    <span>{item.content1.text}</span>
                  </li>
                  <li className="body__item">
                    <p>{item.content2.name}</p>
                    <span>{item.content2.text}</span>
                  </li>
                  <li className="body__item">
                    <p>{item.content3.name}</p>
                    <span>{item.content3.text}</span>
                  </li>
                </ul>
              </div>
            );
          })}
      </div>
    </PriceInquiryLaptopModalLayout>
  );
};

export default PriceInquiryGradeModal;
