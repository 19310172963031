import React, { FunctionComponent } from 'react';
import { MainPageStyle } from 'pages/Home/style';
import { ATM_GUIDE } from 'common/constants/pathUrls';
import { mainIcon } from 'common/constants/imageUrls';
import { ReadyCaution } from 'common/constants/main';
import { SectionContainer } from '../Styles';
import { ITypes } from '../types';
import ScrollAnimation from '../../ScrollAnimation';

const BeforeVisit: FunctionComponent<ITypes> = ({ pathname }) => {
  return (
    <SectionContainer>
      <ScrollAnimation>
        <article>
          <MainPageStyle.HowToAdditionalBanner>
            <MainPageStyle.HowToAdditionalBannerTitle>
              민팃 ATM 방문 전<br />
              <span>필수 준비사항</span>을 알아볼까요?
            </MainPageStyle.HowToAdditionalBannerTitle>
            <MainPageStyle.HowToAdditionalBannerDesc>
              방문 전 필수사항을 미리 준비하시면
              <br />
              보다 빠르고 편리하게 판매하실 수 있습니다
            </MainPageStyle.HowToAdditionalBannerDesc>
            <MainPageStyle.HowToAdditionalBannerBtn
              to={pathname === '/' ? ATM_GUIDE : undefined}
              hrefSelf={
                pathname === '/'
                  ? undefined
                  : 'https://mintit2.page.link/?link=https://event.mintit.co.kr/priceInquiry?linkTo=AtmGuide&apn=com.skn.mintit&afl=https://event.mintit.co.kr/priceInquiry&isi=1577650973&ibi=com.skn.mintit&ifl=https://event.mintit.co.kr/priceInquiry&efr=1'
              }
              type="ghost"
            >
              준비사항 확인하기
            </MainPageStyle.HowToAdditionalBannerBtn>
            <MainPageStyle.HowToAdditionalBannerImg src={mainIcon.Chart} />
          </MainPageStyle.HowToAdditionalBanner>
          <MainPageStyle.HowToAdditionalBottomWrapper>
            <MainPageStyle.HowToAdditionalBottomTitleWrapper>
              <MainPageStyle.HowToAdditionalBottomTitleImg
                src={mainIcon.warningIcon}
              />
              <MainPageStyle.HowToAdditionalBottomTitle>
                유의사항을 확인해주세요.
              </MainPageStyle.HowToAdditionalBottomTitle>
            </MainPageStyle.HowToAdditionalBottomTitleWrapper>
            <MainPageStyle.HowToAdditionalBottomDescList>
              {ReadyCaution.map((item, i) => (
                <MainPageStyle.HowToAdditionalBottomDesc
                  key={i}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              ))}
            </MainPageStyle.HowToAdditionalBottomDescList>
          </MainPageStyle.HowToAdditionalBottomWrapper>
        </article>
      </ScrollAnimation>
    </SectionContainer>
  );
};

export default BeforeVisit;
