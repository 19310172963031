import MainContent from 'components/MainContent';
import PriceInquiryLayout from 'components/PriceInquiryLayout';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { useState } from 'react';
import {
  getPriceInquiryLaptopDetail,
  IPriceInquiryLaptopDetail,
} from '../../apis/priceInquiryLaptop';
import {
  GradePriceItem,
  LaptopPriceInquiryDetailLayout,
  TitleAndText,
} from './styles';
import {
  laptopDetailBackground,
  PriceInquiryImages,
} from '../../common/constants/imageUrls';
import Button from '../../components/Button';
import PriceInquiryGradeModal from '../../components/Modal/PriceInquiryGradeModal';
import useTitle from '../../hooks/useTitle';
import { getBrandImg } from '../../common/utils';
import { NONAME_CODE_DETAIL } from '../../common/constants/strings';

const datas = [
  {
    name: '정상',
    description: '기기의 작동 및 외관이 모두 양호한 상태',
    content1: { name: '외관', text: '미세생활기스, 양호' },
    content2: { name: '액정', text: '멍 또는 백화, 잔상이 없는 상태' },
    content3: { name: '기능', text: '정상' },
  },
  {
    name: '손상',
    description: '기능 문제 없고, 외관상태에 이상이 있는 상태',
    content1: {
      name: '외관',
      text: '굵은 스크래치, 미세크랙(균열), 미세변색(탈색)',
    },
    content2: {
      name: '액정',
      text: '미세(5mm이하) 멍, 기스(켜진화면에서 안보이는)',
    },
    content3: { name: '기능', text: '정상' },
  },
  {
    name: '파손',
    description: '기능 문제 없고, 외관 파손(불량)이 있는 상태',
    content1: {
      name: '외관',
      text: '크랙 또는 파손(깨짐), 변색(탈색), 키보드 파손(키없음)',
    },
    content2: {
      name: '액정',
      text: '5mm이상의 멍, 다수멍, 기스(반짝거리는 기스), 색상이상',
    },
    content3: { name: '기능', text: '정상' },
  },
  {
    name: '고장',
    description: '기능불량 또는 외관 불량이 있는 상태',
    content1: {
      name: '외관',
      text: '굵은 스크래치, 미세크랙(균열), 미세변색(탈색)',
    },
    content2: {
      name: '액정',
      text: '화면불량(무감), 10mm이상의 멍, 줄생김, 파손',
    },
    content3: { name: '기능', text: '키보드, 포트, 전원불량등 기능고장' },
  },
];

const LaptopPriceInquiryDetail = () => {
  useTitle('민팃 - 노트북 시세 조회');

  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const { modelCode } = useParams();

  const { data, error } = useSWR<IPriceInquiryLaptopDetail>(
    [`/api/inquiry/laptop/detail`, modelCode],
    getPriceInquiryLaptopDetail,
    {
      dedupingInterval: 0,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
    },
  );

  if (error && error.message === '404')
    return (
      <PriceInquiryLayout>
        일치하는 데이터가 존재하지 않습니다.
      </PriceInquiryLayout>
    );

  const handelOpenModal = () => {
    setIsVisibleModal(true);
  };
  const handelCloseModal = () => {
    setIsVisibleModal(false);
  };

  console.log(data);

  if (!data) {
    return (
      <PriceInquiryLayout>
        <MainContent>
          <LaptopPriceInquiryDetailLayout>
            <div />
          </LaptopPriceInquiryDetailLayout>
        </MainContent>
      </PriceInquiryLayout>
    );
  }

  if (data && !Object.keys(data || {}).length) {
    return (
      <PriceInquiryLayout>
        <MainContent>
          <LaptopPriceInquiryDetailLayout>
            <div className="empty-data">존재하지 않는 모델번호 입니다.</div>
          </LaptopPriceInquiryDetailLayout>
        </MainContent>
      </PriceInquiryLayout>
    );
  }

  return (
    <PriceInquiryLayout>
      <MainContent>
        <LaptopPriceInquiryDetailLayout>
          <h1 className="hidden">노트북 상세</h1>
          <div className="detail">
            <div className="side">
              <img
                className="side__logo"
                width="25%"
                src={getBrandImg(data?.cate02_num || 0, 'laptop')}
                alt={
                  data?.cate03_num !== NONAME_CODE_DETAIL
                    ? data?.cate02_name
                    : ''
                }
              />
              <img
                className="side__background"
                width="100%"
                src={laptopDetailBackground}
                alt="노트북"
              />
            </div>
            <div className="content">
              <section className="content__item">
                <header className="header">
                  <h2 className="header__name">
                    {data?.ex_price && data?.cate04_name ? (
                      <span>추가보상 모델</span>
                    ) : null}
                    {data?.cate04_name || '모델명 모름 (매각신청)'}
                  </h2>
                  {data?.cate04_name ? (
                    <ul className="header__category-list">
                      <li className="header__category-item">
                        {data?.cate02_name}
                      </li>
                      <li className="header__category-item">
                        {data?.cate03_name}
                      </li>
                      <li className="header__category-item">노트북</li>
                    </ul>
                  ) : null}
                </header>
                <div className="body">
                  <TitleAndText
                    className="body__item"
                    fontSize="20px"
                    mobileFontSize="16px"
                  >
                    <h3>민팃 최대 보상가격</h3>
                    <p className="font-inter">
                      {(data?.btob_price || 0).toLocaleString()} ~{' '}
                      {Number(data?.buy_price_a || '0').toLocaleString()}원
                    </p>
                  </TitleAndText>
                  {data?.ex_price ? (
                    <div className="body__item add-compensation">
                      <div className="add-compensation__icon" />
                      <TitleAndText
                        className="add-compensation__title"
                        fontSize="20px"
                        mobileFontSize="16px"
                      >
                        <h3>추가 보상금</h3>
                        <p className="font-inter">
                          +{Number(data?.ex_price || '0').toLocaleString()}원
                        </p>
                      </TitleAndText>
                      <div className="notice">
                        <h4 className="notice__title">
                          <span />
                          확인해 주세요!
                        </h4>
                        <ul className="notice__list">
                          <li className="notice__item">
                            <span />
                            삼성전자와의 제휴로 진행되는 이벤트입니다. 조건에
                            맞지 않는 경우 추가보상을 받을 수 없습니다.
                          </li>
                          <li className="notice__item">
                            <span />
                            흡집상태, 동작상태에 따라 매입가격은 다를수
                            있습니다.
                            <br />
                            검수후 최종보상가격은 알림톡으로 발송되며, 매입가
                            불만족시 반송요청을 할 수 있습니다.
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : null}
                </div>
                <footer className="footer">
                  <TitleAndText
                    className="footer__total"
                    fontSize="24px"
                    mobileFontSize="20px"
                  >
                    <h3>총 합계</h3>
                    {data?.cate03_num !== NONAME_CODE_DETAIL ? (
                      <p className="font-inter">
                        {(
                          Number(data?.btob_price || '0') +
                          Number(data?.ex_price || '0')
                        ).toLocaleString()}{' '}
                        ~{' '}
                        {(
                          Number(data?.buy_price_a || '0') +
                          Number(data?.ex_price || '0')
                        ).toLocaleString()}
                        원
                      </p>
                    ) : (
                      <p>0 ~ 0원</p>
                    )}
                  </TitleAndText>
                  <Button
                    className="footer__button"
                    type="secondary"
                    to="/priceInquiryLaptop"
                  >
                    다시 조회하기
                  </Button>
                </footer>
              </section>
              <section className="content__price">
                <header className="header">
                  <h2 className="header__title">등급별 시세</h2>
                  <button
                    type="button"
                    className="header__button"
                    onClick={handelOpenModal}
                  >
                    등급기준 <span />
                  </button>
                </header>
                <div>
                  <ul>
                    <GradePriceItem grade="A">
                      <span>A등급</span>
                      <div className="text">
                        <h3>정상</h3>
                        <p>기기의 작동 및 외관이 모두 양호한 상태</p>
                      </div>
                      <p className="price font-inter">
                        {Number(data?.buy_price_a || '0').toLocaleString()}원
                      </p>
                    </GradePriceItem>
                    <GradePriceItem grade="B">
                      <span>B등급</span>
                      <div className="text">
                        <h3>손상</h3>
                        <p>기능 문제 없고, 외관상태에 이상이 있는 상태</p>
                      </div>
                      <p className="price font-inter">
                        {Number(data?.buy_price_b || '0').toLocaleString()}원
                      </p>
                    </GradePriceItem>
                    <GradePriceItem grade="C">
                      <span>C등급</span>
                      <div className="text">
                        <h3>파손</h3>
                        <p>기능 문제 없고, 외관 파손(불량)이 있는 상태</p>
                      </div>
                      <p className="price font-inter">
                        {Number(data?.buy_price_c || '0').toLocaleString()}원
                      </p>
                    </GradePriceItem>
                    <GradePriceItem grade="D">
                      <span>D등급</span>
                      <div className="text">
                        <h3>고장</h3>
                        <p>기능불량 또는 외관불량이 있는 상태</p>
                      </div>
                      <p className="price font-inter">
                        {(data?.btob_price || 0).toLocaleString()}원
                      </p>
                    </GradePriceItem>
                  </ul>
                </div>
              </section>
              <section className="content__how-to">
                <header className="header">
                  <h2 className="header__title">신청방법안내</h2>
                </header>
                <div className="body">
                  <ul className="body__icon-list">
                    <li className="body__icon-item">
                      <div className="body__icon-img-box">
                        <img
                          width="100%"
                          src={PriceInquiryImages.howToApply1}
                          alt=""
                        />
                      </div>
                      <p>
                        Trade-in
                        <br />
                        대상모델
                        <br />
                        구매 및 수령
                      </p>
                    </li>
                    <li className="body__icon-arrow" />
                    <li className="body__icon-item">
                      <div className="body__icon-img-box">
                        <img
                          width="100%"
                          src={PriceInquiryImages.howToApply2}
                          alt=""
                        />
                      </div>
                      <p>
                        수신된 알림톡을 통해
                        <br />
                        사진업로드 및
                        <br />
                        정보삭제 동의
                      </p>
                    </li>
                    <li className="body__icon-arrow" />
                    <li className="body__icon-item">
                      <div className="body__icon-img-box">
                        <img
                          width="100%"
                          src={PriceInquiryImages.howToApply3}
                          alt=""
                        />
                      </div>
                      <p>
                        택배발송
                        <br />
                        운송장번호
                        <br />
                        알림톡에 입력
                      </p>
                    </li>
                    <li className="body__icon-arrow pc-tablet-only" />
                    <li className="body__icon-item">
                      <div className="body__icon-img-box">
                        <img
                          width="100%"
                          src={PriceInquiryImages.howToApply4}
                          alt=""
                        />
                      </div>
                      <p>
                        노트북
                        <br />
                        감정평가
                      </p>
                    </li>
                    <li className="body__icon-arrow" />
                    <li className="body__icon-item">
                      <div className="body__icon-img-box">
                        <img
                          width="100%"
                          src={PriceInquiryImages.howToApply5}
                          alt=""
                        />
                      </div>
                      <p>내 계좌로 송금</p>
                    </li>
                  </ul>
                  <ul className="body__how-to-list">
                    <li className="body__how-to-item">
                      <p className="body__how-to-text">
                        <span>1</span>
                        구매시 중고보상 신청으로 추가보상금을 확인 후,
                        구매완료를 진행해 주세요.
                      </p>
                    </li>
                    <li className="body__how-to-item">
                      <p className="body__how-to-text">
                        <span>2</span>
                        추가보상 신청안내 알림톡의 개인정보동의 확인과 반납
                        노트북의 정면,
                        {window.innerWidth > 720 ? <br /> : ' '}
                        후면라벨(모델명, s/n) 사진을 업로드해 주셔야 정상
                        신청됩니다.
                      </p>
                    </li>
                    <li className="body__how-to-item">
                      <p className="body__how-to-text">
                        <span>3</span>
                        정상 신청분에 한해 평가센터 주소가 알림톡으로 전송되며,
                        {window.innerWidth > 720 ? <br /> : ' '}
                        발송정보를 확인하신 후 구매 시점부터 14일 내 신청,
                        발송절차를 완료해 주세요.
                      </p>
                      <span className="body__how-to-option">
                        (14일내 사진업로드, 택배발송 절차 미진행시 행사는 자동
                        취소처리 됩니다.)
                      </span>
                    </li>
                    <li className="body__how-to-item">
                      <p className="body__how-to-text">
                        <span>4</span>
                        제품이 평가센터에 도착하면 입고확인 알림톡이 전송됩니다.
                        {window.innerWidth > 720 ? <br /> : ' '}
                        계좌입력 버튼을 통해 구매자명과 동일명의의 계좌번호를
                        입력해 주세요.
                      </p>
                      <span className="body__how-to-option">
                        (구매자, 신청자, 예금주가 다른 경우 중고(추가)보상
                        혜택은 제외 됩니다.)
                      </span>
                    </li>
                    <li className="body__how-to-item">
                      <p className="body__how-to-text">
                        <span>5</span>
                        평가센터에서 감정, 검수평가 후 최종 판정금액을
                        알림톡으로 알려드립니다.
                      </p>
                    </li>
                    <li className="body__how-to-item">
                      <p className="body__how-to-text">
                        <span>6</span>
                        추가보상 대상자인 경우 최종 판매동의를 선택하시면
                        합산금액으로 송금됩니다.
                      </p>
                      <span className="body__how-to-option">
                        (단, 중도에 신제품 구매를 취소하는 경우 지급된 대금을
                        환불하셔야 합니다.)
                      </span>
                    </li>
                  </ul>
                </div>
              </section>
            </div>
          </div>
        </LaptopPriceInquiryDetailLayout>
      </MainContent>
      <PriceInquiryGradeModal
        datas={datas}
        visible={isVisibleModal}
        onClose={handelCloseModal}
      />
    </PriceInquiryLayout>
  );
};

export default LaptopPriceInquiryDetail;
