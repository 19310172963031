import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducer';
import { PriceInquiryLaptop } from '../../redux/@types';
import {
  clearInfoLaptop,
  setCurrentInfoLaptop,
  setManufacturerLaptop,
  setModelLaptop,
  setSeriesLaptop,
} from '../../redux/modules/priceInquiryLaptop';

const useInquiryLaptop = () => {
  const dispatch = useDispatch();
  const { manufacturer, series, model, currentInfo } = useSelector<
    RootState,
    PriceInquiryLaptop
  >((state) => state.priceInquiryLaptop);

  const setManufacturer = (item: { name: string; code: string }) => {
    dispatch(setManufacturerLaptop(item));
  };
  const setSeries = (item: { name: string; code: string }) => {
    dispatch(setSeriesLaptop(item));
  };
  const setModel = (item: { name: string; code: string }) => {
    dispatch(setModelLaptop(item));
  };

  const setCurrentInfo = (item: { category: string; code: string }) => {
    dispatch(setCurrentInfoLaptop(item));
  };

  const clearInfo = () => {
    dispatch(clearInfoLaptop());
  };

  return {
    manufacturer,
    series,
    model,
    currentInfo,
    setManufacturer,
    setSeries,
    setModel,
    setCurrentInfo,
    clearInfo,
  };
};

export default useInquiryLaptop;
