import { createGlobalStyle } from 'styled-components';
import { reset } from 'styled-reset';
import { mediaQueries, MOBILE } from './mediaQueries';
import { media } from './theme';

const GlobalStyles = createGlobalStyle`
  ${reset}
  .font-inter {
    font-family: 'Inter', sans-serif;
  }
  
  :focus {
    outline: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  * {
    -webkit-text-size-adjust: none;
    box-sizing: border-box;
    list-style: none;
  }

  html {
    max-width: 100%;
    font-size: 11px;
    -webkit-text-size-adjust: none;
    font-family: 'Noto Sans KR', sans-serif;
    font-display: fallback;

    ${media.tablet} {
      font-size: 10px;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  body {
    max-width: 100%;
    ${mediaQueries(MOBILE)} {
      width: 100vw;
      position: absolute;
    }
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    font-family: 'Noto Sans KR', sans-serif;

    &:disabled {
      cursor: default;
      fill: #f2f3f4;
    }
  }

  a {
    text-decoration: none;
  }

  .pc-tablet-only {
    display: block;

    ${media.mobile} {
      display: none;
    }
  }

  .tablet-mobile-only {
    display: none;

    ${media.tablet} {
      display: block;
    }
  }

  .mobile-only {
    display: none;

    ${media.mobile} {
      display: block;
    }
  }

  html, body, #root, .App {
    height: 100%;

    ${media.mobile} {
      overflow:scroll;
      -webkit-overflow-scrolling:touch;
    }
  }
  
  .hidden {
    display: none;
  }
`;

export default GlobalStyles;
