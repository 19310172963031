import React from 'react';
import styled from 'styled-components';
import {
  iconRadioChecked,
  iconRadioUnchecked,
} from 'common/constants/imageUrls';

interface RadioProps {
  isChecked?: boolean;
  onClick: () => void;
}
const Radio = ({ onClick, isChecked = false }: RadioProps) => {
  return (
    <Container
      onClick={onClick}
      role="radio"
      aria-checked={isChecked ? 'true' : 'false'}
      tabIndex={0}
    >
      {isChecked ? (
        <img src={iconRadioChecked} alt="radio" />
      ) : (
        <img src={iconRadioUnchecked} alt="radio" />
      )}
    </Container>
  );
};

export default Radio;

const Container = styled.div`
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
`;
