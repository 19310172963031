import React, { FunctionComponent, useState } from 'react';
import styled, { css } from 'styled-components';
import Footer from 'components/partials/Footer';
import { AtmGuideSectionContentType } from 'components/atmGuide/AtmGuideSectionContent';
import AtmGuideSectionItem from 'components/atmGuide/AtmGuideSectionItem';
import ScrollAnimation from 'components/ScrollAnimation';
import { rightArrowWhite } from 'common/constants/imageUrls';
import { AtmStyle } from 'components/PriceDetail/Atm';
import Modal from 'components/Modal';
import OSInstallDetailModal from 'components/Modal/OSInstallDetailModal';
import {
  mediaQueries,
  MOBILE,
  SCROLL_BREAK,
  TABLET,
} from 'styles/mediaQueries';
import { androidHowToUse } from 'common/constants/videoUrls';
import { CONNECTOR_URL } from 'common/constants/urls';
import Layout from 'components/Layout';
import { ATM_FIND } from 'common/constants/pathUrls';
import { flexCenterCss, flexColumnCenterCss } from 'styles/commonStyle';
import ConnectQrModal from '../../components/Modal/ConnectQrModal';

const SectionContentItems1: AtmGuideSectionContentType[] = [
  {
    icon: 'phoneSettings1',
    title: '배터리 충전',
    imageName: 'phoneSettings1',
    description: [
      { text: '검사도중 전원이 꺼지지 않도록 배터리는 넉넉히 충전해주세요' },
    ],
  },
  {
    icon: 'phoneSettings2',
    title: '디스플레이 설정 변경',
    imageName: 'phoneSettings2',
    description: [
      {
        text: '설정 > 디스플레이 화면에서 아래의 설정을 변경해주세요',
        option: ['화면 자동꺼짐 시간 : 5분 이상', '디스플레이 밝기 : 최대'],
      },
    ],
  },
  {
    icon: 'visualInspection2',
    title: '데이터 백업',
    imageName: 'visualInspection2',
    description: [
      { text: '휴대폰에 남아있는 소중한 데이터가 날아가지 않게 백업해주세요' },
    ],
  },
];

const SectionContentItems2: AtmGuideSectionContentType[] = [
  {
    icon: 'visualInspection1',
    title: '유심, SD카드 제거',
    imageName: 'visualInspection1',
    description: [{ text: '유심 / SD카드는 제거해주세요' }],
  },
  {
    icon: 'visualInspection3',
    title: '휴대폰 부착물 제거',
    imageName: 'visualInspection3',
    description: [
      {
        text: '액정필름, 케이스등 부착물을 모두 제거하고  외관을 깨끗하게 닦아주세요',
      },
    ],
  },
];

const AtmGuide: FunctionComponent = () => {
  // Modal 오픈 상태 및 이벤트
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const handleDetailModalOpen = () => setIsDetailModalOpen(true);
  const handleDetailModalClose = () => setIsDetailModalOpen(false);

  const [isQrModalVisible, setIsQrModalVisible] = useState(false);
  const handleQrModalOpen = () => {
    setIsQrModalVisible(true);
  };
  const handleQrModalClose = () => {
    setIsQrModalVisible(false);
  };

  return (
    <>
      <Modal
        visible={isDetailModalOpen}
        onClose={handleDetailModalClose}
        width="100vw"
        maxWidth="1920px"
        mobileWidth="min(600px, 90vw)"
        height="90vh"
        maxHeight="1080px"
      >
        <OSInstallDetailModal onClose={handleDetailModalClose} />
      </Modal>
      <Layout>
        <AtmGuidePageStyle.Container>
          <AtmGuidePageStyle.Header>
            <ScrollAnimation delay={0.1}>
              <AtmGuidePageStyle.Title>
                민팃 ATM 방문 전
                <br /> 필수 준비사항을 알아볼까요?
              </AtmGuidePageStyle.Title>
              <AtmGuidePageStyle.Description>
                판매하실 휴대폰으로 아래 내용을 순서대로 따라해 주세요
              </AtmGuidePageStyle.Description>
            </ScrollAnimation>
            <AtmGuidePageStyle.FlowList>
              <AtmGuidePageStyle.FlowItem delay={0.1}>
                휴대폰 설정
              </AtmGuidePageStyle.FlowItem>
              <AtmGuidePageStyle.FlowItem delay={0.2}>
                외관 점검
              </AtmGuidePageStyle.FlowItem>
              <AtmGuidePageStyle.FlowItem delay={0.3}>
                민팃 커넥터 설치
              </AtmGuidePageStyle.FlowItem>
              <AtmGuidePageStyle.FlowItem delay={0.4}>
                ATM 찾기
              </AtmGuidePageStyle.FlowItem>
            </AtmGuidePageStyle.FlowList>
          </AtmGuidePageStyle.Header>
          <AtmGuidePageStyle.FlowSectionList>
            <AtmGuideSectionItem
              index={1}
              title="휴대폰 설정"
              description="검사를 위해 배터리를 충전하고 디스플레이 설정을 변경해주세요"
              contentItems={SectionContentItems1}
            />
            <AtmGuideSectionItem
              index={2}
              title="외관 점검"
              description="유심, SD카드 및 휴대폰 부착물을 모두 제거해주세요"
              contentItems={SectionContentItems2}
            />
            <AtmGuideSectionItem
              index={3}
              title="민팃 커넥터 설치 및 본인인증"
              subText
            >
              <AtmStyle.ConnectWrapper>
                <div>
                  <AtmStyle.ConnectSubText>
                    민팃 커넥터를 설치하신 후 본인인증을 진행해주세요
                    <br />
                    민팃 커넥터는 민팃 이용을 위한 필수 앱으로 개인정보를
                    완벽하게 삭제합니다
                  </AtmStyle.ConnectSubText>
                  <AtmStyle.ConnectSubText>
                    현재 <strong>사용중인 휴대폰</strong>을 판매한다면?
                    {window.innerWidth > 720 ? ' ' : <br />}
                    [바로 설치]를 눌러 설치를 진행해주세요
                  </AtmStyle.ConnectSubText>
                  <AtmStyle.ConnectSubText>
                    이미 <strong>갤럭시 퀀텀3, 갤럭시 점프2를 개통</strong>
                    했다면?
                    {window.innerWidth > 720 ? ' ' : <br />}
                    [다른 휴대폰에 설치]를 눌러 설치해주세요
                  </AtmStyle.ConnectSubText>
                  <ConnectMore onClick={handleDetailModalOpen}>
                    설치 방법 살펴보기
                  </ConnectMore>
                </div>
                <AtmStyle.ConnectButtonWrapper>
                  <AtmStyle.ConnectButton hrefSelf={CONNECTOR_URL}>
                    바로 설치
                  </AtmStyle.ConnectButton>
                  <AtmStyle.ConnectButton onClick={handleQrModalOpen}>
                    다른 휴대폰에 설치
                  </AtmStyle.ConnectButton>
                </AtmStyle.ConnectButtonWrapper>
              </AtmStyle.ConnectWrapper>
            </AtmGuideSectionItem>
            <AtmGuideSectionItem index={4} title="ATM 찾기" temp>
              <AtmStyle.ConnectWrapper>
                <div>
                  <AtmStyle.ConnectSubText>
                    판매 준비가 모두 끝났습니다!
                    <br />
                    7일 이내 가까운 민팃 ATM에 방문해주세요
                    <br />
                    민팃 ATM은 전국 5,500여개 지점에 설치되어 있습니다.
                    <br />
                  </AtmStyle.ConnectSubText>
                  <ContentDesc style={{ width: '100%' }}>
                    ※ 갤럭시 퀀텀3, 갤럭시 점프2 구매처에 따라 반납처가
                    상이하오니 이벤트 안내를 꼭 확인해주세요
                  </ContentDesc>
                </div>
                <AtmStyle.ConnectButtonWrapper>
                  <AtmStyle.ConnectButton hrefSelf={ATM_FIND}>
                    내 주변 ATM 찾아보기
                  </AtmStyle.ConnectButton>
                </AtmStyle.ConnectButtonWrapper>
              </AtmStyle.ConnectWrapper>
            </AtmGuideSectionItem>
          </AtmGuidePageStyle.FlowSectionList>
          <AtmGuidePageStyle.SubSection>
            <ScrollAnimation>
              <AtmGuidePageStyle.SubSectionTitle>
                영상으로 쉽게 따라해보세요!
              </AtmGuidePageStyle.SubSectionTitle>
            </ScrollAnimation>
            <AtmGuidePageStyle.SubSectionContentList>
              <AtmGuidePageStyle.SubSectionContentItem>
                <FlexScrollAnimation widthNotFull>
                  <AtmGuidePageStyle.SubSectionVideoWrapper>
                    <iframe
                      title="android"
                      src={androidHowToUse}
                      frameBorder={16}
                      allowFullScreen
                    />
                  </AtmGuidePageStyle.SubSectionVideoWrapper>
                </FlexScrollAnimation>
              </AtmGuidePageStyle.SubSectionContentItem>
            </AtmGuidePageStyle.SubSectionContentList>
          </AtmGuidePageStyle.SubSection>
        </AtmGuidePageStyle.Container>
      </Layout>
      <Footer />
      <ConnectQrModal visible={isQrModalVisible} onClose={handleQrModalClose} />
    </>
  );
};

const FlexScrollAnimation = styled(ScrollAnimation)`
  ${flexColumnCenterCss};
  justify-content: center;
`;

const ConnectMore = styled(AtmStyle.ConnectMore)`
  ${mediaQueries(MOBILE)} {
    margin: 5%;
  }
`;

const ContentDesc = styled(AtmStyle.ContentDesc)`
  font-size: min(16px, 1.8vw);
  word-break: keep-all;
  ${mediaQueries(MOBILE)} {
    font-size: 12px;
    padding: 0 5%;
  }
`;

const AtmGuidePageStyle = {
  Container: styled.main`
    width: 100%;
    max-width: 1440px;
    min-width: 1220px;
    overflow-x: scroll;
    margin: 0 auto;
    padding: 10.909em 4.364em;

    ${mediaQueries(TABLET)} {
      font-size: 1vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 12.2px;
    }

    ${mediaQueries(MOBILE)} {
      overflow: hidden;
      min-width: auto;
      font-size: 11px;
      padding: 1em 0;

      & > * {
        padding: 0 5%;
      }
    }
  `,
  Header: styled.header`
    margin-bottom: 10.909em;

    ${mediaQueries(TABLET)} {
      font-size: 1vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 12.2px;
    }

    ${mediaQueries(MOBILE)} {
      font-size: 11px;
      margin-bottom: 24px;
    }
  `,
  SubSection: styled.section`
    ${({ theme }) => css`
      position: relative;

      & > a {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        ${flexCenterCss};
        width: 12.708em;
        height: 3.458em;
        border-radius: 9px;
        background: ${theme.common.colors.turquoise};
        ${theme.typo.head24};
        color: ${theme.common.colors.white};
        text-decoration: none;
      }

      ${mediaQueries(TABLET)} {
        & > a {
          font-size: 2.5vw;
        }
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 12.2px;
      }

      ${mediaQueries(MOBILE)} {
        & > a {
          position: relative;
          top: initial;
          transform: none;
          width: 100%;
          height: 52px;
          ${theme.typo.head14};
        }
      }
    `}
  `,
  Title: styled.h2`
    ${({ theme }) => css`
      ${theme.typo.head52};
      line-height: 1.3em;
      margin-bottom: 0.538em;

      ${mediaQueries(TABLET)} {
        font-size: 4vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 52px;
      }

      ${mediaQueries(MOBILE)} {
        ${theme.typo.head24};
        line-height: 1.3em;
        margin: 0 0 12px;
      }
    `}
  `,
  Description: styled.p`
    font-size: 24px;
    line-height: 1.3em;
    word-break: keep-all;

    ${mediaQueries(TABLET)} {
      font-size: 1.8vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 24px;
    }

    ${mediaQueries(MOBILE)} {
      font-size: 14px;
      margin-bottom: 22px;
    }
  `,
  FlowList: styled.ul`
    ${mediaQueries(MOBILE)} {
      display: none;
    }
  `,
  FlowItem: styled(ScrollAnimation)`
    ${({ theme }) => css`
      position: relative;
      display: inline-flex;
      align-items: center;
      ${theme.typo.body24R};

      &:not(:last-child) {
        margin-right: 1.063em;
        padding-right: 1.063em;
      }
      &:not(:last-child):after {
        position: absolute;
        right: -0.531em;
        content: '';
        display: block;
        width: 1.125em;
        height: 1.125em;
        background-color: ${theme.common.colors.turquoise};
        border-radius: 50%;
        background-image: url(${rightArrowWhite});
        background-repeat: no-repeat;
        background-position: center center;
      }

      ${mediaQueries(TABLET)} {
        font-size: 2vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        ${theme.typo.body24R};
      }
    `}
  `,
  FlowSectionList: styled.ul`
    margin-bottom: 14.545em;

    ${mediaQueries(TABLET)} {
      font-size: 1vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 12.2px;
    }

    ${mediaQueries(MOBILE)} {
      margin-bottom: 58px;
      padding: 0;
    }
  `,
  SubSectionTitle: styled.h3`
    font-size: 52px;
    font-weight: bold;
    line-height: 1.3em;
    margin-bottom: 1.231em;

    ${mediaQueries(TABLET)} {
      font-size: 4.5vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 52px;
    }

    ${mediaQueries(MOBILE)} {
      font-size: 24px;
      margin-bottom: 22px;
    }
  `,
  SubSectionContentList: styled.ul`
    display: flex;
    justify-content: space-between;
    ${mediaQueries(MOBILE)} {
      flex-wrap: wrap;
    }
  `,
  SubSectionContentItem: styled.li`
    display: flex;
    flex-wrap: wrap;
    width: 50%;

    &:not(:last-child) {
      margin-right: 1.818em;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 19.063em;
      height: 3.438em;
      border-radius: 0.563em;
      background: #33ccbd;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      text-decoration: none;
    }

    ${mediaQueries(TABLET)} {
      font-size: 1vw;

      button {
        font-size: 1.4vw;
      }
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 12.2px;
      button {
        font-size: 14px;
      }
    }

    ${mediaQueries(MOBILE)} {
      width: 100%;
      margin: 0;

      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 47px;
      }

      button {
        width: 100%;
        height: 49px;
        font-size: 12px;
      }
    }
  `,
  SubSectionVideoWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 1140px;
    width: 100%;
    margin-bottom: 24px;
    iframe {
      width: 100%;
      max-height: 321px;
      height: 500px;
      border-radius: 10px;
      margin-bottom: 19px;
      ${mediaQueries(MOBILE)} {
        width: 90vw;
        height: 60vw;
        margin-bottom: 16px;
      }
    }

    ${mediaQueries(MOBILE)} {
      margin-bottom: 16px;
    }
  `,
  VideoDesc: styled.div`
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;

    ${mediaQueries(MOBILE)} {
      font-size: 16px;
      line-height: 23px;
      font-weight: 700;
    }
  `,
};

export default AtmGuide;
