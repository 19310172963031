import { gradeIcon } from 'common/constants/imageUrls';

export const gradeImgUrls = {
  price_a: gradeIcon.A,
  price_b: gradeIcon.B,
  price_c: gradeIcon.C,
  price_d: gradeIcon.D,
};

// label
export const priceLabel = ['price_a', 'price_b', 'price_c', 'price_d'];

// 가격 단위 변환 함수
export const NumToPrice = (price: number): string => {
  return `${price.toLocaleString('ko-KR')} 원`;
};
