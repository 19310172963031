import React, { FunctionComponent } from 'react';
import { BannerLayoutStyle } from './style';
import ScrollAnimation from '../../ScrollAnimation';
import { ITypes } from '../types';

interface ISection extends ITypes {
  titleImage?: any;
  title: string;
  subtitle: string;
  img: any;
  path: string;
  btnValue: string;
  btnColor?: string;
  btnType?: string;
  color: string;
  isAlignCenter?: boolean;
}

const BannerLayout: FunctionComponent<ISection> = ({
  titleImage,
  title,
  subtitle,
  img,
  path,
  btnValue,
  // btnType,
  color,
  btnColor,
  pathname,
  isAlignCenter,
  ...rest
}) => {
  return (
    <BannerLayoutStyle.Container
      isAlignCenter={isAlignCenter}
      color={color}
      {...rest}
    >
      <ScrollAnimation>
        <article>
          {titleImage ? (
            <BannerLayoutStyle.TitlImgWrapper>
              <img src={titleImage} width="100%" alt={title} />
            </BannerLayoutStyle.TitlImgWrapper>
          ) : (
            <h3 dangerouslySetInnerHTML={{ __html: title }} />
          )}
          <BannerLayoutStyle.Desc
            isAlignCenter={isAlignCenter}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
          <BannerLayoutStyle.Btn
            to={pathname === '/' ? path : undefined}
            href={pathname === '/' ? undefined : path}
            btnColor={btnColor || '#33CCBD'}
          >
            {btnValue}
          </BannerLayoutStyle.Btn>
          <BannerLayoutStyle.Img src={img} />
        </article>
      </ScrollAnimation>
    </BannerLayoutStyle.Container>
  );
};
BannerLayout.defaultProps = {
  btnType: 'primary',
  btnColor: '#33CCBD',
};

export default BannerLayout;
