import React, { FunctionComponent, HTMLProps } from 'react';
import { FlexBoxLayout, FlexBoxLayoutProps } from './styles';

export interface FlexBoxProps
  extends HTMLProps<HTMLDivElement>,
    FlexBoxLayoutProps {}

const FlexBox: FunctionComponent<FlexBoxProps> = ({
  children,
  direction,
  justifyContent,
  withBorder,
  padding,
  contentGap,
  rowCount,
}) => {
  return (
    <FlexBoxLayout
      direction={direction}
      justifyContent={justifyContent}
      withBorder={withBorder}
      padding={padding}
      contentGap={contentGap}
      rowCount={rowCount}
    >
      {children}
    </FlexBoxLayout>
  );
};

export default FlexBox;
