import styled from 'styled-components';
import { mediaQueries, MOBILE } from '../../styles/mediaQueries';

export const Main = styled.main`
  padding-top: 81px;

  ${mediaQueries(MOBILE)} {
    padding-top: 77px;
  }
`;
export const Section = styled.section`
  max-width: 1440px;
  min-width: 1220px;
  padding: 64px 80px;
  margin: 0 auto;

  ${mediaQueries(MOBILE)} {
    min-width: auto;
    padding: 20px;
  }
`;
export const SectionHeader = styled.header`
  margin-bottom: 64px;
  text-align: center;
  ${mediaQueries(MOBILE)} {
    margin-bottom: 40px;
  }
`;
export const SectionTitle = styled.h2`
  margin-bottom: 12px;
  font-size: 48px;
  line-height: 1.3em;
  font-weight: bold;

  ${mediaQueries(MOBILE)} {
    margin-bottom: 4px;
    font-size: 24px;
  }
`;
export const SubTitle = styled.p`
  font-size: 18px;
  line-height: 1.3em;
  text-align: center;

  ${mediaQueries(MOBILE)} {
    font-size: 14px;
  }
`;
export const EventList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 40px 2%;

  ${mediaQueries(MOBILE)} {
    gap: 24px 0;
  }
`;
export const EventItem = styled.li<{ isEnded: boolean }>`
  display: ${({ isEnded }) => (isEnded ? 'none' : 'block')};
  cursor: pointer;
  width: 49%;

  ${mediaQueries(MOBILE)} {
    width: 100%;
  }
`;
export const EventImageBox = styled.div`
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 16px;

  ${mediaQueries(MOBILE)} {
    margin-bottom: 12px;
  }
`;
export const EventTitle = styled.h3`
  font-size: 20px;
  line-height: 1.3em;
  margin-bottom: 8px;
  ${mediaQueries(MOBILE)} {
    font-size: 16px;
    margin-bottom: 4px;
  }
`;
export const EventText = styled.p`
  font-size: 16px;
  line-height: 1.3em;
  color: #4b4b4b;

  ${mediaQueries(MOBILE)} {
    font-size: 14px;
  }
`;
