import styled, { css } from 'styled-components';
import {
  mediaQueries,
  MOBILE,
  SCROLL_BREAK,
  TABLET,
} from 'styles/mediaQueries';
import Button from 'components/Button';
import { flexCenterCss, flexColumnCss } from 'styles/commonStyle';
import { Title } from '../Styles';
import { media } from '../../../styles/theme';
import { mainIcon } from '../../../common/constants/imageUrls';

export const AdditionalCompensationStyle = {
  AdditionalTargetContainer: styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;

    & > span {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 8px;
      background: url(${mainIcon.warningIcon}) no-repeat center center;
      background-size: contain;
    }
    ${mediaQueries(TABLET)} {
      font-size: 1.5vw;

      & > span {
        width: 1.3em;
        height: 1.3em;
      }
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 18.3px;
    }

    ${mediaQueries(MOBILE)} {
      ${({ theme }) => theme.typo.body14R};
      font-weight: bold;
    }
  `,
  PriceContentWrapper: styled.div`
    ${media.mobile} {
      width: 100%;
    }
  `,
  Container: styled.div`
    ${flexColumnCss};
    align-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    height: 61em;
    margin: 0 0 7em;

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      align-items: center;
      margin: 20px 0 16px;
      height: 100%;
    }
  `,
  Img: styled.img`
    height: 100%;
    align-self: center;
    opacity: 0;
    transform: translateY(30px);
    transition: transform 1s, opacity 0.7s;

    ${({ isAnimation }: { isAnimation: boolean }) =>
      isAnimation
        ? css`
            opacity: 1;
            transform: translateY(0);
          `
        : null};

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      width: 100%;
      order: 3;
      margin: 0;
    }
  `,
  DetailTitle: styled(Title)`
    text-align: left;
    ${({ theme }) => css`
      color: ${theme.common.colors.black};

      ${mediaQueries(MOBILE)} {
        text-align: center;
        order: 1;
        margin-bottom: 24px;
      }
    `};
  `,
  SeriesContainer: styled.div`
    display: flex;
    margin: 1.818em 0 3.636em;

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      justify-content: center;
      width: 100%;
      order: 2;
      margin-bottom: 2em;
    }
  `,
  Series: styled.button<{ select: boolean }>`
    ${({ theme, select }) => css`
      ${theme.typo.body24R};
      color: ${select
        ? theme.common.colors.mainText
        : theme.common.colors.subText};
      font-weight: ${select
        ? theme.fontWeights.bold
        : theme.fontWeights.normal};

      &:not(:last-child):after {
        margin: 0 0.833em;
        display: inline-block;
        content: '';
        width: 1px;
        height: 0.833em;
        background-color: ${select
          ? theme.common.colors.mainText
          : theme.common.colors.subText};
      }

      ${mediaQueries(TABLET)} {
        font-size: 1.5vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 18.3px;
      }

      ${mediaQueries(MOBILE)} {
        ${theme.typo.body14R};

        &:not(:last-child):after {
          margin: 0 20px;
        }
      }
    `};
  `,
  DetailBtnContainer: styled.div`
    display: flex;
    width: 52.727em;
    flex-wrap: wrap;
    margin-bottom: 3.636em;
    gap: 1.5em;

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      width: 100%;
      margin: 0 0 12px;
      order: 4;
      gap: 3vw;
    }
  `,
  DetailBtn: styled(Button)`
    ${({ theme }) => css`
      width: 31%;
      height: 4.2em;
      ${theme.typo.body20R};
      border-radius: 8px;

      ${mediaQueries(TABLET)} {
        font-size: 1.2vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 14.64px;
      }

      ${mediaQueries(MOBILE)} {
        ${theme.typo.body12R};
        order: 4;
      }
    `};
  `,
  PaymentContainer: styled.div`
    width: 52.727em;

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      width: 100%;
      order: 5;
    }
  `,
  PaymentWrapper: styled.div<{ alwaysShown?: boolean }>`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2.182em;
    ${({ alwaysShown }) =>
      alwaysShown &&
      css`
        opacity: 1;
        transform: none;
      `}
    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      font-size: 13px;
      margin-bottom: 16px;
      order: 5;
    }
  `,
  PaymentLabel: styled.div<{ design: 'total' | 'plus' | 'normal' }>`
    ${({ theme, design }) => css`
      ${design === 'total' ? theme.typo.body32R : theme.typo.body24R};
      color: ${design === 'plus'
        ? theme.common.colors.turquoise
        : theme.common.colors.mainText};

      ${mediaQueries(TABLET)} {
        font-size: ${design === 'total' ? '2vw' : '1.5vw'};
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: ${design === 'total' ? '18.3px' : '16.2px'};
      }

      ${mediaQueries(MOBILE)} {
        ${design === 'total' ? theme.typo.head16 : theme.typo.body14R};
      }
    `};
  `,
  Payment: styled.div<{
    design: 'total' | 'plus' | 'normal';
    isTotalShow?: boolean;
    alwaysShown?: boolean;
  }>`
    ${({ alwaysShown }) =>
      alwaysShown
        ? css`
            opacity: 1;
          `
        : css`
            opacity: 0;
          `}
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    ${({ isTotalShow }) =>
      isTotalShow &&
      css`
        animation: fadeIn 0.6s ease forwards;
      `}
    ${({ theme, design }) => css`
      display: ${design === 'plus' ? 'flex' : 'inherit'};
      ${design === 'total' ? theme.typo.body32R : theme.typo.body24R};
      color: ${design === 'plus'
        ? theme.common.colors.turquoise
        : theme.common.colors.mainText};
      fill: ${design === 'plus'
        ? theme.common.colors.turquoise
        : theme.common.colors.mainText};
      //애니메이션 부분
      * {
        color: ${design === 'plus'
          ? theme.common.colors.turquoise
          : theme.common.colors.mainText} !important;
        fill: ${design === 'plus'
          ? theme.common.colors.turquoise
          : theme.common.colors.mainText} !important;
      }
      ${mediaQueries(TABLET)} {
        font-size: ${design === 'total' ? '2vw' : '1.5vw'};
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: ${design === 'total' ? '18.3px' : '16.2px'};
      }

      ${mediaQueries(MOBILE)} {
        ${design === 'total' ? theme.typo.body16R : theme.typo.body14R};
      }
    `};
  `,
  PaymentSplit: styled.div`
    ${({ theme }) => css`
      width: 100%;
      border-bottom: 1px solid ${theme.common.colors.mercury};
      margin-bottom: 2.182em;

      ${mediaQueries(TABLET)} {
        font-size: 0.7vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 8.5px;
      }

      ${mediaQueries(MOBILE)} {
        margin: 16px 0;
      }
    `};
  `,
  BtnContainer: styled.div`
    ${flexCenterCss};

    ${mediaQueries(MOBILE)} {
      width: 100%;
    }
  `,
  ModelBtn: styled(Button)`
    ${({ theme }) => css`
      width: 20.292em;
      height: 3.5em;
      ${theme.typo.head24};

      ${mediaQueries(TABLET)} {
        font-size: 1.5vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 18.3px;
      }

      ${mediaQueries(MOBILE)} {
        width: 45%;
        min-width: 156px;
        height: 52px;
        padding: 16px 0;
        ${theme.typo.head14};
      }
    `};
  `,
  RewardBtn: styled(Button)`
    ${({ theme }) => css`
      width: 20.292em;
      height: 3.5em;
      margin-left: 1em;
      ${theme.typo.head24};

      ${mediaQueries(TABLET)} {
        font-size: 1.5vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 18.3px;
      }

      ${mediaQueries(MOBILE)} {
        width: 45%;
        min-width: 156px;
        height: 52px;
        padding: 16px 0;
        ${theme.typo.head14};
      }
    `};
  `,
};
