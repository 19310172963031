import { ActionType, createAction, createReducer } from 'typesafe-actions';

const prefix = 'skn-mintit-web';

const SET_IS_DEPLOYMENT = `${prefix}/SET_IS_DEPLOYMENT` as const;

const initialState = {
  isDeployment: false,
};

export const setIsDeployment = createAction(
  SET_IS_DEPLOYMENT,
  (atr: boolean) => atr,
)();

const setInfo = {
  setIsDeployment,
};
export type setAction = ActionType<typeof setInfo>;

const reducer = createReducer<{ isDeployment: boolean }, setAction>(
  initialState,
  {
    [SET_IS_DEPLOYMENT]: (state, { payload }) => ({
      isDeployment: payload,
    }),
  },
);

export default reducer;
