import useSWR from 'swr';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
import axios from 'axios';
import MainContent from '../../components/MainContent';
import Timeline from '../../components/Timeline';
import {
  LaptopPriceInquiryLayout,
  SelectedLogo,
  SlideSearchInput,
} from './styles';
import FlexBox from '../FlexBox';
import Animation from '../../components/Animation';
import useInquiryLaptop from '../../hooks/useInquiryLaptop';
import { getPriceInquiryLaptop } from '../../apis/priceInquiryLaptop';
import PriceInquiryLayout from '../../components/PriceInquiryLayout';
import useTitle from '../../hooks/useTitle';
import {
  LAPTOP_CODE,
  NONAME_CODE_DETAIL,
} from '../../common/constants/strings';

const LaptopPriceInquiry = () => {
  useTitle('민팃 - 노트북 시세 조회');

  /**
   * state
   */
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isSelectedSearchItem, setIsSelectedSearchItem] = useState<string>();
  const [fetchData, setFetchData] = useState([]);
  const [testData, setTestData] = useState<any[]>([]);
  const {
    manufacturer,
    series,
    model,
    currentInfo: { category, code },
    setManufacturer,
    setSeries,
    setModel,
    setCurrentInfo,
    clearInfo,
  } = useInquiryLaptop();
  const navigate = useNavigate();

  /**
   * fetch state
   */
  const { data, isValidating } = useSWR(
    [`/api/inquiry/laptop`, category, code],
    getPriceInquiryLaptop,
    {
      dedupingInterval: 0,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
    },
  );

  /**
   * useEffect
   */
  useEffect(() => {
    clearInfo();
    axios
      .request({
        url: 'https://d11o63lgw0n6wa.cloudfront.net/prd/app-front/note-book-models/suggest.json',
        method: 'GET',
      })
      .then((res) => setFetchData(res.data));
  }, []);

  useEffect(() => {
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = () => {
      switch (category) {
        case 'manufacturer':
          window.history.back();
          break;
        case 'series':
          setCurrentInfo({
            category: 'manufacturer',
            code: String(LAPTOP_CODE),
          });
          break;
        case 'model':
          setCurrentInfo({ category: 'series', code: manufacturer.code });
          break;
        default:
          break;
      }
    };

    if (category === 'manufacturer') {
      setSeries({ name: '', code: '' });
      setModel({ name: '', code: '' });
    } else if (category === 'series') {
      setModel({ name: '', code: '' });
    }

    return () => {
      window.onpopstate = null;
    };
  }, [category]);

  /**
   * private function
   */
  const getCurrentCategoryName = useCallback(() => {
    switch (category) {
      case 'manufacturer':
        return '제조사';
      case 'series':
        return '시리즈';
      case 'model':
        return '모델';
      default:
        return '제조사';
    }
  }, [category]);

  const getCurrentCode = useCallback(() => {
    switch (category) {
      case 'manufacturer':
        return manufacturer.code;
      case 'series':
        return series.code;
      case 'model':
        return model.code;
      default:
        return '';
    }
  }, [category, manufacturer, series, model]);

  const getCurrentNumber = useCallback(() => {
    switch (category) {
      case 'manufacturer':
        return '01';
      case 'series':
        return '02';
      case 'model':
        return '03';
      default:
        return '00';
    }
  }, [category]);

  /**
   * event function
   */
  const handleSearchData = (value: string) => {
    if (value.length < 4) {
      setIsSearchVisible(false);
      return;
    }

    setTestData(
      fetchData.filter((item: { cate04_name: string }) =>
        item.cate04_name.toLowerCase().includes(value.toLowerCase()),
      ),
    );
    setIsSearchVisible(true);
  };

  const handleItemClick = useCallback(
    (item: { name: string; code: string }) => {
      switch (category) {
        case 'manufacturer':
          setManufacturer(item);
          setCurrentInfo({ category: 'series', code: item.code });
          break;
        case 'series':
          if (+item.code === NONAME_CODE_DETAIL) {
            setModel(item);
            navigate(`/priceInquiryLaptop/${NONAME_CODE_DETAIL}`);
          }
          setSeries(item);
          setCurrentInfo({ category: 'model', code: item.code });
          break;
        case 'model':
          setModel(item);
          navigate(`/priceInquiryLaptop/${item.code}`);
          break;
        default:
          break;
      }
    },
    [category],
  );

  const handleCategoryClick = (type: string) => {
    switch (type) {
      case 'manufacturer':
        setCurrentInfo({
          category: 'manufacturer',
          code: String(LAPTOP_CODE),
        });
        break;
      case 'series':
        setCurrentInfo({ category: 'series', code: manufacturer.code });
        break;
      case 'model':
        break;
      default:
        break;
    }
  };

  return (
    <PriceInquiryLayout>
      <MainContent>
        <LaptopPriceInquiryLayout>
          <FlexBox justifyContent="space-between" contentGap="130px">
            <Timeline
              className="pc-tablet-only"
              timelineItems={[
                {
                  isActive: manufacturer.name !== '',
                  isSelected: category === 'manufacturer',
                  title: '제조사',
                  text:
                    manufacturer.name !== ''
                      ? manufacturer.name
                      : '선택된 제조사가 없습니다.',
                  onClick: () => handleCategoryClick('manufacturer'),
                },
                {
                  isActive: series.name !== '',
                  isSelected: category === 'series',
                  title: '시리즈',
                  text:
                    series.name !== ''
                      ? series.name
                      : '선택된 시리즈가 없습니다.',
                  onClick: () => handleCategoryClick('series'),
                },
                {
                  isActive: model.name !== '',
                  isSelected: category === 'model',
                  title: '모델',
                  text:
                    model.name !== '' ? model.name : '선택된 모델이 없습니다.',
                  onClick: () => handleCategoryClick('model'),
                },
              ]}
            />
            <div className="list-wrapper">
              <header className="list-header">
                <p className="list-step">
                  <span>{getCurrentNumber()}</span>/03
                </p>
                <h2 className="list-title">
                  중고기기의{window.innerWidth < 720 ? <br /> : ' '}
                  {getCurrentCategoryName()}를 선택해 주세요.
                </h2>
              </header>
              <SlideSearchInput
                isVisible={isSearchVisible}
                isFocused={isSearchFocused}
              >
                <div className="search-input-box">
                  <span className="search-icon" />
                  <input
                    className="search-input"
                    placeholder="모델명을 입력해 주세요 (4글자 이상 입력)"
                    onChange={(e) => handleSearchData(e.target.value)}
                    onBlur={() => {
                      setIsSearchFocused(false);
                      setIsSearchVisible(false);
                    }}
                    onFocus={(e) => {
                      setIsSearchFocused(true);
                      handleSearchData(e.target.value);
                    }}
                  />
                </div>
                <ul className="search-list">
                  {testData.map((item) => (
                    <li
                      key={item.cate04_name}
                      className="search-item"
                      onClick={() => {
                        setIsSelectedSearchItem(item.cate04_num);
                        navigate(`/priceInquiryLaptop/${item.cate04_num}`);
                      }}
                      onKeyPress={() => console.log(item.cate04_num)}
                    >
                      <p>{item.cate04_name}</p>
                      <SelectedLogo
                        isSelected={item.cate04_num === isSelectedSearchItem}
                      />
                    </li>
                  ))}
                </ul>
              </SlideSearchInput>
              {data && !isValidating ? (
                <Animation>
                  <ul className="data-list" style={{ transition: '0.3s' }}>
                    {data.map((item, idx) => (
                      <li
                        className={`data-item-wrapper${
                          item.code === getCurrentCode() ? ' selected' : ''
                        }`}
                        key={idx}
                        onClick={() => handleItemClick(item)}
                        onKeyPress={() => handleItemClick(item)}
                      >
                        <div className="data-item">
                          <div className="data-inner">
                            {item.img ? (
                              <img
                                className="data-img"
                                width="60%"
                                src={item.img}
                                alt={item.name}
                              />
                            ) : null}
                            <p className="data-name">{item.name}</p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </Animation>
              ) : null}
            </div>
          </FlexBox>
        </LaptopPriceInquiryLayout>
      </MainContent>
    </PriceInquiryLayout>
  );
};

export default LaptopPriceInquiry;
