import React from 'react';
import styled from 'styled-components';
import { mapIcon } from 'common/constants/imageUrls';
import { mediaQueries, MOBILE } from '../../../styles/mediaQueries';

interface IMapFilterProps {
  selectFilter: (id: string) => void;
  selectList: string[];
}

const list = [
  // id : api 요청시 필요한 데이터
  { label: 'SKT', id: '1485', icon: mapIcon.SK },
  { label: 'KT', id: '4573', icon: mapIcon.KT },
  { label: 'LGU+', id: '4480', icon: mapIcon.LG },
  { label: '이마트', id: '598', icon: mapIcon.Emart },
  { label: '홈플러스', id: '575', icon: mapIcon.Homeplus },
  { label: '롯데마트', id: '998', icon: mapIcon.Lotte },
  { label: '삼성디지털프라자', id: '923', icon: mapIcon.Samsung },
  { label: '하이마트', id: '4043', icon: mapIcon.Himart },
  { label: '전자랜드', id: '7442', icon: mapIcon.Elec },
  { label: '우체국', id: '3978', icon: mapIcon.Post },
  {
    label: '기타',
    id: '608,611,613,930,935,949,4575,6997',
    icon: mapIcon.Mintit,
  },
];

const MapFilter: React.FunctionComponent<IMapFilterProps> = ({
  selectFilter,
  selectList,
}) => {
  return (
    <MapFilterStyle.Container>
      <MapFilterStyle.Wrapper>
        {list.map((item) => (
          <MapFilterStyle.Btn
            type="button"
            key={item.id}
            isSelect={selectList.includes(item.id)}
            onClick={() => selectFilter(item.id)}
          >
            <MapFilterStyle.Icon src={item.icon} alt={`${item.label} 아이콘`} />
            <MapFilterStyle.Span>{item.label}</MapFilterStyle.Span>
          </MapFilterStyle.Btn>
        ))}
      </MapFilterStyle.Wrapper>
    </MapFilterStyle.Container>
  );
};

const MapFilterStyle = {
  Container: styled.div`
    position: absolute;
    top: 40px;
    left: 40px;
    z-index: 10;
    overflow-x: scroll;
    width: calc(100% - 80px);

    ${mediaQueries(MOBILE)} {
      left: 0;
      top: 80px;
      margin: 0 0 0 24px;
      width: 85%;
    }
  `,
  Wrapper: styled.div`
    display: flex;
    min-width: 990px;
    ${mediaQueries(MOBILE)} 
    }
  `,
  Btn: styled.button<{ isSelect: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 12px;
    border-radius: 64px;
    background-color: ${({ isSelect }) => (isSelect ? `#33ccbd` : `#fff`)};
    margin-right: 16px;
    cursor: pointer;
    color: ${({ isSelect }) => (isSelect ? `#fff` : `#000`)};
    :hover {
      background-color: #33ccbd;
      color: #fff;
    }

    :last-child {
      margin-right: 0;
    }

    @media all and (max-width: 720px) {
      background-color: ${({ isSelect }) => (isSelect ? `#33ccbd` : `#f5f5f5`)};
      :hover {
        background-color: ${({ isSelect }) => (isSelect ? `#33ccbd` : `#fff`)};
        color: ${({ isSelect }) => (isSelect ? `#fff` : `#000`)};
      }
    }
  `,
  Icon: styled.img`
    width: 12px;
    height: 16px;
    margin-right: 6px;
  `,
  Span: styled.div`
    width: max-content;
    font-size: 14px;
    @media all and (max-width: 720px) {
      font-size: 12px;
    }
  `,
};

export default MapFilter;
