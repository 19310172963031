import styled, { css } from 'styled-components';
import {
  mediaQueries,
  MOBILE,
  SCROLL_BREAK,
  TABLET,
} from 'styles/mediaQueries';

export const SubBannerStyle = {
  // SubBanner
  Container: styled.div`
    width: 100%;
    max-width: 1280px;
    border-radius: 16px;
    margin: auto;
  `,
  MainImg: styled.img`
    width: 100%;
    max-width: 1280px;
    height: 63.455em;
    border-radius: 16px 16px 0 0;

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      height: 100%;
    }
  `,
  Wrapper: styled.div`
    ${({ theme }) => css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      background-color: ${theme.common.colors.white};
      padding: 5.455em;
      margin-top: -0.182em;
      border: 1px solid ${theme.common.colors.silver};
      border-top: 0;
      border-radius: 0 0 16px 16px;

      ${mediaQueries(TABLET)} {
        font-size: 0.7vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 8.5px;
      }

      ${mediaQueries(MOBILE)} {
        min-width: auto;
        padding: 20px;
        border: none;
        background-color: ${theme.common.colors.alabaster};
      }
    `}
  `,
  BannerWrapper: styled.div`
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    ${mediaQueries(MOBILE)} {
      align-items: inherit;
    }
  `,
  Label: styled.div`
    ${({ theme }) => css`
      width: 4.833em;
      margin-right: 2em;
      ${theme.typo.head24};
      color: ${theme.common.colors.turquoise};

      ${mediaQueries(TABLET)} {
        font-size: 1.5vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 18.3px;
      }

      ${mediaQueries(MOBILE)} {
        ${theme.typo.head14};
        margin-right: 19px;
        min-width: fit-content;
        padding: 0;
      }
    `}
  `,
  Text: styled.div`
    ${({ theme }) => css`
      ${theme.typo.body24L};
      color: ${theme.common.colors.mainText};

      span {
        color: ${theme.common.colors.turquoise};
      }

      ${mediaQueries(TABLET)} {
        font-size: 1.5vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 18.3px;
      }

      ${mediaQueries(MOBILE)} {
        ${theme.typo.body14L};
        word-break: keep-all;
        text-align: left;
      }
    `}
  `,
};
