import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toUpper } from 'lodash';
import Modal from 'components/Modal';
import GradingModal from 'components/Modal/GradingModal';
import OSInstallDetailModal from 'components/Modal/OSInstallDetailModal';
import usePhoneDetail from 'hooks/usePhoneDetail';
import Footer from 'components/partials/Footer';
import Layout from 'components/Layout';
import Animation from 'components/Animation';
import Atm from 'components/PriceDetail/Atm';
import { LabelType, PriceDetail } from 'components/PriceDetail/@types';
import { getEmptyImageIcon } from 'common/utils';
import {
  gradeImgUrls,
  NumToPrice,
  priceLabel,
} from 'components/PriceDetail/utils';
import { usePriceDetail } from 'hooks/usePriceDetail';
import { ATM_GUIDE } from 'common/constants/pathUrls';
import PriceAnimationTest from 'components/Animation/PriceAnimationTest';
import { PriceDetailStyle } from './styles';

const targetModel = [
  'SM-A605',
  'SM-A326',
  'SM-A202',
  'SM-A826',
  'SM-A805',
  'SM-A716',
  'LM-X410',
  'LM-X415',
  'LM-Q510',
  'LM-V500',
  'LM-V510',
  'LM-G900',
  'LM-G910',
  'LM-Q920',
  'LM-F100',
  'LM-X410',
  'LM-X415',
  'LM-Q510',
  'LM-V500',
  'LM-V510',
  'LM-G900',
  'LM-G910',
  'LM-Q920',
  'LM-F100',
];

const PriceDetailPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  // Modal 오픈 상태 및 이벤트
  const [isGradeModalOpen, setIsGradeModalOpen] = useState(false);
  const [isDetailModelOpen, setIsDetailModelOpen] = useState(false);
  // 등급 모달
  const handleGradeModalOpen = () => setIsGradeModalOpen(true);
  const handleGradeModalClose = () => setIsGradeModalOpen(false);
  // 자세히 보기 모달
  const handleDetailModalOpen = () => setIsDetailModelOpen(true);
  const handleDetailModalClose = () => setIsDetailModelOpen(false);

  const [isTotalShow, setIsTotalShow] = useState(false);

  const { clearPhoneInfoHandler } = usePhoneDetail();

  const param = useParams();

  // 선택된 모델
  const { petname } = param;

  // data fetch hook
  const { PriceData, isLoading } = usePriceDetail(petname);

  const [isImgExist, setIsExist] = useState(true);

  // 다시 조회하기
  const returnToFirst = () => {
    clearPhoneInfoHandler();
    return navigate('/priceInquiry', { replace: true });
  };

  // ATM 이용안내 페이지로
  const toAtmPage = () => {
    return navigate(ATM_GUIDE);
  };

  const renderGradePrice = (
    label: LabelType,
    key: number,
    PriceData?: PriceDetail,
  ) => {
    return (
      <PriceDetailStyle.GradeDetailContainer key={key}>
        <PriceDetailStyle.GradeNameContainer>
          <PriceDetailStyle.GradeImg
            src={gradeImgUrls[`${label}`]}
            width={32}
            height={38}
          />{' '}
          <PriceDetailStyle.Grade>
            {toUpper(label).slice(6, 8)} 등급
          </PriceDetailStyle.Grade>
        </PriceDetailStyle.GradeNameContainer>
        {PriceData && (
          <PriceDetailStyle.GradePrice>
            {isLoading ? '0원' : NumToPrice(PriceData[`${label}`])}
          </PriceDetailStyle.GradePrice>
        )}
      </PriceDetailStyle.GradeDetailContainer>
    );
  };

  const handleImageLoadError = (
    e: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    setIsExist(false);

    if (PriceData?.company) {
      e.currentTarget.src = getEmptyImageIcon(PriceData?.company) || '';
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    clearPhoneInfoHandler();
  }, [PriceData]);

  useEffect(() => {
    const Timer = setTimeout(() => {
      setIsTotalShow(true);
    }, 4500);
    return () => clearInterval(Timer);
  }, []);

  return (
    <>
      <Modal
        visible={isGradeModalOpen}
        onClose={handleGradeModalClose}
        width="580px"
        mobileWidth="min(80vw, 480px)"
      >
        <GradingModal onClose={handleGradeModalClose} />
      </Modal>
      <Modal
        visible={isDetailModelOpen}
        onClose={handleDetailModalClose}
        width="100vw"
        maxWidth="1920px"
        mobileWidth="100vw"
        height="80vh"
        maxHeight="100vh"
        borderRadius="0px"
      >
        <OSInstallDetailModal onClose={handleDetailModalClose} />
      </Modal>
      <Layout>
        <Animation isDeep duration={0.5}>
          <PriceDetailStyle.Container>
            {/* pc이미지 */}
            <PriceDetailStyle.ImgPcWrapper
              isImgLoading={isLoading}
              // isImgNotExist={isImgExist}
            >
              <PriceDetailStyle.Img
                src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/images/models/${PriceData?.model_no}.png`}
                onError={handleImageLoadError}
              />
            </PriceDetailStyle.ImgPcWrapper>
            <PriceDetailStyle.DetailContainer>
              {/* 모바일 이미지 */}
              <PriceDetailStyle.ImgMobileWrapper
                isImgLoading={isLoading}
                // isImgNotExist={isImgExist}
              >
                <PriceDetailStyle.Img
                  src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/images/models/${PriceData?.model_no}.png`}
                  onError={handleImageLoadError}
                />
              </PriceDetailStyle.ImgMobileWrapper>
              {/* 가격 디테일 */}
              <PriceDetailStyle.PriceContainer>
                {/* 추가보상 label */}
                <PriceDetailStyle.CmpYn
                  isCmp={
                    targetModel.includes(PriceData?.model_no || '') ? 'Y' : 'N'
                  }
                >
                  {targetModel.includes(PriceData?.model_no || '')
                    ? '추가보상 모델'
                    : '추가보상 제외 모델'}
                </PriceDetailStyle.CmpYn>
                <PriceDetailStyle.TitleContainer>
                  {PriceData?.petname && (
                    <PriceDetailStyle.PhoneName isLoading={isLoading}>
                      {!isLoading ? PriceData?.petname : '로딩중'}
                    </PriceDetailStyle.PhoneName>
                  )}
                </PriceDetailStyle.TitleContainer>
                <PriceDetailStyle.PriceContent
                  isImgExist={isImgExist}
                  isCmp={targetModel.includes(PriceData?.model_no || '')}
                >
                  {/* 민팃 최대 보상금 */}
                  {PriceData && (
                    <PriceDetailStyle.PriceMintitContainer>
                      <PriceDetailStyle.PriceMintitTitle isLoading={isLoading}>
                        민팃 최대 보상가격
                      </PriceDetailStyle.PriceMintitTitle>
                      <PriceDetailStyle.PriceMintitPrice>
                        <p>{PriceData.price_d.toLocaleString('ko-KR')} 원 ~ </p>
                        <PriceAnimationTest
                          price={PriceData.price_a}
                          width="12px"
                          mobileWidth="min(10px, 2.3vw)"
                          delay={0}
                        />{' '}
                        <p>원</p>
                      </PriceDetailStyle.PriceMintitPrice>
                    </PriceDetailStyle.PriceMintitContainer>
                  )}
                  {/* 추가보상 모델일 경우 추가 보상금 */}
                  {targetModel.includes(PriceData?.model_no || '') && (
                    <PriceDetailStyle.PriceCmpContainer
                      isCmp={
                        targetModel.includes(PriceData?.model_no || '')
                          ? 'Y'
                          : 'N'
                      }
                    >
                      <PriceDetailStyle.PriceCmpTitle>
                        추가 보상금
                      </PriceDetailStyle.PriceCmpTitle>
                      <PriceDetailStyle.PriceCmpPrice>
                        <p>+</p>
                        <PriceAnimationTest
                          price={20000}
                          width="12px"
                          mobileWidth="min(10px, 2.3vw)"
                          delay={2000}
                        />{' '}
                        <p>원</p>
                      </PriceDetailStyle.PriceCmpPrice>
                    </PriceDetailStyle.PriceCmpContainer>
                  )}
                </PriceDetailStyle.PriceContent>
                {/* 추가보상 모델일 경우 총 합계 */}
                {PriceData && targetModel.includes(PriceData?.model_no || '') && (
                  <PriceDetailStyle.PriceTotalContainer>
                    <PriceDetailStyle.PriceTotalTitle>
                      총 합계
                    </PriceDetailStyle.PriceTotalTitle>
                    <PriceDetailStyle.PriceTotalPrice isTotalShow={isTotalShow}>
                      {NumToPrice(
                        PriceData.price_d +
                          (targetModel.includes(PriceData?.model_no || '')
                            ? 20000
                            : 0),
                      )}{' '}
                      ~{' '}
                      {NumToPrice(
                        PriceData.price_a +
                          (targetModel.includes(PriceData?.model_no || '')
                            ? 20000
                            : 0),
                      )}
                    </PriceDetailStyle.PriceTotalPrice>
                  </PriceDetailStyle.PriceTotalContainer>
                )}
              </PriceDetailStyle.PriceContainer>
              <PriceDetailStyle.BtnContainer>
                <PriceDetailStyle.RetryBtn
                  type="secondary"
                  onClick={returnToFirst}
                >
                  다시 조회하기
                </PriceDetailStyle.RetryBtn>
                <PriceDetailStyle.AtmBtn onClick={toAtmPage}>
                  판매방법 알아보기
                </PriceDetailStyle.AtmBtn>
              </PriceDetailStyle.BtnContainer>
              <PriceDetailStyle.GradeContainer>
                <PriceDetailStyle.GradeDescContainer>
                  <PriceDetailStyle.GradeTitle>
                    등급별 시세
                  </PriceDetailStyle.GradeTitle>
                  <PriceDetailStyle.GradeDet onClick={handleGradeModalOpen}>
                    등급 기준
                  </PriceDetailStyle.GradeDet>
                </PriceDetailStyle.GradeDescContainer>
                <PriceDetailStyle.GradeDescPrice>
                  {priceLabel.map((label: any, index) =>
                    renderGradePrice(label, index, PriceData),
                  )}
                </PriceDetailStyle.GradeDescPrice>
              </PriceDetailStyle.GradeContainer>
            </PriceDetailStyle.DetailContainer>
          </PriceDetailStyle.Container>
          {/* atm 이용 안내 */}
          <Atm handleDetailModalOpen={handleDetailModalOpen} />
        </Animation>
      </Layout>
      <Footer />
    </>
  );
};

export default PriceDetailPage;
