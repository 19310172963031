import styled from 'styled-components';
import MainContent from 'components/MainContent';
import PriceInquiryLayout from 'components/PriceInquiryLayout';
import FlexBox from 'pages/FlexBox';
import Animation from 'components/Animation';
import { useNavigate } from 'react-router-dom';
import { SamsungLaptopPriceInquiryLayout } from './style';

const SamsungLaptopTransfer = () => {
  const navigate = useNavigate();

  const handleItemClick = (itemName: string) => {
    navigate(`/samsung-laptop-transfer/${itemName}`);
  };

  const getExplainContent = (itemName: string) => {
    let content;
    if (
      itemName === 'NT950QCG-KN38' ||
      itemName === 'NT930QCG-KN38' ||
      itemName === 'NT950XCJ-KN59' ||
      itemName === 'NT930XCJ-KN39'
    ) {
      content = <p className="data-explain">(홈쇼핑 구매자용)</p>;
    }
    return content;
  };

  return (
    <PriceInquiryLayout>
      <MainContent>
        <SamsungLaptopPriceInquiryLayout>
          <FlexBox justifyContent="space-between" contentGap="130px">
            <div className="list-wrapper">
              <header className="list-header">
                <p className="list-step">
                  <span>01</span>/03
                </p>
                <h2 className="list-title">
                  사용하시던 반납대상 노트북 모델을 확인하시고 선택해 주세요.
                </h2>
              </header>

              <Animation>
                <ul className="data-list" style={{ transition: '0.3s' }}>
                  {SAMSUNG_EVENT_ITEMS.map((item, idx) => (
                    <li
                      className={`data-item-wrapper ${idx.toString()}`}
                      key={idx}
                      onClick={() => handleItemClick(item)}
                      onKeyPress={() => handleItemClick(item)}
                    >
                      <div className="data-item">
                        <div className="data-inner">
                          <p className="data-name">{item}</p>
                          {getExplainContent(item)}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </Animation>

              <NoticeConainer>
                <p>
                  <b>※ 유의사항</b>
                </p>
                <p>- 신상품 구매확인 후 보상신청을 진행 하셔야 합니다.</p>
                <p>
                  - 반납하시는 모델명이 반드시 동일해야 합니다. 다른 경우
                  수취거부로 반송되며, 보상 혜택을 받을 수 없습니다. (재신청
                  불가함)
                </p>
                <p>
                  - 반납 신청 후 택배발송은 14일내 진행하셔야 하며, 반납기기의
                  발송을 하지 않는 경우 혜택은 자동 취소 됩니다.
                </p>
                <p>
                  - 택배 발송 때 운송장번호는 반드시 입력하셔야 합니다. 미입력시
                  확인지연이 발생될 수 있습니다.
                </p>
              </NoticeConainer>
            </div>
          </FlexBox>
        </SamsungLaptopPriceInquiryLayout>
      </MainContent>
    </PriceInquiryLayout>
  );
};

export default SamsungLaptopTransfer;

const SAMSUNG_EVENT_ITEMS = [
  'NT950QCG-XN58',
  'NT950XCJ-KN58',
  'NT767XCM-KN59',
  'NT550XCJ-KN38',
  'NT950QCG-KN38',
  'NT930QCG-KN38',
  'NT950XCJ-KN59',
  'NT930XCJ-KN39',
];

const NoticeConainer = styled.div`
  word-break: keep-all;

  b {
    font-weight: 700;
  }

  p {
    text-indent: -10px;
    padding-left: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;
