import styled, { css, DefaultTheme } from 'styled-components';
import Button from 'components/Button';
import {
  mediaQueries,
  MOBILE,
  SCROLL_BREAK,
  TABLET,
} from 'styles/mediaQueries';
import { DetailDesc, SectionContainer } from '../Styles';

export const BannerLayoutStyle = {
  Container: styled(SectionContainer)`
    ${({
      theme,
      isAlignCenter,
      color,
    }: {
      theme: DefaultTheme;
      isAlignCenter?: boolean;
      color: string;
    }) => css`
      article {
        background-color: ${color};
        flex-wrap: wrap;
        align-items: ${isAlignCenter ? 'center' : 'start'};
        ${isAlignCenter
          ? css`
              align-content: space-between;
            `
          : null};
        justify-content: center;
        padding: 3.091em 3.727em 3.091em 7.273em;
        height: 55em;

        ${mediaQueries(MOBILE)} {
          width: 100%;
          height: 100%;
          align-items: center;
          padding: 40px 16px;
        }

        > h3 {
          color: ${theme.common.colors.mainText};
          margin-bottom: 0.462em;

          ${mediaQueries(MOBILE)} {
            text-align: center;
            order: 1;
          }
        }
      }
    `};
  `,
  Desc: styled(DetailDesc)`
    ${({
      theme,
      isAlignCenter,
    }: {
      theme: DefaultTheme;
      isAlignCenter?: boolean;
    }) => css`
      color: ${theme.common.colors.subText};
      text-align: ${isAlignCenter ? 'center' : 'left'};

      ${mediaQueries(MOBILE)} {
        text-align: center;
        order: 2;
      }
    `};
  `,
  Btn: styled(Button)`
    ${({ theme }) => css`
      ${theme.typo.head24};
      width: 17.25em;
      height: 3.458em;
      padding: 1em 0.667em;
      margin-top: 2.5em;
      ${({ btnColor }: { btnColor: string }) =>
        css`
          background: ${btnColor};
          border: 1px solid ${btnColor};
        `}

      ${mediaQueries(TABLET)} {
        font-size: 1.5vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 18.3px;
      }

      ${mediaQueries(MOBILE)} {
        width: 100%;
        height: 52px;
        ${theme.typo.head14};
        order: 4;
        margin-top: 40px;
      }
    `};
  `,
  Img: styled.img`
    height: 40.818em;
    align-self: end;
    margin-right: 16px;

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      width: 100%;
      height: 0%;
      order: 3;
      margin-right: 0;
      margin-top: 32px;
      align-self: center;
    }
  `,
  TitlImgWrapper: styled.div`
    width: 55em;
    margin-bottom: 3em;

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      width: 90%;
    }
  `,
};
