import styled, { css } from 'styled-components';
import React, { useState } from 'react';
import { closedIcon } from 'common/constants/imageUrls';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';
import Modal from 'components/Modal/index';
import DetailModal from 'components/Modal/GradingModal/DetailModal';
import usePhoneDetail from 'hooks/usePhoneDetail';
import { statusHandler } from './utils';
import { AndroidGradeData, IOSGradeData } from './data';

interface GradeData {
  id: string;
  name: string;
  desc: string;
  isQuestion?: boolean;
  detail?: string;
}

export type Grade = 'A' | 'B' | 'C' | 'D';
const GradeLabel = ['A', 'B', 'C', 'D'];

interface GradingModalProps {
  onClose: () => void;
}

const GradingModal: React.FunctionComponent<GradingModalProps> = ({
  onClose,
}) => {
  const { manufacName } = usePhoneDetail();

  // OS
  const [OSIdx, setOSIdx] = useState<'IOS' | 'Android'>(
    manufacName === '아이폰' ? 'IOS' : 'Android',
  );
  // 등급 기준
  const [gradeIdx, setGradeIdx] = useState<Grade>('A');

  // 상세보기 정보 저장
  const [gradeDetail, setGradeDetail] = useState<GradeData>();
  const [grade, setGrade] = useState<Grade>('A');
  const [questionIdx, setQuestionIdx] = useState<number>(1);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = (info: GradeData, gradeIdx: Grade, idx: number) => {
    setGradeDetail(info);
    setGrade(gradeIdx);
    setQuestionIdx(idx);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => setIsModalOpen(false);
  // 상세보기 모달
  const modalContent = (
    gradeIdx: Grade,
    questionIdx: number,
    gradeDetail?: GradeData,
  ) => {
    return (
      <Modal
        isDark
        visibleDouble={isModalOpen}
        onClose={handleCloseModal}
        width="380px"
        mobileWidth="300px"
      >
        {gradeDetail && (
          <DetailModal
            onClose={handleCloseModal}
            title={gradeDetail?.name}
            grade={gradeIdx}
            detail={gradeDetail?.detail}
            questionIdx={questionIdx}
          />
        )}
      </Modal>
    );
  };

  // 등급 컨텐츠 렌더링
  const renderGradeContent = (
    gradeIdx: Grade,
    dataType: typeof IOSGradeData | typeof AndroidGradeData,
  ) => {
    return (
      <ModalStyle.ContentWrapper first={gradeIdx === 'A'} key={gradeIdx}>
        {dataType[gradeIdx].map((data: GradeData, idx) => (
          <ModalStyle.ContentContainer key={data.id}>
            <ModalStyle.ContentName>
              <ModalStyle.DetailName>{data.name}</ModalStyle.DetailName>
              <ModalStyle.DetailBtn
                isQuestion={data.isQuestion}
                onClick={() => handleOpenModal(data, gradeIdx, idx + 1)}
              >
                ?
              </ModalStyle.DetailBtn>
            </ModalStyle.ContentName>
            <ModalStyle.ContentStatus status={data.desc}>
              {data.desc}
            </ModalStyle.ContentStatus>
          </ModalStyle.ContentContainer>
        ))}
      </ModalStyle.ContentWrapper>
    );
  };

  return (
    <ModalStyle.Container>
      {modalContent(grade, questionIdx, gradeDetail)}
      <ModalStyle.HeaderWrapper>
        <ModalStyle.HeaderTitle>등급기준</ModalStyle.HeaderTitle>
        <ModalStyle.HeaderCloseButton src={closedIcon} onClick={onClose} />
      </ModalStyle.HeaderWrapper>
      <ModalStyle.OSContainer>
        <ModalStyle.OSIos
          active={OSIdx === 'IOS'}
          onClick={() => setOSIdx('IOS')}
        >
          아이폰
        </ModalStyle.OSIos>
        <ModalStyle.OSAndroid
          active={OSIdx === 'Android'}
          onClick={() => setOSIdx('Android')}
        >
          안드로이드
        </ModalStyle.OSAndroid>
      </ModalStyle.OSContainer>
      <ModalStyle.GradeWrapper>
        <ModalStyle.GradeContainer>
          <ModalStyle.GradeA
            active={gradeIdx === 'A'}
            onClick={() => setGradeIdx('A')}
          >
            A등급
          </ModalStyle.GradeA>
          <ModalStyle.GradeB
            active={gradeIdx === 'B'}
            onClick={() => setGradeIdx('B')}
          >
            B등급
          </ModalStyle.GradeB>
          <ModalStyle.GradeC
            active={gradeIdx === 'C'}
            onClick={() => setGradeIdx('C')}
          >
            C등급
          </ModalStyle.GradeC>
          <ModalStyle.GradeD
            active={gradeIdx === 'D'}
            onClick={() => setGradeIdx('D')}
          >
            D등급
          </ModalStyle.GradeD>
        </ModalStyle.GradeContainer>
      </ModalStyle.GradeWrapper>
      {OSIdx === 'IOS' ? (
        <ModalStyle.ContentList>
          {GradeLabel.map(
            (grade: string) =>
              gradeIdx === grade && renderGradeContent(grade, IOSGradeData),
          )}
        </ModalStyle.ContentList>
      ) : (
        <ModalStyle.ContentList>
          {GradeLabel.map(
            (grade: string) =>
              gradeIdx === grade && renderGradeContent(grade, AndroidGradeData),
          )}
        </ModalStyle.ContentList>
      )}
    </ModalStyle.Container>
  );
};

export default GradingModal;

// custom mixin
const OSButton = css<{ active?: boolean }>`
  flex: 1;
  color: ${({ active }) => (active ? '#33CCBD' : '#3A3A3C')};
  background-color: ${({ active }) => !active && '#f5f5f5'};
  border-bottom: 1px solid #e1e1e1;
  ${mediaQueries(MOBILE)} {
    font-size: min(18px, 5vw);
    line-height: 10px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const GradeButton = css<{ active?: boolean }>`
  cursor: pointer;
  height: 100%;
  position: relative;
  color: ${({ active }) => active && '#000'};
  ${mediaQueries(MOBILE)} {
    font-size: min(14px, 4vw);
    font-weight: 700;
    height: 50px;
    line-height: 50px;
  }
`;

// styled-component
const ModalStyle = {
  Container: styled.div`
    ${mediaQueries(MOBILE)} {
      padding: 20px 0;
    }
  `,
  HeaderWrapper: styled.div`
    padding: 32px 40px;
    display: flex;
    justify-content: space-between;
    ${mediaQueries(MOBILE)} {
      padding: 0 20px;
    }
  `,
  HeaderTitle: styled.div`
    font-size: 24px;
    line-height: 35px;
    font-weight: bold;

    ${mediaQueries(MOBILE)} {
      font-size: 16px;
      line-height: 23px;
      font-weight: 700;
      margin-bottom: 21px;
    }
  `,
  HeaderCloseButton: styled.img`
    cursor: pointer;
    ${mediaQueries(MOBILE)} {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  `,
  OSContainer: styled.div`
    display: flex;
    font-size: 24px;
    font-weight: bold;
    height: 64px;
    text-align: center;
    line-height: 64px;
    cursor: pointer;
    ${mediaQueries(MOBILE)} {
      height: min(52px, 15vw);
    }
  `,
  OSIos: styled.div<{ active?: boolean }>`
    ${OSButton};
  `,
  OSAndroid: styled.div<{ active?: boolean }>`
    ${OSButton};
  `,
  // 등급
  GradeWrapper: styled.div`
    border-bottom: 1px solid rgb(225, 225, 225);
  `,
  GradeContainer: styled.li`
    margin: 0 auto;
    width: 278px;
    height: 66px;
    line-height: 66px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #8e8e8e;
    ${mediaQueries(MOBILE)} {
      width: min(238px, 80%);
      height: min(50px, 12vw);
      margin: 0 auto;
      font-size: min(14px, 1vw);
    }
  `,
  GradeA: styled.ul<{ active?: boolean }>`
    ${GradeButton};
  `,
  GradeB: styled.div<{ active?: boolean }>`
    ${GradeButton};
  `,
  GradeC: styled.div<{ active?: boolean }>`
    ${GradeButton};
  `,
  GradeD: styled.div<{ active?: boolean }>`
    ${GradeButton};
  `,
  // 등급 상세 내용
  ContentWrapper: styled.ul<{ first?: boolean }>`
    padding: 24px 40px;
    display: flex;
    flex-direction: column;
    & > div:nth-of-type(1),
    & > div:nth-of-type(1) {
      & > div {
        btn {
          display: block !important;
        }
      }
    }
    & > div:nth-of-type(3) {
      //& > div:first-child {
      button {
        display: ${({ first }) => (first ? 'none' : '')} !important;
      }
      //}
    }
    ${mediaQueries(MOBILE)} {
      padding: 12px min(20px, 5vw) 0 min(20px, 5vw);
    }
  `,
  ContentList: styled.li`
    position: relative;
  `,
  ContentContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    ${mediaQueries(MOBILE)} {
      margin: min(2vw, 8px) 0;
    }
  `,
  ContentName: styled.div`
    display: flex;
    align-items: center;
  `,
  DetailName: styled.div`
    font-size: 14px;
    line-height: 14px;
    ${mediaQueries(MOBILE)} {
      font-size: min(3vw, 14px);
    }
  `,

  DetailBtn: styled.button<{ isQuestion?: boolean }>`
    display: ${({ isQuestion }) => (isQuestion ? 'inline-block' : 'none')};
    color: #fff;
    background-color: #dbdbdb;
    border-radius: 50%;
    font-size: 14px;
    width: 14px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    margin-left: 5px;
    ${mediaQueries(MOBILE)} {
      margin-left: min(1vw, 5px);
      width: min(3vw, 12px);
      height: min(3vw, 12px);
      font-size: min(14px, 2vw);
      line-height: min(3vw, 12px);
    }
  `,
  ContentStatus: styled.div<{ status: string }>`
    padding: 4px 8px;
    font-size: min(12px, 3vw);
    border-radius: 100px;
    background-color: ${({ status }) => statusHandler(status).backgroundColor};
    color: ${({ status }) => statusHandler(status).color};
  `,
};
