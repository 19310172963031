import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { upArrow } from 'common/constants/imageUrls';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';
import Button from 'components/Button';
import theme from 'styles/theme';
import { flexCenterCss, flexSpaceBetween } from 'styles/commonStyle';
import { ATM_FIND } from '../../../common/constants/pathUrls';

interface AtmSectionProps {
  title: string;
  onClick?: () => void;
  children: JSX.Element;
  isButton?: boolean;
  btnText?: string;
  temp?: boolean;
}

const AtmSection: React.FunctionComponent<AtmSectionProps> = ({
  title,
  onClick,
  children,
  isButton,
  btnText,
  temp,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSection = () => setIsOpen((prev) => !prev);

  const linkToMap = () => {
    window.location.href = ATM_FIND;
  };

  return (
    <AtmSectionStyle.Wrapper>
      <AtmSectionStyle.TitleContainer onClick={toggleSection}>
        <AtmSectionStyle.Title>{title}</AtmSectionStyle.Title>
        <AtmSectionStyle.MoreContainer>
          <AtmSectionStyle.More>자세히 보기</AtmSectionStyle.More>
          <AtmSectionStyle.MoreIcon src={upArrow} isOpen={isOpen} />
        </AtmSectionStyle.MoreContainer>
      </AtmSectionStyle.TitleContainer>
      <AtmSectionStyle.Content isOpen={isOpen}>
        <>
          {children}
          {isButton &&
            (temp ? (
              <AtmSectionStyle.Btn onClick={linkToMap}>
                {btnText}
              </AtmSectionStyle.Btn>
            ) : (
              <AtmSectionStyle.Btn onClick={onClick}>
                {btnText}
              </AtmSectionStyle.Btn>
            ))}
        </>
      </AtmSectionStyle.Content>
    </AtmSectionStyle.Wrapper>
  );
};

export default AtmSection;

const fadeIn = keyframes`
  0% {
      opacity: 0;
      transform: translateY(10%);
  }
  to {
      opacity: 1;
      transform: translateZ();
  }
`;

const fadeOut = keyframes`
  0% {
      opacity: 1;
  }
  to {
      opacity: 0;
  }
`;

const AtmSectionStyle = {
  Wrapper: styled.div`
    border-top: 1px solid ${theme.common.colors.silver};
    width: min(800px, 80%);
    margin: 0 auto;
    padding: 32px 0;

    ${mediaQueries(MOBILE)} {
      margin: 0 10%;
      padding: 16px 0 24px;
    }
  `,
  TitleContainer: styled.div`
    ${flexSpaceBetween};
    cursor: pointer;
  `,
  Title: styled.div`
    ${theme.typo.head24};
    ${mediaQueries(MOBILE)} {
      ${theme.typo.head16};
    }
  `,
  MoreContainer: styled.div`
    ${flexCenterCss}
  `,
  More: styled.div`
    ${theme.typo.body18R}
    cursor: pointer;
    margin-right: 8px;

    ${mediaQueries(MOBILE)} {
      display: none;
    }
  `,
  MoreIcon: styled.img<{ isOpen: boolean }>`
    width: 32px;
    height: 32px;
    transition: all 0.4s;
    ${({ isOpen }) =>
      isOpen
        ? css`
            transform: rotate(0);
          `
        : css`
            transform: rotate(180deg);
          `}
    ${mediaQueries(MOBILE)} {
      width: 16px;
      height: 16px;
    }
  `,
  Content: styled.div<{ isOpen: boolean }>`
    position: relative;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    opacity: 0;
    ${({ isOpen }) =>
      isOpen
        ? css`
            animation: ${fadeIn} 0.4s;
            animation-delay: 0.2s;
            animation-fill-mode: forwards;
            max-height: 1000px;
          `
        : css`
            animation: ${fadeOut} 0.3s;
            animation-fill-mode: forwards;
            max-height: 0px;
          `};
    ${mediaQueries(MOBILE)} {
      position: static;
      border: none;
    }
  `,
  Btn: styled(Button)`
    position: absolute;
    top: 32px;
    right: 0;
    width: 127px;
    height: 41px;
    ${theme.typo.head12}
    ${flexCenterCss};
    border-radius: 8px;
    margin: 20px 0 10px;
    ${mediaQueries(MOBILE)} {
      position: static;
    }
  `,
};
