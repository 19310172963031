import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import Layout from 'components/Layout';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';
import { Link, useNavigate } from 'react-router-dom';
import { QuizEventImages } from '../../common/constants/imageUrls';
import {
  HOME_URL,
  QUIZ_EVENT_CHOICE_LINK,
} from '../../common/constants/pathUrls';

const QuizEvent: FunctionComponent = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (new Date() > new Date('2022-04-01 00:00:00')) {
      alert('이미 종료된 이벤트입니다.');
      navigate('/');
    }
  });

  return (
    <Layout>
      <QuizEventStyle.Container>
        <QuizEventStyle.Wrapper>
          <QuizEventStyle.BackgroundImage src={QuizEventImages.pc} />
          <div>
            <QuizEventStyle.ImageButton
              style={{
                width: '37.3%',
                height: '1.15%',
                bottom: '94.8%',
                left: '6.2%',
              }}
            >
              <Link to={HOME_URL}>추가보상 자세히보기</Link>
            </QuizEventStyle.ImageButton>
            <QuizEventStyle.ImageButton
              style={{
                width: '35.3%',
                height: '1.4%',
                bottom: '65.05%',
                left: '32.4%',
              }}
            >
              <a href={QUIZ_EVENT_CHOICE_LINK}>정답 제출하기</a>
            </QuizEventStyle.ImageButton>
            <QuizEventStyle.ImageButton
              style={{
                width: '28%',
                height: '0.9%',
                bottom: '22.75%',
                left: '7.6%',
              }}
            >
              <Link to={HOME_URL}>추가보상 자세히보기</Link>
            </QuizEventStyle.ImageButton>
          </div>
        </QuizEventStyle.Wrapper>
      </QuizEventStyle.Container>
    </Layout>
  );
};

export default QuizEvent;

const QuizEventStyle = {
  Container: styled.main`
    width: 100%;
    max-width: 1220px;
    margin: 0 auto;

    ${mediaQueries(MOBILE)} {
      padding: 0;
    }
  `,
  Wrapper: styled.section`
    position: relative;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
  `,
  BackgroundImage: styled.img`
    width: 100%;
  `,
  ImageButton: styled.div`
    position: absolute;
    text-indent: -9999px;

    & > a,
    & > button {
      display: flex;
      width: 100%;
      height: 100%;
    }
  `,
};
