import styled from 'styled-components';

const MarketingAgree = () => {
  return (
    <MarketingAgreeLayout>
      <h1>[마케팅 이용동의]</h1>
      <div>
        <ul>
          <li>수집항목 : 이름, 휴대폰번호</li>
          <li>
            수집 및 이용목적 : SMS을 통한 민팃 이벤트 홍보/마케팅 목적으로 활용
          </li>
          <li>보유기간 : 3개월</li>
        </ul>
        <p>
          *마케팅 활용에 동의하지 않을 권리가 있으며, 미동의 시 이벤트 참여가
          불가합니다.
        </p>
      </div>
    </MarketingAgreeLayout>
  );
};

const MarketingAgreeLayout = styled.section`
  padding: 60px 40px;
  max-width: 1440px;
  margin: 0 auto;
  h1 {
    font-size: 24px;
    margin-bottom: 18px;
  }

  ul {
    margin-bottom: 24px;
  }

  li {
    font-size: 18px;
    margin-bottom: 12px;
  }

  p {
    font-size: 16px;
  }
`;

export default MarketingAgree;
