import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import persistStore, { store } from 'redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { SWRConfig } from 'swr';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import App from 'App';
import reportWebVitals from './reportWebVitals';
import GlobalStyles from './styles/GlobalStyles';
import ScrollTop from './components/ScrollTop';

ReactGA.initialize('UA-186701653-2');
ReactGA.pageview(window.location.pathname + window.location.search);

const tagManagerArgs = {
  gtmId: 'GTM-TXJ58TP',
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistStore}>
        <SWRConfig>
          <BrowserRouter>
            <GlobalStyles />
            <ScrollTop />
            <App />
          </BrowserRouter>
        </SWRConfig>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
