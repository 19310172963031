import styled, { css } from 'styled-components';

export interface FlexBoxLayoutProps {
  direction?: 'row' | 'column';
  justifyContent?: 'center' | 'start' | 'end' | 'space-between';
  withBorder?: boolean;
  padding?: string;
  contentGap?: string;
  rowCount?: number;
}

export const FlexBoxLayout = styled.div<FlexBoxLayoutProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justifyContent }) => justifyContent};
  width: 100%;
  padding: ${({ padding }) => padding};
  ${({ withBorder }) =>
    withBorder
      ? css`
          border: 1px solid #222;
          border-radius: 8px;
        `
      : null}
  box-sizing: border-box;

  ${({ rowCount }) =>
    rowCount
      ? css`
          flex-wrap: wrap;
          border: red 1px solid;
          & > * {
            width: ${100 / rowCount}% !important;
          }
        `
      : null}

  & > *:not(:last-child) {
    ${({ direction, contentGap }) => {
      if (direction === 'row') {
        return css`
          margin-right: ${contentGap};
        `;
      }

      if (direction === 'column') {
        return css`
          margin-bottom: ${contentGap};
        `;
      }

      return css``;
    }}
  }
`;

FlexBoxLayout.defaultProps = {
  direction: 'row',
  justifyContent: 'center',
  withBorder: false,
  padding: '20px',
  contentGap: '12px',
};

export default {
  FlexBoxLayout,
};
