import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { PriceInquiryLaptop } from 'redux/@types';
import { LAPTOP_CODE } from '../../common/constants/strings';

const prefix = 'skn-mintit-web';
const SET_MANUFACTURER_LAPTOP = `${prefix}/SET_MANUFACTURER_LAPTOP` as const;
const SET_SERIES_LAPTOP = `${prefix}/SET_SERIES_LAPTOP` as const;
const SET_MODEL_LAPTOP = `${prefix}/SET_MODEL_LAPTOP` as const;
const SET_CURRENT_INFO_LAPTOP = `${prefix}/SET_CURRENT_INFO_LAPTOP` as const;
const CLEAR_INFO_LAPTOP = `${prefix}/CLEAR_INFO_LAPTOP` as const;

const initialState = {
  manufacturer: { name: '', code: '' },
  series: { name: '', code: '' },
  model: { name: '', code: '' },
  currentInfo: { category: 'manufacturer', code: String(LAPTOP_CODE) },
};

export const setManufacturerLaptop = createAction(
  SET_MANUFACTURER_LAPTOP,
  (atr: { name: string; code: string }) => atr,
)();
export const setSeriesLaptop = createAction(
  SET_SERIES_LAPTOP,
  (atr: { name: string; code: string }) => atr,
)();
export const setModelLaptop = createAction(
  SET_MODEL_LAPTOP,
  (atr: { name: string; code: string }) => atr,
)();
export const setCurrentInfoLaptop = createAction(
  SET_CURRENT_INFO_LAPTOP,
  (atr: { category: string; code: string }) => atr,
)();
export const clearInfoLaptop = createAction(CLEAR_INFO_LAPTOP)();

const setInfo = {
  setManufacturer: setManufacturerLaptop,
  setSeries: setSeriesLaptop,
  setModel: setModelLaptop,
  setCurrentInfo: setCurrentInfoLaptop,
  clearPhoneInfo: clearInfoLaptop,
};
export type setAction = ActionType<typeof setInfo>;

const reducer = createReducer<PriceInquiryLaptop, setAction>(initialState, {
  [SET_MANUFACTURER_LAPTOP]: (state, { payload }) => ({
    ...state,
    manufacturer: payload,
  }),
  [SET_SERIES_LAPTOP]: (state, { payload }) => ({
    ...state,
    series: payload,
  }),
  [SET_MODEL_LAPTOP]: (state, { payload }) => ({
    ...state,
    model: payload,
  }),
  [SET_CURRENT_INFO_LAPTOP]: (state, { payload }) => ({
    ...state,
    currentInfo: payload,
  }),
  [CLEAR_INFO_LAPTOP]: (state) => ({
    ...state,
    manufacturer: { name: '', code: '' },
    series: { name: '', code: '' },
    model: { name: '', code: '' },
    currentInfo: { category: 'manufacturer', code: String(LAPTOP_CODE) },
  }),
});

export default reducer;
