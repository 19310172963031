import {
  mainIcon,
  priceGradeA,
  priceGradeB,
  priceGradeC,
  priceGradeD,
  rightArrow,
} from 'common/constants/imageUrls';
import styled from 'styled-components';
import { media } from '../../styles/theme';

export const TitleAndText = styled.div<{
  fontSize: string;
  mobileFontSize: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${({ fontSize }) => fontSize};

  *:first-child {
    color: #8e8e8e;
  }
  *:last-child {
    font-weight: bold;
  }

  ${media.mobile} {
    font-size: ${({ mobileFontSize }) => mobileFontSize};
  }
`;

export const GradePriceItem = styled.li<{ grade: 'A' | 'B' | 'C' | 'D' }>`
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    text-indent: -9999px;
    width: 56px;
    height: 56px;
    margin-right: 24px;
    background: url(${({ grade }) => {
      switch (grade) {
        case 'A':
          return priceGradeA;
        case 'B':
          return priceGradeB;
        case 'C':
          return priceGradeC;
        case 'D':
          return priceGradeD;
        default:
          return '';
      }
    }});
  }
  .text {
    h3 {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: bold;
    }

    p {
      font-size: 14px;
      line-height: 18px;
      color: #8e8e8e;
    }
  }
  .price {
    margin-left: auto;
    font-size: 24px;
    font-weight: bold;
  }

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  ${media.mobile} {
    span {
      flex: 0 0 auto;
      margin-right: 12px;
    }
    .text {
      flex-grow: 1;
      h3 {
        margin-bottom: 2px;
        font-size: 16px;
      }
      p {
        font-size: 12px;
      }
    }

    .price {
      flex: 0 0 auto;
      width: fit-content;
      margin-left: 16px;
      font-size: 16px;
    }
  }
`;

export const BudsPriceInquiryDetailLayout = styled.section`
  padding: 120px 160px;

  .empty-data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50vh;
    font-size: 20px;
  }

  .detail {
    display: flex;

    // 상세 이미지
    .side {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 30vw;
      max-width: 430px;
      min-width: 345px;
      height: fit-content;
      margin-right: 60px;

      .side__logo {
        position: absolute;
      }
    }

    .content {
      flex: 1;

      // 상세 컨텐츠
      .content__item {
        .header {
          padding-bottom: 24px;

          .header__name {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            font-size: 32px;
            font-weight: bold;

            span {
              order: 1;
              font-size: 14px;
              color: #33ccbd;
              border: 1px solid #33ccbd;
              padding: 8px 18px;
              border-radius: 100px;
              margin-left: 16px;
            }
          }

          .header__category-list {
            display: flex;
            align-items: center;

            .header__category-item {
              font-size: 16px;
              color: #8e8e8e;

              &:not(:last-child) {
                display: flex;
                align-items: center;
                position: relative;
                margin-right: 24px;

                &::before {
                  position: absolute;
                  right: -12.5px;
                  content: '';
                  display: block;
                  width: 1px;
                  height: 80%;
                  background: #8e8e8e;
                }
              }
            }
          }
        }
        .body {
          padding: 28px 0;
          border-top: 1px solid #e1e1e1;
          border-bottom: 1px solid #e1e1e1;

          .body__item {
            &.add-compensation {
              position: relative;
              padding: 24px;
              border: 2px solid #33ccbd;
              border-radius: 24px;

              .add-compensation__icon {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: -12px;
                left: 50%;
                transform: translate(-50%);
                content: '';
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                color: #fff;
                background: #33ccbd;
                &::after {
                  position: absolute;
                  content: '';
                  display: block;
                  width: 12px;
                  height: 2px;
                  border-radius: 100px;
                  background: #fff;
                }
                &::before {
                  position: absolute;
                  content: '';
                  display: block;
                  width: 2px;
                  height: 12px;
                  border-radius: 100px;
                  background: #fff;
                }
              }
            }

            .add-compensation__title {
              margin-bottom: 16px;
              color: #33ccbd;

              h3 {
                color: #33ccbd;
                font-weight: bold;
              }
            }

            .notice {
              font-size: 14px;

              .notice__title {
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                font-weight: bold;
                color: #33ccbd;

                span {
                  content: '';
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 16px;
                  height: 16px;
                  margin-right: 8px;
                  background: url(${mainIcon.warningIcon}) no-repeat center
                    center;
                  background-size: contain;
                }
              }

              .notice__list {
                padding-left: 8px;
                .notice__item {
                  display: flex;
                  align-items: start;
                  line-height: 20px;
                  color: #8e8e8e;

                  span {
                    flex: 0 0 auto;
                    position: relative;
                    top: 8px;
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    margin-right: 8px;
                    border-radius: 100%;
                    background: #8e8e8e;
                  }

                  &:not(:last-child) {
                    margin-bottom: 6px;
                  }
                }
              }
            }

            &:not(:last-child) {
              margin-bottom: 28px;
            }
          }
        }
        .footer {
          padding: 30px 0;

          .footer__total {
            font-weight: bold;
            margin-bottom: 24px;
          }

          .footer__button {
            width: 100%;
            padding: 18px 0;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
      .content__price {
        margin-bottom: 30px;
        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 30px 0;
          .header__title {
            font-size: 24px;
            font-weight: bold;
          }
          .header__button {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #8e8e8e;
            padding: 0;

            span {
              position: relative;
              top: 1px;
              display: inline-block;
              width: 20px;
              height: 20px;
              background: url(${rightArrow}) no-repeat center center;
              background-size: contain;
            }
          }
        }
      }
      .content__how-to {
        padding: 24px 0;
        .header {
          padding: 24px 0;

          .header__title {
            font-size: 24px;
            font-weight: bold;
          }
        }
        .body {
          .body__icon-list {
            display: flex;
            justify-content: space-between;
            margin-bottom: 32px;

            .body__icon-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 80px;
              text-align: center;

              .body__icon-img-box {
                display: flex;
                align-items: center;
                position: relative;
                width: 100%;
                margin: 0 auto 4px;
              }

              p {
                width: max-content;
                font-size: 12px;
                line-height: 18px;
                color: #4b4b4b;
              }
            }
            .body__icon-arrow {
              position: relative;
              top: 40px;
              transform: translateY(-50%);
              width: 20px;
              height: 20px;
              background: url(${rightArrow}) no-repeat;
              background-size: contain;
            }
          }
          .body__how-to-list {
            padding: 24px;
            background: #fafafa;
            border-radius: 24px;

            .body__how-to-item {
              .body__how-to-text {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: bold;
                line-height: 24px;
                color: #4b4b4b;

                span {
                  flex: 0 0 auto;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 32px;
                  height: 32px;
                  margin-right: 12px;
                  border-radius: 50%;
                  background: #eee;
                  color: #4b4b4b;
                }
              }
              .body__how-to-option {
                display: block;
                margin-top: 12px;
                padding-left: 44px;
                font-size: 14px;
                line-height: 18px;
                color: #8e8e8e;
              }

              :not(:last-child) {
                margin-bottom: 24px;
              }
            }
          }
        }
      }
    }
  }

  ${media.mobile} {
    padding: 0;

    .empty-data {
      font-size: 16px;
    }

    .detail {
      flex-direction: column;

      // 상세 이미지
      .side {
        max-width: initial;
        min-width: auto;
        width: 100%;
        background: #fafafa;
        margin: 0 0 24px;
      }

      .content {
        padding: 0 16px;
        .content__item {
          .header {
            .header__name {
              flex-direction: column;
              align-items: flex-start;
              font-size: 24px;
              margin-bottom: 12px;

              span {
                order: 0;
                margin: 0 0 12px;
                padding: 11px 18px;
                font-size: 12px;
              }
            }
          }
          .body {
            .body__item {
              .notice {
                .notice__list {
                  .notice__item {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
        .content__price {
          .header {
            .header__title {
              font-size: 16px;
            }
          }
        }
        .content__how-to {
          .header {
            padding: 30px 0;

            .header__title {
              font-size: 16px;
              font-weight: bold;
            }
          }
          .body {
            .body__icon-list {
              justify-content: center;
              flex-wrap: wrap;

              .body__icon-item {
                width: 27%;

                .body__icon-img-box {
                  width: 80%;
                }
                :nth-child(1) {
                  margin-bottom: 24px;
                }
              }
              .body__icon-arrow {
                top: 11vw;
              }
            }
            .body__how-to-list {
              padding: 16px;
              .body__how-to-item {
                .body__how-to-text {
                  align-items: flex-start;
                  font-size: 14px;
                  span {
                  }
                }
                .body__how-to-option {
                  margin-top: 8px;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
