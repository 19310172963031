import { useEffect, useState } from 'react';

const useWindowResize = () => {
  const [isMobile, setIsMobile] = useState(true);
  useEffect(() => {
    const updateWindowWidth = () => {
      if (window.innerWidth >= 720) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    };
    window.addEventListener('resize', updateWindowWidth);
    return () => window.removeEventListener('resize', updateWindowWidth);
  }, []);

  return { isMobile };
};

export default useWindowResize;
