import useSWR from 'swr';
import { HttpMethod, request } from 'common/network';
import { searchPriceDetail } from 'common/constants/urls';
import { PriceDetail } from 'components/PriceDetail/@types';
import { useNavigate } from 'react-router-dom';
import { PRICE_INQUIRY_ERROR } from 'common/constants/pathUrls';

// 시세 조회 hook
export const usePriceDetail = (petname?: string) => {
  const navigate = useNavigate();

  const { data, error, isValidating } = useSWR<PriceDetail>(
    ['priceDetail', petname],
    async () => {
      let res;
      try {
        res = await request(HttpMethod.POST, searchPriceDetail, {
          body: { petname },
        });
      } catch (e) {
        navigate(PRICE_INQUIRY_ERROR, { replace: true });
      }
      if (res?.data?.dataset === {}) {
        navigate(PRICE_INQUIRY_ERROR, { replace: true });
      }
      if (!res?.data?.dataset?.petname) {
        navigate(PRICE_INQUIRY_ERROR, { replace: true });
      }
      return res?.data.dataset;
    },
    { revalidateOnFocus: true },
  );

  return {
    PriceData: data,
    isLoading: isValidating,
    error,
  };
};
