import React, { FunctionComponent } from 'react';
import Section from 'components/main/Section';
import { BannerData } from 'common/constants/main';
import { BottomText, BottomTextWrap } from 'components/main/Styles';
import { mainIcon } from 'common/constants/imageUrls';
import { SubBannerStyle } from './style';

interface ISubBanner {
  isFixed: boolean;
}

const SubBanner: FunctionComponent<ISubBanner> = ({ isFixed }) => {
  const renderBanner = () => {
    return BannerData.map((item, i) => (
      <SubBannerStyle.BannerWrapper key={i}>
        <SubBannerStyle.Label>{item.label}</SubBannerStyle.Label>
        <SubBannerStyle.Text dangerouslySetInnerHTML={{ __html: item.value }} />
      </SubBannerStyle.BannerWrapper>
    ));
  };

  return (
    <Section
      first
      isFixed={isFixed}
      title="갤럭시 퀀텀3 / 갤럭시 점프2 출시기념<br/>민팃 X 삼성 추가보상 이벤트"
      subtitle={`갤럭시 퀀텀3 / 갤럭시 점프2 개통하고${
        window.innerWidth < 720 ? '<br />' : ' '
      } 민팃으로 중고폰 판매하시면,<br />무조건 <span>2만원</span>의 추가 보상금을 드립니다.`}
    >
      <SubBannerStyle.Container>
        <SubBannerStyle.MainImg
          src={
            window.innerWidth > 720
              ? mainIcon.subBanner
              : mainIcon.subBannerMobile
          }
          alt="sub banner 이미지"
        />
        <SubBannerStyle.Wrapper>{renderBanner()}</SubBannerStyle.Wrapper>
      </SubBannerStyle.Container>
      <BottomTextWrap>
        <BottomText>
          <span />
          이벤트 기간은 휴대폰 개통일 기준이며,
          <br className="mobile-only" />
          &nbsp;사전예약 고객의 개통이 시작되면
          <br className="mobile-only" />
          &nbsp;4월 26일부터 이벤트 신청이 가능합니다.
        </BottomText>
      </BottomTextWrap>
    </Section>
  );
};

export default SubBanner;
