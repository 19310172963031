export const maxSizeHandler = (size: 'sm' | 'md' | 'lg') => {
  if (size === 'sm') return '123px';
  if (size === 'md') return '154px';
  return '243px';
};

export const sizeHandler = (size: 'sm' | 'md' | 'lg') => {
  if (size === 'sm') return '123px';
  if (size === 'md') return '154px';
  return '243px';
};

export const fontSizeHandler = (size: 'sm' | 'md' | 'lg') => {
  if (size === 'sm') return '14px';
  if (size === 'md') return '18px';
  return '20px';
};

// media query
export const mobileSizeHandler = (size: 'sm' | 'md' | 'lg') => {
  if (size === 'sm') return 'min(80px, 22vw)';
  if (size === 'md') return '25vw';
  return '40vw';
};

export const mobileFontSizeHandler = (size: 'sm' | 'md' | 'lg') => {
  if (size === 'sm') return 'min(10px, 2.5vw)';
  if (size === 'md') return 'min(16px, 4vw)';
  return 'min(16px, 4vw)';
};

// test
// export const mobileSizeHandler = (size: 'sm' | 'md' | 'lg') => {
//   if (size === 'sm') return '79px';
//   if (size === 'md') return '97px';
//   if (size === 'lg') return '148px';
// }
