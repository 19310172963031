import styled from 'styled-components';

export interface MainContentLayoutProps {
  maxWidth?: string;
}

export const MainContentLayout = styled.main<MainContentLayoutProps>`
  max-width: ${({ maxWidth, theme }) =>
    maxWidth ?? theme.contentWidth.mainMaxWidth}px;
  margin: 0 auto;
`;
