import styled, { css } from 'styled-components';

export const SectionStyle = {
  RewardInfo: styled.div`
    ${({ theme }) => css`
      display: inline-block;
      padding: 0.417em 1.333em;
      ${theme.typo.head24};
      margin-bottom: 1.667em;
      color: ${theme.common.colors.white};
      background-color: ${theme.common.colors.turquoise};
      border-radius: 100px;

      @media screen and (max-width: 1440px) {
        font-size: 1.5vw;
      }

      @media screen and (max-width: 1220px) {
        font-size: 18.3px;
      }

      @media screen and (max-width: 720px) {
        padding: 10px 28px;
        ${theme.typo.head12};
      }
    `}
  `,
};
