import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducer';
import { PriceInquiryTablet } from '../../redux/@types';
import {
  clearInfoTablet,
  setCurrentInfoTablet,
  setManufacturerTablet,
  setModelTablet,
  setSeriesTablet,
} from '../../redux/modules/priceInquiryTablet';

const useInquiryTablet = () => {
  const dispatch = useDispatch();
  const { manufacturer, series, model, currentInfo } = useSelector<
    RootState,
    PriceInquiryTablet
  >((state) => state.priceInquiryTablet);

  const setManufacturer = (item: { name: string; code: string }) => {
    dispatch(setManufacturerTablet(item));
  };
  const setSeries = (item: { name: string; code: string }) => {
    dispatch(setSeriesTablet(item));
  };
  const setModel = (item: { name: string; code: string }) => {
    dispatch(setModelTablet(item));
  };

  const setCurrentInfo = (item: { category: string; code: string }) => {
    dispatch(setCurrentInfoTablet(item));
  };

  const clearInfo = () => {
    dispatch(clearInfoTablet());
  };

  return {
    manufacturer,
    series,
    model,
    currentInfo,
    setManufacturer,
    setSeries,
    setModel,
    setCurrentInfo,
    clearInfo,
  };
};

export default useInquiryTablet;
