import { useCallback, useState } from 'react';
import _ from 'lodash';

export const useDebounce = (obj: any = null, wait = 300) => {
  const [state, setState] = useState(obj);
  const setDebouncedState = (_value: any) => {
    debounce(_value);
  };

  const debounce = useCallback(
    _.debounce((_props) => {
      setState(_props);
    }, wait),
    [],
  );
  return [state, setDebouncedState];
};
