import styled, { css, DefaultTheme } from 'styled-components';
import {
  mediaQueries,
  MOBILE,
  SCROLL_BREAK,
  TABLET,
} from 'styles/mediaQueries';
import { mainIcon } from 'common/constants/imageUrls';
import { flexColumnCenterCss } from 'styles/commonStyle';

interface IType {
  theme: DefaultTheme;
  first?: boolean;
  isFixed?: boolean;
}

export const SectionContainer = styled.section<IType>`
  ${({ theme, first, isFixed, color }) => css`
    width: 100vw;
    ${color &&
    css`
      background-color: ${color};
    `};

    article {
      ${flexColumnCenterCss};
      max-width: 1440px;
      min-width: 1220px;
      margin: ${isFixed ? theme.header.height : '0'} auto 0;
      padding: 10.909em 7.273em;
      text-align: center;
      overflow-x: hidden;

      ${mediaQueries(TABLET)} {
        font-size: 0.7vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 8.5px;
      }

      ${mediaQueries(MOBILE)} {
        min-width: auto;
        margin-top: ${first && theme.header.height};
        padding: 40px 16px;
      }

      > h3 {
        margin-bottom: 0.462em;
        word-break: keep-all;
        color: ${theme.common.colors.mainText};
        ${theme.typo.head52};

        ${mediaQueries(TABLET)} {
          font-size: 3.5vw;
        }

        ${mediaQueries(SCROLL_BREAK)} {
          font-size: 42.7px;
        }

        ${mediaQueries(MOBILE)} {
          margin-bottom: 16px;
          ${theme.typo.head26};
        }
      }

      > p {
        color: ${theme.common.colors.subText};
        margin-bottom: 2.143em;
        ${theme.typo.body28R};

        > span {
          font-weight: ${theme.fontWeights.bold};
          color: ${theme.common.colors.turquoise};
        }

        ${mediaQueries(TABLET)} {
          font-size: 1.7vw;
        }

        ${mediaQueries(SCROLL_BREAK)} {
          font-size: 20.74px;
        }

        ${mediaQueries(MOBILE)} {
          ${theme.typo.body14R};
        }
      }

      > h4 {
        color: ${theme.common.colors.turquoise};
        word-break: keep-all;
        ${theme.typo.head24};

        ${mediaQueries(TABLET)} {
          font-size: 1.5vw;
        }

        ${mediaQueries(SCROLL_BREAK)} {
          font-size: 18.3px;
        }

        ${mediaQueries(MOBILE)} {
          ${theme.typo.head14}
        }
      }

      .detail_desc {
        ${theme.typo.body24R};
        color: ${theme.common.colors.mainText};
        text-align: left;
        word-break: keep-all;

        ${mediaQueries(TABLET)} {
          font-size: 1.5vw;
        }

        ${mediaQueries(SCROLL_BREAK)} {
          font-size: 18.1px;
        }

        ${mediaQueries(MOBILE)} {
          ${theme.typo.body16R};
        }
      }
    }
  `};
`;

export const Title = styled.div`
  ${({ theme }) => css`
    margin-bottom: 0.462em;
    color: ${theme.common.colors.mainText};
    ${theme.typo.head52};

    ${mediaQueries(TABLET)} {
      font-size: 3.5vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 42.7px;
    }

    ${mediaQueries(MOBILE)} {
      margin-bottom: 16px;
      ${theme.typo.head26};
    }
  `}
`;

export const DetailTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.common.colors.turquoise};
    word-break: keep-all;

    ${theme.typo.head24}
    ${mediaQueries(TABLET)} {
      font-size: 1.5vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 18.3px;
    }

    ${mediaQueries(MOBILE)} {
      ${theme.typo.head14}
    }
  `}
`;

export const DetailDesc = styled.div`
  ${({ theme }) => css`
    ${theme.typo.body24R};
    color: ${theme.common.colors.mainText};
    font-size: 24px;
    line-height: 1.5em;
    color: #1f1f1f;

    text-align: left;
    word-break: keep-all;

    ${mediaQueries(TABLET)} {
      font-size: 1.5vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 18.1px;
    }

    ${mediaQueries(MOBILE)} {
      ${theme.typo.body16R};
    }
  `}
`;

export const BottomTitle = styled.div`
  ${({ theme }) => css`
    ${theme.typo.body24R};
    color: ${theme.common.colors.mainText};
    font-weight: 400;
    margin: 80px 0 16px 0;

    ${mediaQueries(MOBILE)} {
      ${theme.typo.body12R};
      width: 80%;
      margin: 12px auto 4px;
      word-break: keep-all;
    }
  `}
`;

export const BottomDesc = styled.div`
  ${({ theme }) => css`
    ${theme.typo.body16R};
    color: ${theme.common.colors.mainText};

    ${mediaQueries(TABLET)} {
      font-size: 1vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 12px;
    }

    ${mediaQueries(MOBILE)} {
      ${theme.typo.body12R};
      word-break: keep-all;
    }
  `}
`;

export const BottomTextWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 5em 0 1em 0;

  ${mediaQueries(TABLET)} {
    font-size: 1.3vw;
  }

  ${mediaQueries(SCROLL_BREAK)} {
    font-size: 15.86px;
  }

  ${mediaQueries(MOBILE)} {
    align-items: flex-start;
    margin: 40px auto 0;
  }
`;

export const BottomText = styled.p`
  ${({ theme }) => css`
    ${theme.typo.body16R};
    color: ${theme.common.colors.mainText};
    display: flex;
    align-items: center;
    width: 100%;

    span {
      display: block;
      width: 17px;
      height: 17px;
      background: url(${mainIcon.warningIcon}) no-repeat center center;
      background-size: contain;
      margin-right: 6px;
    }

    ${mediaQueries(TABLET)} {
      font-size: 1.3vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 15.86px;
    }

    ${mediaQueries(MOBILE)} {
      position: relative;
      align-items: flex-start;
      word-break: keep-all;
      ${theme.typo.body14R};
      color: ${theme.common.colors.subText};

      span {
        position: absolute;
        top: 1.7px;
        left: -24px;
        width: 18px;
        height: 18px;
        ${({ indent }: { indent?: number }) =>
          indent
            ? css`
                left: ${indent}px;
              `
            : null}
      }
    }
  `}
`;
