import {
  appleLogoIcon,
  dellLogoIcon,
  deviceImg,
  hpLogoIcon,
  lenovoLogoIcon,
  lgLogoIcon,
  msLogoIcon,
  samsungLogoIcon,
} from '../constants/imageUrls';
import {
  APPLE_CODE_LAPTOP,
  APPLE_CODE_TABLET,
  APPLE_CODE_WATCH,
  DELL_CODE_LAPTOP,
  HP_CODE_LAPTOP,
  LENOVO_CODE_LAPTOP,
  LG_CODE_LAPTOP,
  MS_CODE_LAPTOP,
  SAMSUNG_CODE_BUDS,
  SAMSUNG_CODE_LAPTOP,
  SAMSUNG_CODE_TABLET,
  SAMSUNG_CODE_WATCH,
} from '../constants/strings';

export const getBrandImg = (
  code: number,
  type: 'laptop' | 'tablet' | 'watch' | 'buds',
): string => {
  if (type === 'laptop') {
    switch (code) {
      case SAMSUNG_CODE_LAPTOP:
        return samsungLogoIcon;
      case APPLE_CODE_LAPTOP:
        return appleLogoIcon;
      case LG_CODE_LAPTOP:
        return lgLogoIcon;
      case HP_CODE_LAPTOP:
        return hpLogoIcon;
      case MS_CODE_LAPTOP:
        return msLogoIcon;
      case DELL_CODE_LAPTOP:
        return dellLogoIcon;
      case LENOVO_CODE_LAPTOP:
        return lenovoLogoIcon;
      default:
        return '';
    }
  }
  if (type === 'tablet') {
    switch (code) {
      case SAMSUNG_CODE_TABLET:
        return samsungLogoIcon;
      case APPLE_CODE_TABLET:
        return appleLogoIcon;
      default:
        return '';
    }
  }

  if (type === 'watch') {
    switch (code) {
      case SAMSUNG_CODE_WATCH:
        return samsungLogoIcon;
      case APPLE_CODE_WATCH:
        return appleLogoIcon;
      default:
        return '';
    }
  }

  if (type === 'buds') {
    switch (code) {
      case SAMSUNG_CODE_BUDS:
        return samsungLogoIcon;
      default:
        return '';
    }
  }

  return '';
};

export const getCsrf = () => {
  let text = '';
  const length = 32;
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  const csrf = btoa(text);

  return csrf;
};

export const getEmptyImageIcon = (company: string) => {
  if (company.includes('삼성전자')) {
    return deviceImg.SamsungDevice;
  }
  if (company.includes('애플')) {
    return deviceImg.AppleDevice;
  }
  if (company.includes('LG전자')) {
    return deviceImg.LGDevice;
  }
  if (company.includes('팬택')) {
    return deviceImg.PantechDevice;
  }
  if (company.includes('블랙베리')) {
    return deviceImg.BlackberryDevice;
  }
  if (company.includes('TG앤컴퍼니')) {
    return deviceImg.TGDevice;
  }
  if (company.includes('화웨이')) {
    return deviceImg.HuawelDevice;
  }
  if (company.includes('샤오미')) {
    return deviceImg.XiaomiDevice;
  }
  if (company.includes('알칼텔')) {
    return deviceImg.AlcatelDevice;
  }
  if (company.includes('블루버드')) {
    return deviceImg.BluebirdDevice;
  }
  if (company.includes('카시오')) {
    return deviceImg.CasioDevice;
  }
  if (company.includes('델')) {
    return deviceImg.DellDevice;
  }
  if (company.includes('HTC')) {
    return deviceImg.HtcDevice;
  }
  if (company.includes('모토로라')) {
    return deviceImg.MotorolaDevice;
  }
  if (company.includes('노키아')) {
    return deviceImg.NokiaDevice;
  }
  if (company.includes('테이크')) {
    return deviceImg.TakeDevice;
  }
  if (company.includes('ZTE')) {
    return deviceImg.ZteDevice;
  }
  if (company.includes('Sharp')) {
    return deviceImg.SharpDevice;
  }
  if (company.includes('소니 모바일')) {
    return deviceImg.SonyDevice;
  }

  return null;
};

export interface IValidateType {
  type: string;
  value: string;
}

export const validation = ({ value, type }: IValidateType) => {
  let phone;
  switch (type) {
    case 'name':
      if (/^[가-힣]{2,}|[a-zA-Z]{2,}$/.test(value)) {
        return {
          error: false,
          notice: undefined,
        };
      }
      return {
        error: true,
        notice: '올바른 이름을 입력해주세요.',
      };
    case 'phone':
      phone = value.replaceAll('-', '').trim();
      if (
        /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/.test(phone) &&
        phone !== ''
      ) {
        return {
          error: false,
          notice: undefined,
        };
      }
      return {
        error: true,
        notice: '핸드폰 번호가 올바르지 않습니다.',
      };
    default:
      return {
        error: false,
        notice: undefined,
      };
  }
};

export function getDistanceFromLatLonInKm(
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number,
) {
  function deg2rad(deg: number) {
    return deg * (Math.PI / 180);
  }
  const R = 6371;
  // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  // deg2rad below
  const dLon = deg2rad(lng2 - lng1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  // Distance in m
  return d * 1000;
}

export const additionalTargetItems = [];
