import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Layout from 'components/Layout';
import PriceInquiryLayout from 'components/PriceInquiry/PriceInquiryLayout';
import { ModelType } from 'components/PriceInquiry/@types';
import RadioButton from 'components/Button/RadioButton';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';
import usePhoneDetail from 'hooks/usePhoneDetail';
import { useModel } from 'hooks/usePriceInquiry';
import { modelIdxToName } from 'pages/PriceInquiry/SelectManufacturer/utils';

const SelectModelPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const param = useParams();

  // 선택된 시리즈
  const { manufacIdx, generationIdx } = param;

  // redux 상태 hook
  const { modelId, setManufacHandler, setSeriesHandler, setModelHandler } =
    usePhoneDetail();

  // 모델 fetch hook
  const { ModelData } = useModel(manufacIdx, generationIdx);

  // 시세조회결과 페이지로 이동
  const handleSearchPrice = (modelPetname: string) => {
    const atr = ModelData?.find((idx) => idx.cate03_name === modelPetname);
    if (atr) {
      setModelHandler(atr.model_no);
      navigate(`/priceDetail/${modelPetname}`);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // url로 상태값 넣기
    if (param.manufacIdx) {
      const name = modelIdxToName(param.manufacIdx);
      setManufacHandler(name);
    }
    if (param.generationIdx) {
      setSeriesHandler(param.generationIdx);
    }
  }, [window, param]);

  return (
    <Layout>
      <PriceInquiryLayout currentSection={3}>
        <Model.Container>
          {ModelData?.map((data: ModelType) => (
            <Model.BoxWrapper key={data.cate03_name}>
              <RadioButton
                text={data.model_no}
                size="md"
                onClick={() => handleSearchPrice(data.cate03_name)}
                active={data.model_no === modelId}
              />
            </Model.BoxWrapper>
          ))}
        </Model.Container>
      </PriceInquiryLayout>
    </Layout>
  );
};

export default SelectModelPage;

const Model = {
  Container: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 24px;
    margin-bottom: 160px;
    ${mediaQueries(MOBILE)} {
      margin: 0;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      gap: min(16px, 2vw);
    }
  `,
  BoxWrapper: styled.div``,
};
