import { connectorImg } from 'common/constants/imageUrls';

export const AndroidData = [
  {
    url: connectorImg.android1,
    desc: `팝업창에서 '다운로드'를 클릭해 주세요.`,
  },
  {
    url: connectorImg.android2,
    desc: `팝업 창에서 '열기'를 클릭해 주세요.`,
  },
  {
    url: connectorImg.android3,
    desc: `'설치'를 누르면 설치가 완료됩니다.`,
  },
  {
    url: connectorImg.android4,
    desc: `앱을 실행한 뒤 접근권한을 모두 허용해 주세요.`,
  },
  {
    url: connectorImg.android5,
    desc: `버튼을 눌러 IMEI 값을 입력해 주세요.`,
  },
  {
    url: connectorImg.android6,
    desc: `본인 인증을 진행해 주세요.`,
  },
];

export const IOSData = [
  {
    url: connectorImg.ios1,
    desc: `팝업창에서 '허용'을 클릭해 주세요.`,
  },
  {
    url: connectorImg.ios2,
    desc: `설정 > 일반 > VPN 및 기기관리로 이동해주세요.`,
  },
  {
    url: connectorImg.ios3,
    desc: `Mintit Co., Ltd.Profile을 설치해 주세요.`,
  },
  {
    url: connectorImg.ios4,
    desc: `원격 관리 '신뢰'를 진행해주세요.`,
  },
  {
    url: connectorImg.ios5,
    desc: `약 30초 후 팝업이 뜨면 '설치'를 클릭해주세요.`,
  },
  {
    url: connectorImg.ios6,
    desc: `앱을 실행한 뒤 접근권한을 모두 허용해 주세요.`,
  },
  {
    url: connectorImg.ios7,
    desc: `본인 인증을 진행해 주세요.`,
  },
];
