import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';
import theme from 'styles/theme';
import { flexCenterCss } from 'styles/commonStyle';

interface PriceAnimationTestProps {
  price: number;
  // 가로 길이
  width?: string;
  // 모바일 길이
  mobileWidth?: string;
  // 구분 key
  key?: string;
  // 딜레이 시간이 있을 경우
  delay?: number;
  // 시작해야 하는 시점이 있을 경우 props로 받아오기
  propsAnimationStart?: boolean;
}

const priceLabel: number[] = [9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

const PriceAnimationTest: React.FunctionComponent<PriceAnimationTestProps> = ({
  price,
  width,
  mobileWidth,
  key,
  delay,
  propsAnimationStart = true,
}) => {
  const [isAnimationStart, setIsAnimationStart] = useState(false);
  const [newPrice, setNewPrice] = useState<number>(price);

  useLayoutEffect(() => {
    setNewPrice(0);
    setNewPrice(price);
  }, [price, delay, key, propsAnimationStart]);

  useEffect(() => {
    const timer = setTimeout(() => setIsAnimationStart(true), delay);
    return () => {
      clearTimeout(timer);
    };
  }, [price, delay, key, propsAnimationStart]);

  // 자릿수만큼 0 ~ 9 펼치기
  const slidePrice = (priceUnit: number, delay: string, idx: string) => {
    return (
      <AnimationStyle.Container
        width={width}
        mobileWidth={mobileWidth}
        key={idx}
      >
        <AnimationStyle.Overflow
          isAnimationStart={isAnimationStart}
          propsAnimationStart={propsAnimationStart}
          delay={`${delay}s`}
          stop={priceUnit}
        >
          {priceLabel.map((p: number, index) => (
            <AnimationStyle.Slider key={`key ${index}`}>
              <AnimationStyle.SliderNum>{p}</AnimationStyle.SliderNum>
            </AnimationStyle.Slider>
          ))}
        </AnimationStyle.Overflow>
      </AnimationStyle.Container>
    );
  };

  const child = [];

  const stringPrice = newPrice.toString();
  const priceLength = stringPrice.length;

  for (let i = 0; i < priceLength; i += 1) {
    child.push(
      slidePrice(
        parseInt(stringPrice[i], 10),
        ((priceLength - i) / 6).toString(),
        `key ${i}`,
      ),
    );
  }

  // 천 ~ 십만 콤마 찍기
  if (priceLength >= 4 && priceLength < 7) {
    child.splice(
      priceLength - 3,
      0,
      <div className="coma" key="m1">
        ,
      </div>,
    );
  }
  // 십만 ~ 억 콤마 찍기
  else if (priceLength >= 7) {
    child.splice(
      priceLength - 6,
      0,
      <div className="coma" key="b1">
        ,
      </div>,
    );
    child.splice(
      priceLength - 2,
      0,
      <div className="coma" key="b2">
        ,
      </div>,
    );
  }

  return (
    <AnimationStyle.Wrapper>
      <AnimationStyle.RenderPriceWrapper>
        {child}
      </AnimationStyle.RenderPriceWrapper>
    </AnimationStyle.Wrapper>
  );
};

export default PriceAnimationTest;

const rotate = (count: number) => keyframes`
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotatex(calc(-1080deg + 36deg * ${count}));
  }

`;

const AnimationStyle = {
  Wrapper: styled.div``,
  RenderPriceWrapper: styled.div`
    display: flex;
    align-items: center;
    & .coma {
      color: #1f1f1f;
      align-self: flex-end;
      ${mediaQueries(MOBILE)} {
      }
    }
    height: 100%;
  `,
  Container: styled.div<{
    width?: string;
    mobileWidth?: string;
  }>`
    overflow: hidden;
    float: left;
    height: 100%;
    ${({ width }) =>
      css`
        width: ${width};
      `}
    ${mediaQueries(MOBILE)} {
      ${({ mobileWidth }) =>
        css`
          width: ${mobileWidth};
        `}
    }
  `,
  Overflow: styled.ul<{
    delay: string;
    isAnimationStart: boolean;
    propsAnimationStart: boolean;
    stop: number;
  }>`
    display: flex;
    justify-content: center;
    align-content: flex-start;
    height: 100%;
    ${({ isAnimationStart, propsAnimationStart, delay, stop }) =>
      isAnimationStart &&
      propsAnimationStart &&
      css`
        animation-delay: ${delay};
        animation-timing-function: cubic-bezier(0.6, 0.1, 0.8, 0.2);
        animation-duration: 1.6s;
        animation-name: ${rotate(stop)};
        animation-fill-mode: forwards;
      `}
    transform-style: preserve-3d;
    transform-origin: 0 50%;
  `,
  Slider: styled.li`
    &:nth-child(1) {
      transform: rotateX(36deg) translateZ(45px);
    }
    &:nth-child(2) {
      transform: rotateX(72deg) translateZ(45px);
    }
    &:nth-child(3) {
      transform: rotateX(108deg) translateZ(45px);
    }
    &:nth-child(4) {
      transform: rotateX(144deg) translateZ(45px);
    }
    &:nth-child(5) {
      transform: rotateX(180deg) translateZ(45px);
    }
    &:nth-child(6) {
      transform: rotateX(216deg) translateZ(45px);
    }
    &:nth-child(7) {
      transform: rotateX(252deg) translateZ(45px);
    }
    &:nth-child(8) {
      transform: rotateX(288deg) translateZ(45px);
    }
    &:nth-child(9) {
      transform: rotateX(324deg) translateZ(45px);
    }
    &:nth-child(10) {
      transform: rotateX(0deg) translateZ(45px);
    }
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${flexCenterCss};
  `,
  SliderNum: styled.p`
    display: block;
    background-color: ${theme.common.colors.white};
  `,
};
