import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Layout from 'components/Layout';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';
import { AffiliateNoticeImages } from '../../common/constants/imageUrls';

const AffiliateNotice: FunctionComponent = () => {
  return (
    <Layout>
      <AffiliateNoticeStyles.Container>
        <AffiliateNoticeStyles.Wrapper>
          <AffiliateNoticeStyles.BackgroundImage
            className="pc-tablet-only"
            src={AffiliateNoticeImages.pc}
          />
          <AffiliateNoticeStyles.BackgroundImage
            className="mobile-only"
            src={AffiliateNoticeImages.mobile}
          />
        </AffiliateNoticeStyles.Wrapper>
      </AffiliateNoticeStyles.Container>
    </Layout>
  );
};

export default AffiliateNotice;

const AffiliateNoticeStyles = {
  Container: styled.main`
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    ${mediaQueries(MOBILE)} {
      padding: 0;
    }
  `,
  Wrapper: styled.section`
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
  `,
  BackgroundImage: styled.img`
    width: 100%;
  `,
};
