import styled from 'styled-components';
import { closedIcon } from '../../common/constants/imageUrls';

export const EndModalBox = styled.div<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
`;

export const EndModalLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 480px;
  padding: 32px;
  border-radius: 16px;
  font-size: 16px;
  background: #fff;
  text-align: center;
  word-break: keep-all;
  line-height: 1.3em;

  h1 {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: bold;
  }
`;

export const CloseButton = styled.span`
  cursor: pointer;
  position: absolute;
  right: 1em;
  top: 1em;
  display: flex;
  width: 20px;
  height: 20px;
  background: url(${closedIcon});
`;
