import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';
import { headerLogo } from 'common/constants/imageUrls';
import {
  BUDS_PRICE_INQUIRY,
  LAPTOP_PRICE_INQUIRY,
  SAMSUNG_LAPTOP_TRANSFER,
  TABLET_PRICE_INQUIRY,
  WATCH_PRICE_INQUIRY,
} from 'common/constants/pathUrls';
import theme from 'styles/theme';

interface HeaderProps {
  isFullSize?: boolean;
  isFixed?: boolean;
}

const PriceInquiryHeader: React.FunctionComponent<HeaderProps> = ({
  isFullSize,
  ...rest
}) => {
  const getHomeUrl = () => {
    const { pathname } = window.location;

    if (pathname.indexOf('Laptop') > -1) {
      return LAPTOP_PRICE_INQUIRY;
    }

    if (pathname.indexOf('Watch') > -1) {
      return WATCH_PRICE_INQUIRY;
    }

    if (pathname.indexOf('Buds') > -1) {
      return BUDS_PRICE_INQUIRY;
    }

    if (pathname.indexOf('samsung-laptop-transfer') > -1) {
      return SAMSUNG_LAPTOP_TRANSFER;
    }

    return TABLET_PRICE_INQUIRY;
  };

  return (
    <NavbarStyle.Base isFullSize={isFullSize} {...rest}>
      <NavbarStyle.Header>
        <NavbarStyle.LogoWrapper to={getHomeUrl()}>
          <NavbarStyle.Logo src={headerLogo} />
        </NavbarStyle.LogoWrapper>
      </NavbarStyle.Header>
    </NavbarStyle.Base>
  );
};

export default PriceInquiryHeader;

const NavbarStyle = {
  Base: styled.header<{ isFullSize?: boolean }>`
    z-index: 1000;
    width: 100%;
    max-width: 1440px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 40px;

    ${({ isFullSize }) =>
      isFullSize
        ? css`
            max-width: initial;
            padding: 0 48px;
          `
        : null};

    @media screen and (max-width: 1220px) {
      width: 1220px;
    }

    ${mediaQueries(MOBILE)} {
      width: 100%;
      height: 64px;
      padding: 20px;
      margin: 0;
    }
  `,
  Header: styled.div`
    height: 32px;

    ${mediaQueries(MOBILE)} {
      position: relative;
      height: 100%;
      width: 100%;
      border-bottom: #f4f4f4;
    }
  `,
  LogoWrapper: styled(Link)`
    position: relative;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;

    ${mediaQueries(MOBILE)} {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 99px;
      height: 24px;
    }
  `,
  Logo: styled.img`
    height: 100%;
  `,
  MenuList: styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ${mediaQueries(MOBILE)} {
      display: none;
    }
  `,
  MenuAtr: styled(Link)`
    ${theme.typo.body16R};
    color: ${theme.common.colors.mainText};
    text-decoration: none;
    cursor: pointer;
    padding: 20px 0;

    &:hover {
      color: ${theme.common.colors.turquoise};
    }

    &:not(:last-child) {
      margin-right: 3%;
    }

    @media screen and (max-width: 760px) {
      font-size: 14px;
    }
  `,
  MenuAtrEx: styled.a`
    font-size: min(16px, 1.9vw);
    font-weight: ${theme.fontWeights.normal};
    color: ${theme.common.colors.mainText};
    text-decoration: none;
    cursor: pointer;
    padding: 20px 0;

    span {
      border-radius: 7px;
      color: ${theme.button.colors.textGray};
      background-color: ${theme.common.colors.wildSand};
      font-size: 13px;
      padding: 10px 17px;
    }

    &:hover {
      color: ${theme.common.colors.turquoise};
    }

    span:hover {
      color: #444444;
      background-color: #eeeeee;
    }

    &:not(:last-child) {
      margin-right: 3%;
    }

    @media screen and (max-width: 760px) {
      font-size: 14px;
    }
  `,
  MoreWrapper: styled.div`
    display: none;

    ${mediaQueries(MOBILE)} {
      display: block;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  `,
  More: styled.img`
    width: 100%;
    height: 100%;
  `,
};
