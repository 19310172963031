import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import usePhoneDetail from 'hooks/usePhoneDetail';
import Layout from 'components/Layout';
import PriceInquiryLayout from 'components/PriceInquiry/PriceInquiryLayout';
import { SeriesType } from 'components/PriceInquiry/@types';
import RadioButton from 'components/Button/RadioButton';
import styled from 'styled-components';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';
import { useSeries } from 'hooks/usePriceInquiry';
import { modelIdxToName } from 'pages/PriceInquiry/SelectManufacturer/utils';

const SelectSeriesPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const param = useParams();

  // 선택된 제조사
  const { manufacIdx } = param;

  // redux 상태 hook
  const { seriesId, setManufacHandler, setSeriesHandler, setModelHandler } =
    usePhoneDetail();

  // 시리즈 fetch hook
  const { SeriesData } = useSeries(manufacIdx);

  // 모델 선택 페이지로 이동
  const handleSelectModel = (generation: string) => {
    setSeriesHandler(generation);
    navigate(`/priceInquiry/${manufacIdx}/${generation}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setModelHandler('');
    // url로 상태값 넣기
    if (param.manufacIdx) {
      const name = modelIdxToName(param.manufacIdx);
      setManufacHandler(name);
    }
  }, [window, param]);

  return (
    <Layout>
      <PriceInquiryLayout currentSection={2}>
        <Series.Container>
          {SeriesData?.map((data: SeriesType) => (
            <Series.BoxWrapper key={data.generation}>
              <RadioButton
                text={data.generation}
                size="md"
                onClick={() => handleSelectModel(data.generation)}
                active={data.generation === seriesId}
              />
            </Series.BoxWrapper>
          ))}
        </Series.Container>
      </PriceInquiryLayout>
    </Layout>
  );
};

export default SelectSeriesPage;

const Series = {
  Container: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-bottom: 160px;
    ${mediaQueries(MOBILE)} {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 2vw;
      margin: 0;
    }
  `,
  BoxWrapper: styled.div``,
};
