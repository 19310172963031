import styled, { css } from 'styled-components';
import { mediaQueries, MOBILE, SCROLL_BREAK } from 'styles/mediaQueries';
import { rightArrow } from 'common/constants/imageUrls';
import Button from 'components/Button';
import { flexCenterCss, flexSpaceBetween } from 'styles/commonStyle';
import theme from 'styles/theme';

const CustomBtn = styled(Button)`
  ${theme.typo.head18};
  border-radius: 8px;
  padding: 20px 0;
  flex: 1;

  ${mediaQueries(MOBILE)} {
    ${theme.typo.head14};
    padding: 14px 20px;
  }
`;

export const PriceDetailStyle = {
  Container: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 120px auto 64px;
    padding: 0 10px 0 0;
    ${mediaQueries(SCROLL_BREAK)} {
      min-width: 1220px;
    }
    ${mediaQueries(MOBILE)} {
      width: 90%;
      min-width: auto;
      margin: 0 auto;
      padding: 0;
    }
  `,
  ImgPcWrapper: styled.div<{
    isImgNotExist?: boolean;
    isImgLoading?: boolean;
  }>`
    ${flexCenterCss};
    margin-right: 10px;
    ${({ isImgNotExist }) =>
      isImgNotExist
        ? css`
            width: 450px;
            height: 450px;
            margin: 10px 0 0 0;
          `
        : css`
            width: 300px;
            height: 300px;
            margin: 40px 40px 0 0;
          `}
    ${({ isImgLoading }) =>
      isImgLoading
        ? css`
            opacity: 0;
          `
        : css`
            opacity: 1;
          `}
    ${mediaQueries(MOBILE)} {
      display: none;
    }
  `,
  ImgMobileWrapper: styled.div<{
    isImgNotExist?: boolean;
    isImgLoading?: boolean;
  }>`
    display: none;
    ${mediaQueries(MOBILE)} {
      display: block;
      position: absolute;
      left: 0;
      ${({ isImgNotExist }) =>
        isImgNotExist
          ? css`
              top: 4vw;
              width: 24vw;
              height: 24vw;
            `
          : css`
              top: 20px;
              width: min(20vw, 120px);
              height: min(20vw, 120px);
            `}
      ${({ isImgLoading }) =>
        isImgLoading
          ? css`
              opacity: 0;
            `
          : css`
              opacity: 1;
            `}
    }
  `,
  Img: styled.img`
    width: 100%;
    height: 100%;
  `,
  DetailContainer: styled.div`
    padding: 0 10px;
    display: inline-block;

    ${mediaQueries(MOBILE)} {
      position: relative;
      margin: 0 5%;
      padding: 0;
      width: 100%;
    }
  `,
  PriceContainer: styled.div`
    padding: 0 0 32px 0;
    ${mediaQueries(MOBILE)} {
      margin: 10px 0 0 0;
      padding: 10px 0 4vw 0;
    }
  `,
  TitleContainer: styled.div`
    display: flex;
  `,
  // 추가보상 모델
  CmpYn: styled.div<{ isCmp: 'Y' | 'N' | undefined }>`
    ${theme.typo.head16};
    display: inline-block;
    border-radius: 100px;
    margin: 0 0 10px 0;
    padding: 10px 31px;
    ${({ isCmp }) =>
      isCmp === 'Y'
        ? css`
            background-color: ${theme.button.colors.borderPrimary};
            color: ${theme.common.colors.white};
          `
        : css`
            background-color: ${theme.button.colors.bgGray};
            color: ${theme.button.colors.textGray};
          `}

    ${mediaQueries(MOBILE)} {
      ${theme.typo.body10R};
      margin: 0 auto 1vw min(24vw, 140px);
      padding: min(1.5vw, 10px) min(3.5vw, 28px);
    }
  `,
  PhoneName: styled.div<{ isLoading: boolean }>`
    ${theme.typo.head24};
    color: ${theme.common.colors.mainText};
    opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
    ${mediaQueries(MOBILE)} {
      ${theme.typo.head16};
      margin: 0 0 4vw min(24vw, 140px);
      padding-top: 10px;
      text-align: left;
      word-break: keep-all;
    }
  `,
  // 민팃 최대 매입가격
  PriceContent: styled.div<{ isImgExist: boolean; isCmp: boolean }>`
    position: relative;
    width: 630px;
    padding: 24px 0;
    margin: 24px 0 0 0;
    border-top: 1px solid ${theme.common.colors.silver};
    ${theme.typo.head40};
    color: ${theme.common.colors.darkGray};

    ${mediaQueries(MOBILE)} {
      width: auto;
      border-top: none;
      ${theme.typo.head16};
      padding: min(6vw) 0 16px;
      margin: 2vw 0 0 0;
      ${({ isImgExist }) =>
        isImgExist
          ? css`
              margin-top: 10px;
            `
          : css`
              padding-top: 10px;
            `}
      ${({ isCmp }) =>
        !isCmp
          ? css`
              margin-top: 30px;
            `
          : css`
              padding-top: 10px;
            `}
    }
  `,
  PriceMintitContainer: styled.div`
    ${flexSpaceBetween};
    ${theme.typo.head20};
    margin-bottom: 26px;
    ${mediaQueries(MOBILE)} {
      font-size: min(16px, 4vw);
      line-height: 21px;
      margin: min(16px, 3vw) 0;
    }
  `,
  PriceMintitTitle: styled.div<{ isLoading: boolean }>`
    color: ${theme.common.colors.subText};
    opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  `,
  PriceMintitPrice: styled.div`
    color: ${theme.common.colors.mainText};
    display: flex;
    * {
      color: ${theme.common.colors.mainText} !important;
      ${theme.typo.head20} !important;

      ${mediaQueries(MOBILE)} {
        font-size: min(16px, 4vw) !important;
      }
    }
    > div {
      margin: 0 min(5px, 1vw);
      ${mediaQueries(MOBILE)} {
        margin: 0 min(3px, 2vw) !important;
      }
    }
  `,
  // 추가보상금
  PriceCmpContainer: styled.div<{ isCmp: 'Y' | 'N' }>`
    ${flexSpaceBetween};
    ${theme.typo.head20};
    color: ${theme.common.colors.turquoise};
    margin-top: ${({ isCmp }) => (isCmp === 'Y' ? '26px' : '0px')};

    ${mediaQueries(MOBILE)} {
      font-size: min(16px, 4vw);
      line-height: 21px;
      margin: min(16px, 3vw) 0;
    }
  `,
  PriceCmpTitle: styled.div``,
  PriceCmpPrice: styled.div`
    display: flex;
    * {
      color: ${theme.common.colors.turquoise} !important;
      ${theme.typo.head20} !important;
      ${mediaQueries(MOBILE)} {
        font-size: min(16px, 4vw) !important;
      }
    }
    > div {
      margin: 0 5px;
      ${mediaQueries(MOBILE)} {
        margin: 0 3px;
      }
    }
  `,
  // 총 합계
  PriceTotalContainer: styled.div`
    ${flexSpaceBetween};
    ${theme.typo.head20};
    border-top: 1px solid ${theme.common.colors.silver};
    padding-top: 26px;

    ${mediaQueries(MOBILE)} {
      font-size: min(16px, 4vw);
      padding-top: 26px;
      margin-bottom: 12px;
    }
  `,
  PriceTotalTitle: styled.div`
    color: ${theme.common.colors.subText};
  `,
  PriceTotalPrice: styled.div<{ isTotalShow: boolean }>`
    ${flexSpaceBetween};
    color: ${theme.common.colors.mainText};
    transition: all 0.6s ease;
    ${({ isTotalShow }) =>
      isTotalShow
        ? css`
            opacity: 1;
          `
        : css`
            opacity: 0;
          `}
  `,
  GradeContainer: styled.div``,
  GradeDescContainer: styled.div`
    ${flexSpaceBetween};
    padding: 32px 0;
    ${mediaQueries(MOBILE)} {
      padding: 24px 0;
    }
  `,
  GradeDescPrice: styled.div`
    ${mediaQueries(MOBILE)} {
      margin-bottom: 26px;
    }
  `,
  // 등급별 시세
  GradeTitle: styled.div`
    ${theme.typo.head24}
    ${mediaQueries(MOBILE)} {
      ${theme.typo.head18}
    }
  `,
  // 등급 기준
  GradeDet: styled.div`
    position: relative;
    ${flexCenterCss};
    ${theme.typo.head16};
    color: ${theme.common.colors.neutralGray};
    height: 20px;
    cursor: pointer;
    padding-right: 10px;
    &::after {
      content: '';
      background: url(${rightArrow});
      width: 20px;
      height: 20px;
      position: absolute;
      right: -10px;
      top: 50%;
      transform: translateY(-50%);
      ${mediaQueries(MOBILE)} {
        transform: translateY(-50%) scale(0.9);
      }
    }
    ${mediaQueries(MOBILE)} {
      height: 16px;
    }
  `,
  // 버튼
  BtnContainer: styled.div`
    ${flexSpaceBetween};
    font-weight: ${theme.fontWeights.medium};
    gap: 12px;
    padding-bottom: 32px;

    ${mediaQueries(MOBILE)} {
      border-bottom: none;
      padding-bottom: 10px;
    }
  `,
  RetryBtn: styled(CustomBtn)``,
  AtmBtn: styled(CustomBtn)``,
  // 등급별 시세
  GradeDetailContainer: styled.div`
    ${flexSpaceBetween};
    height: 40px;
    line-height: 40px;
    margin-bottom: 24px;
  `,
  GradeNameContainer: styled.div`
    ${flexSpaceBetween};
  `,

  GradeImg: styled.img`
    margin-right: 12px;
  `,
  Grade: styled.div`
    ${theme.typo.body18R};
    ${mediaQueries(MOBILE)} {
      ${theme.typo.body14R};
    }
  `,
  GradePrice: styled.div`
    ${theme.typo.head18};
    ${mediaQueries(MOBILE)} {
      ${theme.typo.head14};
    }
  `,
};
