import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { closedIcon } from 'common/constants/imageUrls';
import { mediaQueries, MOBILE, TABLET } from 'styles/mediaQueries';
import {
  AndroidData,
  IOSData,
} from 'components/Modal/OSInstallDetailModal/data';
import { flexCenterCss } from '../../../styles/commonStyle';

interface OSInstallDetailModalProps {
  onClose: () => void;
}

type OSType = 'Android' | 'IOS';
interface DataType {
  url: string;
  desc: string;
}

const OSInstallDetailModal: React.FunctionComponent<
  OSInstallDetailModalProps
> = ({ onClose }) => {
  const [selectOS] = useState<OSType>('Android');

  const content = (title: string, OS: OSType) => {
    let selectData;
    if (OS === 'Android') selectData = AndroidData;
    else if (OS === 'IOS') selectData = IOSData;
    return (
      <ModalStyle.ContentContainer isShow={selectOS === OS}>
        <ModalStyle.Desc>{title}</ModalStyle.Desc>
        <ModalStyle.DetailWrapper>
          {selectData?.map((data: DataType, index) => (
            <ModalStyle.DetailContainer key={index}>
              <ModalStyle.DetailImg src={data.url} />
              <ModalStyle.DetailTitle>
                <ModalStyle.DetailIndex>
                  {(index + 1).toString()}
                </ModalStyle.DetailIndex>
                <ModalStyle.DetailDesc>{data.desc}</ModalStyle.DetailDesc>
              </ModalStyle.DetailTitle>
            </ModalStyle.DetailContainer>
          ))}
        </ModalStyle.DetailWrapper>
      </ModalStyle.ContentContainer>
    );
  };

  return (
    <ModalStyle.Container>
      <ModalStyle.CloseBtn src={closedIcon} onClick={onClose} />
      <ModalStyle.Title>민팃 커넥터 앱 설치방법</ModalStyle.Title>
      {/* 컨텐츠 */}
      {content('민팃 커넥터 안드로이드 설치 방법', 'Android')}
    </ModalStyle.Container>
  );
};

export default OSInstallDetailModal;

const ModalStyle = {
  Container: styled.div`
    max-width: 1440px;
    position: relative;
    margin: 0 auto;
    padding: 80px 188px 40px;
    ${mediaQueries(TABLET)} {
      padding: 80px 120px 40px;
    }
    ${mediaQueries(MOBILE)} {
      padding: 0;
    }
  `,
  CloseBtn: styled.img`
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 30px;

    ${mediaQueries(MOBILE)} {
      position: static;
      display: block;
      padding: 5px;
      margin: 10px 10px 10px auto;
    }
  `,
  Title: styled.div`
    color: #1f1f1f;
    font-size: 30px;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 84px;
    padding: 40px 0 0 0;
    ${mediaQueries(MOBILE)} {
      font-size: min(32px, 6.5vw);
      margin: 0;
      padding: min(36px, 8vw) min(50px, 8vw);
      border-top: 1px solid #f4f4f4;
    }
  `,
  TabContainer: styled.li`
    display: none;
    ${mediaQueries(MOBILE)} {
      display: flex;
      font-size: 14px;
      line-height: 18px;
      font-weight: 700;
      text-align: center;
    }
  `,
  TabAndroid: styled.ul<{ isSelected: boolean }>`
    cursor: pointer;
    padding: min(20px, 4.5vw) 0;
    flex: 1;
    position: relative;
    &:after {
      ${({ isSelected }) =>
        isSelected
          ? css`
              background-color: #000;
            `
          : css`
              background-color: #fafafa;
            `}
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  `,
  TabIOS: styled.ul<{ isSelected: boolean }>`
    cursor: pointer;
    padding: min(20px, 4.5vw) 0;
    flex: 1;
    position: relative;
    &:after {
      ${({ isSelected }) =>
        isSelected
          ? css`
              background-color: #000;
            `
          : css`
              background-color: #fafafa;
            `};
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  `,
  ContentContainer: styled.div<{ isShow: boolean }>`
    padding: min(36px, 8vw) 0;
    display: block;
    ${mediaQueries(MOBILE)} {
      display: ${({ isShow }) => (isShow ? 'block' : 'none')};
      text-align: center;
      padding: min(36px, 8vw) min(50px, 8vw);
    }
  `,
  Desc: styled.div`
    color: #4b4b4b;
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    margin-bottom: 56px;
    word-break: keep-all;
    ${mediaQueries(MOBILE)} {
      margin-bottom: 40px;
      font-size: min(20px, 4.2vw);
      line-height: 20px;
      text-align: left;
    }
  `,
  DetailWrapper: styled.div`
    display: grid;
    justify-content: center;
    gap: min(28px, 8vw);
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-bottom: 60px;
    ${mediaQueries(TABLET)} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: min(36px, 6vw);
    }
    ${mediaQueries(MOBILE)} {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }
  `,
  DetailContainer: styled.div`
    margin-bottom: 50px;
    ${mediaQueries(MOBILE)} {
      min-width: auto;
      width: 100%;
      margin: 0px auto min(20px, 4vw);
    }
  `,
  DetailImg: styled.img`
    width: 100%;
    height: 90%;
    margin-bottom: min(18px, 1vw);
    ${mediaQueries(TABLET)} {
      max-height: 90%;
    }
    ${mediaQueries(MOBILE)} {
      margin-bottom: 12px;
    }
  `,
  DetailTitle: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: #4b4b4b;
    font-size: 14px;
    font-weight: 700;
    ${mediaQueries(MOBILE)} {
      height: auto;
      font-size: min(13px, 3.6vw);
    }
  `,
  DetailIndex: styled.div`
    border: 1px solid #4b4b4b;
    width: min(14px, 3vw);
    height: min(14px, 3vw);
    font-size: min(14px, 2.5vw);
    ${flexCenterCss};
    margin-right: min(5px, 1vw);
    border-radius: 50%;
    ${mediaQueries(MOBILE)} {
      width: min(18px, 3vw);
      height: min(18px, 3vw);
      width: min(18px, 3vw);
    }
  `,
  DetailDesc: styled.div`
    font-size: min(14px, 1vw);
    font-weight: 700;
    line-height: 23.17px;
    ${mediaQueries(MOBILE)} {
      font-size: min(18px, 3vw);
    }
  `,
};
