import React, {
  FunctionComponent,
  TouchEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Button from 'components/Button';
import useWindowResize from 'hooks/useWindowResize';
import AtmGuideSectionContent, {
  AtmGuideSectionContentType,
} from 'components/atmGuide/AtmGuideSectionContent';
import {
  mediaQueries,
  MOBILE,
  SCROLL_BREAK,
  TABLET,
} from 'styles/mediaQueries';
import { flexCenterCss } from '../../../styles/commonStyle';
import ScrollAnimation from '../../ScrollAnimation';
import { ATM_FIND } from '../../../common/constants/pathUrls';

export interface AtmGuideSectionItemType {
  index: number;
  title: string;
  description?: string;
  contentItems?: AtmGuideSectionContentType[];
  isOutLink?: boolean;
  temp?: boolean;
  buttonOption?: { value: string; href: string };
  subText?: boolean;
}

const AtmGuideSectionItem: FunctionComponent<AtmGuideSectionItemType> = ({
  index,
  title,
  description,
  contentItems,
  buttonOption,
  isOutLink,
  temp,
  children,
  subText,
}) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [transition, setTransition] = useState(false);

  const contentListRef = useRef<HTMLUListElement>(null);

  const { isMobile } = useWindowResize();

  const linkToMap = () => {
    window.location.href = ATM_FIND;
  };

  useEffect(() => {
    console.log(contentItems?.length);
    console.log(contentItems);
    if (!contentListRef.current) {
      return;
    }

    contentListRef.current.addEventListener(
      'transitionend',
      handelTransitionEnd,
    );
  });

  useEffect(() => {
    if (!contentListRef.current) {
      return;
    }

    if (window.innerWidth > 720) {
      return;
    }

    if (touchStart - touchEnd < 15 && touchStart - touchEnd > -15) {
      contentListRef.current.style.transform = `translateX(-${
        selectedItem * 100
      }%)`;
      return;
    }

    if (touchStart > touchEnd && contentItems) {
      if (selectedItem < contentItems.length - 1) {
        setSelectedItem((current) => current + 1);
      }
    } else if (selectedItem !== 0) {
      setSelectedItem((current) => current - 1);
    }
  }, [touchEnd]);

  useEffect(() => {
    if (!isMobile && contentListRef.current) {
      contentListRef.current.style.transform = `translateX(0)`;
    }
  }, [isMobile]);

  const handelTransitionEnd = () => {
    setTransition(false);
  };

  const handleTouchMove = (e: TouchEvent<HTMLUListElement>) => {
    if (window.innerWidth > 720) {
      return;
    }

    const containerWidth = e.currentTarget.getBoundingClientRect().width;
    let translateX =
      e.touches[0].pageX - touchStart - containerWidth * selectedItem;

    console.log('translateX : ', translateX);

    if (translateX >= 0) {
      translateX = 0;
    }

    if (translateX <= containerWidth * -((contentItems?.length || 3) - 1)) {
      translateX = containerWidth * -((contentItems?.length || 3) - 1);
    }

    e.currentTarget.style.transform = `translateX(${translateX}px)`;
  };

  return (
    <AtmGuideSectionItemStyles.FlowSectionItem>
      <AtmGuideSectionItemStyles.Section>
        <ScrollAnimation>
          <AtmGuideSectionItemStyles.SectionTitle>
            <span>{index}.</span>
            {title}
          </AtmGuideSectionItemStyles.SectionTitle>
        </ScrollAnimation>
        <ScrollAnimation>
          {description ? (
            <AtmGuideSectionItemStyles.SectionDescription
              style={
                subText
                  ? {
                      width: buttonOption ? '60%' : '100%',
                      marginBottom: '12px',
                    }
                  : { width: buttonOption ? '60%' : '100%' }
              }
            >
              {description}
            </AtmGuideSectionItemStyles.SectionDescription>
          ) : null}
        </ScrollAnimation>
        <AtmGuideSectionItemStyles.SectionContentWrapper>
          <AtmGuideSectionItemStyles.SectionContentList
            transition={transition}
            style={{
              transform: `translateX(-${selectedItem * 100}%)`,
              transition: transition ? 'transform 0.1s' : 'none',
            }}
            ref={contentListRef}
            onTouchStart={(e) => setTouchStart(e.touches[0].pageX)}
            onTouchEnd={(e) => {
              setTransition(true);
              setTouchEnd(e.changedTouches[0].pageX);
            }}
            onTouchMove={handleTouchMove}
          >
            {contentItems
              ? contentItems.map((item, idx) => {
                  return (
                    <AtmGuideSectionContent
                      key={item.title}
                      {...item}
                      index={idx / 2}
                    />
                  );
                })
              : null}
          </AtmGuideSectionItemStyles.SectionContentList>
          {contentItems ? (
            <AtmGuideSectionItemStyles.SectionContentListController>
              {contentItems.map((item, index) => {
                return (
                  <AtmGuideSectionItemStyles.SectionContentListControllerItem
                    isSelected={selectedItem === index}
                    key={item.title}
                    onClick={() => {
                      setTransition(true);
                      setSelectedItem(index);
                    }}
                  >
                    {index}
                  </AtmGuideSectionItemStyles.SectionContentListControllerItem>
                );
              })}
            </AtmGuideSectionItemStyles.SectionContentListController>
          ) : null}
          {children}
          {/* eslint-disable-next-line no-nested-ternary */}
          {buttonOption ? (
            isOutLink ? (
              <AtmGuideSectionItemStyles.SectionDescriptionBtm>
                <a href={buttonOption.href}>{buttonOption.value}</a>
              </AtmGuideSectionItemStyles.SectionDescriptionBtm>
            ) : (
              <AtmGuideSectionItemStyles.SectionDescriptionBtm>
                {temp ? (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <Link
                    to=""
                    // to={buttonOption.href}
                    onClick={() => {
                      linkToMap();
                    }}
                  >
                    {buttonOption.value}
                  </Link>
                ) : (
                  <Link to={buttonOption.href}>{buttonOption.value}</Link>
                )}
              </AtmGuideSectionItemStyles.SectionDescriptionBtm>
            )
          ) : null}
        </AtmGuideSectionItemStyles.SectionContentWrapper>
      </AtmGuideSectionItemStyles.Section>
    </AtmGuideSectionItemStyles.FlowSectionItem>
  );
};

const AtmGuideSectionItemStyles = {
  Section: styled.section`
    ${({ theme }) => css`
      position: relative;

      a {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        ${flexCenterCss};
        width: 12.708em;
        height: 3.458em;
        border-radius: 0.375em;
        background: ${theme.common.colors.turquoise};
        ${theme.typo.head24};
        color: ${theme.common.colors.white};
        text-decoration: none;
      }

      ${mediaQueries(TABLET)} {
        a {
          font-size: 1.7vw;
        }
      }

      ${mediaQueries(SCROLL_BREAK)} {
        a {
          ${theme.typo.head24};
        }
      }

      ${mediaQueries(MOBILE)} {
        & > * {
          padding: 0 5%;
        }

        a {
          position: relative;
          top: initial;
          transform: none;
          padding: 0;
          width: 100%;
          height: 52px;
          ${theme.typo.head14};
          margin: 0 auto;
        }
      }
    `}
  `,
  FlowSectionItem: styled.li`
    &:not(:last-child) {
      margin-bottom: 10.909em;
    }

    ${mediaQueries(TABLET)} {
      font-size: 1vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 12.2px;
    }

    ${mediaQueries(MOBILE)} {
      &:not(:last-child) {
        margin-bottom: 42px;
      }
    }
  `,
  SectionTitle: styled.h3`
    ${({ theme }) => css`
      ${theme.typo.head32};
      margin-bottom: 1em;

      & > span {
        margin-right: 0.5em;
      }

      ${mediaQueries(TABLET)} {
        font-size: 2.5vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 32px;
      }

      ${mediaQueries(MOBILE)} {
        ${theme.typo.head16};
        margin-bottom: 8px;

        & > span {
          margin-right: 8px;
        }
      }
    `}
  `,
  SectionDescription: styled.p`
    ${({ theme }) => css`
      ${theme.typo.body24R};
      line-height: 1.4em;
      margin-bottom: 1.458em;
      word-break: keep-all;

      ${mediaQueries(TABLET)} {
        font-size: 1.8vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 24px;
      }

      ${mediaQueries(MOBILE)} {
        width: 100% !important;
        font-size: 14px;
        margin: 18px 0;
      }
    `}
  `,
  SectionDescriptionBtm: styled(Button)`
    ${mediaQueries(MOBILE)} {
      padding: 0 10px;
      margin: 5%;
    }
  `,
  SectionContentWrapper: styled.div`
    ${mediaQueries(MOBILE)} {
      overflow: hidden;
      padding: 0;
    }
  `,
  SectionContentList: styled.ul<{ transition: boolean }>`
    display: flex;
    justify-content: flex-start;
    width: auto;
    ${({ transition }) =>
      transition
        ? css`
            -webkit-transition: transform 0.1s;
          `
        : css`
            -webkit-transition: none;
          `}
  `,
  SectionContentListController: styled.ul`
    display: none;

    ${mediaQueries(MOBILE)} {
      ${flexCenterCss};
      margin-top: 16px;
    }
  `,
  SectionContentListControllerItem: styled.li<{ isSelected?: boolean }>`
    ${({ theme, isSelected }) => css`
      cursor: pointer;
      text-indent: -99999px;
      width: 8px;
      height: 8px;
      background: ${isSelected
        ? theme.common.colors.turquoise
        : theme.common.colors.darkSilver};
      border-radius: 50%;
      margin: 0 4px;
    `}
  `,
};

export default AtmGuideSectionItem;
