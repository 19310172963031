import React, { FocusEvent, FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';

type InputType =
  | 'primary'
  | 'primaryDisabled'
  | 'ghost'
  | 'ghostDisabled'
  | 'secondary'
  | 'secondaryDisabled';

interface IInputProps {
  type?: InputType;
  id: string;
  onChange: (e: FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error: boolean;
  placeholder: string;
  value: string;
  max?: number;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
}

const Input: FunctionComponent<IInputProps> = ({
  id,
  onChange,
  disabled = false,
  error = false,
  placeholder,
  value,
  onBlur,
  max,
  ...rest
}) => {
  return (
    <InputStyle.Input
      error={error}
      onChange={onChange}
      id={id}
      onBlur={onBlur}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      maxLength={max}
      {...rest}
    />
  );
};

export default Input;

const InputStyle = {
  Input: styled.input<{ error: boolean }>`
    ${({ theme, error }) => css`
      ${error ? theme.input.error : theme.input.primary};

      ::placeholder {
        color: #cacaca;
      }

      ${mediaQueries(MOBILE)} {
        ${error ? theme.input.errorMobile : theme.input.primaryMobile};
      }
    `}
  `,
};
