import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  ManufacturerData,
  ManufacturerType,
} from 'components/PriceInquiry/@types';
import usePhoneDetail from 'hooks/usePhoneDetail';
import { closedIcon } from 'common/constants/imageUrls';
import RadioButton from 'components/Button/RadioButton';
import { modelToImg } from 'pages/PriceInquiry/SelectManufacturer/utils';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';

interface EtcManufacturerModalProps {
  onClose: () => void;
  ManufacturerList?: ManufacturerData;
}

const EtcManufacturerModal: React.FunctionComponent<
  EtcManufacturerModalProps
> = ({ onClose, ManufacturerList }) => {
  const { manufacName, setManufacUrl, setManufacHandler } = usePhoneDetail();
  const navigate = useNavigate();

  const handleSelectSeries = (manufacIdx: string) => {
    // 시리즈 페이즈로 이동
    setManufacUrl(manufacIdx);
    // eslint-disable-next-line camelcase
    const atr: ManufacturerType | undefined = ManufacturerList?.find(
      (idx) => idx.model === manufacIdx,
    );
    if (atr) {
      setManufacHandler(atr.model_name);
      navigate(`/priceInquiry/${manufacIdx}`);
    }
  };

  return (
    <Modal.Container>
      <Modal.HeaderWrapper>
        <Modal.HeaderTitle>기타 제조사</Modal.HeaderTitle>
        <Modal.HeaderCloseButton src={closedIcon} onClick={onClose} />
      </Modal.HeaderWrapper>
      <Modal.ContentWrapper>
        {ManufacturerList?.map((data: ManufacturerType) => (
          <Modal.BoxWrapper key={data.model}>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <RadioButton
              text={data.model_name}
              img={modelToImg(data.model_name)}
              size="sm"
              onClick={() => handleSelectSeries(data.model)}
              active={manufacName === data.model_name}
            />
          </Modal.BoxWrapper>
        ))}
      </Modal.ContentWrapper>
    </Modal.Container>
  );
};

export default EtcManufacturerModal;

const Modal = {
  Container: styled.div`
    padding-bottom: 40px;
    ${mediaQueries(MOBILE)} {
      padding: 20px;
    }
  `,
  HeaderWrapper: styled.div`
    padding: 32px 40px;
    display: flex;
    justify-content: space-between;
    ${mediaQueries(MOBILE)} {
      padding: 0;
      position: relative;
    }
  `,
  HeaderTitle: styled.div`
    font-size: 24px;
    line-height: 35px;
    font-weight: bold;
    ${mediaQueries(MOBILE)} {
      font-size: 16px;
      font-weight: 700;
      line-height: 23px;
      margin-bottom: 22px;
    }
  `,
  HeaderCloseButton: styled.img`
    cursor: pointer;
    ${mediaQueries(MOBILE)} {
      position: absolute;
      top: 0px;
      right: 0px;
    }
  `,
  ContentWrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin: 0 40px;
    ${mediaQueries(MOBILE)} {
      display: grid;
      flex-wrap: nowrap;
      margin: 0 auto;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      width: auto;
      gap: 2vw;
    }
  `,
  BoxWrapper: styled.div``,
};
