import React, { FunctionComponent } from 'react';
import { mainIcon } from 'common/constants/imageUrls';
import { HomeCaution } from 'common/constants/main';
import Section from 'components/main/Section';
import { HowToAdditionalStyle } from './style';

const HowToAdditional: FunctionComponent<{ color: string }> = ({ color }) => {
  return (
    <Section
      title="갤럭시 퀀텀3 / 갤럭시 점프2 개통하고<br />민팃 추가보상금 받는 방법"
      color={color}
    >
      <HowToAdditionalStyle.OrderWrapper>
        <HowToAdditionalStyle.Order delay={window.innerWidth > 720 ? 0.05 : 0}>
          <HowToAdditionalStyle.OrderNum>1</HowToAdditionalStyle.OrderNum>
          <HowToAdditionalStyle.OrderImg src={mainIcon.Sale} />
          <HowToAdditionalStyle.OrderTextWrapper>
            <HowToAdditionalStyle.OrderTitle>
              휴대폰 개통하기
            </HowToAdditionalStyle.OrderTitle>
            <HowToAdditionalStyle.OrderText>
              갤럭시 퀀텀3 / 갤럭시 점프2를
              {window.innerWidth > 720 ? <br /> : ' '}
              개통합니다.
            </HowToAdditionalStyle.OrderText>
          </HowToAdditionalStyle.OrderTextWrapper>
        </HowToAdditionalStyle.Order>
        <HowToAdditionalStyle.OrderDiv
          delay={window.innerWidth > 720 ? 0.1 : 0}
        >
          <HowToAdditionalStyle.OrderDivImg
            src={mainIcon.MainArrow}
            width="100%"
            height="100%"
          />
        </HowToAdditionalStyle.OrderDiv>
        <HowToAdditionalStyle.Order delay={window.innerWidth > 720 ? 0.15 : 0}>
          <HowToAdditionalStyle.OrderNum>2</HowToAdditionalStyle.OrderNum>
          <HowToAdditionalStyle.OrderImg src={mainIcon.Bell} />
          <HowToAdditionalStyle.OrderTextWrapper>
            <HowToAdditionalStyle.OrderTitle>
              중고폰 판매 준비하기
            </HowToAdditionalStyle.OrderTitle>
            <HowToAdditionalStyle.OrderText>
              현재 페이지에서 판매 전<br />
              필수 사항을 확인하고 판매를 준비합니다.
            </HowToAdditionalStyle.OrderText>
          </HowToAdditionalStyle.OrderTextWrapper>
        </HowToAdditionalStyle.Order>
        <HowToAdditionalStyle.OrderDiv
          delay={window.innerWidth > 720 ? 0.2 : 0}
        >
          <HowToAdditionalStyle.OrderDivImg
            src={mainIcon.MainArrow}
            width="100%"
            height="100%"
          />
        </HowToAdditionalStyle.OrderDiv>
        <HowToAdditionalStyle.Order delay={window.innerWidth > 720 ? 0.25 : 0}>
          <HowToAdditionalStyle.OrderNum>3</HowToAdditionalStyle.OrderNum>
          <HowToAdditionalStyle.OrderImg src={mainIcon.MintitAtm} />
          <HowToAdditionalStyle.OrderTextWrapper>
            <HowToAdditionalStyle.OrderTitle>
              민팃 ATM 반납하기
            </HowToAdditionalStyle.OrderTitle>
            <HowToAdditionalStyle.OrderText>
              개통일로부터 14일 이내
              <br />
              민팃 ATM에 방문해 중고폰을 반납합니다.
            </HowToAdditionalStyle.OrderText>
          </HowToAdditionalStyle.OrderTextWrapper>
        </HowToAdditionalStyle.Order>
        <HowToAdditionalStyle.OrderDiv
          delay={window.innerWidth > 720 ? 0.3 : 0}
        >
          <HowToAdditionalStyle.OrderDivImg
            src={mainIcon.MainArrow}
            width="100%"
            height="100%"
          />
        </HowToAdditionalStyle.OrderDiv>
        <HowToAdditionalStyle.Order delay={window.innerWidth > 720 ? 0.35 : 0}>
          <HowToAdditionalStyle.OrderNum>4</HowToAdditionalStyle.OrderNum>
          <HowToAdditionalStyle.OrderImg src={mainIcon.WithDraw} />
          <HowToAdditionalStyle.OrderTextWrapper>
            <HowToAdditionalStyle.OrderTitle>
              입금받기
            </HowToAdditionalStyle.OrderTitle>
            <HowToAdditionalStyle.OrderText>
              계좌정보 입력 후 즉시 입금됩니다.
              <br />
              <span>
                추가 보상금은 개통 14일
                <br />
                유지 확인 후 입금됩니다.
              </span>
            </HowToAdditionalStyle.OrderText>
          </HowToAdditionalStyle.OrderTextWrapper>
        </HowToAdditionalStyle.Order>
      </HowToAdditionalStyle.OrderWrapper>
      <HowToAdditionalStyle.BottomWrapper section>
        <HowToAdditionalStyle.BottomTitleWrapper>
          <HowToAdditionalStyle.BottomTitleImg src={mainIcon.warningIcon} />
          <HowToAdditionalStyle.BottomTitle>
            아래의 경우 추가보상 혜택이 제외될 수 있습니다.
          </HowToAdditionalStyle.BottomTitle>
        </HowToAdditionalStyle.BottomTitleWrapper>
        <HowToAdditionalStyle.BottomDescList>
          {HomeCaution.map((item, i) => (
            <HowToAdditionalStyle.BottomDesc key={i}>
              {item}
            </HowToAdditionalStyle.BottomDesc>
          ))}
        </HowToAdditionalStyle.BottomDescList>
      </HowToAdditionalStyle.BottomWrapper>
    </Section>
  );
};

export default HowToAdditional;
