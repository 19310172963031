// 메인 페이지
export const HOME_URL = '/';

// ATM 이용안내 페이지
export const ATM_FIND = '/atmOfUse';
export const ATM_GUIDE = '/atmGuide';

// 시세조회 페이지
export const SELECT_MODEL = '/priceInquiry/:manufacIdx/:generationIdx';
export const SELECT_SERIES = '/priceInquiry/:manufacIdx';
export const PRICE_DETAIL = '/priceDetail/:petname';
export const PRICE_INQUIRY = '/priceInquiry';
export const PRICE_INQUIRY_ERROR = '/priceInquiry/error';

// 민팃 홈
export const HOME_MINTIT = process.env.REACT_APP_HOME_MINTIT_LINK;
// 민팃 소개 페이지
export const ABOUT_MINTIT = process.env.REACT_APP_ABOUT_MINTIT_LINK;
// 이용약관
export const TERMS_MINTIT = process.env.REACT_APP_TERMS_MINTIT_LINK;
// 개인정보처리방침
export const PRIVACY_MINTIT = process.env.REACT_APP_PRIVACY_MINTIT_LINK;

// 에러 페이지
export const ERROR = '*';

// SNS

export const YOUTUBE_SNS =
  'https://www.youtube.com/channel/UC5bT0Fy-Ui6jJLPvw0P051g';
export const FACEBOOK_SNS = 'https://www.facebook.com/mintit.official/';
export const INSTAR_SNS = 'https://www.instagram.com/mintit_official/';

// 룰렛 이벤트 페이지
export const ROULETTE_EVENT = '/rouletteEvent';

// 퀴즈 이벤트 페이지
export const QUIZ_EVENT = '/quizEvent';

// 다른 모델 확인하기 페이지
export const FIND_MODEL = '/findModel';

// 제휴사별 안내사항
export const AFFILIATE_NOTICE = '/affiliateNotice';

// 마케팅 동의 (룰렛 이벤트)
export const MARKETING_AGREE = '/event/marketingAgree';

// 모바일 추가보상 이벤트
export const MOBILE_HOME = '/mobile';

// 퀴즈 이벤트 신청페이지 URL
export const QUIZ_EVENT_CHOICE_LINK =
  process.env.REACT_APP_QUIZ_EVENT_CHOICE_LINK;

// 신규웹 노트북 시세조회
export const LAPTOP_PRICE_INQUIRY = '/priceInquiryLaptop';
export const LAPTOP_PRICE_INQUIRY_DETAIL = '/priceInquiryLaptop/:modelCode';

// 신규웹 태블릿 시세조회
export const TABLET_PRICE_INQUIRY = '/priceInquiryTablet';
export const TABLET_PRICE_INQUIRY_DETAIL = '/priceInquiryTablet/:modelCode';

// 신규웹 워치 시세조회
export const WATCH_PRICE_INQUIRY = '/priceInquiryWatch';
export const WATCH_PRICE_INQUIRY_DETAIL = '/priceInquiryWatch/:modelCode';

// 신규웹 버즈 시세조회
export const BUDS_PRICE_INQUIRY = '/priceInquiryBuds';
export const BUDS_PRICE_INQUIRY_DETAIL = '/priceInquiryBuds/:modelCode';

// 이벤트 리스트
export const EVENT_LIST_PAGE = '/event';
export const EVENT_DETAIL_PAGE = '/event/:id';
export const EVENT_ATM_PAGE = '/mobile/atmOfUse';

export const WATCH_PRICE_INQUIRY_HOME = '/priceInquiryWatchHome';

export const SAMSUNG_LAPTOP_TRANSFER = '/samsung-laptop-transfer';
export const SAMSUNG_LAPTOP_TRANSFER_DETAIL =
  '/samsung-laptop-transfer/:modelCode';
