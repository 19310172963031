import React from 'react';
import {
  imgEventDetail04,
  imgEventDetail05,
  imgEventHomeBanner04,
  imgEventHomeBanner05,
  imgEventHomeBanner06Mobile,
  imgEventHomeBanner07Mobile,
  imgEventHomeBanner08Mobile,
} from '../../common/constants/imageUrls';

export interface IEventInfo {
  img: string;
  bannerImg: string;
  title: string;
  description: string;
  links?: {
    text: string;
    href: string;
    style: React.CSSProperties;
    isExternal?: boolean;
    id?: string;
  }[];
  startDate: string;
  endDate: string;
  externalHref?: string;
}

export const eventInfoList: Record<string, IEventInfo> = {
  '1': {
    img: imgEventDetail04,
    bannerImg: imgEventHomeBanner04,
    title: '제값 못 받던 서랍 속 깨진폰, LCD 파손폰 등등',
    description: 'D는 C등급으로, C는 B등급으로 한 단계 업 보상 !',
    links: [
      {
        text: 'ATM 찾기',
        href: 'https://mintit2.page.link/?link=https://event.mintit.co.kr/mobile/atmOfUse?linkTo=Map&apn=com.skn.mintit&afl=https://event.mintit.co.kr/mobile/atmOfUse&isi=1577650973&ibi=com.skn.mintit&ifl=https://event.mintit.co.kr/mobile/atmOfUse&efr=1',
        style: {
          width: '85%',
          height: '1.3%',
          bottom: '40.5%',
          left: '50%',
          transform: 'translateX(-50%)',
        },
        isExternal: true,
      },
    ],
    startDate: '2022/06/01 00:00:00',
    endDate: '2022/06/30 23:59:59',
  },
  '3': {
    img: imgEventDetail05,
    bannerImg: imgEventHomeBanner05,
    title: '쓸모 없어진 가게 입구 QR체크 인증 태블릿',
    description: '추가보상도 받고, 최대 50만원 지원금도 받자 !',
    links: [
      {
        text: '태블릿 판매하기',
        href: 'https://tablet.mintit.co.kr',
        id: 'tabletSale',
        style: {
          width: '85%',
          height: '1.6%',
          bottom: '37.1%',
          left: '50%',
          transform: 'translateX(-50%)',
        },
        isExternal: true,
      },
    ],
    startDate: '2022/06/01 00:00:00',
    endDate: '2022/06/30 23:59:59',
  },
  // '6': {
  //   img: imgEventDetail05,
  //   bannerImg: imgEventHomeBanner05,
  //   title: '민팃 이용했다면? 리뷰왕 도전!',
  //   description: '당첨자 10명에게 스벅 3만원권 증정',
  //   externalHref: 'https://www.mintit.co.kr/evt/review_event.do',
  //   links: [],
  //   startDate: '2022/05/01 00:00:00',
  //   endDate: '2022/05/31 23:59:59',
  // },
  '6': {
    img: imgEventDetail05,
    bannerImg: imgEventHomeBanner06Mobile,
    title: 'NEW 갤럭시Z 플립 득템 찬스',
    description: '민팃 앱 다운만 해도, NEW 갤럭시 Z플립 득템 찬스!',
    externalHref: 'https://www.mintit.co.kr/evt/app_reward.do',
    links: [],
    startDate: '2022/07/28 00:00:00',
    endDate: '2022/08/24 23:59:59',
  },
  '7': {
    img: imgEventDetail05,
    bannerImg: imgEventHomeBanner08Mobile,
    title: '민팃 X 청소연구소 이벤트',
    description: '우리집은 청소연구소에! 중고폰은 민팃에게!',
    externalHref: 'https://www.mintit.co.kr/evt/home_lab_event.do',
    links: [],
    startDate: '2022/07/01 00:00:00',
    endDate: '2022/07/31 23:59:59',
  },
  '8': {
    img: imgEventDetail05,
    bannerImg: imgEventHomeBanner07Mobile,
    title: '민팃 이용했다면? 리뷰왕 도전!',
    description: '당첨자 10명에게 이마트 기프티콘 증정',
    externalHref: 'https://www.mintit.co.kr/evt/review_event.do',
    links: [],
    startDate: '2022/08/01 00:00:00',
    endDate: '2022/08/30 23:59:59',
  },
};
