import React, { FunctionComponent } from 'react';
import Home from 'pages/Home';
import AtmGuide from 'pages/AtmGuide';
import {
  HOME_URL,
  ATM_FIND,
  ATM_GUIDE,
  PRICE_INQUIRY,
  SELECT_MODEL,
  SELECT_SERIES,
  PRICE_DETAIL,
  ROULETTE_EVENT,
  FIND_MODEL,
  AFFILIATE_NOTICE,
  MARKETING_AGREE,
  PRICE_INQUIRY_ERROR,
  MOBILE_HOME,
  QUIZ_EVENT,
  LAPTOP_PRICE_INQUIRY,
  LAPTOP_PRICE_INQUIRY_DETAIL,
  TABLET_PRICE_INQUIRY,
  TABLET_PRICE_INQUIRY_DETAIL,
  WATCH_PRICE_INQUIRY,
  WATCH_PRICE_INQUIRY_DETAIL,
  EVENT_LIST_PAGE,
  EVENT_DETAIL_PAGE,
  EVENT_ATM_PAGE,
  WATCH_PRICE_INQUIRY_HOME,
  BUDS_PRICE_INQUIRY,
  BUDS_PRICE_INQUIRY_DETAIL,
  SAMSUNG_LAPTOP_TRANSFER,
  SAMSUNG_LAPTOP_TRANSFER_DETAIL,
} from 'common/constants/pathUrls';
import PriceDetailPage from 'pages/PriceDetail';
import PriceInquiryPage from 'pages/PriceInquiry/SelectManufacturer';
import SelectModelPage from 'pages/PriceInquiry/SelectModel';
import SelectSeriesPage from 'pages/PriceInquiry/SelectSeries';
import ErrorPage from 'hooks/usePriceInquiry/errorPage';
import FindModel from 'pages/FindModel';
import NiceRes from 'pages/NiceRes';
import BudsPriceInquiry from 'pages/BudsPriceInquiry';
import BudsPriceInquiryDetail from 'pages/BudsPriceInquiryDetail';
import SamsungLaptopTransfer from 'pages/SamsungLaptopTransfer';
import SamsunglaptopTransferDetail from 'pages/SamsunglaptopTransferDetail';
import AffiliateNotice from '../pages/AffiliateNotice';
import MarketingAgree from '../pages/MarketingAgree';
import QuizEvent from '../pages/QuizEvent';
import AtmOfUse from '../pages/AtmOfUse';
import LaptopPriceInquiry from '../pages/LaptopPriceInquiry';
import LaptopPriceInquiryDetail from '../pages/LaptopPriceInquiryDetail';
import TabletPriceInquiryDetail from '../pages/TabletPriceInquiryDetail';
import TabletPriceInquiry from '../pages/TabletPriceInquiry';
import WatchPriceInquiry from '../pages/WatchPriceInquiry';
import WatchPriceInquiryDetail from '../pages/WatchPriceInquiryDetail';

import EventListHome from '../pages/EventListHome';
import EventDetail from '../pages/EventDetail';
import EventAtmOfUse from '../pages/EventAtmOfUse';

export type RouteInfo = {
  path: string;
  component: typeof React.Component | FunctionComponent<any>;
};

export const ROUTES: Array<RouteInfo> = [
  {
    path: '/component/niceRes.do',
    component: NiceRes,
  },
  {
    path: HOME_URL,
    component: Home,
  },
  {
    path: ATM_FIND,
    component: AtmOfUse,
  },
  {
    path: ATM_GUIDE,
    component: AtmGuide,
  },
  {
    path: ROULETTE_EVENT,
    component: () => {
      window.location.href = '/';
      alert('종료된 이벤트 입니다.');

      return null;
    },
  },
  {
    path: QUIZ_EVENT,
    component: QuizEvent,
  },
  {
    path: FIND_MODEL,
    component: FindModel,
  },
  {
    path: AFFILIATE_NOTICE,
    component: AffiliateNotice,
  },
  {
    path: MARKETING_AGREE,
    component: MarketingAgree,
  },
  {
    path: MOBILE_HOME,
    component: Home,
  },
  {
    path: LAPTOP_PRICE_INQUIRY,
    component: LaptopPriceInquiry,
  },
  {
    path: LAPTOP_PRICE_INQUIRY_DETAIL,
    component: LaptopPriceInquiryDetail,
  },
  {
    path: TABLET_PRICE_INQUIRY,
    component: TabletPriceInquiry,
  },
  {
    path: TABLET_PRICE_INQUIRY_DETAIL,
    component: TabletPriceInquiryDetail,
  },
  {
    path: WATCH_PRICE_INQUIRY,
    component: WatchPriceInquiry,
  },
  {
    path: WATCH_PRICE_INQUIRY_DETAIL,
    component: WatchPriceInquiryDetail,
  },
  {
    path: BUDS_PRICE_INQUIRY,
    component: BudsPriceInquiry,
  },
  {
    path: BUDS_PRICE_INQUIRY_DETAIL,
    component: BudsPriceInquiryDetail,
  },
  {
    path: EVENT_LIST_PAGE,
    component: EventListHome,
  },
  {
    path: EVENT_DETAIL_PAGE,
    component: EventDetail,
  },
  {
    path: EVENT_ATM_PAGE,
    component: EventAtmOfUse,
  },
  {
    path: WATCH_PRICE_INQUIRY_HOME,
    component: WatchPriceInquiry,
  },
  {
    path: SAMSUNG_LAPTOP_TRANSFER,
    component: SamsungLaptopTransfer,
  },
  {
    path: SAMSUNG_LAPTOP_TRANSFER_DETAIL,
    component: SamsunglaptopTransferDetail,
  },
];

export const ANIMATION_ROUTES: Array<RouteInfo> = [
  {
    path: SELECT_MODEL,
    component: SelectModelPage,
  },
  {
    path: SELECT_SERIES,
    component: SelectSeriesPage,
  },
  {
    path: PRICE_INQUIRY,
    component: PriceInquiryPage,
  },
  {
    path: PRICE_DETAIL,
    component: PriceDetailPage,
  },
  {
    path: PRICE_INQUIRY_ERROR,
    component: ErrorPage,
  },
];
