import { Route, Routes, useLocation } from 'react-router-dom';
import { ANIMATION_ROUTES, ROUTES } from 'routes';
import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/theme';
import { useEffect } from 'react';
import DeployModal from './components/Modal/DeployModal';
import {
  BUDS_PRICE_INQUIRY,
  BUDS_PRICE_INQUIRY_DETAIL,
  LAPTOP_PRICE_INQUIRY,
  LAPTOP_PRICE_INQUIRY_DETAIL,
  PRICE_INQUIRY,
  PRICE_INQUIRY_ERROR,
  SAMSUNG_LAPTOP_TRANSFER,
  TABLET_PRICE_INQUIRY,
  TABLET_PRICE_INQUIRY_DETAIL,
  WATCH_PRICE_INQUIRY,
  WATCH_PRICE_INQUIRY_DETAIL,
} from './common/constants/pathUrls';
import EndModal from './components/EndModal';

const excludeEventPage = [
  PRICE_INQUIRY,
  '/priceDetail',
  PRICE_INQUIRY_ERROR,
  WATCH_PRICE_INQUIRY,
  LAPTOP_PRICE_INQUIRY,
  TABLET_PRICE_INQUIRY,
  WATCH_PRICE_INQUIRY,
  WATCH_PRICE_INQUIRY_DETAIL,
  BUDS_PRICE_INQUIRY,
  BUDS_PRICE_INQUIRY_DETAIL,
  LAPTOP_PRICE_INQUIRY_DETAIL,
  TABLET_PRICE_INQUIRY_DETAIL,
  SAMSUNG_LAPTOP_TRANSFER,
  '/event',
];

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log('changed~!!!');
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {new Date() > new Date('2022-06-15 23:59:59') &&
      pathname !== '/' &&
      !excludeEventPage
        .map(
          (item) => pathname.toLowerCase().indexOf(item.toLowerCase()) !== -1,
        )
        .includes(true) ? (
        <EndModal />
      ) : null}
      <div className="App">
        <DeployModal />
        <AnimatePresence exitBeforeEnter>
          <Routes>
            {ROUTES.map(({ path, component: Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
            {ANIMATION_ROUTES.map(({ path, component: Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
          </Routes>
        </AnimatePresence>
      </div>
    </ThemeProvider>
  );
};

export default App;
