import React, { FunctionComponent, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';
import Header from 'components/partials/Header';
import useScroll from 'hooks/useScroll';

interface LayoutProps {
  isFullSize?: boolean;
}

const Layout: FunctionComponent<LayoutProps> = ({ children, isFullSize }) => {
  const HeaderRef = useRef<HTMLDivElement>(null);
  const { x } = useScroll();

  // fixed 되었을때 가로 스크롤 생기게 하는 부분
  useEffect(() => {
    if (HeaderRef?.current) {
      if (window.innerWidth > 720 && window.innerWidth <= 1220) {
        HeaderRef.current.style.left = `${0 - x}px`;
      }
    }
  }, [x]);

  return (
    <LayoutStyle.Container isFullSize={isFullSize}>
      <LayoutStyle.HeaderContainer ref={HeaderRef}>
        <LayoutStyle.HeaderBase />
      </LayoutStyle.HeaderContainer>
      <LayoutStyle.Children isFullSize={isFullSize}>
        {children}
      </LayoutStyle.Children>
    </LayoutStyle.Container>
  );
};

export default Layout;

const LayoutStyle = {
  HeaderBase: styled(Header)`
    @media screen and (max-width: 1220px) {
      width: 1220px;
    }

    @media screen and (max-width: 720px) {
      width: 100%;
    }
  `,
  Container: styled.div<{ isFullSize?: boolean }>`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    ${({ isFullSize }) =>
      isFullSize
        ? css`
            height: 100vh;
            overflow: hidden;

            header {
              flex-shrink: 0;
            }
          `
        : null};

    @media screen and (max-width: 1220px) {
      width: 1220px;
      overflow-x: auto;
    }

    ${mediaQueries(MOBILE)} {
      width: 100vw;
    }
  `,
  HeaderContainer: styled.div`
    width: 100vw;
    background-color: #fff;
    z-index: 100;
    position: fixed;
    top: 0;

    @media screen and (max-width: 1220px) {
      width: 1220px;
    }

    @media screen and (max-width: 720px) {
      width: 100%;
      position: fixed !important;
      top: 0 !important;
    }
  `,
  Children: styled.div<{ isFullSize?: boolean }>`
    padding-top: 80px;
    ${({ isFullSize }) =>
      isFullSize
        ? css`
            display: flex;
            flex-direction: column;
            height: 100vh;
          `
        : null}
  `,
};
