import useSWR from 'swr';
import { HttpMethod, request } from 'common/network';
import { searchBaseUrl } from 'common/constants/urls';
import {
  ManufacturerData,
  ModelData,
  SeriesData,
} from 'components/PriceInquiry/@types';

// 제조사 hook
export const useManufacturer = () => {
  const { data, error, isValidating } = useSWR<ManufacturerData>(
    'manufacturer',
    async () => {
      const res = await request(HttpMethod.GET, searchBaseUrl, {
        param: {
          state: 'maker',
        },
      });
      return res.data.dataset;
    },
    { revalidateOnFocus: true },
  );

  return {
    ManufacturerData: data,
    isLoading: isValidating,
    error,
  };
};

// 시리즈 hook
export const useSeries = (manufacIdx?: string) => {
  const { data, error, isValidating } = useSWR<SeriesData>(
    ['series', manufacIdx],
    async () => {
      const res = await request(HttpMethod.GET, searchBaseUrl, {
        param: {
          state: 'series',
          manufacturer_idx: manufacIdx,
        },
      });
      return res.data.dataset;
    },
    { revalidateOnFocus: true },
  );

  return {
    SeriesData: data,
    isLoading: isValidating,
    error,
  };
};

// 모델 hook
export const useModel = (manufacIdx?: string, generationIdx?: string) => {
  const { data, error, isValidating } = useSWR<ModelData>(
    ['model', manufacIdx, generationIdx],
    async () => {
      const res = await request(HttpMethod.GET, searchBaseUrl, {
        param: {
          state: 'model',
          manufacturer_idx: manufacIdx,
          generation: generationIdx,
        },
      });
      return res?.data.dataset;
    },
    { revalidateOnFocus: true },
  );

  return {
    ModelData: data,
    isLoading: isValidating,
    error,
  };
};
