import styled from 'styled-components';
import { mediaQueries, MOBILE } from '../../styles/mediaQueries';

export const Main = styled.main``;
export const EventBox = styled.div`
  max-width: 1440px;
  min-width: 1220px;
  margin: 0 auto;
  padding: 80px;

  ${mediaQueries(MOBILE)} {
    min-width: auto;
    padding: 77px 20px;
  }
`;
export const EventHeader = styled.header`
  padding: 50px 0;

  ${mediaQueries(MOBILE)} {
    padding: 10px 0 30px;
  }
`;
export const Description = styled.p`
  font-size: 26px;
  color: #545e5e;
  line-height: 1.3em;
  word-break: keep-all;
  margin-bottom: 10px;

  em {
    font-weight: bold;
  }
  ${mediaQueries(MOBILE)} {
    font-size: 14px;
  }
`;
export const Title = styled.h2`
  font-size: 36px;
  ${mediaQueries(MOBILE)} {
    font-size: 20px;
  }
`;
export const ImageBox = styled.div`
  position: relative;
  width: 100%;
`;
export const LinkBox = styled.div`
  cursor: pointer;
  position: absolute;
  text-indent: -9999px;
`;
export const Empty = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding-top: 81px;
  font-size: 20px;
  text-align: center;
  word-break: keep-all;

  ${mediaQueries(MOBILE)} {
    padding-top: 77px;
  }
`;
