import { HttpMethod, request } from '../../common/network';
import { getBrandImg } from '../../common/utils';
import {
  NONAME_CODE,
  NONAME_CODE_DETAIL,
} from '../../common/constants/strings';

const baseUrl = `/rest/api/etc/product/note-book`;

export interface IPriceInquiryItem {
  name: string;
  code: string;
  img?: string;
}

const getSortRes = (arr: any[], nonameCode: number) => {
  let noneNamed = null;
  const sortRes = [
    ...arr.filter((item: any) => {
      if (item.cate02_num !== nonameCode) {
        return true;
      }

      noneNamed = item;

      return false;
    }),
  ];

  if (noneNamed) sortRes.push(noneNamed);

  return sortRes;
};

export const getPriceInquiryLaptop = (
  url: string,
  category: string,
  code: string,
): Promise<IPriceInquiryItem[]> => {
  let apiUrl = `${baseUrl}/category`;

  switch (category) {
    case 'manufacturer':
      apiUrl += '/cate02/';
      break;
    case 'series':
      apiUrl += '/cate03/';
      break;
    case 'model':
      apiUrl += '/cate04/';
      break;
    default:
      apiUrl += '/cate02/';
      break;
  }

  return request(HttpMethod.GET, apiUrl + code).then((res) => {
    const filterRes = res.data.dataset;

    switch (category) {
      case 'manufacturer':
        return getSortRes(filterRes, NONAME_CODE).map((item: any) => ({
          name: item.cate02_name,
          code: item.cate02_num,
          img: getBrandImg(item.cate02_num, 'laptop'),
        }));
      case 'series':
        return filterRes.map((item: any) => ({
          name: item.cate03_name,
          code: item.cate03_num,
        }));
      case 'model':
        return filterRes.map((item: any) => ({
          name: item.cate04_name,
          code: item.cate04_num,
        }));
      default:
        return [];
    }

    return res;
  });
};

export interface IPriceInquiryLaptopDetail {
  btob_price: string;
  btob_price_min: string;
  buy_price_a: string;
  buy_price_a_min: string;
  buy_price_b: string;
  buy_price_b_min: string;
  buy_price_c: string;
  buy_price_c_min: string;
  cate01_name: string;
  cate02_name: string;
  cate03_name: string;
  cate04_name: string;
  cate01_num: number;
  cate02_num: number;
  cate03_num: number;
  cate04_num: number;
  ex_price: string;
  fcart_num: number;
  future_standard_price: string;
  mem_num: number;
  pro_num: number;
  pro_number: string;
  reg_date: string;
  standard_price: string;
  additional_price: number;
}

export const getPriceInquiryLaptopDetail = (url: string, code: string) => {
  const apiUrl = `${baseUrl}/detail/`;

  if (code === String(NONAME_CODE_DETAIL)) {
    return request(
      HttpMethod.GET,
      `/rest/api/etc/product/tablet/detail/${code}`,
    ).then((res) => {
      if (res.data === '') {
        throw new Error('404');
      }

      return res.data.dataset;
    });
  }

  return request(HttpMethod.GET, apiUrl + code).then((res) => {
    if (res.data === '') {
      throw new Error('404');
    }

    return res.data.dataset;
  });
};

interface INoteBookDetailReqParams {
  modelNo: string;
}

export const getPriceInquiryNoteBookDetail = ({
  modelNo,
}: INoteBookDetailReqParams) => {
  return request(
    HttpMethod.GET,
    `/rest/api/etc/product/note-book/detail/${modelNo}`,
  ).then((res) => {
    if (res.data === '') {
      throw new Error('404');
    }

    return res.data.dataset;
  });
};

interface OrderNumberPostBody {
  proNum: string;
  memNum: string;
  salePhoneNumber: string;
}

export interface OrderNumberPostResponse {
  ord_number: string;
  cart_num: string;
}

export const requestCreateOrderNumber = ({
  proNum,
  memNum,
  salePhoneNumber,
}: OrderNumberPostBody): Promise<OrderNumberPostResponse> => {
  return request(
    HttpMethod.POST,
    `/rest/api/sale/samsung/laptop-transfer/order-number`,
    {
      body: {
        pro_num: proNum,
        mem_num: memNum,
        sale_phone_number: salePhoneNumber,
      },
    },
  ).then((res) => {
    if (res.data === '') {
      throw new Error('404');
    }

    if (res.data.code === 'APP_0000') {
      return res.data.dataset;
    }

    throw new Error(res.data.msg);
  });
};

export interface BankListResponse {
  comm_cd: string;
  comm_nm: string;
}

export const getBankList = () => {
  return request(
    HttpMethod.GET,
    `/rest/api/sale/samsung/laptop-transfer/bank`,
  ).then((res) => {
    if (res.data === '') {
      throw new Error('404');
    }

    return res.data.dataset;
  });
};

interface OrderPostBody {
  cate01_num: string;
  ord_number: string;
  cart_num: string;
  pro_num: string;
  om_name: string;
  om_hp: string;
  om_bank_name: string;
  om_acc_name: string;
  om_account: string;
}

export interface OrderPostResponse {
  ord_num: string;
}

export const requestCreateOrder = (
  body: OrderPostBody,
): Promise<OrderPostResponse> => {
  return request(
    HttpMethod.POST,
    `/rest/api/sale/samsung/laptop-transfer/order`,
    { body },
  ).then((res) => {
    if (res.data === '') {
      throw new Error('404');
    }

    if (res.data.code === 'APP_0000') {
      return res.data.dataset;
    }

    throw new Error(res.data.msg);
  });
};

export interface CheckAccountValidationPostBody {
  acnt_no: string;
  atm_acnt_nm: string;
  bnk_cd: string;
  bnk_nm: string;
}

export const requestCheckAccountValidation = (
  body: CheckAccountValidationPostBody,
): Promise<'SUCCESS' | 'ERROR'> => {
  return request(HttpMethod.POST, `/common/name/inquiry`, { body }).then(
    (res) => {
      return res.data;
    },
  );
};
