// 조회 baseurl
export const searchBaseUrl = '/rest/api/sale/est/price';

// 시세 조회
export const searchPriceDetail = '/rest/api/sale/est/v1/price/rslt';

// 이미지 조회
export const phoneImgUrl = 'https://d11o63lgw0n6wa.cloudfront.net';

// ATM 조회
export const SEARCH_ATM_URL = '/rest/api/etc/atm';

// 커넥터 설치
export const CONNECTOR_URL = 'https://app.mintit.co.kr/';

// 개인정보처리방침 url
export const PRIVACY_POLICY_URL =
  'https://www.mintit.co.kr/component/privacyPolicy.do?id=PRVC_PG';
