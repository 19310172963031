/* eslint-disable camelcase */
import { ReactNode, useEffect, useState } from 'react';
import { CSSObject } from 'styled-components';
import PriceInquiryLayout from 'components/PriceInquiryLayout';
import { useNavigate, useParams } from 'react-router-dom';
import MainContent from 'components/MainContent';
import {
  GradePriceItem,
  LaptopPriceInquiryDetailLayout,
  TitleAndText,
} from 'pages/LaptopPriceInquiryDetail/styles';
import {
  iconCheckedCircle,
  iconChevronDown,
  iconSecurity,
  mainIcon,
} from 'common/constants/imageUrls';
import Button from 'components/Button';
import PriceInquiryGradeModal from 'components/Modal/PriceInquiryGradeModal';
import Checkbox from 'components/Checkbox';
import { useFormik } from 'formik';
import Radio from 'components/Radio';
import Loading from 'components/Loading';
import {
  BankListResponse,
  getBankList,
  getPriceInquiryNoteBookDetail,
  IPriceInquiryLaptopDetail,
  OrderNumberPostResponse,
  requestCheckAccountValidation,
  requestCreateOrder,
  requestCreateOrderNumber,
} from 'apis/priceInquiryLaptop';
import {
  SellSection,
  SectionTitle,
  CheckBoxSpan,
  Container,
  SolutionContainer,
  StepContainer,
  InfoListItem,
  InfoList,
  Divider,
  FormKeySpan,
  FormItemLabelWrapper,
  FromItemWrapper,
  FormItemArticle,
  StyledInput,
  FormContainer,
  TermsContainer,
  Table,
  Th,
  Td,
  SelectContainer,
  Select,
  SellingInfoListItem,
  SellingInfoList,
  WarningContainer,
  ResetButton,
  CompleteButton,
  ButtonsContainer,
  FinishArticle,
  GoHomeButton,
  StyledWarningContainer,
  RadioContainer,
  RadioList,
  SellButton,
  WarningRow,
  TotalPriceContainer,
} from './style';

type formikInitValueType = {
  name: string;
  phoneNumber: string;
  bankCode: string;
  bankAccountNumber: string;
  deliveryType: string;
  terms: boolean;
};

const labelWrapperInlineStyle = {
  minWidth: '112px',
  maxWidth: '112px',
  marginRight: '24px',
};

const SamsunglaptopTransferDetail = () => {
  const [step, setStep] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [agreeState, setAgreeState] = useState({
    deleteDataAgree: false,
    finalPriceAfterCheckAgree: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [noteBookDetailInfo, setNoteBookDetailInfo] =
    useState<IPriceInquiryLaptopDetail>(defaultLaptopDetailValue);
  const [bankList, setBankList] = useState<BankListResponse[]>([]);
  const [orderNumber, setOrderNumber] =
    useState<OrderNumberPostResponse | null>(null);

  const isProduction = process.env.REACT_APP_ENV === 'product';

  const { modelCode } = useParams();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
      phoneNumber: '',
      bankCode: '',
      bankAccountNumber: '',
      deliveryType: '편의점 택배',
      terms: false,
    },
    onSubmit: async (value) => {
      try {
        setIsLoading(true);

        const accountValidationResponse = await requestCheckAccountValidation({
          acnt_no: value.bankAccountNumber,
          atm_acnt_nm: value.name,
          bnk_cd: value.bankCode,
          bnk_nm:
            bankList.find(({ comm_cd }) => value.bankCode === comm_cd)
              ?.comm_nm ?? '',
        });

        if (accountValidationResponse !== 'SUCCESS') {
          alert('유효하지 않은 계좌정보 입니다.');
          return;
        }

        const orderNum = await requestCreateOrderNumber({
          proNum: noteBookDetailInfo.pro_num.toString(),
          memNum: isProduction ? '1089' : '1014',
          salePhoneNumber: value.phoneNumber,
        });

        setOrderNumber(orderNum);
        goNextStep();
      } catch (e) {
        alert(e);
      } finally {
        setIsLoading(false);
      }
    },
    validate: (values) => {
      const errors: Partial<Record<keyof formikInitValueType, string>> = {};
      const { name, phoneNumber, bankCode, bankAccountNumber } = values;
      if (name.length === 0) {
        errors.name = '이름을 입력해주세요.';
      }
      if (phoneNumber.replaceAll('-', '').length !== 11) {
        errors.phoneNumber = '휴대폰 번호가 올바르지 않습니다.';
      }
      if (!bankCode) {
        errors.bankCode = '계좌 은행을 선택해주세요';
      }
      if (bankAccountNumber.length === 0) {
        errors.bankAccountNumber = '계좌번호를 입력해주세요';
      }

      return errors;
    },
    validateOnChange: false,
  });

  const goNextStep = () => {
    setStep((prev) => prev + 1);
  };

  const goSamsungLaptopMainPage = () => {
    navigate('/samsung-laptop-transfer');
  };

  const handleApplyLaptopEvent = async () => {
    if (!orderNumber || !modelCode) return;

    try {
      setIsLoading(true);

      await requestCreateOrder({
        cate01_num: isProduction ? '673' : '672',
        ord_number: orderNumber.ord_number,
        cart_num: orderNumber.cart_num,
        pro_num: noteBookDetailInfo.pro_num.toString(),
        om_name: formik.values.name,
        om_hp: formik.values.phoneNumber,
        om_bank_name:
          bankList.find(({ comm_cd }) => comm_cd === formik.values.bankCode)
            ?.comm_nm ?? '',
        om_acc_name: formik.values.name,
        om_account: formik.values.bankAccountNumber,
      });

      goNextStep();
    } catch (e) {
      alert(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handelCloseModal = () => {
    setIsModalOpen(false);
  };

  const getNoteBookModelNoViaModelCode = (modelCode: string) => {
    switch (modelCode) {
      case 'NT950QCG-XN58':
        return isProduction ? '443913' : '354115';
      case 'NT950XCJ-KN58':
        return isProduction ? '443500' : '353702';
      case 'NT550XCJ-KN38':
        return isProduction ? '446782' : '357014';
      case 'NT767XCM-KN59':
        return isProduction ? '443437' : '353639';
      case 'NT950QCG-KN38':
        return '443891';
      case 'NT930QCG-KN38':
        return '443859';
      case 'NT950XCJ-KN59':
        return '443501';
      case 'NT930XCJ-KN39':
        return '443458';
      default:
        return '';
    }
  };

  const getAdditionalCompensationViaModelCode = (modelCode: string) => {
    switch (modelCode) {
      case 'NT950QCG-XN58':
        return 108000;
      case 'NT950XCJ-KN58':
        return 94000;
      case 'NT767XCM-KN59':
        return 86000;
      case 'NT550XCJ-KN38':
        return 68000;
      default:
        return 0;
    }
  };

  const getTitleContent = (modelCode: string) => {
    let content;
    if (
      modelCode === 'NT950QCG-XN58' ||
      modelCode === 'NT950XCJ-KN58' ||
      modelCode === 'NT550XCJ-KN38' ||
      modelCode === 'NT767XCM-KN59'
    ) {
      content = (
        <>
          <h3>민팃 최대 보상가격</h3>
          <p className="font-inter">
            {Number(noteBookDetailInfo.btob_price_min).toLocaleString()} ~{' '}
            {Number(noteBookDetailInfo.buy_price_a).toLocaleString()}원
          </p>
        </>
      );
    } else {
      content = <h3>삼노환 CJ홈쇼핑 구매자용 신청페이지입니다.</h3>;
    }
    return content;
  };

  const getExPriceContent = (modelCode: string) => {
    let content;
    if (
      modelCode === 'NT950QCG-XN58' ||
      modelCode === 'NT950XCJ-KN58' ||
      modelCode === 'NT550XCJ-KN38' ||
      modelCode === 'NT767XCM-KN59'
    ) {
      content = (
        <>
          <h3>삼노환 보상금</h3>
          <p className="font-inter">
            +
            {modelCode
              ? Number(noteBookDetailInfo.ex_price).toLocaleString()
              : 0}
            원
          </p>
        </>
      );
    }
    return content;
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const data = await getPriceInquiryNoteBookDetail({
          modelNo: getNoteBookModelNoViaModelCode(modelCode as string),
        });

        let detailData = {
          ...data,
        };

        switch (modelCode) {
          case 'NT950QCG-XN58':
            detailData = {
              ...data,
              btob_price: '77000',
              btob_price_min: '46000',
              buy_price_a: '510000',
              buy_price_a_min: '304000',
              buy_price_b: '408000',
              buy_price_b_min: '243000',
              buy_price_c: '255000',
              buy_price_c_min: '152000',
              ex_price: '108000',
            };
            break;
          case 'NT950XCJ-KN58':
            detailData = {
              ...data,
              btob_price: '62000',
              btob_price_min: '36000',
              buy_price_a: '410000',
              buy_price_a_min: '242000',
              buy_price_b: '328000',
              buy_price_b_min: '194000',
              buy_price_c: '205000',
              buy_price_c_min: '121000',
              ex_price: '94000',
            };
            break;
          case 'NT767XCM-KN59':
            detailData = {
              ...data,
              btob_price: '42000',
              btob_price_min: '24000',
              buy_price_a: '280000',
              buy_price_a_min: '158000',
              buy_price_b: '224000',
              buy_price_b_min: '126000',
              buy_price_c: '140000',
              buy_price_c_min: '79000',
              ex_price: '86000',
            };
            break;
          case 'NT550XCJ-KN38':
            detailData = {
              ...data,
              btob_price: '29000',
              btob_price_min: '16000',
              buy_price_a: '193000',
              buy_price_a_min: '106000',
              buy_price_b: '154000',
              buy_price_b_min: '85000',
              buy_price_c: '97000',
              buy_price_c_min: '53000',
              ex_price: '68000',
            };
            break;
          case 'NT950QCG-KN38':
            detailData = {
              ...data,
              btob_price: '135000',
              btob_price_min: '105000',
              buy_price_a: '900000',
              buy_price_a_min: '700000',
              buy_price_b: '720000',
              buy_price_b_min: '560000',
              buy_price_c: '450000',
              buy_price_c_min: '350000',
              ex_price: '0',
            };
            break;
          case 'NT930QCG-KN38':
            detailData = {
              ...data,
              btob_price: '127500',
              btob_price_min: '97500',
              buy_price_a: '850000',
              buy_price_a_min: '650000',
              buy_price_b: '680000',
              buy_price_b_min: '520000',
              buy_price_c: '425000',
              buy_price_c_min: '325000',
              ex_price: '0',
            };
            break;
          case 'NT950XCJ-KN59':
            detailData = {
              ...data,
              btob_price: '127500',
              btob_price_min: '97500',
              buy_price_a: '850000',
              buy_price_a_min: '650000',
              buy_price_b: '680000',
              buy_price_b_min: '520000',
              buy_price_c: '425000',
              buy_price_c_min: '325000',
              ex_price: '0',
            };
            break;
          case 'NT930XCJ-KN39':
            detailData = {
              ...data,
              btob_price: '105000',
              btob_price_min: '81000',
              buy_price_a: '700000',
              buy_price_a_min: '540000',
              buy_price_b: '560000',
              buy_price_b_min: '432000',
              buy_price_c: '350000',
              buy_price_c_min: '270000',
              ex_price: '0',
            };
            break;
          default:
            detailData = {
              ...data,
              btob_price: '77000',
              btob_price_min: '46000',
              buy_price_a: '510000',
              buy_price_a_min: '304000',
              buy_price_b: '408000',
              buy_price_b_min: '243000',
              buy_price_c: '255000',
              buy_price_c_min: '152000',
              ex_price: '108000',
            };
            break;
        }

        setNoteBookDetailInfo(detailData);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [modelCode]);

  useEffect(() => {
    if (step === 1) {
      try {
        (async () => {
          const fetchedBankList = await getBankList();

          setBankList(fetchedBankList);
        })();
      } catch (e) {
        setBankList([]);
      }
    }
  }, [step]);

  const renderStepContent = () => {
    const {
      btob_price,
      btob_price_min,
      buy_price_a,
      buy_price_a_min,
      buy_price_b,
      buy_price_b_min,
      buy_price_c,
      buy_price_c_min,
      ex_price,
      cate01_name,
      cate02_name,
      cate03_name,
      cate04_name,
    } = noteBookDetailInfo;

    switch (step) {
      case 0:
        return (
          <>
            <div className="detail">
              <div className="side">
                <img
                  className="side__background"
                  width="100%"
                  src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/images/note-book-models/${modelCode}.jpg`}
                  alt="노트북"
                />
              </div>
              <div className="content">
                <section className="content__item">
                  <header className="header">
                    <h2 className="header__name">{modelCode}</h2>
                    <ul className="header__category-list">
                      <li className="header__category-item">{cate02_name}</li>
                      <li className="header__category-item">{cate03_name}</li>
                      <li className="header__category-item">{cate01_name}</li>
                    </ul>
                  </header>
                  <div className="body">
                    <TitleAndText
                      className="body__item"
                      fontSize="20px"
                      mobileFontSize="16px"
                    >
                      {getTitleContent(`${modelCode}`)}
                    </TitleAndText>

                    <div className="body__item add-compensation">
                      <div className="add-compensation__icon" />
                      <TitleAndText
                        className="add-compensation__title"
                        fontSize="20px"
                        mobileFontSize="16px"
                      >
                        {getExPriceContent(`${modelCode}`)}
                      </TitleAndText>
                      <div className="notice">
                        <h4 className="notice__title">
                          <span />
                          확인해 주세요!
                        </h4>
                        <ul
                          className="notice__list"
                          style={{ wordBreak: 'keep-all' }}
                        >
                          <li className="notice__item">
                            <span />
                            SK네트웍스와의 제휴로 진행되는 이벤트입니다. 조건에
                            맞지 않는 경우 보상이 거절될 수 있습니다.
                          </li>
                          <li className="notice__item">
                            <span />
                            외관상태, 기능상태에 따라 매입가격은 다를 수
                            있습니다. 검수 후 최종보상가격은 알림톡으로
                            안내되며, 보상가 불만족시 반송요청을 할 수 있습니다.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <footer className="footer">
                    <TitleAndText
                      className="footer__total"
                      fontSize="24px"
                      mobileFontSize="20px"
                    >
                      <h3>총 합계</h3>
                      <TotalPriceContainer>
                        <p className="font-inter total-left">
                          {(
                            Number(btob_price_min) + Number(ex_price)
                          ).toLocaleString()}{' '}
                          ~{' '}
                          <em className="total-right">
                            {(
                              Number(buy_price_a) + Number(ex_price)
                            ).toLocaleString()}
                            원
                          </em>
                        </p>
                      </TotalPriceContainer>
                    </TitleAndText>
                    <Button
                      className="footer__button"
                      type="secondary"
                      to="/samsung-laptop-transfer"
                    >
                      다시 조회하기
                    </Button>
                  </footer>
                </section>
                <section className="content__price">
                  <header className="header">
                    <h2 className="header__title">등급별 시세</h2>
                    <button
                      type="button"
                      className="header__button"
                      onClick={handleOpenModal}
                    >
                      등급기준 <span />
                    </button>
                  </header>
                  <div>
                    <ul>
                      <GradePriceItem grade="A">
                        <span style={{ flexShrink: 0 }}>A등급</span>
                        <div style={{ width: '100%' }} className="text extra">
                          <h3
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            정상
                            <p className="price font-inter">
                              {Number(buy_price_a_min).toLocaleString()} ~{' '}
                              <em>{Number(buy_price_a).toLocaleString()}원</em>
                            </p>
                          </h3>
                          <p>기기의 작동 및 외관이 모두 양호한 상태</p>
                        </div>
                      </GradePriceItem>
                      <GradePriceItem grade="B">
                        <span style={{ flexShrink: 0 }}>B등급</span>
                        <div style={{ width: '100%' }} className="text extra">
                          <h3
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            손상
                            <p className="price font-inter">
                              {Number(buy_price_b_min).toLocaleString()} ~{' '}
                              <em>{Number(buy_price_b).toLocaleString()}원</em>
                            </p>
                          </h3>
                          <p>기능 문제 없고, 외관상태에 이상이 있는 상태</p>
                        </div>
                      </GradePriceItem>
                      <GradePriceItem grade="C">
                        <span style={{ flexShrink: 0 }}>C등급</span>
                        <div style={{ width: '100%' }} className="text extra">
                          <h3
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            파손
                            <p className="price font-inter">
                              {Number(buy_price_c_min).toLocaleString()} ~{' '}
                              <em>{Number(buy_price_c).toLocaleString()}원</em>
                            </p>
                          </h3>
                          <p>기능 문제 없고, 외관 파손(불량)이 있는 상태</p>
                        </div>
                      </GradePriceItem>
                      <GradePriceItem grade="D">
                        <span style={{ flexShrink: 0 }}>D등급</span>
                        <div style={{ width: '100%' }} className="text extra">
                          <h3
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            고장
                            <p className="price font-inter">
                              {Number(btob_price_min).toLocaleString()} ~{' '}
                              <em>{Number(btob_price).toLocaleString()}원</em>
                            </p>
                          </h3>
                          <p>기능불량 또는 외관불량이 있는 상태</p>
                        </div>
                      </GradePriceItem>
                    </ul>
                  </div>
                </section>
                <SellSection>
                  <header>
                    <SectionTitle>판매하기</SectionTitle>
                  </header>

                  <Checkbox
                    onClick={() => {
                      setAgreeState((prev) => {
                        return {
                          ...prev,
                          deleteDataAgree: !prev.deleteDataAgree,
                        };
                      });
                    }}
                    isChecked={agreeState.deleteDataAgree}
                  >
                    <CheckBoxSpan>데이터 완전 삭제 동의</CheckBoxSpan>
                  </Checkbox>

                  <Container>
                    <p>
                      판매하시는 노트북에 남아있는 소중한 개인정보 유출 방지를
                      위해,{`\n`}평가센터 도착즉시 민팃의 “개인정보 완전삭제
                      솔루션“을 통해 데이터를 삭제합니다.
                    </p>
                    <p>
                      <strong>
                        판매를 철회하실 경우 데이터 복구가 불가하오니 신중하게
                        선택해주세요.
                      </strong>
                    </p>
                  </Container>

                  <SolutionContainer>
                    <figure>
                      <img src={iconSecurity} alt="security" />
                    </figure>
                    <div>
                      <p className="bold">개인정보 완전삭제 솔루션</p>
                      <p>
                        저장장치에 남아있는 운영체제(OS)까지 복원되지 않는
                        상태로 완전 삭제되며,{`\n`}삭제 후 데이터 삭제 인증서를
                        발송해 드립니다.
                      </p>
                    </div>
                  </SolutionContainer>

                  <Checkbox
                    onClick={() => {
                      setAgreeState((prev) => {
                        return {
                          ...prev,
                          finalPriceAfterCheckAgree:
                            !prev.finalPriceAfterCheckAgree,
                        };
                      });
                    }}
                    isChecked={agreeState.finalPriceAfterCheckAgree}
                  >
                    <CheckBoxSpan>
                      검수 후 최종 가격이 결정됩니다. 동의하십니까?
                    </CheckBoxSpan>
                  </Checkbox>

                  <SellButton
                    type={
                      !agreeState.deleteDataAgree ||
                      !agreeState.finalPriceAfterCheckAgree
                        ? 'primaryDisabled'
                        : 'primary'
                    }
                    disabled={
                      !agreeState.deleteDataAgree ||
                      !agreeState.finalPriceAfterCheckAgree
                    }
                    onClick={goNextStep}
                  >
                    민팃에 판매하기
                  </SellButton>
                </SellSection>
              </div>
            </div>
            <PriceInquiryGradeModal
              datas={gradeInfoModalContents}
              visible={isModalOpen}
              onClose={handelCloseModal}
            />
          </>
        );

      case 1:
        return (
          <div className="detail">
            <div className="side">
              <img
                className="side__background"
                width="100%"
                src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/images/note-book-models/${modelCode}.jpg`}
                alt="노트북"
              />
            </div>

            <div className="content">
              <section className="content__item">
                <header className="header">
                  <StepContainer>
                    <span className="cur-step">02</span>
                    <span>/</span>
                    <span className="total-step">03</span>
                  </StepContainer>
                  <h2
                    className="header__name"
                    style={{
                      fontFamily: 'Inter',
                    }}
                  >
                    판매 정보를 입력해 주세요
                  </h2>
                </header>
              </section>

              <InfoList>
                <h5>상품정보</h5>

                {[
                  { key: '제품군', value: cate01_name },
                  { key: '제조사', value: cate02_name },
                  { key: '시리즈', value: cate03_name },
                  { key: '모델', value: cate04_name },
                  {
                    key: '최대 매입가격',
                    value: `${(
                      Number(buy_price_a) +
                      getAdditionalCompensationViaModelCode(modelCode || '')
                    ).toLocaleString()}원`,
                  },
                ].map((item, idx) => {
                  const { key, value } = item;
                  return (
                    <InfoListItem key={idx.toString()}>
                      <span className="info-list-item-key">{key}</span>
                      <span className="info-list-item-value">{value}</span>
                    </InfoListItem>
                  );
                })}
              </InfoList>

              <Divider />

              <FormContainer>
                <FormItem
                  label={<FormKeySpan>이름</FormKeySpan>}
                  labelWrapperStyle={labelWrapperInlineStyle}
                  required
                  input={
                    <StyledInput
                      id="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      placeholder="이름을 입력해주세요"
                      error={!!formik.errors.name}
                    />
                  }
                />
                <FormItem
                  label={<FormKeySpan>휴대폰 번호</FormKeySpan>}
                  labelWrapperStyle={labelWrapperInlineStyle}
                  required
                  input={
                    <StyledInput
                      id="phoneNumber"
                      onChange={formik.handleChange}
                      value={formik.values.phoneNumber}
                      placeholder="예)0101234567"
                      error={!!formik.errors.phoneNumber}
                    />
                  }
                />
                <FormItem
                  label={<FormKeySpan>입금받을 계좌</FormKeySpan>}
                  labelWrapperStyle={labelWrapperInlineStyle}
                  className="small-padding-bottom"
                  required
                  input={
                    <SelectContainer>
                      <Select
                        id="bankCode"
                        onChange={(e) =>
                          formik.setFieldValue('bankCode', e.target.value)
                        }
                        value={formik.values.bankCode}
                      >
                        <option value="">
                          본인명의 계좌 은행을 선택해 주세요
                        </option>
                        {bankList?.map(({ comm_cd, comm_nm }) => {
                          return (
                            <option key={comm_cd} value={comm_cd}>
                              {comm_nm}
                            </option>
                          );
                        })}
                      </Select>
                      <img src={iconChevronDown} alt="계좌 리스트" />
                    </SelectContainer>
                  }
                />
                <FormItem
                  required
                  formWrapperStyle={{ marginLeft: 'auto' }}
                  input={
                    <StyledInput
                      id="bankAccountNumber"
                      onChange={formik.handleChange}
                      placeholder="계좌번호를 입력해 주세요"
                      value={formik.values.bankAccountNumber}
                      error={!!formik.errors.bankAccountNumber}
                    />
                  }
                />
                <FormItem
                  label={<FormKeySpan>배송방식(무료)</FormKeySpan>}
                  labelWrapperStyle={labelWrapperInlineStyle}
                  required
                  input={
                    <RadioList>
                      <RadioContainer
                        onClick={() => {
                          formik.setFieldValue('deliveryType', '편의점 택배');
                        }}
                      >
                        <Radio
                          isChecked={
                            formik.values.deliveryType === '편의점 택배'
                          }
                          onClick={() => {
                            return undefined;
                          }}
                        />
                        <span>편의점 택배</span>
                      </RadioContainer>

                      <RadioContainer
                        onClick={() => {
                          formik.setFieldValue('deliveryType', '일반 택배');
                        }}
                      >
                        <Radio
                          isChecked={formik.values.deliveryType === '일반 택배'}
                          onClick={() => {
                            return undefined;
                          }}
                        />
                        <span>일반 택배</span>
                      </RadioContainer>
                    </RadioList>
                  }
                />
                <FormItem
                  label={<FormKeySpan>이용동의</FormKeySpan>}
                  labelWrapperStyle={labelWrapperInlineStyle}
                  required
                  input={
                    <Checkbox
                      isChecked={formik.values.terms}
                      onClick={() =>
                        formik.setFieldValue('terms', !formik.values.terms)
                      }
                    >
                      <CheckBoxSpan>개인정보 수집 및 활용 동의</CheckBoxSpan>
                    </Checkbox>
                  }
                />
              </FormContainer>

              <TermsContainer>
                <div>
                  <p>
                    <b>개인정보 수집 및 이용 동의</b>
                  </p>
                  <p>
                    MINTIT(민팃)에서는 적법하고 공정한 수단에 의하여 고객님의
                    개인정보를 수집합니다.
                  </p>
                </div>

                <div>
                  <p>
                    <b>개인정보 수집 항목 및 이용 목적</b>
                  </p>
                  <p>
                    MINTIT(민팃)은 개인정보보호법 제 32조에 근거하여 아래와 같이
                    개인정보를 수집하고 이용합니다.
                  </p>
                </div>

                <Table>
                  <tr>
                    <Th>구분</Th>
                    <Th>수집항목</Th>
                    <Th>이용목적</Th>
                    <Th>보유기간</Th>
                  </tr>
                  <tr>
                    <Td>필수</Td>
                    <Td>이름,연락처,은행계좌번호</Td>
                    <Td>고객님이 판매하신 중고기기{`\n`}대금 입금 시 활용</Td>
                    <Td>보유기간</Td>
                  </tr>
                </Table>
              </TermsContainer>

              <SellButton
                type={
                  !!formik.values.name &&
                  !!formik.values.phoneNumber &&
                  !!formik.values.bankCode &&
                  !!formik.values.bankAccountNumber &&
                  !!formik.values.terms
                    ? 'primary'
                    : 'primaryDisabled'
                }
                disabled={
                  !(
                    !!formik.values.name &&
                    !!formik.values.phoneNumber &&
                    !!formik.values.bankCode &&
                    !!formik.values.bankAccountNumber &&
                    !!formik.values.terms
                  )
                }
                onClick={formik.handleSubmit}
              >
                계속하기
              </SellButton>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="detail">
            <div className="side">
              <img
                className="side__background"
                width="100%"
                src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/images/note-book-models/${modelCode}.jpg`}
                alt="노트북"
              />
            </div>

            <div className="content">
              <section className="content__item">
                <header className="header">
                  <StepContainer>
                    <span className="cur-step">03</span>
                    <span>/</span>
                    <span className="total-step">03</span>
                  </StepContainer>
                  <h2
                    className="header__name"
                    style={{
                      fontFamily: 'Inter',
                      wordBreak: 'keep-all',
                    }}
                  >
                    아래의 정보로 판매를 진행하시겠어요?
                  </h2>
                </header>
              </section>

              <InfoList>
                <h5>상품정보</h5>

                {[
                  { key: '제품군', value: cate01_name },
                  { key: '제조사', value: cate02_name },
                  { key: '시리즈', value: cate03_name },
                  { key: '모델', value: cate04_name },
                  {
                    key: '최대 매입가격',
                    value: `${(
                      Number(buy_price_a) +
                      getAdditionalCompensationViaModelCode(modelCode || '')
                    ).toLocaleString()}원`,
                  },
                ].map((item, idx) => {
                  const { key, value } = item;
                  return (
                    <InfoListItem key={idx.toString()}>
                      <span className="info-list-item-key">{key}</span>
                      <span className="info-list-item-value">{value}</span>
                    </InfoListItem>
                  );
                })}
              </InfoList>

              <Divider />

              <InfoList>
                <h5>등록정보</h5>

                {[
                  { key: '판매신청번호', value: orderNumber?.ord_number },
                  { key: '판매자명', value: formik.values.name },
                  { key: '휴대폰 번호', value: formik.values.phoneNumber },
                  {
                    key: '입금받을 은행',
                    value: bankList.find(
                      ({ comm_cd }) => comm_cd === formik.values.bankCode,
                    )?.comm_nm,
                  },
                  { key: '계좌번호', value: formik.values.bankAccountNumber },
                  { key: '배송방식', value: formik.values.deliveryType },
                ].map((item, idx) => {
                  const { key, value } = item;
                  return (
                    <InfoListItem key={idx.toString()}>
                      <span className="info-list-item-key">{key}</span>
                      <span className="info-list-item-value">{value}</span>
                    </InfoListItem>
                  );
                })}
              </InfoList>

              <Divider />

              <SellingInfoList>
                <h5>판매가격</h5>

                {[
                  {
                    key: '판매 신청 금액',
                    value: `${(
                      Number(buy_price_a) +
                      getAdditionalCompensationViaModelCode(modelCode || '')
                    ).toLocaleString()}원`,
                  },
                  { key: '배송비', value: '0원' },
                  {
                    key: '정산 예정 금액',
                    value: `${(
                      Number(buy_price_a) +
                      getAdditionalCompensationViaModelCode(modelCode || '')
                    ).toLocaleString()}원`,
                    isBold: true,
                  },
                ].map((item, idx) => {
                  const { key, value } = item;
                  return (
                    <SellingInfoListItem
                      key={idx.toString()}
                      isBold={item?.isBold}
                    >
                      <span className="selling-info-list-item-key">{key}</span>
                      <span className="selling-info-list-item-value">
                        {value}
                      </span>
                    </SellingInfoListItem>
                  );
                })}
              </SellingInfoList>

              <WarningContainer>
                <WarningRow>
                  <img src={mainIcon.warningIcon} alt="warning" />
                  <p>
                    체크해 주신 제품상태와 확인된 제품상태가 상이할 경우 차감이
                    발생할 수 있습니다.
                  </p>
                </WarningRow>
                <WarningRow>
                  <img src={mainIcon.warningIcon} alt="warning" />
                  <p>
                    권한실행 해당월 이후 신청시기에 따라 보상금액이 달라 질 수
                    있습니다
                  </p>
                </WarningRow>
              </WarningContainer>

              <ButtonsContainer>
                <ResetButton onClick={() => setStep(1)}>
                  다시 입력하기
                </ResetButton>

                <CompleteButton onClick={handleApplyLaptopEvent}>
                  완료하기
                </CompleteButton>
              </ButtonsContainer>
            </div>
          </div>
        );

      case 3:
        return (
          <FinishArticle>
            <h1>신청이 완료되었습니다!</h1>

            <figure>
              <img src={iconCheckedCircle} alt="check" />
            </figure>

            <h2>택배발송만 남았어요</h2>

            <div className="delivery-step">
              <span>1</span>
              <p>카카오 알림톡에서 접수번호 확인</p>
            </div>
            <div className="delivery-step">
              <span>2</span>
              <p>꼼꼼히 포장 후 택배발송</p>
            </div>
            <div className="delivery-step">
              <span>3</span>
              <p>
                택배발송 운송번호는 카카오 알림톡 하단의 배송정보 입력창에 입력
              </p>
            </div>

            <StyledWarningContainer>
              <WarningRow>
                <img src={mainIcon.warningIcon} alt="warning" />
                <p>
                  신청기기의 진행상태는 카카오 알림톡과 신청페이지 상단의
                  보상진행조회에서 접수번호로 조회가능합니다.
                </p>
              </WarningRow>
            </StyledWarningContainer>

            <GoHomeButton onClick={goSamsungLaptopMainPage}>
              홈으로 이동
            </GoHomeButton>
          </FinishArticle>
        );
      default:
        return null;
    }
  };

  return (
    <PriceInquiryLayout>
      {isLoading ? <Loading /> : null}
      <MainContent>
        <LaptopPriceInquiryDetailLayout>
          <h1 className="hidden">노트북 상세</h1>
          {renderStepContent()}
        </LaptopPriceInquiryDetailLayout>
      </MainContent>
    </PriceInquiryLayout>
  );
};

export default SamsunglaptopTransferDetail;

const gradeInfoModalContents = [
  {
    name: '정상',
    description: '기기의 작동 및 외관이 모두 양호한 상태',
    content1: { name: '외관', text: '미세생활기스, 양호' },
    content2: { name: '액정', text: '멍 또는 백화, 잔상이 없는 상태' },
    content3: { name: '기능', text: '정상' },
  },
  {
    name: '손상',
    description: '기능 문제 없고, 외관상태에 이상이 있는 상태',
    content1: {
      name: '외관',
      text: '굵은 스크래치, 미세크랙(균열), 미세변색(탈색)',
    },
    content2: {
      name: '액정',
      text: '미세(5mm이하) 멍, 기스(켜진화면에서 안보이는)',
    },
    content3: { name: '기능', text: '정상' },
  },
  {
    name: '파손',
    description: '기능 문제 없고, 외관 파손(불량)이 있는 상태',
    content1: {
      name: '외관',
      text: '크랙 또는 파손(깨짐), 변색(탈색), 키보드 파손(키없음)',
    },
    content2: {
      name: '액정',
      text: '5mm이상의 멍, 다수멍, 기스(반짝거리는 기스), 색상이상',
    },
    content3: { name: '기능', text: '정상' },
  },
  {
    name: '고장',
    description: '기능불량 또는 외관 불량이 있는 상태',
    content1: {
      name: '외관',
      text: '굵은 스크래치, 미세크랙(균열), 미세변색(탈색)',
    },
    content2: {
      name: '액정',
      text: '화면불량(무감), 10mm이상의 멍, 줄생김, 파손',
    },
    content3: { name: '기능', text: '키보드, 포트, 전원불량등 기능고장' },
  },
];

interface FormItemProps {
  required?: boolean;
  label?: ReactNode;
  input: ReactNode;
  className?: string;
  style?: CSSObject;
  labelWrapperStyle?: CSSObject;
  formWrapperStyle?: CSSObject;
}

const FormItem = ({
  required = false,
  label,
  input,
  className,
  style,
  labelWrapperStyle,
  formWrapperStyle,
}: FormItemProps) => {
  return (
    <FormItemArticle className={className} style={style}>
      {!!label && (
        <FormItemLabelWrapper isRequired={required} style={labelWrapperStyle}>
          {label}
        </FormItemLabelWrapper>
      )}

      <FromItemWrapper style={formWrapperStyle}>{input}</FromItemWrapper>
    </FormItemArticle>
  );
};

const defaultLaptopDetailValue: IPriceInquiryLaptopDetail = {
  btob_price: '0',
  btob_price_min: '0',
  buy_price_a: '0',
  buy_price_a_min: '0',
  buy_price_b: '0',
  buy_price_b_min: '0',
  buy_price_c: '0',
  buy_price_c_min: '0',
  cate01_name: '',
  cate02_name: '',
  cate03_name: '',
  cate04_name: '',
  cate01_num: 0,
  cate02_num: 0,
  cate03_num: 0,
  cate04_num: 0,
  ex_price: '0',
  fcart_num: 0,
  future_standard_price: '0',
  mem_num: 0,
  pro_num: 0,
  pro_number: '0',
  reg_date: '',
  standard_price: '0',
  additional_price: 0,
};
