import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import HeaderOnly from '../../components/HeaderOnly';
import * as S from './styles';
import { eventInfoList } from './data';
import useTitle from '../../hooks/useTitle';
import { getIsEnded } from '../EventListHome';

const EventDetail = () => {
  useTitle('민팃 - 이벤트/캠페인');

  const params = useParams<{ id: string }>();
  const eventInfo = eventInfoList[Number(params.id)];
  const links = eventInfo?.links;

  const navigate = useNavigate();

  const handleClickLink = (href: string, isExternal?: boolean) => {
    if (isExternal) {
      window.location.href = href;
    } else {
      navigate(href);
    }
  };

  useEffect(() => {
    if (
      window?.location &&
      eventInfo &&
      !getIsEnded(eventInfo.startDate, eventInfo.endDate) &&
      eventInfo.externalHref
    ) {
      window.location.href = eventInfo.externalHref;
    }
  }, []);

  return (
    <>
      <HeaderOnly href="/event" />
      <S.Main>
        {eventInfo && !getIsEnded(eventInfo.startDate, eventInfo.endDate) ? (
          <S.EventBox>
            <S.ImageBox>
              <img
                width="100%"
                src={eventInfo.img}
                alt=""
                onError={() => console.log('error~!!')}
              />
              {links &&
                links.map((item) => (
                  <S.LinkBox
                    key={item.href}
                    onClick={() => handleClickLink(item.href, item.isExternal)}
                    style={item.style}
                    id={item.id}
                  >
                    {item.text}
                  </S.LinkBox>
                ))}
            </S.ImageBox>
          </S.EventBox>
        ) : (
          <S.Empty>종료되었거나, 존재하지 않는 이벤트입니다.</S.Empty>
        )}
      </S.Main>
    </>
  );
};

export default EventDetail;
