import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { flexCenterCss } from 'styles/commonStyle';
import theme from 'styles/theme';

type BtnType =
  | 'primary'
  | 'primaryDisabled'
  | 'ghost'
  | 'ghostDisabled'
  | 'secondary'
  | 'secondaryDisabled'
  | 'info'
  | 'infoSelect';

interface BtnProps {
  type?: BtnType;
  href?: string;
  hrefSelf?: string;
  to?: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}

const Button: FunctionComponent<BtnProps> = ({
  type,
  children,
  to,
  onClick,
  disabled,
  href,
  hrefSelf,
  className,
  ...rest
}) => {
  if (to) {
    return (
      <ButtonStyle.Link className={className} {...rest} to={to} btnstyle={type}>
        {children}
      </ButtonStyle.Link>
    );
  }
  if (href) {
    return (
      <ButtonStyle.A
        {...rest}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        btnstyle={type}
        className={className}
      >
        {children}
      </ButtonStyle.A>
    );
  }
  if (hrefSelf) {
    return (
      <ButtonStyle.A
        {...rest}
        href={hrefSelf}
        target="_self"
        btnstyle={type}
        className={className}
      >
        {children}
      </ButtonStyle.A>
    );
  }
  if (onClick) {
    return (
      <ButtonStyle.Button
        type="button"
        {...rest}
        onClick={onClick}
        disabled={disabled}
        btnstyle={type}
        className={className}
      >
        {children}
      </ButtonStyle.Button>
    );
  }
  return (
    <ButtonStyle.Button
      type="submit"
      {...rest}
      disabled={disabled}
      btnstyle={type}
      className={className}
    >
      {children}
    </ButtonStyle.Button>
  );
};

Button.defaultProps = {
  type: 'primary',
};

export default Button;

const CommonStyle = css<{ btnstyle?: BtnType }>`
  ${flexCenterCss};
  border-radius: 8px;

  &:not(:disabled):hover {
    opacity: 0.8;
  }

  ${({ btnstyle }) => {
    switch (btnstyle) {
      case 'primaryDisabled':
        return css`
          ${theme.button.primaryDisabled}
        `;
      case 'ghost':
        return css`
          ${theme.button.ghost};

          &:not(:disabled):hover {
            ${theme.button.primary};
          }
        `;
      case 'ghostDisabled':
        return css`
          ${theme.button.ghostDisabled}
        `;
      case 'secondary':
        return css`
          ${theme.button.secondary}
        `;
      case 'secondaryDisabled':
        return css`
          ${theme.button.secondaryDisabled}
        `;
      case 'info':
        return css`
          ${theme.button.info}
        `;
      case 'infoSelect':
        return css`
          ${theme.button.infoSelect}
        `;
      default:
        return css`
          ${theme.button.primary};
        `;
    }
  }}
`;

const ButtonStyle = {
  Link: styled(Link)`
    ${CommonStyle}
  `,

  A: styled.a`
    ${CommonStyle}
  `,

  Button: styled.button`
    ${CommonStyle}
  `,
};
