import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';
import HeaderOnly from '../../components/HeaderOnly';
import useTitle from '../../hooks/useTitle';
import { eventInfoList } from '../EventDetail/data';

export const getIsEnded = (start: string, end: string) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const nowDate = new Date();

  return !(startDate < nowDate && endDate > nowDate);
};

const EventListHome = () => {
  useTitle('민팃 - 이벤트/캠페인');

  const navigate = useNavigate();

  const handleLinkClick = (path: string, isExternal?: boolean) => {
    if (window?.location && isExternal) {
      window.location.href = path;

      return;
    }
    navigate(path);
  };

  return (
    <>
      <HeaderOnly href="/event" />
      <S.Main>
        <S.Section>
          <S.SectionHeader>
            <S.SectionTitle>다양한 이벤트를 확인하세요</S.SectionTitle>
          </S.SectionHeader>
          <S.EventList>
            {Object.keys(eventInfoList).map((key) => (
              <S.EventItem
                key={key}
                onClick={() =>
                  eventInfoList[key].externalHref
                    ? handleLinkClick(
                        eventInfoList[key].externalHref as string,
                        true,
                      )
                    : handleLinkClick(`/event/${key}`)
                }
                isEnded={getIsEnded(
                  eventInfoList[key].startDate,
                  eventInfoList[key].endDate,
                )}
              >
                <S.EventImageBox>
                  <img
                    width="100%"
                    src={eventInfoList[key].bannerImg}
                    alt={eventInfoList[key].title}
                  />
                </S.EventImageBox>
                <S.EventTitle>
                  <span>{eventInfoList[key].title}</span>
                </S.EventTitle>
                <S.EventText>{eventInfoList[key].description}</S.EventText>
              </S.EventItem>
            ))}
          </S.EventList>
        </S.Section>
      </S.Main>
    </>
  );
};

export default EventListHome;
