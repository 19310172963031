import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { closedIcon, headerLogo } from 'common/constants/imageUrls';
import {
  ATM_GUIDE,
  HOME_URL,
  QUIZ_EVENT,
  ATM_FIND,
  HOME_MINTIT,
  PRICE_INQUIRY,
} from 'common/constants/pathUrls';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';
import theme from 'styles/theme';

interface HeaderProps {
  visible: boolean;
  onClose?: () => void;
  isFixed?: boolean;
}

const DropDown: React.FunctionComponent<HeaderProps> = ({
  visible,
  onClose,
  isFixed,
}) => {
  return (
    <DropDownStyle.Base visible={visible} isFixed={isFixed}>
      <DropDownStyle.Header>
        <DropDownStyle.LogoWrapper to={HOME_URL} onClick={onClose}>
          <DropDownStyle.Logo src={headerLogo} />
        </DropDownStyle.LogoWrapper>
        <DropDownStyle.ClosedWrapper onClick={onClose}>
          <DropDownStyle.Closed src={closedIcon} />
        </DropDownStyle.ClosedWrapper>
      </DropDownStyle.Header>
      <DropDownStyle.MenuList>
        <DropDownStyle.MenuAtr to={PRICE_INQUIRY} onClick={onClose}>
          <DropDownStyle.Menu>추가보상금 확인</DropDownStyle.Menu>
        </DropDownStyle.MenuAtr>
        <DropDownStyle.MenuAtr to={ATM_FIND}>
          <DropDownStyle.Menu>민팃 ATM 찾기</DropDownStyle.Menu>
        </DropDownStyle.MenuAtr>
        <DropDownStyle.MenuAtr to={ATM_GUIDE} onClick={onClose}>
          <DropDownStyle.Menu>민팃 ATM 이용방법</DropDownStyle.Menu>
        </DropDownStyle.MenuAtr>
        {new Date() < new Date('2022-04-01 00:00:00') ? (
          <DropDownStyle.MenuAtr to={QUIZ_EVENT} onClick={onClose}>
            <DropDownStyle.Menu> 퀴즈 이벤트</DropDownStyle.Menu>
          </DropDownStyle.MenuAtr>
        ) : null}
        <DropDownStyle.MenuAtrEx href={HOME_MINTIT} target="_blank">
          <DropDownStyle.Menu>
            <span>홈페이지 가기</span>
          </DropDownStyle.Menu>
        </DropDownStyle.MenuAtrEx>
      </DropDownStyle.MenuList>
    </DropDownStyle.Base>
  );
};
export default DropDown;

const DropDownStyle = {
  Base: styled.header<{ visible: boolean; isFixed?: boolean }>`
    display: none;

    ${mediaQueries(MOBILE)} {
      ${({ visible }) =>
        visible
          ? css`
              display: block;
              transform: translateY(0px);
              height: 100%;
              touch-action: none;
            `
          : css`
              display: block;
              transform: translateY(-640px);
            `}
      transition: transform 0.4s;
      position: fixed;
      top: 0;
      left: 0;
      background-color: ${theme.common.colors.white};
      z-index: 9999;
      flex-direction: column;
      justify-content: flex-start;
    }
  `,
  Header: styled.div`
    height: 64px;

    ${mediaQueries(MOBILE)} {
      min-width: 100vw;
      position: relative;
    }
  `,
  LogoWrapper: styled(Link)`
    position: relative;
    left: 0;
    height: 100%;
    width: 132px;
    cursor: pointer;

    ${mediaQueries(MOBILE)} {
      width: 99px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
    }
  `,
  Logo: styled.img`
    width: 100%;
    height: 100%;
  `,
  ClosedWrapper: styled.div`
    display: none;

    ${mediaQueries(MOBILE)} {
      width: 24px;
      height: 24px;
      display: block;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  `,
  Closed: styled.img`
    width: 100%;
    height: 100%;
  `,
  MenuList: styled.div`
    display: flex;

    ${mediaQueries(MOBILE)} {
      flex-direction: column;
      width: 100%;
      text-align: left;
      padding: 0 20px;
    }
  `,
  MenuAtr: styled(Link)`
    padding: 0 5px;
  `,
  MenuAtrEx: styled.a`
    padding: 0 5px;

    &:hover {
      color: ${theme.common.colors.turquoise};
    }
  `,
  Menu: styled.p`
    display: block;
    ${theme.typo.body16R};
    color: ${theme.common.colors.mainText};
    text-decoration: none;
    cursor: pointer;
    padding: 20px 0;

    span {
      color: ${theme.button.colors.textGray};
      background-color: ${theme.common.colors.wildSand};
      ${theme.typo.body12R};
      border-radius: 7px;
      padding: 10px 17px;
    }

    span:hover {
      color: #444444;
      background-color: ${theme.button.colors.bgGray};
    }

    &:hover {
      color: ${theme.common.colors.turquoise};
    }

    &:not(:last-child) {
      margin-right: 60px;
    }
  `,
};
