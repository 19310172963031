import useSWR from 'swr';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MainContent from '../../components/MainContent';
import Timeline from '../../components/Timeline';
import { BudsPriceInquiryLayout } from './styles';
import FlexBox from '../FlexBox';
import Animation from '../../components/Animation';
import PriceInquiryLayout from '../../components/PriceInquiryLayout';
import useTitle from '../../hooks/useTitle';
import { BUDS_CODE } from '../../common/constants/strings';
import { getPriceInquiryBuds } from '../../apis/priceInquiryBuds';
import useInquiryBuds from '../../hooks/useInquiryBuds';

const BudsPriceInquiry = () => {
  useTitle('민팃 - 버즈 시세 조회');

  /**
   * state
   */
  const [modelNo, setModelNo] = useState<undefined | string>(undefined);

  const {
    manufacturer,
    series,
    model,
    currentInfo: { category, code },
    setManufacturer,
    setSeries,
    setModel,
    setCurrentInfo,
    clearInfo,
  } = useInquiryBuds();
  const navigate = useNavigate();

  /**
   * fetch state
   */
  const { data, isValidating } = useSWR(
    [`/api/inquiry/buds`, category, code, modelNo],
    getPriceInquiryBuds,
    {
      dedupingInterval: 0,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
    },
  );

  /**
   * useEffect
   */
  useEffect(() => {
    clearInfo();
  }, []);

  useEffect(() => {
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = () => {
      switch (category) {
        case 'manufacturer':
          window.history.back();
          break;
        case 'series':
          setModelNo(undefined);
          setCurrentInfo({
            category: 'manufacturer',
            code: String(BUDS_CODE),
          });
          break;
        case 'model':
          setCurrentInfo({ category: 'series', code: manufacturer.code });
          break;
        default:
          break;
      }
    };

    if (category === 'manufacturer') {
      setSeries({ name: '', code: '' });
      setModel({ name: '', code: '' });
      setModelNo(undefined);
    } else if (category === 'series') {
      setModel({ name: '', code: '' });
    }

    return () => {
      window.onpopstate = null;
    };
  }, [category]);

  /**
   * private function
   */
  const getCurrentCategoryName = useCallback(() => {
    switch (category) {
      case 'manufacturer':
        return '제조사';
      case 'series':
        return '시리즈';
      case 'model':
        return '모델';
      default:
        return '제조사';
    }
  }, [category]);

  const getCurrentCode = useCallback(() => {
    switch (category) {
      case 'manufacturer':
        return manufacturer.code;
      case 'series':
        return series.code;
      case 'model':
        return model.code;
      default:
        return '';
    }
  }, [category, manufacturer, series, model]);

  const getCurrentNumber = useCallback(() => {
    switch (category) {
      case 'manufacturer':
        return '01';
      case 'series':
        return '02';
      case 'model':
        return '03';
      default:
        return '00';
    }
  }, [category]);

  const getIsSelected = useCallback(
    (item: any) => {
      if (modelNo) {
        return item.name === modelNo ? ' selected' : '';
      }
      return item.code === getCurrentCode() ? ' selected' : '';
    },
    [category],
  );

  /**
   * event function
   */
  const handleItemClick = useCallback(
    (item: { name: string; code: string }) => {
      switch (category) {
        case 'manufacturer':
          setManufacturer(item);
          setCurrentInfo({ category: 'series', code: item.code });
          break;
        case 'series':
          setSeries(item);
          setModelNo(item.name);
          setCurrentInfo({ category: 'model', code: item.code });
          break;
        case 'model':
          setModel(item);
          navigate(`/priceInquiryBuds/${item.code}`);
          break;
        default:
          break;
      }
    },
    [category],
  );

  const handleCategoryClick = (type: string) => {
    switch (type) {
      case 'manufacturer':
        setCurrentInfo({
          category: 'manufacturer',
          code: String(BUDS_CODE),
        });
        setModelNo(undefined);
        break;
      case 'series':
        setCurrentInfo({ category: 'series', code: manufacturer.code });
        break;
      case 'model':
        break;
      default:
        break;
    }
  };

  return (
    <PriceInquiryLayout>
      <MainContent>
        <BudsPriceInquiryLayout>
          <FlexBox justifyContent="space-between" contentGap="130px">
            <Timeline
              className="pc-tablet-only"
              timelineItems={[
                {
                  isActive: manufacturer.name !== '',
                  isSelected: category === 'manufacturer',
                  title: '제조사',
                  text:
                    manufacturer.name !== ''
                      ? manufacturer.name
                      : '선택된 제조사가 없습니다.',
                  onClick: () => handleCategoryClick('manufacturer'),
                },
                {
                  isActive: series.name !== '',
                  isSelected: category === 'series',
                  title: '시리즈',
                  text:
                    series.name !== ''
                      ? series.name
                      : '선택된 시리즈가 없습니다.',
                  onClick: () => handleCategoryClick('series'),
                },
                {
                  isActive: model.name !== '',
                  isSelected: category === 'model',
                  title: '모델',
                  text:
                    model.name !== '' ? model.name : '선택된 모델이 없습니다.',
                  onClick: () => handleCategoryClick('model'),
                },
              ]}
            />
            <div className="list-wrapper">
              <header className="list-header">
                <p className="list-step">
                  <span>{getCurrentNumber()}</span>/03
                </p>
                <h2 className="list-title">
                  중고기기의{window.innerWidth < 720 ? <br /> : ' '}
                  {getCurrentCategoryName()}를 선택해 주세요.
                </h2>
              </header>
              {data && !isValidating ? (
                <Animation>
                  <ul className="data-list" style={{ transition: '0.3s' }}>
                    {data.map((item, idx) => {
                      return (
                        <li
                          className={`data-item-wrapper${getIsSelected(item)}`}
                          key={idx}
                          onClick={() => handleItemClick(item)}
                          onKeyPress={() => handleItemClick(item)}
                        >
                          <div className="data-item">
                            <div className="data-inner">
                              {item.img ? (
                                <img
                                  className="data-img"
                                  width="60%"
                                  src={item.img}
                                  alt={item.name}
                                />
                              ) : null}
                              <p className="data-name">{item.name}</p>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </Animation>
              ) : null}
            </div>
          </FlexBox>
        </BudsPriceInquiryLayout>
      </MainContent>
    </PriceInquiryLayout>
  );
};

export default BudsPriceInquiry;
