import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import priceInquiry from 'redux/modules/priceInquiry';
import priceInquiryLaptop from 'redux/modules/priceInquiryLaptop';
import deployState from '../modules/deployState';
import priceInquiryTablet from '../modules/priceInquiryTablet';
import priceInquiryWatch from '../modules/priceInquiryWatch';
import priceInquiryBuds from '../modules/priceInquiryBuds';

const persisConfig = {
  key: 'root',
  storage,
  whitelist: [
    'priceInquiry',
    'deployState',
    'priceInquiryLaptop',
    'priceInquiryWatch',
    'priceInquiryBuds',
  ],
};

const reducer = combineReducers({
  priceInquiry,
  deployState,
  priceInquiryLaptop,
  priceInquiryTablet,
  priceInquiryWatch,
  priceInquiryBuds,
});

export default persistReducer(persisConfig, reducer);

export type RootState = ReturnType<typeof reducer>;
