import Button from 'components/Button';
import Input from 'components/Input';
import styled, { css } from 'styled-components';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';

export const SellSection = styled.section`
  header {
    padding-bottom: 24px;
  }
`;

export const SellButton = styled(Button).attrs({})`
  width: 100%;
  padding: 18px 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-top: 24px;
`;

export const SectionTitle = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`;

export const CheckBoxSpan = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #16191d;
  padding-left: 16px;
`;

export const Container = styled.div`
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #777f88;
    padding: 8px 0 8px 40px;
    white-space: pre-wrap;

    &:last-child {
      padding-bottom: 0;
    }
  }

  strong {
    color: #ec2d30;
  }
`;

export const SolutionContainer = styled.div`
  display: flex;
  background: #f8f8f8;
  border-radius: 20px;
  padding: 20px;
  white-space: pre-wrap;
  margin: 16px 0px 16px 40px;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5e656e;

    &.bold {
      color: #16191d;
      font-weight: 500;
      padding-bottom: 4px;
    }
  }

  figure {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;

export const StepContainer = styled.div`
  font-family: 'Inter';
  padding-bottom: 4px;

  span {
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    color: #16191d;

    &.total-step {
      color: #93999f;
    }
  }

  ${mediaQueries(MOBILE)} {
    span {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
    }
  }
`;

export const InfoListItem = styled.li`
  display: flex;
  font-size: 16px;
  line-height: 24px;

  .info-list-item-key {
    color: #16191d;
    font-weight: 500;
    min-width: 112px;
    max-width: 112px;
    margin-right: 24px;
  }

  .info-list-item-value {
    color: #5e656e;
    flex: 1;
  }
`;

export const InfoList = styled.ul`
  ${InfoListItem} {
    padding-bottom: 16px;

    &:last-child {
      padding-bottom: 0px;
    }
  }

  h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 16px;
  }

  ${mediaQueries(MOBILE)} {
    h5 {
      font-size: 18px;
      line-height: 28px;
    }
  }
`;

export const Divider = styled.div`
  border-top: 1px solid #e1e1e1;
  margin: 24px 0;
`;

export const FormKeySpan = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #16191d;
  min-width: 112px;
  max-width: 112px;
`;

export const FormItemLabelWrapper = styled.div<{ isRequired: boolean }>`
  ${({ isRequired }) =>
    isRequired &&
    css`
      &::after {
        content: '*';
        color: #ec2d30;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        padding-left: 4px;
      }
    `};
  min-width: 112px;
  max-width: 112px;
  margin-right: 24px;

  ${mediaQueries(MOBILE)} {
    margin: 0 0 6px 0;
  }
`;

export const FromItemWrapper = styled.div`
  ${mediaQueries(MOBILE)} {
    width: 100%;
  }
`;

export const FormItemArticle = styled.article`
  display: flex;
  align-items: center;

  ${mediaQueries(MOBILE)} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledInput = styled(Input)`
  width: 494px;
  height: 56px;

  ${mediaQueries(MOBILE)} {
    width: 100%;
  }
`;

export const FormContainer = styled.div`
  ${FormItemArticle} {
    padding-bottom: 24px;

    &.small-padding-bottom {
      padding-bottom: 12px;
    }
  }
`;

export const TermsContainer = styled.div`
  padding: 20px;
  border: 1px solid #eaebed;
  border-radius: 8px;
  margin: 0 0 48px 136px;

  > div {
    padding-bottom: 24px;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #5e656e;
  }

  b {
    display: inline-block;
    padding-bottom: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #16191d;
  }

  ${mediaQueries(MOBILE)} {
    margin-left: 0px;
  }
`;

export const Table = styled.table`
  border: 1px solid #eaebed;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  word-break: keep-all;
`;

export const Th = styled.th`
  padding: 8px;
  background: #f8f8f8;
  vertical-align: middle;
`;

export const Td = styled.td`
  padding: 8px 12px;
  vertical-align: middle;
  text-align: center;
  white-space: pre-wrap;
`;

export const SelectContainer = styled.div`
  position: relative;

  img {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
`;

export const Select = styled.select`
  width: 494px;
  height: 56px;
  border: 1px solid #eaebed;
  border-radius: 8px;
  padding-left: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  ${mediaQueries(MOBILE)} {
    width: 100%;
  }
`;

export const SellingInfoListItem = styled.li<{ isBold?: boolean }>`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  .selling-info-list-item-key {
    color: #16191d;
  }

  .selling-info-list-item-value {
    color: #5e656e;
    flex: 1;
    text-align: right;

    ${({ isBold }) =>
      isBold &&
      css`
        color: #16191d;
      `};
  }

  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
    `};
`;

export const SellingInfoList = styled.ul`
  h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 16px;
  }

  ${SellingInfoListItem} {
    padding-bottom: 16px;

    &:last-child {
      padding-bottom: 0px;
    }
  }
`;

export const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  background: #f8f8f8;
  border-radius: 8px;
  margin-top: 12px;
`;

export const WarningRow = styled.div`
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    padding-bottom: 8px;
  }

  img {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }

  p {
    word-break: keep-all;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5e656e;
  }
`;

export const ResetButton = styled(Button).attrs({
  type: 'secondary',
})`
  flex: 1;
  height: 64px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  border-radius: 16px;
`;

export const CompleteButton = styled(Button).attrs({
  type: 'primary',
})`
  flex: 1;
  height: 64px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  border-radius: 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 48px;
`;

export const FinishArticle = styled.article`
  h1 {
    font-family: 'Inter';
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
  }

  figure {
    width: 240px;
    height: 240px;
    margin: 32px auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    padding-bottom: 32px;
  }

  div.delivery-step {
    display: flex;
    word-break: keep-all;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #16191d;
    padding-top: 24px;

    &:first-of-type {
      padding-top: 0;
    }

    span {
      font-family: 'Inter';
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      width: 28px;
      height: 28px;
      background: #f8f8f8;
      border-radius: 64px;
      text-align: center;
      color: #777f88;
      margin-right: 12px;
      flex-shrink: 0;
    }
  }

  ${mediaQueries(MOBILE)} {
    padding: 0 16px;

    h1 {
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
    }

    figure {
      width: 142px;
      height: 142px;
      margin: 24px auto;
    }

    h2 {
      font-size: 20px;
      line-height: 30px;
    }
  }
`;

export const GoHomeButton = styled(Button)`
  width: 100%;
  height: 64px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  border-radius: 16px;
  margin-top: 48px;
`;

export const StyledWarningContainer = styled(WarningContainer)`
  margin-top: 32px;
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #16191d;
    padding-left: 8px;
  }
`;

export const RadioList = styled.div`
  display: flex;
  gap: 32px;
`;

export const TotalPriceContainer = styled.p`
  .total-left {
    font-size: 20px;
    font-weight: 400;
    color: #000;
  }

  .total-right {
    font-size: 24px;
    font-weight: 700;
    color: #000;
  }
`;
