import styled from 'styled-components';
import Modal from '../index';
import { closedIcon } from '../../../common/constants/imageUrls';
import { media } from '../../../styles/theme';

export const PriceInquiryLaptopModalLayout = styled(Modal)`
  width: 100%;
  max-width: 580px;
  padding: 32px 40px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    .header__title {
      font-size: 24px;
      font-weight: bold;
    }
    .header__close {
      display: flex;
      width: 20px;
      height: 20px;
      text-indent: -9999px;
      background: url(${closedIcon}) no-repeat center center;
      background-size: contain;
    }
  }
  .body {
    .nav {
      margin-bottom: 24px;
      .nav__list {
        display: flex;
        .nav__item {
          padding: 10px 0 16px;

          button {
            padding: 0;
            font-size: 16px;
            font-weight: bold;
            color: #8e8e8e;
            :hover {
              color: #1f1f1f;
            }
          }
          &--active {
            border-bottom: 2px solid #33ccbd;

            button {
              color: #1f1f1f;
            }
          }
          :not(:last-child) {
            margin-right: 16px;
          }
        }
      }
    }
    .body__description {
      margin-bottom: 16px;
      p {
        padding: 16px 0;
        border-radius: 16px;
        text-align: center;
        font-size: 16px;
        color: #33ccbd;
        background: #fafafa;
      }
    }
    .body__list {
      .body__item {
        display: flex;
        font-size: 16px;
        line-height: 24px;
        color: #4b4b4b;
        p {
          flex: 0 0 auto;
          margin-right: 16px;
        }
        span {
          font-weight: bold;
        }
        :not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
  }

  ${media.mobile} {
    max-width: inherit;
    height: 100vh;
    border-radius: 0;

    .header {
      .header__title {
        font-size: 16px;
      }
    }
  }
`;
