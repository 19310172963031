import React, { FunctionComponent } from 'react';
import { AFFILIATE_NOTICE } from 'common/constants/pathUrls';
import Section from 'components/main/Section';
import { AdditionalPartnerData } from 'common/constants/main';
import { BottomTextWrap } from 'components/main/Styles';
import { AdditionalPartnerStyle } from './style';
import { ITypes } from '../types';

const AdditionalPartner: FunctionComponent<ITypes> = ({ pathname }) => {
  const renderSection = () => {
    return AdditionalPartnerData.map((item, i) => {
      let delayValue = 0;
      switch (i) {
        case 0:
          delayValue = 0.08;
          break;
        case 1:
          delayValue = 0.15;
          break;
        default:
          delayValue = 0;
          break;
      }
      return (
        <AdditionalPartnerStyle.BoxWrapper
          delay={window.innerWidth > 720 ? delayValue : 0}
          key={i}
        >
          <AdditionalPartnerStyle.BoxImgContainer>
            {item.imgs.map((img, index) =>
              i === 0 ? (
                <AdditionalPartnerStyle.BoxImg src={img} key={index} />
              ) : (
                <AdditionalPartnerStyle.BrandBoxImg src={img} key={index} />
              ),
            )}
          </AdditionalPartnerStyle.BoxImgContainer>
          <AdditionalPartnerStyle.BoxText
            dangerouslySetInnerHTML={{ __html: item.text }}
          />
        </AdditionalPartnerStyle.BoxWrapper>
      );
    });
  };

  return (
    <Section
      title="구매처 별 신청방법 Check!"
      subtitle={`구매처 별 추가보상 신청 방법이 상이 하오니${
        window.innerWidth < 720 ? '<br />' : ' '
      }유의사항을 꼭 확인해주세요.`}
    >
      <AdditionalPartnerStyle.BoxContainer>
        {renderSection()}
      </AdditionalPartnerStyle.BoxContainer>
      {window.innerWidth < 720 ? (
        <BottomTextWrap style={{ margin: '1em 0 0' }}>SKT / KT</BottomTextWrap>
      ) : null}
      <AdditionalPartnerStyle.Btn
        to={pathname === '/' ? AFFILIATE_NOTICE : undefined}
        href={
          pathname === '/'
            ? undefined
            : 'https://event.mintit.co.kr/affiliateNotice'
        }
      >
        구매처 별 유의사항 확인
      </AdditionalPartnerStyle.Btn>
    </Section>
  );
};

export default AdditionalPartner;
