import React, { FunctionComponent } from 'react';
import { SectionContainer } from '../Styles';
import { SectionStyle } from './style';
import ScrollAnimation from '../../ScrollAnimation';

interface ISection {
  sectionLabel?: string;
  title: string;
  subtitle?: string;
  first?: boolean;
  isFixed?: boolean;
  color?: string;
}

const Section: FunctionComponent<ISection> = ({
  sectionLabel,
  title,
  subtitle,
  first,
  isFixed,
  children,
  color,
  ...rest
}) => {
  return (
    <SectionContainer first={first} isFixed={isFixed} color={color} {...rest}>
      <ScrollAnimation>
        <article>
          {sectionLabel && (
            <SectionStyle.RewardInfo>{sectionLabel}</SectionStyle.RewardInfo>
          )}
          <h3 dangerouslySetInnerHTML={{ __html: title }} />
          {subtitle && <p dangerouslySetInnerHTML={{ __html: subtitle }} />}
          {children}
        </article>
      </ScrollAnimation>
    </SectionContainer>
  );
};

export default Section;
