import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { atmGuideIcons, rightArrowWhite } from 'common/constants/imageUrls';
import { mediaQueries, MOBILE, SCROLL_BREAK } from 'styles/mediaQueries';
import AtmSection from 'components/PriceDetail/AtmSection';
import { useNavigate } from 'react-router-dom';
// import { CONNECTOR_URL } from 'common/constants/urls';
import { ATM_FIND } from 'common/constants/pathUrls';
import theme from 'styles/theme';
import { flexCenterCss } from 'styles/commonStyle';
import Button from 'components/Button';
import ScrollAnimation from '../../ScrollAnimation';
import { CONNECTOR_URL } from '../../../common/constants/urls';
import ConnectQrModal from '../../Modal/ConnectQrModal';

interface AtmProps {
  handleDetailModalOpen: () => void;
}

const Atm: React.FunctionComponent<AtmProps> = ({ handleDetailModalOpen }) => {
  const navigate = useNavigate();

  const [isQrModalVisible, setIsQrModalVisible] = useState(false);
  const handleQrModalOpen = () => {
    setIsQrModalVisible(true);
  };
  const handleQrModalClose = () => {
    setIsQrModalVisible(false);
  };
  return (
    <>
      <AtmStyle.Wrapper>
        <AtmStyle.Container>
          <AtmStyle.Title>
            민팃 ATM 방문 전<br />
            필수 준비사항을 알아볼까요?
          </AtmStyle.Title>
          <AtmStyle.Description>
            판매하실 휴대폰으로 아래 내용을 순서대로 따라해 주세요
          </AtmStyle.Description>
          <AtmStyle.OrderContainer>
            <AtmStyle.OrderDes>휴대폰 설정</AtmStyle.OrderDes>
            <AtmStyle.OrderImg />
            <AtmStyle.OrderDes>외관 점검</AtmStyle.OrderDes>
            <AtmStyle.OrderImg />
            <AtmStyle.OrderDes>민팃 커넥터 설치 및 본인인증</AtmStyle.OrderDes>
            <AtmStyle.OrderImg />
            <AtmStyle.OrderDes>ATM찾기</AtmStyle.OrderDes>
          </AtmStyle.OrderContainer>
          <AtmStyle.Desc>
            미리 준비하시면 더 빠르고 더 간편하게 민팃을 이용하실 수 있어요
          </AtmStyle.Desc>
        </AtmStyle.Container>
        {/* 휴대폰 설정 */}
        <AtmSection title="휴대폰 설정">
          <>
            <AtmStyle.ContentContainer url>
              <AtmStyle.ContentTitle url={atmGuideIcons.phoneSettings1}>
                배터리 충전
              </AtmStyle.ContentTitle>
              <AtmStyle.ContentDesc>
                검사도중 전원이 꺼지지 않도록 배터리는 넉넉히 충전해주세요
              </AtmStyle.ContentDesc>
            </AtmStyle.ContentContainer>
            <AtmStyle.ContentContainer url>
              <AtmStyle.ContentTitle url={atmGuideIcons.phoneSettings2}>
                디스플레이 설정 변경
              </AtmStyle.ContentTitle>
              <AtmStyle.ContentDesc>
                디스플레이 검사를 위해 설정을 변경해주세요
              </AtmStyle.ContentDesc>
              <AtmStyle.ContentDetail>
                화면 자동장금 시간 5분 이상{' '}
                <div>
                  설정 {'>'} 디스플레이 및 밝기 {'>'} 자동 장금 {'>'} 5분 이상
                </div>
              </AtmStyle.ContentDetail>
              <AtmStyle.ContentDetail>
                디스플레이 밝기 최대{' '}
                <div>
                  설정 {'>'} 디스플레이 및 밝기 {'>'} 최대 밝기
                </div>
              </AtmStyle.ContentDetail>
            </AtmStyle.ContentContainer>
            <AtmStyle.ContentContainer url>
              <AtmStyle.ContentTitle url={atmGuideIcons.visualInspection2}>
                데이터 백업
              </AtmStyle.ContentTitle>
              <AtmStyle.ContentDesc>
                휴대폰에 남아있는 소중한 데이터가 날아가지 않게 백업해주세요
              </AtmStyle.ContentDesc>
            </AtmStyle.ContentContainer>
          </>
        </AtmSection>
        {/* 외관 검사 */}
        <AtmSection title="외관 점검">
          <>
            <AtmStyle.ContentContainer url>
              <AtmStyle.ContentTitle url={atmGuideIcons.visualInspection1}>
                유심, SD카드 제거
              </AtmStyle.ContentTitle>
              <AtmStyle.ContentDesc>
                유심, SD카드는 제거해주세요
              </AtmStyle.ContentDesc>
            </AtmStyle.ContentContainer>
            <AtmStyle.ContentContainer url>
              <AtmStyle.ContentTitle url={atmGuideIcons.visualInspection3}>
                휴대폰 부착물 제거
              </AtmStyle.ContentTitle>
              <AtmStyle.ContentDesc>
                액정필름, 케이스 등 부착물을 모두 제거하고 외관을 깨끗하게
                닦아주세요
              </AtmStyle.ContentDesc>
            </AtmStyle.ContentContainer>
          </>
        </AtmSection>
        {/* 민팃 커넥터 설치 및 본인인증 */}
        <AtmSection title="민팃 커넥터 설치 및 본인인증">
          <AtmStyle.ContentWrapper>
            <div>
              <AtmStyle.ContentContainer>
                <AtmStyle.ContentDesc style={{ marginTop: '22px' }}>
                  민팃 커넥터를 설치 및 실행하신 뒤 본인인증을 진행해주세요
                  <br />
                  민팃 커넥터는 중고폰 판매를 위한 필수 앱으로 개인정보를
                  완벽하게 삭제합니다.
                </AtmStyle.ContentDesc>
                <AtmStyle.ContentDesc style={{ marginTop: '22px' }}>
                  현재 사용중인 휴대폰을 판매한다면 [바로 설치]를 클릭해주시고{' '}
                  <br />
                  갤럭시 퀀텀3 / 갤럭시 점프2를 이미 개통했다면 [다른 휴대폰에
                  설치]를 클릭해주세요
                </AtmStyle.ContentDesc>
              </AtmStyle.ContentContainer>
              <AtmStyle.ConnectMore onClick={handleDetailModalOpen}>
                자세히 보기
              </AtmStyle.ConnectMore>
            </div>
            <AtmStyle.ConnectButtonWrapper noPadding>
              <AtmStyle.ConnectButtonSmall hrefSelf={CONNECTOR_URL}>
                바로 설치
              </AtmStyle.ConnectButtonSmall>
              <AtmStyle.ConnectButtonSmall onClick={handleQrModalOpen}>
                다른 휴대폰에 설치
              </AtmStyle.ConnectButtonSmall>
            </AtmStyle.ConnectButtonWrapper>
          </AtmStyle.ContentWrapper>
        </AtmSection>
        {/* ATM 찾기 */}
        <AtmSection
          title="ATM 찾기"
          btnText="내 주변 ATM 찾아보기"
          isButton
          temp
          onClick={() => navigate(ATM_FIND)}
        >
          <AtmStyle.ContentContainer>
            <AtmStyle.ContentDesc style={{ marginTop: '22px' }}>
              판매 준비가 모두 끝났습니다! <br />
              7일 이내 가까운 민팃 ATM에 방문해주세요.
              <br />
              민팃 ATM은 전국 5,500여개 지점에 설치되어 있습니다.
            </AtmStyle.ContentDesc>
            <AtmStyle.ContentDesc
              style={{ width: '100%', marginTop: '12px' }}
              line
            >
              ※ 갤럭시 퀀텀3 / 갤럭시 점프2 구매처에 따라 반납처가 상이하오니
              이벤트 안내를 꼭 확인해주세요
            </AtmStyle.ContentDesc>
          </AtmStyle.ContentContainer>
        </AtmSection>
      </AtmStyle.Wrapper>
      <ConnectQrModal visible={isQrModalVisible} onClose={handleQrModalClose} />
    </>
  );
};

export default Atm;

export const AtmStyle = {
  Wrapper: styled.div`
    background-color: ${theme.common.colors.alabaster};
    padding: 58px 0;
    ${mediaQueries(SCROLL_BREAK)} {
      min-width: 1220px;
    }
    ${mediaQueries(MOBILE)} {
      min-width: auto;
    }
  `,
  Container: styled.div`
    background-color: ${theme.common.colors.alabaster};
    width: min(800px, 80%);
    margin: 0 auto 36px;
    ${mediaQueries(MOBILE)} {
      margin: 0 10%;
    }
  `,
  Title: styled.div`
    ${theme.typo.head32};
    margin-bottom: 16px;
    ${mediaQueries(MOBILE)} {
      ${theme.typo.head18};
      margin-bottom: 24px;
    }
    & > div {
      display: inline-block;
      ${mediaQueries(MOBILE)} {
        display: block;
      }
    }
  `,
  Description: styled.p`
    ${theme.typo.body20R};
    margin-bottom: 16px;
  `,
  OrderContainer: styled.div`
    display: flex;
    align-items: center;
    max-width: 600px;
    margin-bottom: 16px;
    ${mediaQueries(MOBILE)} {
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 30px;
    }
  `,
  OrderDes: styled.div`
    position: relative;
    ${theme.typo.body16R};
    margin-right: 16px;
    ${mediaQueries(MOBILE)} {
      ${theme.typo.body14R};
      margin-right: 4px;
    }
  `,
  OrderImg: styled.img`
    background: url(${rightArrowWhite}) no-repeat center
      ${theme.common.colors.turquoise};
    background-size: 8px;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    margin-right: 16px;
    ${mediaQueries(MOBILE)} {
      width: min(16px, 4vw);
      height: min(16px, 4vw);
      background-size: 1vw;
      margin-right: 4px;
    }
  `,
  Desc: styled.div`
    ${theme.typo.body16R};
    color: ${theme.button.colors.textGray};
    ${mediaQueries(MOBILE)} {
      display: none;
    }
  `,
  ContentWrapper: styled.div`
    display: flex;
    justify-content: space-between;

    ${mediaQueries(MOBILE)} {
      flex-direction: column;
    }
  `,
  ContentContainer: styled.div<{ url?: boolean }>`
    padding-left: ${({ url }) => url && '48px'};
  `,
  ContentTitle: styled.div<{ url?: string }>`
    ${theme.typo.head16};
    color: ${theme.common.colors.mainText};
    position: relative;
    width: 50%;
    margin: 36px 0 16px;
    word-break: keep-all;
    &:after {
      content: '';
      ${({ url }) =>
        url &&
        css`
          ${flexCenterCss};
          width: 32px;
          height: 32px;
          background: url(${url}) no-repeat center
            ${theme.common.colors.turquoise};
          border-radius: 50%;
          border: none;
          position: absolute;
          left: -48px;
          top: 50%;
          transform: translateY(-50%);
        `}
    }
    ${mediaQueries(MOBILE)} {
      ${theme.typo.head14};
      width: 100%;
      margin-bottom: 8px;
    }
  `,
  ContentDesc: styled.div<{ line?: boolean }>`
    ${theme.typo.body16R};
    color: ${theme.button.colors.textGray};
    word-break: keep-all;
    ${mediaQueries(MOBILE)} {
      ${({ line }) =>
        line
          ? css`
              ${theme.typo.body12R};
            `
          : css`
              ${theme.typo.body14R};
            `}
      margin-top: 10px;

      width: 100%;
    }
  `,
  ContentDetail: styled.div`
    ${theme.typo.head16};
    color: ${theme.common.colors.subText};
    width: 50%;
    margin-top: 16px;
    word-break: keep-all;
    & > div {
      font-weight: ${theme.fontWeights.normal};
    }
    ${mediaQueries(MOBILE)} {
      ${theme.typo.body14R};
      width: 100%;
    }
  `,
  ConnectWrapper: styled(ScrollAnimation)`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    ${mediaQueries(MOBILE)} {
      flex-direction: column;
      align-items: flex-start;

      & > div {
        width: 100%;
      }
    }
  `,
  ConnectButtonWrapper: styled.div`
    ${mediaQueries(MOBILE)} {
      display: flex;
      align-items: flex-start;
      padding: 0 5%;
      margin-top: 24px;

      ${({ noPadding }: { noPadding?: boolean }) =>
        noPadding
          ? css`
              width: 100%;
              padding: 0;
            `
          : null}
    }
  `,
  ConnectButton: styled(Button)`
    position: static !important;
    top: 0 !important;
    transform: none !important;
    width: 12.708em !important;
    height: 3.458em !important;
    padding: 8px 0 !important;
    margin-bottom: 0 !important;
    border-radius: 0.375em !important;
    font-size: 24px;
    font-weight: bold;
    text-align: center;

    &:not(:last-child) {
      margin-bottom: 24px !important;
    }

    @media screen and (max-width: 1440px) {
      font-size: 1.7vw !important;
    }

    @media screen and (max-width: 1440px) {
      font-size: 24px !important;
    }

    ${mediaQueries(MOBILE)} {
      width: 50% !important;
      height: 54px;
      font-size: 14px !important;
      &:not(:last-child) {
        margin-bottom: 0px !important;
        margin-right: 12px;
      }
      &:last-child:first-child {
        width: 100% !important;
      }
    }
  `,
  ConnectButtonSmall: styled(Button)`
    width: 127px;
    height: 41px;
    ${theme.typo.head12}
    ${flexCenterCss};
    border-radius: 8px;
    margin: 20px 0 10px;

    ${mediaQueries(MOBILE)} {
      width: 50% !important;
      font-size: 14px !important;

      &:not(:last-child) {
        margin-bottom: 0px !important;
        margin-right: 12px;
      }
    }
  `,
  ConnectDescList: styled.ul`
    margin-top: 32px;
    color: ${theme.button.colors.textGray};
  `,
  ConnectSubText: styled.p`
    font-size: 18px;
    line-height: 1.3em;
    margin-bottom: 2em;
    word-break: keep-all;

    ${mediaQueries(MOBILE)} {
      font-size: 14px;
      margin-bottom: 18px;
      padding: 0 5%;
    }
  `,
  ConnectDesc: styled.li`
    ${theme.typo.body16R};
    position: relative;
    padding-left: 20px;
    word-break: keep-all;
    width: max-content;
    ${mediaQueries(MOBILE)} {
      ${theme.typo.body12R};
      width: 100%;
    }
    & > strong {
      font-weight: ${theme.fontWeights.bold};
    }
    &:last-child {
      &:before {
        display: none;
      }
    }
    &:before {
      content: '';
      border-radius: 50%;
      background-color: ${theme.common.colors.black};
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      width: 4px;
      height: 4px;
      ${mediaQueries(MOBILE)} {
        top: 8px;
      }
    }
  `,
  ConnectMore: styled.div`
    ${theme.typo.body16R};
    color: ${theme.button.colors.textGray};
    cursor: pointer;
    margin-top: 32px;
    text-decoration: underline;
    ${mediaQueries(MOBILE)} {
      ${theme.typo.body12R};
    }
  `,
};
