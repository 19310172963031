import { manufacturerImg } from 'common/constants/imageUrls';

export const modelToImg = (name: string) => {
  if (name === '삼성전자') {
    return manufacturerImg.SamsungLogo;
  }
  if (name === 'LG전자') {
    return manufacturerImg.LGLogo;
  }
  if (name === '애플') {
    return manufacturerImg.AppleLogo;
  }
  if (name === '팬택') {
    return manufacturerImg.PantechLogo;
  }
  if (name === '블랙베리') {
    return manufacturerImg.BlackberryLogo;
  }
  if (name === '소니 모바일') {
    return manufacturerImg.SonyLogo;
  }
  if (name === 'TG앤컴퍼니') {
    return manufacturerImg.TGLogo;
  }
  if (name === '화웨이') {
    return manufacturerImg.HuawelLogo;
  }
  if (name === '샤오미') {
    return manufacturerImg.XiaomiLogo;
  }
  if (name === '알칼텔') {
    return manufacturerImg.AlcatelLogo;
  }
  // 스테이징
  // if (name === '') {
  //   return manufacturerImg.BluebirdLogo;
  // }
  if (name === '카시오') {
    return manufacturerImg.CasioLogo;
  }
  if (name === '델') {
    return manufacturerImg.DellLogo;
  }
  if (name === 'HTC') {
    return manufacturerImg.ThcLogo;
  }
  if (name === '모토로라') {
    return manufacturerImg.MotorolaLogo;
  }
  if (name === '노키아') {
    return manufacturerImg.NokiaLogo;
  }
  if (name === '테이크') {
    return manufacturerImg.TakeLogo;
  }
  if (name === 'ZTE') {
    return manufacturerImg.ZteLogo;
  }
  // 스테이징
  // if (name === '') {
  //   return manufacturerImg.SharpLogo;
  // }
  return manufacturerImg.Etc;
};

// 임시 방편
export const modelIdxToName = (Idx: string) => {
  if (Idx === '1207') {
    return '삼성전자';
  }
  if (Idx === '1209') {
    return '애플';
  }
  if (Idx === '1208') {
    return 'LG전자';
  }
  if (Idx === '1210') {
    return '팬택';
  }
  if (Idx === '8937') {
    return '블랙베리';
  }
  if (Idx === '1212') {
    return '소니 모바일';
  }
  if (Idx === '8707') {
    return 'TG앤컴퍼니';
  }
  if (Idx === '8723') {
    return '화웨이';
  }
  if (Idx === '8907') {
    return '샤오미';
  }
  if (Idx === '8938') {
    return '알칼텔';
  }
  if (Idx === '8940') {
    return '카시오';
  }
  if (Idx === '8941') {
    return '델';
  }
  if (Idx === '8942') {
    return 'HTC';
  }
  if (Idx === '8943') {
    return '모토로라';
  }
  if (Idx === '8944') {
    return '노키아';
  }
  if (Idx === '8945') {
    return '테이크';
  }
  return 'ZTE';
};
