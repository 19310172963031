import React from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { mediaQueries, MOBILE, SCROLL_BREAK } from 'styles/mediaQueries';
import usePhoneDetail from 'hooks/usePhoneDetail';
import Animation from 'components/Animation';
import theme from 'styles/theme';

interface PriceInquiryLayoutProps {
  children: any;
  currentSection: number;
}

const sectionTitle = [
  '제조사를 선택해 주세요.',
  '시리즈를 선택해 주세요.',
  '모델을 선택해 주세요.',
];

const PriceInquiryLayout: React.FunctionComponent<PriceInquiryLayoutProps> = ({
  children,
  currentSection,
}) => {
  const { manufacUrl, manufacName, seriesId, modelId } = usePhoneDetail();

  const navigate = useNavigate();
  const param = useParams();

  const toManufacturer = () => navigate('/priceInquiry');
  const toSeries = () => {
    if (manufacUrl !== '' && param.manufacIdx) {
      navigate(`/priceInquiry/${manufacUrl}`);
    }
  };
  const toModel = () => {
    if (manufacUrl && seriesId && param.generationIdx) {
      navigate(`/priceInquiry/${manufacUrl}/${seriesId}`);
    }
  };

  return (
    <Wrapper>
      {/* submenu */}
      <SideMenu.Container>
        <SideMenu.MenuList>
          <SideMenu.MenuAtr active onClick={toManufacturer}>
            <div>01. 제조사</div>
            <p>{manufacName === '' ? '선택해주세요.' : manufacName}</p>
          </SideMenu.MenuAtr>
          <SideMenu.MenuAtr active={currentSection !== 1} onClick={toSeries}>
            <div>02. 시리즈</div>
            <p>{seriesId === '' ? '선택해주세요.' : seriesId}</p>
          </SideMenu.MenuAtr>
          <SideMenu.MenuAtr active={currentSection === 3} onClick={toModel}>
            <div>03. 모델</div>
            <p>{modelId === '' ? '선택해주세요.' : modelId}</p>
          </SideMenu.MenuAtr>
        </SideMenu.MenuList>
      </SideMenu.Container>
      {/* content */}
      <Content.Container>
        <Animation>
          <Content.HeaderSection isManufac={param === {}}>
            <Content.HeaderPage>
              <strong>{`0${currentSection}`}</strong> / 03
            </Content.HeaderPage>
            <Content.HeaderTitle>
              {sectionTitle[currentSection - 1]}
            </Content.HeaderTitle>
          </Content.HeaderSection>
          <Content.MainSection>{children}</Content.MainSection>
        </Animation>
      </Content.Container>
    </Wrapper>
  );
};

export default PriceInquiryLayout;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  margin: 120px auto 60px;
  ${mediaQueries(SCROLL_BREAK)} {
    min-width: 1220px;
  }

  ${mediaQueries(MOBILE)} {
    min-width: auto;
    width: 100%;
    margin: 24px 0 30px;
    min-height: 600px;
  }
`;

const SideMenu = {
  Container: styled.div`
    height: 290px;
    margin: 0 100px 10px;
    ${mediaQueries(MOBILE)} {
      display: none;
      margin: 0;
    }
  `,
  MenuList: styled.li`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  `,
  MenuAtr: styled.ul<{ active?: boolean }>`
    display: inline-block;
    ${({ active }) =>
      active
        ? css`
            color: ${theme.common.colors.darkGray};
            cursor: pointer;
          `
        : css`
            color: ${theme.common.colors.silver};
            cursor: unset;
          `};
    & > div {
      ${theme.typo.head20};
      line-height: 23px;
      margin-bottom: 16px;
    }
    & > p {
      ${theme.typo.body16R}
    }
    &:hover {
      ${({ active }) =>
        active
          ? css`
              transform: scale(1.1);
            `
          : css`
              transform: none;
            `}
    }
  `,
};

const Content = {
  Container: styled.div`
    width: 700px;
    margin-right: 10px;
    ${mediaQueries(MOBILE)} {
      display: flex;
      width: 100vw;
      min-width: 240px;
      padding: 0;
      margin: 0;
    }
  `,
  HeaderSection: styled.div<{ isManufac?: boolean }>`
    ${theme.typo.head18};
    ${mediaQueries(MOBILE)} {
      justify-items: flex-start;
      ${({ isManufac }) =>
        isManufac
          ? css`
              width: 82vw;
            `
          : css`
              width: 79vw;
            `}
    }
  `,
  HeaderPage: styled.div`
    color: ${theme.common.colors.neutralGray};
    & > strong {
      color: ${theme.common.colors.darkGray};
    }
    ${mediaQueries(MOBILE)} {
      font-size: max(3vw, 12px);
      line-height: min(4vw, 24px);
    }
  `,
  HeaderTitle: styled.div`
    ${theme.typo.head52};
    word-break: keep-all;
    margin: 16px 0 80px;
    ${mediaQueries(MOBILE)} {
      font-size: min(6vw, 30px);
      line-height: min(36px, 8vw);
      font-weight: 700;
      margin: 8px auto min(6vw, 36px);
    }
  `,
  MainSection: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
};
