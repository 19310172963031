import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { PriceInquiryBuds } from 'redux/@types';
import { BUDS_CODE } from '../../common/constants/strings';

const prefix = 'skn-mintit-web';
const SET_MANUFACTURER_BUDS = `${prefix}/SET_MANUFACTURER_BUDS` as const;
const SET_SERIES_BUDS = `${prefix}/SET_SERIES_BUDS` as const;
const SET_MODEL_BUDS = `${prefix}/SET_MODEL_BUDS` as const;
const SET_CURRENT_INFO_BUDS = `${prefix}/SET_CURRENT_INFO_BUDS` as const;
const CLEAR_INFO_BUDS = `${prefix}/CLEAR_INFO_BUDS` as const;

const initialState = {
  manufacturer: { name: '', code: '' },
  series: { name: '', code: '' },
  model: { name: '', code: '' },
  currentInfo: { category: 'manufacturer', code: String(BUDS_CODE) },
};

export const setManufacturerBuds = createAction(
  SET_MANUFACTURER_BUDS,
  (atr: { name: string; code: string }) => atr,
)();
export const setSeriesBuds = createAction(
  SET_SERIES_BUDS,
  (atr: { name: string; code: string }) => atr,
)();
export const setModelBuds = createAction(
  SET_MODEL_BUDS,
  (atr: { name: string; code: string }) => atr,
)();
export const setCurrentInfoBuds = createAction(
  SET_CURRENT_INFO_BUDS,
  (atr: { category: string; code: string }) => atr,
)();
export const clearInfoBuds = createAction(CLEAR_INFO_BUDS)();

const setInfo = {
  setManufacturer: setManufacturerBuds,
  setSeries: setSeriesBuds,
  setModel: setModelBuds,
  setCurrentInfo: setCurrentInfoBuds,
  clearPhoneInfo: clearInfoBuds,
};
export type setAction = ActionType<typeof setInfo>;

const reducer = createReducer<PriceInquiryBuds, setAction>(initialState, {
  [SET_MANUFACTURER_BUDS]: (state, { payload }) => ({
    ...state,
    manufacturer: payload,
  }),
  [SET_SERIES_BUDS]: (state, { payload }) => ({
    ...state,
    series: payload,
  }),
  [SET_MODEL_BUDS]: (state, { payload }) => ({
    ...state,
    model: payload,
  }),
  [SET_CURRENT_INFO_BUDS]: (state, { payload }) => ({
    ...state,
    currentInfo: payload,
  }),
  [CLEAR_INFO_BUDS]: (state) => ({
    ...state,
    manufacturer: { name: '', code: '' },
    series: { name: '', code: '' },
    model: { name: '', code: '' },
    currentInfo: { category: 'manufacturer', code: String(BUDS_CODE) },
  }),
});

export default reducer;
