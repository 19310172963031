import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IMapDataProps } from 'pages/Map';
import { mapIcon } from 'common/constants/imageUrls';
import { useDebounce } from '../../../hooks/useDebounce';
import { ellipsisDoubleLine } from '../../../styles/commonStyle';

interface IMapFilterProps {
  data: IMapDataProps[];
  mapLocation: { x: number; y: number };
  onClick: (idx: number) => void;
  onSearch: (text: string) => void;
  markers: any[];
  selectIndex?: number;
  setSelectedAtm?: (item: any) => void;
}

const MapNav: React.FunctionComponent<IMapFilterProps> = ({
  data,
  mapLocation,
  onClick,
  onSearch,
  markers,
  selectIndex,
  setSelectedAtm,
}) => {
  const MapListRef = useRef<HTMLDivElement>(null);
  const MapListMobileRef = useRef<HTMLDivElement>(null);
  const CurrentPage = useRef(1);

  const [isOpend, setIsOpend] = useState(false);
  const [paginationData, setPaginationData] = useState<IMapDataProps[]>([]);
  const [target, setTarget] = useState<any>(null);
  const [targetMobile, setTargetMobile] = useState<any>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [debounceText, setDebounceText] = useDebounce(keyword);

  useEffect(() => {
    MapListRef?.current?.scrollTo(0, 0);
    MapListMobileRef?.current?.scrollTo(0, 0);
    CurrentPage.current = 1;
    setPaginationData(data.slice(0, 10));
  }, [markers]);

  useEffect(() => {
    onSearch(debounceText);
  }, [debounceText]);

  const onIntersect = async ([entry]: any[], observer: any) => {
    if (
      entry.isIntersecting &&
      data.length &&
      Math.ceil(data.length / 10) > CurrentPage.current
    ) {
      // console.log('data-length', data.length);
      // console.log('max-page', Math.ceil(data.length / 10));
      console.log('current-page', CurrentPage.current);
      observer.unobserve(entry.target);
      setPaginationData((current) =>
        current.concat(
          data.slice(CurrentPage.current * 10, CurrentPage.current * 10 + 10),
        ),
      );
      CurrentPage.current += 1;
      observer.observe(entry.target);
    }
  };

  useEffect(() => {
    let observer: any;
    if (target && data) {
      observer = new IntersectionObserver(onIntersect, {
        threshold: 0.4,
      });
      observer.observe(target);
      observer.observe(targetMobile);
    }
    return () => observer && observer.disconnect();
  }, [data]);

  const getIsSelected = (i: number) => {
    return selectIndex === i;
  };
  const handleOpen = () => {
    setIsOpend(true);
    if (setSelectedAtm) {
      setSelectedAtm(null);
    }
  };

  const handleClose = () => {
    setIsOpend(false);
  };

  const handleItemClick = (i: number) => {
    if (isOpend) {
      setIsOpend(false);
    }
    onClick(i);
  };

  return (
    <>
      <MapNavStyle.Container>
        <MapNavStyle.FilterWrapper>
          <MapNavStyle.InputWrapper isFocused={isFocused}>
            <MapNavStyle.InputImg src={mapIcon.Search} />
            <MapNavStyle.Input
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                setDebounceText(e.target.value);
              }}
              placeholder="ATM 위치 검색"
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </MapNavStyle.InputWrapper>
        </MapNavStyle.FilterWrapper>
        <MapNavStyle.NavListWrapper>
          <MapNavStyle.TitleWrapper>
            <MapNavStyle.Title>내위치</MapNavStyle.Title>
            <MapNavStyle.Title>{data.length}건</MapNavStyle.Title>
          </MapNavStyle.TitleWrapper>
          <MapNavStyle.ListWrapper ref={MapListRef}>
            <MapNavStyle.ListInnerWrapper>
              {paginationData.map((item, i) => (
                <MapNavStyle.ItemContainer
                  isSelected={getIsSelected(i)}
                  key={i}
                  onClick={() => handleItemClick(i)}
                >
                  <MapNavStyle.ItemWrapper>
                    <>
                      <MapNavStyle.ItemTitle>
                        {item.atm_display_name}
                      </MapNavStyle.ItemTitle>
                      {item.sts === '2' && (
                        <MapNavStyle.ItemFix>수리중</MapNavStyle.ItemFix>
                      )}
                    </>
                    <MapNavStyle.InfoWrapper>
                      <MapNavStyle.Addr>{item.addr}</MapNavStyle.Addr>
                      <MapNavStyle.Info>
                        <MapNavStyle.Locate>{item.locate}</MapNavStyle.Locate>
                        <div>운영 시간 {item.info_run_hours}</div>
                      </MapNavStyle.Info>
                      <MapNavStyle.Info>
                        휴무일 {item.off_days}
                      </MapNavStyle.Info>
                    </MapNavStyle.InfoWrapper>
                  </MapNavStyle.ItemWrapper>
                  <MapNavStyle.Btn
                    href={`https://map.kakao.com/link/from/내위치,${mapLocation.x},${mapLocation.y}/to/${item.atm_display_name},${item.lat},${item.lon}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    길찾기
                  </MapNavStyle.Btn>
                </MapNavStyle.ItemContainer>
              ))}
              <div ref={setTarget} />
            </MapNavStyle.ListInnerWrapper>
          </MapNavStyle.ListWrapper>
        </MapNavStyle.NavListWrapper>
      </MapNavStyle.Container>
      <MapNavStyle.MobileContainer>
        <MapNavStyle.InputWrapper isFocused={isFocused} isOpend={isOpend}>
          <MapNavStyle.InputImg
            onClick={handleClose}
            src={isOpend ? mapIcon.Arrow : mapIcon.Search}
          />
          <MapNavStyle.Input
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
              setDebounceText(e.target.value);
            }}
            placeholder="ATM 위치 검색"
            onFocus={() => {
              if (!isOpend) {
                handleOpen();
              }
              setIsFocused(true);
            }}
            onBlur={() => setIsFocused(false)}
          />
          <button
            type="button"
            style={{ width: '80px' }}
            onClick={() => {
              setKeyword('');
              setDebounceText('');
            }}
          >
            취소
          </button>
        </MapNavStyle.InputWrapper>
      </MapNavStyle.MobileContainer>
      <MapNavStyle.MobileNavListWrapper isOpend={isOpend}>
        <MapNavStyle.TitleWrapper>
          <MapNavStyle.Title>내위치</MapNavStyle.Title>
          <MapNavStyle.Title>{data.length}건</MapNavStyle.Title>
        </MapNavStyle.TitleWrapper>
        <MapNavStyle.ListWrapper ref={MapListMobileRef}>
          <MapNavStyle.ListInnerWrapper>
            {paginationData.map((item, i) => (
              <MapNavStyle.ItemContainer
                isSelected={getIsSelected(i)}
                key={i}
                onClick={() => handleItemClick(i)}
              >
                <MapNavStyle.ItemWrapper>
                  <>
                    <MapNavStyle.ItemTitle>
                      {item.atm_display_name}
                    </MapNavStyle.ItemTitle>
                    {item.sts === '2' && (
                      <MapNavStyle.ItemFix>수리중</MapNavStyle.ItemFix>
                    )}
                  </>
                  <MapNavStyle.InfoWrapper>
                    <MapNavStyle.Addr>{item.addr}</MapNavStyle.Addr>
                    <MapNavStyle.Info>
                      <MapNavStyle.Locate>{item.locate}</MapNavStyle.Locate>
                      <div>운영 시간 {item.info_run_hours}</div>
                    </MapNavStyle.Info>
                    <MapNavStyle.Info>휴무일 {item.off_days}</MapNavStyle.Info>
                  </MapNavStyle.InfoWrapper>
                </MapNavStyle.ItemWrapper>
                <MapNavStyle.Btn
                  href={`https://map.kakao.com/link/from/내위치,${mapLocation.x},${mapLocation.y}/to/${item.atm_display_name},${item.lat},${item.lon}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  길찾기
                </MapNavStyle.Btn>
              </MapNavStyle.ItemContainer>
            ))}
          </MapNavStyle.ListInnerWrapper>
          <div style={{ height: '50px' }} ref={setTargetMobile} />
        </MapNavStyle.ListWrapper>
      </MapNavStyle.MobileNavListWrapper>
    </>
  );
};

export default MapNav;

const MapNavStyle = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 480px;
    height: 100%;
    background-color: #fafafa;
    overflow: hidden;

    @media all and (max-width: 720px) {
      display: none;
    }
  `,
  FilterWrapper: styled.div`
    padding: 40px;
    border-bottom: 1px solid #e1e1e1;
  `,
  InputWrapper: styled.div<{ isFocused: boolean; isOpend?: boolean }>`
    padding: 15px 16px;
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-radius: 8px;
    & > button {
      display: none;
      width: 100%;
    }

    ${({ isFocused }) =>
      isFocused
        ? css`
            border: 2px solid #33ccbd;
            padding: 14px 15px;
          `
        : null}

    ${({ isOpend }) =>
      isOpend
        ? css`
            & > img {
              transform: rotate(180deg);
              cursor: pointer;
            }

            & > button {
              display: block;
            }
          `
        : null}
  `,
  Input: styled.input`
    width: 100%;
    border: none;
  `,
  InputImg: styled.img<{ isOpend?: boolean }>`
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 12px;
  `,
  NavListWrapper: styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,
  TitleWrapper: styled.div`
    display: flex;
    justify-content: space-between;
    padding: 24px 40px;
    border-bottom: 1px solid #e1e1e1;
  `,
  Title: styled.span`
    font-size: 16px;
    font-weight: 700;
  `,
  ListWrapper: styled.div`
    flex: 1;
    overflow: scroll;
  `,
  ListInnerWrapper: styled.div``,
  EmptyList: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    font-size: 14px;
    color: #969696;
  `,
  ItemContainer: styled.div<{ isSelected: boolean }>`
    height: 182px;
    padding: 0 40px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    ${({ isSelected }) =>
      isSelected
        ? css`
            background: #eee;
          `
        : null}
    :hover,
      :focus {
      background-color: #eee;
    }

    :not(:last-child) {
      border-bottom: 1px solid #e1e1e1;
    }
  `,
  ItemWrapper: styled.div`
    width: 290px;
  `,
  ItemTitle: styled.span`
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.5rem;
  `,
  ItemFix: styled.span`
    display: inline-block;
    padding: 4px 8px;
    color: #f64c4c;
    border-radius: 100px;
    margin-left: 7px;
    background: #ffccd2;
    font-size: 12px;
    font-weight: 400;
  `,
  InfoWrapper: styled.div`
    width: 100%;
    word-break: keep-all;
  `,
  Addr: styled.div`
    font-size: 14px;
    color: #3a3a3c;
    line-height: 2rem;
    ${ellipsisDoubleLine}
  `,
  Info: styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #969696;
    margin: 6px 0;
  `,
  Locate: styled.div`
    display: flex;
    align-items: center;

    :after {
      content: '';
      display: inline-block;
      width: 1px;
      height: 12px;
      background-color: #969696;
      margin: 0 8px;
    }
  `,
  Btn: styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 91px;
    height: 42px;
    text-decoration: none;
    border-radius: 8px;
    color: #fff;
    background-color: #33ccbd;
    font-size: 14px;
    font-weight: 700;
  `,
  MobileContainer: styled.div`
    display: none;
    position: absolute;
    z-index: 50;
    width: 100%;
    padding: 16px 24px;

    @media all and (max-width: 720px) {
      display: block;
    }
  `,
  MobileNavListWrapper: styled.div<{ isOpend: boolean }>`
    width: 100%;
    display: none;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    padding-top: 130px;
    background: #fff;
    overflow: hidden;

    transition: transform 0.3s;

    transform: translateX(${({ isOpend }) => (isOpend ? '0px' : '100%')});

    @media all and (max-width: 720px) {
      display: flex;
    }
  `,
};
