import React, {
  FunctionComponent,
  useEffect,
  useLayoutEffect,
  // useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { mainIcon, Quiz, QuizTitle } from 'common/constants/imageUrls';
import { MainPageStyle } from 'pages/Home/style';
import AdditionalCompensation from 'components/main/AdditionalCompensation';
import useScroll from 'hooks/useScroll';
import HowToAdditional from 'components/main/HowToAdditional';
import AdditionalPartner from 'components/main/AdditionalPartner';
import SubBanner from 'components/main/SubBanner';
import BeforeVisit from 'components/main/BeforeVisit';
import { useLocation } from 'react-router-dom';
import Header from 'components/partials/Header';
import BannerLayout from '../../components/main/BannerLayout';
import { QUIZ_EVENT } from '../../common/constants/pathUrls';

const Home: FunctionComponent = () => {
  const { pathname } = useLocation();

  const MainRef = useRef<HTMLDivElement>(null);
  const HeaderRef = useRef<HTMLDivElement>(null);
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const { x } = useScroll();

  useLayoutEffect(() => {
    window.location.href = 'https://www.mintit.co.kr/galaxys23_event';
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((v) => {
        const { target } = v;
        if (target) {
          if (!v.isIntersecting) {
            setIsFixed(true);
          } else {
            setIsFixed(false);
          }
        }
      });
    }, {});

    if (MainRef.current) {
      observer.observe(MainRef.current);
    }
  }, []);

  // fixed 되었을때 가로 스크롤 생기게 하는 부분
  useEffect(() => {
    if (HeaderRef?.current) {
      HeaderRef.current.style.left = `${0 - x}px`;
    }
  }, [x]);

  // const handleChange = (e: any) => {
  //   const formData = new FormData();

  //   formData.append('file', e.target.files[0]);

  //   request(HttpMethod.POST, '/common/image/upload/prodimage', {
  //     body: formData,
  //   }).then((res) => {
  //     console.log(res);
  //   });
  // };

  return (
    <MainPageStyle.Container>
      {/* 메인 화면 */}
      <MainPageStyle.MainWrapper ref={MainRef} pathname={pathname}>
        <MainPageStyle.BtnWrapper>
          {/* <MainPageStyle.Down>아래로 스크롤</MainPageStyle.Down> */}
          <MainPageStyle.DownIcon
            src={mainIcon.downArrow}
            width={24}
            height={12}
          />
        </MainPageStyle.BtnWrapper>
      </MainPageStyle.MainWrapper>
      {/* 헤더 */}
      {pathname === '/' && (
        <MainPageStyle.HeaderWrapper
          isFixed={isFixed}
          id="header"
          ref={HeaderRef}
        >
          <Header isFixed={isFixed} />
        </MainPageStyle.HeaderWrapper>
      )}
      {/* subBanner */}
      <SubBanner isFixed={isFixed} />
      {/* 추가보상금 */}
      <AdditionalCompensation pathname={pathname} />
      {/* 추가보상 제휴처 */}
      <AdditionalPartner pathname={pathname} />
      {/* 추가보상금 더 받는 법 */}
      <HowToAdditional color="#fafafa" />
      {/* 방문전 준비사항 */}
      <BeforeVisit pathname={pathname} />
      {/* 행운의 이벤트 */}
      {new Date() < new Date('2022-04-01 00:00:00') ? (
        <BannerLayout
          title="울트랄라 신나는 퀴즈 이벤트"
          titleImage={QuizTitle}
          subtitle="참여만 해도 갤럭시 S22 득템의 기회가 온다!<br/>
행운의 주인공은 과연 누가될까?"
          img={Quiz}
          path={QUIZ_EVENT}
          btnValue="이벤트 참여하기"
          color="#F4DFEE"
          btnColor="#2D1679"
          pathname={pathname}
          isAlignCenter
        />
      ) : null}
      {/* Footer */}
      {pathname === '/' && <MainPageStyle.FooterContainer />}
    </MainPageStyle.Container>
  );
};

export default Home;
