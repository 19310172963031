import React, { FunctionComponent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as S from './styles';
import { headerLogo } from '../../common/constants/imageUrls';

interface HeaderOnlyProps {
  href?: string;
}

const HeaderOnly: FunctionComponent<HeaderOnlyProps> = ({ href }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAtmPage = location.pathname.indexOf('atmOfUse') !== -1;

  const handleClickLogo = () => {
    if (href) {
      navigate(href);
    }
  };

  const handleClickLink = () => {
    if (window.location.href.indexOf('stg-') !== -1) {
      window.location.href = 'https://stg.barunfon.com/';
    } else {
      window.location.href = 'https://www.mintit.co.kr/';
    }
  };

  return (
    <S.Header className="mobile-header">
      <S.HeaderBox isFull={isAtmPage}>
        <S.LogoBox onClick={handleClickLogo}>
          <img src={headerLogo} alt="mintit" />
        </S.LogoBox>
        <S.LinkBox>
          <S.Link onClick={handleClickLink}>홈페이지 가기</S.Link>
        </S.LinkBox>
      </S.HeaderBox>
    </S.Header>
  );
};

HeaderOnly.defaultProps = {
  href: '/',
};

export default HeaderOnly;
