import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { atmGuideIcons, atmGuideImages } from 'common/constants/imageUrls';
import ScrollAnimation from 'components/ScrollAnimation';
import {
  mediaQueries,
  MOBILE,
  SCROLL_BREAK,
  TABLET,
} from 'styles/mediaQueries';
import { flexCenterCss } from '../../../styles/commonStyle';

export interface AtmGuideSectionContentDescriptionType {
  text: string;
  option?: string[];
}

export interface AtmGuideSectionContentType {
  icon: string;
  title: string;
  imageName: string;
  description: AtmGuideSectionContentDescriptionType[];
  index?: number;
}

const AtmGuideSectionContent: FunctionComponent<AtmGuideSectionContentType> = ({
  icon,
  title,
  imageName,
  description,
  index,
}) => {
  return (
    <AtmGuideSectionContentStyles.SectionContentItem>
      <ScrollAnimation delay={index}>
        <AtmGuideSectionContentStyles.SectionContentTitle icon={icon}>
          <span />
          {title}
        </AtmGuideSectionContentStyles.SectionContentTitle>
        <AtmGuideSectionContentStyles.SectionContentImageWrapper>
          <img src={atmGuideImages[imageName]} width="100%" alt="" />
        </AtmGuideSectionContentStyles.SectionContentImageWrapper>

        {description
          ? description.map(({ text, option }) => {
              return (
                <AtmGuideSectionContentStyles.SectionContentDescription
                  key={text}
                >
                  {text}
                  {option ? (
                    <ul>
                      {option.map((item, idx) => (
                        <li key={idx}>
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </AtmGuideSectionContentStyles.SectionContentDescription>
              );
            })
          : null}
      </ScrollAnimation>
    </AtmGuideSectionContentStyles.SectionContentItem>
  );
};

const AtmGuideSectionContentStyles = {
  SectionContentItem: styled.li`
    width: 33.33333%;
    &:not(:last-child) {
      margin-right: 3.091em;
    }

    ${mediaQueries(TABLET)} {
      font-size: 1vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 12.2px;
    }

    ${mediaQueries(MOBILE)} {
      width: 100%;
      flex-shrink: 0;
      padding: 0 5%;

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  `,
  SectionContentTitle: styled.h4<{ icon: string }>`
    ${({ theme, icon }) => css`
      display: flex;
      align-items: center;
      ${theme.typo.head20};
      line-height: 1em;
      margin-bottom: 0.8em;

      & > span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 1.6em;
        height: 1.6em;
        margin-right: 0.8em;
        border-radius: 50%;
        background-color: #33ccbd;
        background-image: url(${`${atmGuideIcons[icon]}`});
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 1.2em 1.2em;
      }

      ${mediaQueries(TABLET)} {
        font-size: 1.6vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 20px;
      }

      ${mediaQueries(MOBILE)} {
        font-size: 20px;
      }
    `}
  `,
  SectionContentImageWrapper: styled.div`
    ${({ theme }) => css`
      position: relative;
      ${flexCenterCss};
      border-radius: 16px;
      margin-bottom: 16px;
      background: ${theme.common.colors.wildSand};
      overflow: hidden;
    `}
  `,
  SectionContentDescription: styled.p`
    font-size: 16px;
    line-height: 1.4em;
    color: #4b4b4b;
    word-break: keep-all;

    ul {
      padding-left: 0.5em;
    }

    ul li {
      list-style: inside;
    }
    &:not(:last-child) {
      margin-bottom: 1em;
    }
    & span {
      color: #8e8e8e;
    }

    ${mediaQueries(TABLET)} {
      font-size: 1.2vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 16px;
    }

    ${mediaQueries(MOBILE)} {
      font-size: 14px;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  `,
};

export default AtmGuideSectionContent;
