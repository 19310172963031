import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducer';
import { PriceInquiryWatch } from '../../redux/@types';
import {
  clearInfoWatch,
  setCurrentInfoWatch,
  setManufacturerWatch,
  setModelWatch,
  setSeriesWatch,
} from '../../redux/modules/priceInquiryWatch';

const useInquiryWatch = () => {
  const dispatch = useDispatch();
  const { manufacturer, series, model, currentInfo } = useSelector<
    RootState,
    PriceInquiryWatch
  >((state) => state.priceInquiryWatch);

  const setManufacturer = (item: { name: string; code: string }) => {
    dispatch(setManufacturerWatch(item));
  };
  const setSeries = (item: { name: string; code: string }) => {
    dispatch(setSeriesWatch(item));
  };
  const setModel = (item: { name: string; code: string }) => {
    dispatch(setModelWatch(item));
  };

  const setCurrentInfo = (item: { category: string; code: string }) => {
    dispatch(setCurrentInfoWatch(item));
  };

  const clearInfo = () => {
    dispatch(clearInfoWatch());
  };

  return {
    manufacturer,
    series,
    model,
    currentInfo,
    setManufacturer,
    setSeries,
    setModel,
    setCurrentInfo,
    clearInfo,
  };
};

export default useInquiryWatch;
