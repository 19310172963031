export const statusHandler = (status: string) => {
  if (status === '정상')
    return {
      backgroundColor: '#EBFFFE',
      color: '#1B877E',
    };
  if (status === '-')
    return {
      backgroundColor: '#fff',
      color: '#000',
    };
  return {
    backgroundColor: '#ffd8d8',
    color: '#ff3b3b',
  };
};
