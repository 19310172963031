import styled from 'styled-components';
import Modal from '../index';
import useIsDeploy from '../../../hooks/useIsDeploy';
import Button from '../../Button';
import { closedIcon } from '../../../common/constants/imageUrls';

const DeployModal = () => {
  const { isDeployment, setIsDeplymentHandler } = useIsDeploy();

  const handleClosed = () => {
    console.log(isDeployment);
    setIsDeplymentHandler(false);
  };

  return (
    <Modal
      width="90%"
      maxWidth="400px"
      visible={isDeployment}
      onClose={handleClosed}
    >
      <DeployModalStyle.Container>
        <DeployModalStyle.CloseButton onClick={handleClosed}>
          닫기
        </DeployModalStyle.CloseButton>
        <div>
          <h1>긴급 점검</h1>
        </div>
        <div>
          <p>
            시스템 점검중입니다.
            <br />
            일부 기능 사용이 제한됩니다.
            <br />
            <span>( 00:00 ~ 00:30 )</span>
          </p>
        </div>
        <DeployModalStyle.Button onClick={handleClosed}>
          확인
        </DeployModalStyle.Button>
      </DeployModalStyle.Container>
    </Modal>
  );
};

const DeployModalStyle = {
  Container: styled.div`
    text-align: center;
    position: relative;
    padding: 40px;

    h1 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    p {
      color: #4e4e4e;
      font-size: 16px;
      line-height: 1.4em;
      margin-bottom: 30px;
    }
  `,
  CloseButton: styled.button`
    position: fixed;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    text-indent: -99999px;
    background: url(${closedIcon}) no-repeat center center;
    background-size: contain;
  `,
  Button: styled(Button)`
    width: 100%;
    margin: 0 auto;
    padding: 10px 0;
    border-radius: 100px;
  `,
};

export default DeployModal;
