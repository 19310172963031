import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

export enum ScrollAnimationMode {
  FADE_IN = 'fadeIn',
}

export enum ScrollAnimaionDirection {
  RIGHT = 'right',
  LEFT = 'left',
  TOP = 'top',
  BOTTOM = 'bottom',
}

interface ScrollAnimationProps {
  mode?: ScrollAnimationMode;
  direction?: ScrollAnimaionDirection;
  widthNotFull?: boolean;
  noAnimation?: boolean;
  delay?: number;
}

interface ScrollAnimationStyleProps extends ScrollAnimationProps {
  isVisible: boolean;
}

const ScrollAnimation: FunctionComponent<ScrollAnimationProps> = ({
  children,
  mode,
  direction,
  widthNotFull,
  noAnimation,
  delay,
  ...rest
}) => {
  const AnimationRef = useRef<HTMLDivElement>(null);

  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((v) => {
          if (v.intersectionRatio > 0.2) {
            setIsVisible(true);
          }
        });
      },
      {
        threshold: 0.2,
      },
    );

    if (AnimationRef.current) {
      observer.observe(AnimationRef.current);
    }
  }, []);

  return (
    <AnimationLayout
      ref={AnimationRef}
      mode={mode}
      direction={direction}
      widthNotFull={widthNotFull}
      isVisible={isVisible}
      noAnimation={noAnimation}
      delay={delay}
      {...rest}
    >
      {children}
    </AnimationLayout>
  );
};

const AnimationLayout = styled.div<ScrollAnimationStyleProps>`
  ${({ widthNotFull }) =>
    widthNotFull &&
    css`
      width: 100%;
    `}
  opacity: 0;
  transform: translateY(30px);

  ${({ isVisible }) =>
    isVisible
      ? css`
          opacity: 1;
          transform: translateY(0);
        `
      : null}
  transition: transform 0.7s, opacity 0.7s;
  transition-delay: ${({ delay }) => `${delay}s` ?? '0s'};
  @media screen and (max-width: 720px) {
    transition-delay: 0.1s;
    ${({ noAnimation }) =>
      noAnimation &&
      css`
        opacity: 1;
        transition: none;
        transform: translateY(0);
      `}
  }
`;

ScrollAnimation.defaultProps = {
  mode: ScrollAnimationMode.FADE_IN,
  direction: ScrollAnimaionDirection.BOTTOM,
};

export default ScrollAnimation;
