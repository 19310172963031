import { FunctionComponent, HTMLProps } from 'react';
import { TimelineLayout } from './styles';
import { checkIcon } from '../../common/constants/imageUrls';

export interface TimelineItemProps {
  isActive: boolean;
  isSelected: boolean;
  title: string;
  text: string;
  onClick: () => void;
}

export interface TimelineProps extends HTMLProps<HTMLDivElement> {
  timelineItems: TimelineItemProps[];
}

const Timeline: FunctionComponent<TimelineProps> = ({
  timelineItems,
  className,
}) => {
  return (
    <TimelineLayout className={className}>
      <ul className="timeline-list">
        {timelineItems.map((item, idx) => (
          <li
            className={`timeline-item${item.isActive ? ' active' : ''}${
              item.isSelected ? ' selected' : ''
            }`}
            key={idx}
          >
            <span className="timeline-icon">
              {item.isActive && !item.isSelected ? (
                <img src={checkIcon} alt={item.title} />
              ) : (
                idx + 1
              )}
            </span>
            <div
              role="navigation"
              className="timeline-content"
              onClick={
                item.isActive && !item.isSelected ? item.onClick : undefined
              }
              onKeyPress={
                item.isActive && !item.isSelected ? item.onClick : undefined
              }
            >
              <p className="timeline-title">{item.title}</p>
              <p className="timeline-text">{item.text}</p>
            </div>
          </li>
        ))}
      </ul>
    </TimelineLayout>
  );
};

export default Timeline;
