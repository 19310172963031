import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const variants = {
  start: { opacity: 0, x: 0, y: 200 },
  middle: { opacity: 1, x: 0, y: 0 },
  end: { opacity: 0, x: 0, y: -200 },
};

const detailVariants = {
  start: { opacity: 0, x: 0, y: 1000 },
  middle: { opacity: 1, x: 0, y: 0 },
  end: { opacity: 0, x: 0, y: -200 },
};

interface AnimationProps {
  children: any;
  isDeep?: boolean;
  duration?: number;
}

const Animation: React.FunctionComponent<AnimationProps> = ({
  children,
  isDeep,
  duration = 0.3,
}) => {
  return (
    <Wrapper
      variants={isDeep ? detailVariants : variants}
      initial="start"
      animate="middle"
      exit="end"
      transition={{ type: 'linear', delay: 0.1, duration }}
    >
      {children}
    </Wrapper>
  );
};

export default Animation;

const Wrapper = styled(motion.div)`
  margin: 0 auto;
`;
