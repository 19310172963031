import styled from 'styled-components';

export const TimelineLayout = styled.div`
  min-width: 200px;

  .timeline-list {
    position: relative;
    padding-left: 23.5px;
  }

  .timeline-list:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0px;
    width: 2px;
    height: calc(100% - 24px);
    transform: translateY(-50%);
    background: #e1e1e1;
  }

  .timeline-item {
    position: relative;
    color: #cacaca;
  }
  .timeline-item.active,
  .timeline-item.selected {
    color: #1f1f1f;
  }
  .timeline-item:not(:last-child) {
    margin-bottom: 64px;
  }

  .timeline-icon {
    position: absolute;
    top: 0px;
    left: -34.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background: #e1e1e1;
  }
  .timeline-item {
    &.active {
      .timeline-icon {
        background: #33ccbd;
      }

      .timeline-content {
        cursor: pointer;
      }
      &:not(.selected) .timeline-content:hover {
        transform: scale(1.1);
        transform-origin: left;
      }
    }
  }
  .timeline-item.selected .timeline-icon {
    background: #8e8e8e;
  }
  .timeline-title {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: bold;
  }
  .timeline-text {
    position: absolute;
    font-size: 16px;
  }
`;
