import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { PriceInquiryTablet } from 'redux/@types';
import { TABLET_CODE } from '../../common/constants/strings';

const prefix = 'skn-mintit-web';
const SET_MANUFACTURER_TABLET = `${prefix}/SET_MANUFACTURER_TABLET` as const;
const SET_SERIES_TABLET = `${prefix}/SET_SERIES_TABLET` as const;
const SET_MODEL_TABLET = `${prefix}/SET_MODEL_TABLET` as const;
const SET_CURRENT_INFO_TABLET = `${prefix}/SET_CURRENT_INFO_TABLET` as const;
const CLEAR_INFO_TABLET = `${prefix}/CLEAR_INFO_TABLET` as const;

const initialState = {
  manufacturer: { name: '', code: '' },
  series: { name: '', code: '' },
  model: { name: '', code: '' },
  currentInfo: { category: 'manufacturer', code: String(TABLET_CODE) },
};

export const setManufacturerTablet = createAction(
  SET_MANUFACTURER_TABLET,
  (atr: { name: string; code: string }) => atr,
)();
export const setSeriesTablet = createAction(
  SET_SERIES_TABLET,
  (atr: { name: string; code: string }) => atr,
)();
export const setModelTablet = createAction(
  SET_MODEL_TABLET,
  (atr: { name: string; code: string }) => atr,
)();
export const setCurrentInfoTablet = createAction(
  SET_CURRENT_INFO_TABLET,
  (atr: { category: string; code: string }) => atr,
)();
export const clearInfoTablet = createAction(CLEAR_INFO_TABLET)();

const setInfo = {
  setManufacturer: setManufacturerTablet,
  setSeries: setSeriesTablet,
  setModel: setModelTablet,
  setCurrentInfo: setCurrentInfoTablet,
  clearPhoneInfo: clearInfoTablet,
};
export type setAction = ActionType<typeof setInfo>;

const reducer = createReducer<PriceInquiryTablet, setAction>(initialState, {
  [SET_MANUFACTURER_TABLET]: (state, { payload }) => ({
    ...state,
    manufacturer: payload,
  }),
  [SET_SERIES_TABLET]: (state, { payload }) => ({
    ...state,
    series: payload,
  }),
  [SET_MODEL_TABLET]: (state, { payload }) => ({
    ...state,
    model: payload,
  }),
  [SET_CURRENT_INFO_TABLET]: (state, { payload }) => ({
    ...state,
    currentInfo: payload,
  }),
  [CLEAR_INFO_TABLET]: (state) => ({
    ...state,
    manufacturer: { name: '', code: '' },
    series: { name: '', code: '' },
    model: { name: '', code: '' },
    currentInfo: { category: 'manufacturer', code: String(TABLET_CODE) },
  }),
});

export default reducer;
