import { ReactNode } from 'react';
import { iconCheckCircle, iconCheckedCircle } from 'common/constants/imageUrls';
import styled from 'styled-components';

interface CheckboxProps {
  onClick: () => void;
  isChecked?: boolean;
  children?: ReactNode;
}

const Checkbox = ({ onClick, isChecked, children }: CheckboxProps) => {
  return (
    <Container
      role="checkbox"
      tabIndex={0}
      aria-checked={isChecked ? 'true' : 'false'}
      onClick={onClick}
    >
      <Figure>
        <img
          src={isChecked ? iconCheckedCircle : iconCheckCircle}
          alt="checkbox"
        />
      </Figure>
      {children}
    </Container>
  );
};

export default Checkbox;

const Container = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Figure = styled.figure`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;
