import { mapIcon } from 'common/constants/imageUrls';
import { IMarkerImages } from './index';

/**
 * 클러스터러 생성
 */
export const createCluster = (map: any) => {
  return new window.kakao.maps.MarkerClusterer({
    map,
    averageCenter: true,
    minLevel: 4,
    calculator: [10, 100, 1000],
    styles: [
      {
        // 2 ~ 9
        width: '36px',
        height: '36px',
        background: `url(${mapIcon.bg_cluster_1}) no-repeat center / cover`,
        borderRadius: '50%',
        color: '#FFF',
        textAlign: 'center',
        lineHeight: '36px',
        fontSize: '14px',
      },
      {
        // 10 ~ 99
        width: '48px',
        height: '48px',
        background: `url(${mapIcon.bg_cluster_2}) no-repeat center / cover`,
        borderRadius: '50%',
        color: '#FFF',
        textAlign: 'center',
        lineHeight: '48px',
        fontSize: '14px',
      },
      {
        // 100 ~ 999
        width: '52px',
        height: '52px',
        background: `url(${mapIcon.bg_cluster_3}) no-repeat center / cover`,
        borderRadius: '50%',
        color: '#FFF',
        textAlign: 'center',
        lineHeight: '52px',
        fontSize: '14px',
      },
      {
        // 1000 ~
        width: '64px',
        height: '64px',
        background: `url(${mapIcon.bg_cluster_4}) no-repeat center / cover`,
        borderRadius: '50%',
        color: '#FFF',
        textAlign: 'center',
        lineHeight: '64px',
        fontSize: '14px',
      },
    ],
  });
};

/**
 * 마커 생성
 */
// export const createMarkers = (
//   map: any,
//   mapData: IMapDataProps[],
//   location: any,
//   markerImages: IMarkerImages,
//   setSelectedMarkerState: any,
//   selectedMarkerState: any,
// ) => {
//   const result = mapData.map((item) => {
//     const markerImage = markerImages?.markerImageEtc;
//     const selectedMarkerImage = markerImages?.markerImageEtcSelected;
//
//     const position = new window.kakao.maps.LatLng(item.lat, item.lon);
//
//     const marker = new window.kakao.maps.Marker({
//       map,
//       position,
//       title: item.atm_name,
//       image: markerImage,
//       clickable: true,
//     });
//
//     const overlay = new window.kakao.maps.CustomOverlay({
//       map,
//       position,
//       zIndex: 100,
//       content: content(item, { lat: location.Ma, lon: location.La }),
//     });
//
//     overlay.setMap(null);
//
//     //   window.kakao.maps.event.addListener(marker, 'click', function () {
//     //     overlay.setMap(map);
//     //     marker.setImage(selectedMarkerImage);
//     //
//     //     setSelectedMarkerState((current: any) => {
//     //       const { selectedMarker, normalImage, selectedOverlay } = current;
//     //
//     //       if (selectedMarker !== null && marker !== selectedMarker) {
//     //         selectedMarker.setImage(normalImage);
//     //         selectedOverlay.setMap(null);
//     //       } else if (marker === selectedMarker) {
//     //         selectedMarker.setImage(normalImage);
//     //         selectedOverlay.setMap(null);
//     //
//     //         return {
//     //           selectedMarker: null,
//     //           selectedImage: null,
//     //           normalImage: null,
//     //           selectedOverlay: null,
//     //         };
//     //       }
//     //
//     //       map.setLevel(2);
//     //
//     //       return {
//     //         selectedMarker: marker,
//     //         normalImage: markerImage,
//     //         selectedImage: selectedMarkerImage,
//     //         selectedOverlay: overlay,
//     //       };
//     //     });
//     //
//     //     map.setCenter(
//     //       new window.kakao.maps.LatLng(parseFloat(item.lat) + 0.0007, item.lon),
//     //     );
//     //   });
//     //   return marker;
//   });
//
//   if (selectedMarkerState.selectedMarker !== null) {
//     selectedMarkerState.selectedOverlay.setMap(null);
//   }
//   return result;
// };

/**
 * 마커 이미지 생성
 */

const getMarkerImage = (chname: string, sts: string, isActive: boolean) => {
  if (sts === '2') {
    switch (chname) {
      case 'SKT_mini':
        return isActive ? mapIcon.StsSK : mapIcon.UnStsSK;
      case 'SK':
        return isActive ? mapIcon.StsSK : mapIcon.UnStsSK;
      case '삼성디지털프라자':
        return isActive ? mapIcon.StsSamsung : mapIcon.UnStsSamsung;
      case '이마트':
        return isActive ? mapIcon.StsEmart : mapIcon.UnStsEmart;
      case '홈플러스':
        return isActive ? mapIcon.StsHomeplus : mapIcon.UnStsHomeplus;
      case '롯데마트':
        return isActive ? mapIcon.StsLotte : mapIcon.UnStsLotte;
      case '하이마트':
        return isActive ? mapIcon.StsHimart : mapIcon.UnStsHimart;
      case '우체국':
        return isActive ? mapIcon.StsPost : mapIcon.UnStsPost;
      case 'LG유플러스':
        return isActive ? mapIcon.StsLG : mapIcon.UnStsLG;
      case 'KT':
        return isActive ? mapIcon.StsKT : mapIcon.UnStsKT;
      case '전자랜드':
        return isActive ? mapIcon.StsElec : mapIcon.UnStsElec;
      default:
        return isActive ? mapIcon.StsMintit : mapIcon.UnStsMintit;
    }
  } else {
    switch (chname) {
      case 'SKT_mini':
        return isActive ? mapIcon.SK : mapIcon.UnSK;
      case 'SK':
        return isActive ? mapIcon.SK : mapIcon.UnSK;
      case '삼성디지털프라자':
        return isActive ? mapIcon.Samsung : mapIcon.UnSamsung;
      case '이마트':
        return isActive ? mapIcon.Emart : mapIcon.UnEmart;
      case '홈플러스':
        return isActive ? mapIcon.Homeplus : mapIcon.UnHomeplus;
      case '롯데마트':
        return isActive ? mapIcon.Lotte : mapIcon.UnLotte;
      case '하이마트':
        return isActive ? mapIcon.Himart : mapIcon.UnHimart;
      case '우체국':
        return isActive ? mapIcon.Post : mapIcon.UnPost;
      case 'LG유플러스':
        return isActive ? mapIcon.LG : mapIcon.UnLG;
      case 'KT':
        return isActive ? mapIcon.KT : mapIcon.UnKT;
      case '전자랜드':
        return isActive ? mapIcon.Elec : mapIcon.UnElec;
      default:
        return isActive ? mapIcon.Mintit : mapIcon.UnMintit;
    }
  }
};

export const createMarkerImage = (
  chname: string,
  sts: string,
  isActive = false,
) => {
  const image = getMarkerImage(chname, sts, isActive);
  const markerWidth = isActive ? 34 : 28;
  const markerHeight = isActive ? 48 : 28;

  const size = new window.kakao.maps.Size(markerWidth, markerHeight);
  return new window.kakao.maps.MarkerImage(image, size);
};

export const createMarker = (atm: any) => {
  const { lat, lon, chname } = atm;
  const image = createMarkerImage(chname, atm.sts);
  const position = new window.kakao.maps.LatLng(lat, lon);
  const marker = new window.kakao.maps.Marker({
    image,
    position,
    clickable: true,
    zIndex: 1,
  });
  marker.normalImage = image;
  marker.atmInfo = atm;

  return marker;
};

export const createMarkerImages: () => IMarkerImages = () => {
  const normalSize = new window.kakao.maps.Size(28, 28);
  const selectedSize = new window.kakao.maps.Size(34, 48);

  const markerImageSk = new window.kakao.maps.MarkerImage(
    mapIcon.UnSK,
    normalSize,
  );
  const markerImageSkSelected = new window.kakao.maps.MarkerImage(
    mapIcon.SK,
    selectedSize,
  );
  const markerImageSd = new window.kakao.maps.MarkerImage(
    mapIcon.UnSamsung,
    normalSize,
  );
  const markerImageSdSelected = new window.kakao.maps.MarkerImage(
    mapIcon.Samsung,
    selectedSize,
  );
  const markerImageEmart = new window.kakao.maps.MarkerImage(
    mapIcon.UnEmart,
    normalSize,
  );
  const markerImageEmartSelected = new window.kakao.maps.MarkerImage(
    mapIcon.Emart,
    selectedSize,
  );
  const markerImageHomePlus = new window.kakao.maps.MarkerImage(
    mapIcon.UnHomeplus,
    normalSize,
  );
  const markerImageHomePlusSelected = new window.kakao.maps.MarkerImage(
    mapIcon.Homeplus,
    selectedSize,
  );
  const markerImageLotte = new window.kakao.maps.MarkerImage(
    mapIcon.UnLotte,
    normalSize,
  );
  const markerImageLotteSelected = new window.kakao.maps.MarkerImage(
    mapIcon.Lotte,
    selectedSize,
  );
  const markerImageHiMart = new window.kakao.maps.MarkerImage(
    mapIcon.UnHimart,
    normalSize,
  );
  const markerImageHiMartSelected = new window.kakao.maps.MarkerImage(
    mapIcon.Himart,
    selectedSize,
  );
  const markerImagePost = new window.kakao.maps.MarkerImage(
    mapIcon.UnPost,
    normalSize,
  );
  const markerImagePostSelected = new window.kakao.maps.MarkerImage(
    mapIcon.Post,
    selectedSize,
  );
  const markerImageLg = new window.kakao.maps.MarkerImage(
    mapIcon.UnLG,
    normalSize,
  );
  const markerImageLgSelected = new window.kakao.maps.MarkerImage(
    mapIcon.LG,
    selectedSize,
  );
  const markerImageKt = new window.kakao.maps.MarkerImage(
    mapIcon.UnKT,
    normalSize,
  );
  const markerImageKtSelected = new window.kakao.maps.MarkerImage(
    mapIcon.KT,
    selectedSize,
  );
  const markerImageElec = new window.kakao.maps.MarkerImage(
    mapIcon.UnElec,
    normalSize,
  );
  const markerImageElecSelected = new window.kakao.maps.MarkerImage(
    mapIcon.Elec,
    selectedSize,
  );
  const markerImageEtc = new window.kakao.maps.MarkerImage(
    mapIcon.UnMintit,
    normalSize,
  );
  const markerImageEtcSelected = new window.kakao.maps.MarkerImage(
    mapIcon.Mintit,
    selectedSize,
  );

  return {
    markerImageSk,
    markerImageSkSelected,
    markerImageSd,
    markerImageSdSelected,
    markerImageEmart,
    markerImageEmartSelected,
    markerImageHomePlus,
    markerImageHomePlusSelected,
    markerImageLotte,
    markerImageLotteSelected,
    markerImageHiMart,
    markerImageHiMartSelected,
    markerImagePost,
    markerImagePostSelected,
    markerImageLg,
    markerImageLgSelected,
    markerImageKt,
    markerImageKtSelected,
    markerImageEtc,
    markerImageEtcSelected,
    markerImageElec,
    markerImageElecSelected,
  };
};

/**
 * chname에 따른 마커 전달
 */
export const getMarkerState = (chname: string) => {
  let result;

  switch (chname) {
    case 'SKT_mini':
      result = {
        normalImage: 'markerImageSk',
        selectedImage: 'markerImageSkSelected',
        zIndex: 10,
      };
      break;
    case 'SK':
      result = {
        normalImage: 'markerImageSk',
        selectedImage: 'markerImageSkSelected',
        zIndex: 10,
      };
      break;
    case '삼성디지털프라자':
      result = {
        normalImage: 'markerImageSd',
        selectedImage: 'markerImageSdSelected',
        zIndex: 9,
      };
      break;
    case '이마트':
      result = {
        normalImage: 'markerImageEmart',
        selectedImage: 'markerImageEmartSelected',
        zIndex: 8,
      };
      break;
    case '홈플러스':
      result = {
        normalImage: 'markerImageHomePlus',
        selectedImage: 'markerImageHomePlusSelected',
        zIndex: 7,
      };
      break;
    case '롯데마트':
      result = {
        normalImage: 'markerImageLotte',
        selectedImage: 'markerImageLotteSelected',
        zIndex: 6,
      };
      break;
    case '하이마트':
      result = {
        normalImage: 'markerImageHiMart',
        selectedImage: 'markerImageHiMartSelected',
        zIndex: 5,
      };
      break;
    case '우체국':
      result = {
        normalImage: 'markerImagePost',
        selectedImage: 'markerImagePostSelected',
        zIndex: 4,
      };
      break;
    case 'LG유플러스':
      result = {
        normalImage: 'markerImageLg',
        selectedImage: 'markerImageLgSelected',
        zIndex: 3,
      };
      break;
    case 'KT':
      result = {
        normalImage: 'markerImageKt',
        selectedImage: 'markerImageKtSelected',
        zIndex: 2,
      };
      break;
    case '전자랜드':
      result = {
        normalImage: 'markerImageElec',
        selectedImage: 'markerImageElecSelected',
        zIndex: 2,
      };
      break;
    // 기타
    default:
      result = {
        normalImage: 'markerImageEtc',
        selectedImage: 'markerImageEtcSelected',
        zIndex: 1,
      };
      break;
  }

  return result;
};
