import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducer';
import { PriceInquiryBuds } from '../../redux/@types';
import {
  clearInfoBuds,
  setCurrentInfoBuds,
  setManufacturerBuds,
  setModelBuds,
  setSeriesBuds,
} from '../../redux/modules/priceInquiryBuds';

const useInquiryWatch = () => {
  const dispatch = useDispatch();
  const { manufacturer, series, model, currentInfo } = useSelector<
    RootState,
    PriceInquiryBuds
  >((state) => state.priceInquiryBuds);

  const setManufacturer = (item: { name: string; code: string }) => {
    dispatch(setManufacturerBuds(item));
  };
  const setSeries = (item: { name: string; code: string }) => {
    dispatch(setSeriesBuds(item));
  };
  const setModel = (item: { name: string; code: string }) => {
    dispatch(setModelBuds(item));
  };

  const setCurrentInfo = (item: { category: string; code: string }) => {
    dispatch(setCurrentInfoBuds(item));
  };

  const clearInfo = () => {
    dispatch(clearInfoBuds());
  };

  return {
    manufacturer,
    series,
    model,
    currentInfo,
    setManufacturer,
    setSeries,
    setModel,
    setCurrentInfo,
    clearInfo,
  };
};

export default useInquiryWatch;
