import styled, { css } from 'styled-components';
import ScrollAnimation from 'components/ScrollAnimation';
import { DetailDesc, DetailTitle } from 'components/main/Styles';
import Button from 'components/Button';
import {
  mediaQueries,
  MOBILE,
  SCROLL_BREAK,
  TABLET,
} from 'styles/mediaQueries';
import { flexCenterCss, flexColumnCenterCss } from 'styles/commonStyle';

export const HowToAdditionalStyle = {
  OrderWrapper: styled.div`
    ${flexCenterCss};
    width: 100%;
    margin: 7.273em 0 3.636em;

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      flex-direction: column;
      margin: 40px 0;
    }
  `,
  Order: styled(ScrollAnimation)`
    ${({ theme }) => css`
      ${flexColumnCenterCss};
      width: 27.727em;
      height: 32em;
      border: 1px solid ${theme.common.colors.mercury};
      border-radius: 16px;
      padding: 4.364em 0;
      background-color: ${theme.common.colors.white};

      ${mediaQueries(TABLET)} {
        font-size: 0.77vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 10px;
      }

      ${mediaQueries(MOBILE)} {
        flex-direction: row;
        align-items: start;
        justify-content: start;
        width: 100%;
        height: 100%;
        padding: 30px;
        border: 1px solid ${theme.common.colors.silver};
      }
    `}
  `,
  OrderNum: styled.div`
    ${({ theme }) => css`
      ${flexCenterCss};
      width: 2.5em;
      height: 2.5em;
      border-radius: 50%;
      background-color: ${theme.common.colors.turquoise};
      color: #fff;
      ${theme.typo.head16};

      ${mediaQueries(TABLET)} {
        font-size: 1vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 12.2px;
      }

      ${mediaQueries(MOBILE)} {
        display: none;
        justify-content: start;
        align-items: start;
      }
    `}
  `,
  OrderImg: styled.img`
    width: 6.182em;
    height: 6.182em;
    margin: 1.818em 0;

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      width: 32px;
      height: 32px;
      margin: 0 20px 0 0;
    }
  `,
  OrderTitle: styled.div`
    ${({ theme }) => css`
      ${theme.typo.head20};
      color: ${theme.common.colors.black};
      margin-bottom: 0.1em;

      ${mediaQueries(TABLET)} {
        font-size: 1.2vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 14.64px;
      }

      ${mediaQueries(MOBILE)} {
        ${theme.typo.head14};
      }
    `}
  `,
  OrderTextWrapper: styled.div`
    ${mediaQueries(MOBILE)} {
      text-align: left;
    }
  `,
  OrderText: styled.div`
    ${({ theme }) => css`
      ${theme.typo.body16R};
      color: ${theme.common.colors.black};

      span {
        ${theme.typo.body14R};
        color: ${theme.common.colors.subText};

        ${mediaQueries(TABLET)} {
          font-size: 0.9vw;
        }

        ${mediaQueries(SCROLL_BREAK)} {
          font-size: 10.98px;
        }

        ${mediaQueries(MOBILE)} {
          ${theme.typo.body12R};
        }
      }

      ${mediaQueries(TABLET)} {
        font-size: 1vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 12.2px;
      }

      ${mediaQueries(MOBILE)} {
        ${theme.typo.body12R};
      }
    `}
  `,
  OrderDiv: styled(ScrollAnimation)`
    ${({ theme }) => css`
      ${flexCenterCss};
      width: 1.818em;
      height: 1.818em;
      border-radius: 50%;
      background-color: ${theme.common.colors.turquoise};
      z-index: 1;
      transform: rotate(-90deg) scale(4);

      ${mediaQueries(TABLET)} {
        font-size: 0.7vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 8.5px;
      }

      ${mediaQueries(MOBILE)} {
        width: 17px;
        height: 17px;
        transform: scale(3);
      }
    `}
  `,
  OrderDivImg: styled.img``,
  Banner: styled.div`
    ${({ theme }) => css`
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: start;
      justify-content: center;
      width: 100%;
      height: 42.818em;
      margin-bottom: 3.636em;
      padding: 5.273em 5.455em;
      border-radius: 16px;
      color: ${theme.common.colors.white};
      background-color: ${theme.common.colors.turquoise};

      ${mediaQueries(TABLET)} {
        font-size: 0.7vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 8.5px;
      }

      ${mediaQueries(MOBILE)} {
        height: 100%;
        ${theme.typo.body14R};
        padding: 30px 24px;
        margin: 24px 0;
      }
    `}
  `,
  BannerTitle: styled.div`
    ${({ theme }) => css`
      ${theme.typo.body40T};
      text-align: left;
      color: ${theme.common.colors.mainText};

      span {
        font-weight: ${theme.fontWeights.bold};
      }

      ${mediaQueries(TABLET)} {
        font-size: 2.7vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 32.94px;
      }

      ${mediaQueries(MOBILE)} {
        ${theme.typo.body20T};
        margin-bottom: 20px;
        order: 1;
      }
    `}
  `,
  BannerDesc: styled(DetailDesc)`
    ${({ theme }) => css`
      color: ${theme.common.colors.white};
      margin: 0.667em 0 2.5em;
      font-weight: ${theme.fontWeights.normal};

      ${mediaQueries(MOBILE)} {
        ${theme.typo.body14R};
        margin-top: 0;
        margin-bottom: 44px;
        order: 2;
      }
    `}
  `,
  BannerBtn: styled(Button)`
    ${({ theme }) => css`
      width: 12.917em;
      height: 3.458em;
      ${theme.typo.head24};
      background-color: ${theme.common.colors.white} !important;

      ${mediaQueries(TABLET)} {
        font-size: 1.5vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 18.3px;
      }

      ${mediaQueries(MOBILE)} {
        ${flexCenterCss};
        width: 100%;
        height: 52px;
        ${theme.typo.body14R};
        order: 4;
        margin-top: 40px;
      }
    `}
  `,
  BannerImg: styled.img`
    height: 34.818em;
    margin-right: 10em;
    align-self: end;

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      width: 130px;
      height: 143px;
      align-self: center;
      order: 3;
      margin: 0;
    }
  `,

  BottomWrapper: styled(ScrollAnimation)<{ section?: boolean }>`
    ${({ theme, section }) => css`
      width: 100%;
      padding: 3.636em 2.909em;
      text-align: left;
      border-radius: 16px;
      background-color: ${section
        ? theme.common.colors.white
        : theme.common.colors.wildSand};
      border: ${section ? `1px solid ${theme.common.colors.mercury}` : 'none'};

      ${mediaQueries(TABLET)} {
        font-size: 0.7vw;
      }

      ${mediaQueries(SCROLL_BREAK)} {
        font-size: 8.5px;
      }

      ${mediaQueries(MOBILE)} {
        padding: 20px;
        margin-bottom: 0;
      }
    `}
  `,
  BottomTitleWrapper: styled.div`
    display: flex;
    align-items: center;

    ${mediaQueries(MOBILE)} {
      align-items: start;
    }
  `,
  BottomTitleImg: styled.img`
    width: 2.182em;
    height: 2.182em;
    margin-right: 0.727em;

    ${mediaQueries(TABLET)} {
      font-size: 0.7vw;
    }

    ${mediaQueries(SCROLL_BREAK)} {
      font-size: 8.5px;
    }

    ${mediaQueries(MOBILE)} {
      width: 20px;
      height: 20px;
    }
  `,
  BottomTitle: styled(DetailTitle)`
    ${({ theme }) => css`
      color: ${theme.common.colors.mainText};
      margin-left: 0.333em;
    `}
  `,
  BottomDescList: styled.ul`
    margin-left: 2.909em;
    margin-top: 2.182em;
    word-break: keep-all;

    @media screen and (max-width: 720px) {
      text-align: left;
      margin-top: 16px;
    }
  `,
  BottomDesc: styled.li`
    ${({ theme }) => css`
      list-style: disc;
      ${theme.typo.body16R};
      color: ${theme.common.colors.mainText};

      :not(:last-child) {
        margin-bottom: 0.313em;
      }

      @media screen and (max-width: 1440px) {
        font-size: 1vw;
      }

      @media screen and (max-width: 1220px) {
        font-size: 12.2px;
      }

      @media screen and (max-width: 720px) {
        ${theme.typo.body12R};
        word-break: keep-all;
      }
    `}
  `,
};
