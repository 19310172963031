import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
  clearPhoneInfo,
  setManufacturer,
  setManufacturerUrl,
  setModel,
  setSeries,
} from 'redux/modules/priceInquiry';
import { RootState } from 'redux/reducer';

const UsePhoneDetail = () => {
  const dispatch = useDispatch();
  // 제조사 url 저장
  const setManufacUrl = useCallback(
    (idx: string) => {
      dispatch(setManufacturerUrl(idx));
    },
    [dispatch],
  );
  // 제조사 조회
  const setManufacHandler = useCallback(
    (idx: string) => {
      dispatch(setManufacturer(idx));
    },
    [dispatch],
  );
  // 시리즈 조회
  const setSeriesHandler = useCallback(
    (idx: string) => {
      dispatch(setSeries(idx));
    },
    [dispatch],
  );
  // 모델 조회
  const setModelHandler = useCallback(
    (idx: string) => {
      dispatch(setModel(idx));
    },
    [dispatch],
  );
  // 조회 정보 초기화
  const clearPhoneInfoHandler = useCallback(() => {
    dispatch(clearPhoneInfo());
  }, [dispatch]);
  const manufacUrl = useSelector<RootState, string>(
    (state) => state.priceInquiry.manufacturer_url,
  );
  const manufacName = useSelector<RootState, string>(
    (state) => state.priceInquiry.manufacturer,
  );
  const seriesId = useSelector<RootState, string>(
    (state) => state.priceInquiry.generation,
  );
  const modelId = useSelector<RootState, string>(
    (state) => state.priceInquiry.petname,
  );
  return {
    setManufacUrl,
    setManufacHandler,
    setSeriesHandler,
    setModelHandler,
    clearPhoneInfoHandler,
    manufacUrl,
    manufacName,
    seriesId,
    modelId,
  };
};

export default UsePhoneDetail;
