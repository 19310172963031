import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import styled from 'styled-components';
import MapNav from 'components/map/Nav';
import Filter from 'components/map/Filter';
import KakaoMapStyle from 'components/map/KakaoMap';
import { mapIcon } from 'common/constants/imageUrls';
import { HttpMethod, request } from 'common/network';
import Loading from 'components/Loading';
import { SEARCH_ATM_URL } from 'common/constants/urls';
import { createCluster, createMarker, createMarkerImage } from './kakaoUtils';
import HeaderOnly from '../../components/HeaderOnly';

function getLocation(timeout = false): Promise<{ lat: number; lon: number }> {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      // GPS를 지원하면
      navigator.geolocation.getCurrentPosition(
        function (position) {
          resolve({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        function (error) {
          console.error(error);
          reject(new Error());
        },
        {
          enableHighAccuracy: false,
          maximumAge: 0,
          timeout: timeout ? 500 : Infinity,
        },
      );
    } else {
      resolve({
        lat: 37.5767785,
        lon: 126.8977657,
      });
    }
  });
}

const content = (
  item: IMapDataProps,
  { lat, lon }: { lat: number; lon: number },
  addr: string,
  distance?: number,
) => {
  return `
    <div class="event">
      <div class="event_wrapper">
        <div class="event_header">
          <div class="event_header-title">${item.atm_display_name}</div>
            ${
              item.sts === '2'
                ? `<div class="event_header-fix">수리중</div>`
                : ''
            }
            </div>
            ${distance ? `<div><p>400m</p></div>` : ``}
            <div class="evnet_info">
              <div class="event_info-title">주소</div>
              <div class="event_info-info">
                <img src=${mapIcon.Locate} class="event_img" alt="주소 아이콘"/>
                <div>
                  <div class="event_info-info-main">${item.addr}</div>
                  <div class="event_info-info-sub">지번: ${addr}</div>
                </div>
              </div>
            </div>
            <div class="evnet_info">
              <div class="event_info-title">정보</div>
              <div class="event_info-info">
                <img src=${mapIcon.Clock} class="event_img" alt="정보 아이콘"/>
                  <div>
                    <div class="event_info-info-main">
                      휴무일 ${item.off_days}
                    </div>
                    <div class="event_info-info-main">
                      운영 시간 ${item.info_run_hours}  
                    </div>
                  </div>
                </div>
            </div>
            <a class="event_btn" target="_blank" href=${`https://map.kakao.com/link/from/내위치,${lat},${lon}/to/${item.atm_display_name
              .split(' ')
              .join('%20')},${item.lat},${item.lon}`}>길찾기</a>
        </div>
    </div>
  `;
};

export interface IMarkerImages {
  markerImageSk: any;
  markerImageSkSelected: any;
  markerImageSd: any;
  markerImageSdSelected: any;
  markerImageEmart: any;
  markerImageEmartSelected: any;
  markerImageHomePlus: any;
  markerImageHomePlusSelected: any;
  markerImageLotte: any;
  markerImageLotteSelected: any;
  markerImageHiMart: any;
  markerImageHiMartSelected: any;
  markerImagePost: any;
  markerImagePostSelected: any;
  markerImageLg: any;
  markerImageLgSelected: any;
  markerImageKt: any;
  markerImageKtSelected: any;
  markerImageEtc: any;
  markerImageEtcSelected: any;
}

export interface IMapDataProps {
  atm_name: string;
  area: null;
  addr: string;
  locate: string;
  off_days: string;
  fg_use: string;
  lon: string;
  lat: string;
  sido: string;
  sigugun: string;
  atm_display_name: string;
  info_run_hours: string;
  upd_date: string;
  com_main_num: number;
  atm_num: number;
  atm_type: string;
  srt: string;
  himart_srt: string;
  sts: string;
  chname: string;
}

export interface ISelectedMarkerState {
  selectedMarker: any;
  normalImage: any;
  selectedImage: any;
  selectedOverlay: any;
}

const AtmOfUse: FunctionComponent = () => {
  /**
   * Ref
   */
  const kakaoMap = useRef<HTMLDivElement>(null);
  const selectedMarker = useRef<any>(null);
  const selectedOverlay = useRef<any>(null);
  const currentMyLocation = useRef<any>(null);
  /**
   * State
   */
  const [isLocationSet, setIsLoacationSet] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isInit, setIsInit] = useState(true);

  const [map, setMap] = useState<any>();
  const [cluster, setCluster] = useState<any>();
  const [markers, setMarkers] = useState<any[]>([]);
  const [initData, setInitData] = useState<IMapDataProps[]>([]);
  const [mapData, setMapData] = useState<IMapDataProps[]>([]);
  const [location, setLocation] = useState<any>();
  const [selectIndex, setSelectIndex] = useState<number | undefined>(undefined);
  const [selectedAtm, setSelectedAtm] = useState<IMapDataProps>();

  const [searchKey, setSearchKey] = useState('');
  const [filterList, setFilterList] = useState<string[]>([]);

  /**
   * Private
   */
  // eslint-disable-next-line camelcase
  const fetchData = (filterList: string[], searchKey: string) => {
    if (filterList.length === 0 || filterList.length === 10) {
      let result = initData;

      if (searchKey) {
        result = result.filter((item: { atm_display_name: string }) => {
          return (
            (item.atm_display_name.indexOf(searchKey.toUpperCase()) ||
              item.atm_display_name.indexOf(searchKey.toLowerCase())) > -1
          );
        });
      }

      setMapData((current) => {
        if (current === result) {
          setIsLoading(false);
        }

        return result;
      });
    } else {
      const filteringData = initData.filter(
        (item: { com_main_num: number; atm_display_name: string }) => {
          let result = false;

          filterList.forEach((selected: string) => {
            if (selected.split(',').includes(String(item.com_main_num))) {
              result = true;
            }
          });

          if (
            !(
              ((item.atm_display_name as string).indexOf(
                searchKey.toUpperCase(),
              ) > -1 ||
                (item.atm_display_name as string).indexOf(
                  searchKey.toLowerCase(),
                )) > -1
            )
          ) {
            result = false;
          }

          return result;
        },
      );

      setMapData((current) => {
        if (current === filteringData) {
          setIsLoading(false);
        }

        return filteringData;
      });
    }
  };

  const filtering = (filterList: string[], searchkey: string) => {
    setIsLoading(true);
    setSelectedAtm(undefined);
    selectedMarker.current?.setImage(selectedMarker.current.normalImage);
    selectedMarker.current?.setZIndex(1);
    selectedOverlay.current?.setMap(null);
    fetchData(filterList, searchkey);
  };

  /**
   * UseEffect
   */
  // On screen loaded
  useEffect(() => {
    // Initialize Map
    const kakoMapElement = kakaoMap.current;

    const options = {
      center: new window.kakao.maps.LatLng(37.5767785, 126.8977657),
      level: 3,
      maxLevel: 11,
    };

    const map = new window.kakao.maps.Map(kakoMapElement, options);
    const cluster = createCluster(map);

    setMap(map);
    setCluster(cluster);

    // Fetch map data
    request(HttpMethod.GET, SEARCH_ATM_URL, {
      param: {
        // eslint-disable-next-line camelcase
        com_main_nums:
          '1485,575,598,923,998,3978,4043,4480,608,611,613,930,935,949,4573,4575,6997,7442',
        // eslint-disable-next-line camelcase
        search_key: '',
        sts: '1,2',
      },
      notUsedAccept: true,
    })
      .then((res) => {
        const { dataset } = res.data;

        setIsInit(false);
        setInitData(dataset || []);
        setMapData(dataset || []);
      })
      .catch((e) => {
        console.error(e);
        setInitData([]);
        setMapData([]);
        setIsLoading(false);
      });
  }, []);

  // On map loaded
  useEffect(() => {
    // Fetch initial location
    if (map) {
      getLocation(true)
        .then((res) => {
          setLocation(new window.kakao.maps.LatLng(res.lat, res.lon));
        })
        .catch((error) => {
          console.error(error);
          setLocation(new window.kakao.maps.LatLng(37.5767785, 126.8977657));
        })
        .finally(() => {
          setIsLoacationSet(false);
        });

      window.kakao.maps.event.addListener(map, 'dragstart', function () {
        if (selectedOverlay && selectedOverlay.current) {
          selectedMarker.current?.setImage(selectedMarker.current.normalImage);
          selectedMarker.current?.setZIndex(1);
          selectedOverlay.current.setMap(null);
          setSelectIndex(undefined);
          setSelectedAtm(undefined);
        }
      });
    }
  }, [map]);

  // On location changed
  useEffect(() => {
    if (map && location) {
      map.setCenter(location);
    }
  }, [map, location]);

  // On map data changed
  useEffect(() => {
    if (map && mapData && !isInit) {
      if (cluster) {
        cluster.clear();
      }
      markers.forEach((marker) => {
        marker.setMap(null);
      });

      const markerList = mapData.map((atm) => {
        const marker = createMarker(atm);

        marker.setMap(map);

        window.kakao.maps.event.addListener(marker, 'click', () =>
          handleMarkerClick(marker),
        );

        return marker;
      });

      setIsLoading(false);
      setMarkers(markerList);
    }
  }, [map, mapData]);

  // On marker created
  useEffect(() => {
    if (cluster && markers) {
      cluster.addMarkers(markers);

      setSelectIndex(undefined);
    }
  }, [markers, cluster]);

  useEffect(() => {
    setIsLoading(true);
    filtering(filterList, searchKey);
  }, [filterList, searchKey]);

  const handleMarkerClick = (marker: any) => {
    const atmInfo = get(marker, 'atmInfo');
    const lat = get(atmInfo, 'lat');
    const lon = get(atmInfo, 'lon');
    const chname = get(atmInfo, 'chname');

    setSelectedAtm(atmInfo);

    const activedMarkerImage = createMarkerImage(chname, atmInfo.sts, true);
    const moveLatLng = new window.kakao.maps.LatLng(
      parseFloat(lat) + (window.innerWidth > 720 ? 0.0007 : 0),
      lon,
    );

    // 카카오맵 지번 불러오기 API
    const geocoder = new window.kakao.maps.services.Geocoder();
    const coords = new window.kakao.maps.LatLng(atmInfo.lat, atmInfo.lon);

    let addr = '';

    // 지번 불러와서 불러온 지번을 이용해서 오버레이를 만듦
    geocoder.coord2RegionCode(coords.getLng(), coords.getLat(), (res: any) => {
      addr = res[0].address_name;

      const overlay = new window.kakao.maps.CustomOverlay({
        position: marker.getPosition(),
        zIndex: 100,
        content: content(
          atmInfo,
          {
            lat: location?.Ma ?? 37.5767785,
            lon: location?.La ?? 126.8977657,
          },
          addr,
        ),
      });

      if (!selectedOverlay || selectedOverlay?.current !== overlay) {
        selectedOverlay.current?.setMap(null);
      }
      overlay.setMap(map);
      selectedOverlay.current = overlay;
    });

    if (!selectedMarker || selectedMarker?.current !== marker) {
      selectedMarker.current?.setImage(selectedMarker.current.normalImage);
      selectedMarker.current?.setZIndex(1);
      selectedOverlay.current?.setMap(null);

      marker.setImage(activedMarkerImage);
      marker.setZIndex(2);
    }
    selectedMarker.current = marker;

    map.setLevel(2);
    map.panTo(moveLatLng);
  };

  /**
   * Event
   */
  const handleNavClick = (index: number) => {
    const marker = get(markers, `[${index}]`);
    handleMarkerClick(marker);
    // goMarker(index);
    setSelectIndex(index);
  };
  const handleSearch = (keyword: string) => {
    setSearchKey(keyword);
  };
  const handleFilter = (id: string) => {
    if (filterList.includes(id)) {
      setFilterList(filterList.filter((select) => select !== id));
    } else {
      setFilterList((current) => [...current, id]);
    }
  };
  const handleMyLocationClick = () => {
    setIsLoading(true);
    setSelectedAtm(undefined);
    getLocation()
      .then((res) => {
        const myLocation = new window.kakao.maps.LatLng(res.lat, res.lon);

        setLocation(myLocation);

        if (!currentMyLocation.current) {
          const marker = new window.kakao.maps.Marker({
            map,
            image: new window.kakao.maps.MarkerImage(
              mapIcon.myLocationImage,
              new window.kakao.maps.Size(40, 40),
            ),
            zIndex: 10,
          });

          currentMyLocation.current = marker;
        }

        currentMyLocation.current.setPosition(myLocation);
      })
      .catch((error) => {
        console.error(error);
        setLocation(new window.kakao.maps.LatLng(37.5767785, 126.8977657));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLocationSet || isLoading ? <Loading /> : null}
      <MapStyle.Container>
        <HeaderOnly href="/event" />
        <MapStyle.Wrapper>
          <MapNav
            data={mapData}
            mapLocation={{
              x: location?.Ma || 37.5767785,
              y: location?.La || 126.8977657,
            }}
            onClick={handleNavClick}
            onSearch={handleSearch}
            markers={markers}
            selectIndex={selectIndex}
            setSelectedAtm={setSelectedAtm}
          />
          <MapStyle.Map>
            <Filter selectFilter={handleFilter} selectList={filterList} />
            <KakaoMapStyle ref={kakaoMap} />
          </MapStyle.Map>
          <MapStyle.MyLocationBtn onClick={handleMyLocationClick}>
            <MapStyle.BtnImage src={mapIcon.MyLocation} />
          </MapStyle.MyLocationBtn>
        </MapStyle.Wrapper>
        {selectedAtm ? (
          <MapStyle.MobileOverlay>
            <header className="mobile-overlay-header">
              <h2>{selectedAtm.atm_display_name}</h2>
              {selectedAtm.sts === '2' ? <span>수리중</span> : null}
            </header>
            <div className="mobile-overlay-info">
              <p className="mobile-overlay-info_addr">{selectedAtm.addr}</p>
              <div className="mobile-overlay-info_detail">
                <p>
                  {selectedAtm.locate}
                  <span>운영 시간 {selectedAtm.info_run_hours}</span>
                </p>
              </div>
              <p className="mobile-overlay-info_off">
                휴무일 매주 {selectedAtm.off_days}
              </p>
            </div>
            <a
              className="mobile-overlay-button"
              href={`https://map.kakao.com/link/from/내위치,${location.Ma},${
                location.La
              }/to/${selectedAtm.atm_display_name.split(' ').join('%20')},${
                selectedAtm.lat
              },${selectedAtm.lon}`}
            >
              길찾기
            </a>
          </MapStyle.MobileOverlay>
        ) : null}
      </MapStyle.Container>
    </>
  );
};

export async function getStaticProps() {
  const res = await request(HttpMethod.GET, '/rest/api/etc/atm', {
    param: {
      com_main_nums:
        '1485,575,598,923,998,3978,4043,4480,608,611,613,930,935,949,4573,4575,6997,7442',
    },
    notUsedAccept: true,
  });
  const { data } = res;

  return {
    props: {
      data: data.dataset,
    },
  };
}

export default AtmOfUse;

const MapStyle = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  Wrapper: styled.div`
    position: relative;
    display: flex;
    flex: 1;
    height: 100%;
    overflow: hidden;
  `,
  Map: styled.div`
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
  `,
  Nav: styled.div`
    width: 30vh;
    height: 100vh;
    max-width: 480px;
  `,
  MyLocationBtn: styled.button`
    position: absolute;
    right: 30px;
    bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 8;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #33ccbd;
    cursor: pointer;
  `,
  BtnImage: styled.img`
    width: 28px;
    height: 28px;
  `,
  MobileOverlay: styled.div`
    display: none;
    width: 100%;
    padding: 25px;
    background: #fff;

    .mobile-overlay-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 18px;

      h2 {
        max-width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;
        font-weight: bold;
      }

      span {
        padding: 4px 8px;
        background: #ffccd2;
        font-size: 12px;
        color: #f64c4c;
        border-radius: 100px;
      }
    }

    .mobile-overlay-info {
      &_addr {
        font-size: 14px;
        margin-bottom: 4px;
      }
      &_detail {
        font-size: 12px;
        color: #969696;
        margin-bottom: 4px;

        span {
          position: relative;
          display: inline-flex;
          align-items: center;
          margin-left: 8px;
          padding-left: 8px;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            width: 1px;
            height: 80%;
            background: #969696;
          }
        }
      }
      &_off {
        font-size: 12px;
        color: #969696;
      }
    }

    .mobile-overlay-button {
      display: inline-block;
      padding: 8px 10px;
      border-radius: 100px;
      background: #33ccbd;
      font-size: 12px;
      color: #fff;
      float: right;
    }

    @media screen and (max-width: 720px) {
      display: block;
    }
  `,
};
