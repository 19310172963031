import React from 'react';
import Layout from 'components/Layout';
import styled from 'styled-components';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';

const ErrorPage: React.FunctionComponent = () => {
  return (
    <Layout>
      <ErrorStyle.Container>
        <ErrorStyle.Title>해당 페이지가 존재하지 않습니다.</ErrorStyle.Title>
      </ErrorStyle.Container>
    </Layout>
  );
};

export default ErrorPage;

const ErrorStyle = {
  Container: styled.div`
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    ${mediaQueries(MOBILE)} {
      height: 50vh;
    }
  `,
  Title: styled.div`
    font-size: 24px;
    font-weight: 700;
    ${mediaQueries(MOBILE)} {
      font-size: 16px;
    }
  `,
};
