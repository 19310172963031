import { HttpMethod, request } from 'common/network';
import React, { useEffect } from 'react';

const NiceRes = () => {
  useEffect(() => {
    const test = async () => {
      const res = await request(
        HttpMethod.GET,
        '/rest/api/component/getNicePassDecData',
      );
      console.log(res);
    };

    test();
  }, []);

  return <div>인증중..</div>;
};

export default NiceRes;
