import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled, { css, keyframes } from 'styled-components';
import { mediaQueries, MOBILE } from 'styles/mediaQueries';

interface ModalProps {
  width?: string;
  maxWidth?: string;
  mobileWidth?: string;
  height?: string;
  mobileHeight?: string;
  maxHeight?: string;
  padding?: string;
  isDark?: boolean;
  borderRadius?: string;
  // 두 모달이 적용될 때 스크롤 방지
  visibleDouble?: boolean;
  visible?: boolean;
  onClose: () => void;
  children: any;
}

const Modal: React.FunctionComponent<ModalProps> = ({
  width,
  maxWidth,
  mobileWidth,
  height,
  mobileHeight,
  maxHeight,
  padding,
  visibleDouble,
  visible,
  onClose,
  isDark,
  borderRadius,
  children,
  ...rest
}) => {
  // BackDrop 화면으로 모달창 닫기
  const modalCloseHandler = () => onClose();

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [visible]);

  const content =
    visible || visibleDouble ? (
      <ModalStyle.Container>
        <ModalStyle.BackDrop onClick={modalCloseHandler} isDark={isDark} />
        <ModalStyle.Modal
          width={width}
          maxWidth={maxWidth}
          mobileWidth={mobileWidth}
          height={height}
          mobileHeight={mobileHeight}
          maxHeight={maxHeight}
          borderRadius={borderRadius}
          padding={padding}
          open={visible}
          {...rest}
        >
          {children}
        </ModalStyle.Modal>
      </ModalStyle.Container>
    ) : null;

  // 모달 생성
  return ReactDOM.createPortal(
    content,
    document.getElementById('modal') as HTMLElement,
  );
};

export default Modal;

const up = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) translateY(10%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) translateZ();
  }
`;

const ModalStyle = {
  Container: styled.div``,
  BackDrop: styled.div<{ isDark?: boolean }>`
    z-index: 9998;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ isDark }) =>
      isDark ? ' rgba(0,0,0,0.5)' : 'rgba(0, 0, 0, 0.15)'};
  `,
  Modal: styled.div<{
    width?: string;
    maxWidth?: string;
    mobileWidth?: string;
    height?: string;
    mobileHeight?: string;
    maxHeight?: string;
    borderRadius?: string;
    padding?: string;
    open?: boolean;
  }>`
    display: block;
    z-index: 9999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    ${({ open }) =>
      open &&
      css`
        animation: ${up} 0.3s;
      `};
    box-shadow: 0px 8px 40px 0px rgba(58, 58, 60, 0.2);
    border-radius: ${({ borderRadius }) => borderRadius || '24px'};
    background-color: #fff;
    width: ${({ width }) => width};
    max-width: ${({ maxWidth }) => maxWidth};
    height: ${({ height }) => height};
    max-height: ${({ maxHeight }) => maxHeight};
    padding: ${({ padding }) => padding};
    overflow-y: scroll;
    ${mediaQueries(MOBILE)} {
      width: ${({ mobileWidth }) => mobileWidth};
      height: ${({ mobileHeight }) => mobileHeight};
      overflow-y: scroll;
    }
  `,
};
