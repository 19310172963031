import { css } from 'styled-components';

export const ellipsisDoubleLine = css`
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const flexCenterCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const flexColumnCenterCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const flexColumnCss = css`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

export const flexSpaceBetween = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const backgroundCss = css`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
