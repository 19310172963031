import styled from 'styled-components';
import { media } from '../../styles/theme';

export const LaptopPriceInquiryLayout = styled.section`
  padding: 123px 160px;

  /* 데이터 리스트 wrapper */
  .list-wrapper {
    flex: 1;

    /* 데이터 리스트 header */

    .list-header {
      .list-step {
        font-size: 18px;
        margin-bottom: 12px;

        span {
          color: #8e8e8e;
        }
      }

      .list-title {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 32px;
        line-height: 36px;
      }
    }

    /* 데이터 리스트 */
    .data-list {
      display: flex;
      flex-wrap: wrap;

      .data-item-wrapper {
        width: calc(25% - 12px);
        margin-bottom: 16px;

        &:not(:nth-child(4n)) {
          margin-right: 16px;
        }

        &.selected {
          .data-item {
            border: 1px solid #33ccbd;
            background: #d6f5f2;
            color: #33ccbd;
          }
        }

        .data-item {
          cursor: pointer;
          position: relative;
          width: 100%;
          height: 0;
          padding-top: 100%;
          border: 1px solid #e1e1e1;
          border-radius: 40px;

          &:hover {
            border: 1px solid #33ccbd;
            background: #d6f5f2;
            color: #33ccbd;
          }

          .data-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            font-size: 16px;
            text-align: center;
            padding: 0 12px;

            .data-name {
              word-break: break-all;
            }
            .data-img {
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }

  ${media.mobile} {
    padding: 0;

    .list-wrapper {
      /* 데이터 리스트 */
      .data-list {
        .data-item-wrapper {
          width: calc(33% - 12px);

          &:not(:nth-child(4n)) {
            margin-right: 0px;
          }
          &:not(:nth-child(3n)) {
            margin-right: 16px;
          }

          .data-item {
            border-radius: 24px;

            .data-inner {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
`;
