import { mainIcon } from 'common/constants/imageUrls';

export const HomeCaution = [
  '휴대폰 구매자, PASS 인증자, 예금주가 다른 경우',
  '분실 및 도난 등록된 폰, 전원이 켜지지 않는 폰, 화면(LCD)이 안보이는 폰, 해외 직구폰을 반납한 경우',
  '반납 가능한 매장이 아닌 곳에 반납한 경우',
  '제품 구매/개통일로부터 14일 이후 중고폰을 반납하는 경우',
  '민팃 ATM 반납일로부터 14일 이후 계좌번호를 입력 할 경우',
  '미성년자의 경우',
];

export const ReadyCaution = [
  '본 이벤트 내용 및 혜택은 당사 사정에 의해 사전 공지 없이 변경 또는 종료될 수 있습니다.',
  '본 이벤트는 당사가 안내한 제휴처에서 갤럭시 퀀텀3, 갤럭시 점프2를 개통한 고객에 한해 참여가 가능합니다.',
  '추가 보상금 지급 여부는 민팃 ATM에서 팝업으로 확인할 수 있습니다. 팝업이 나오지 않을 경우 갤럭시 퀀텀3, 갤럭시 점프2 구매처로 문의 바랍니다.',
  '추가 보상금은 최초 접수한 휴대폰 1대에 한하여 지급됩니다.',
  '추가 보상금이 지급된 이후 휴대폰 반품은 불가합니다. (단, 수거 후 검사 반품은 가능)',
  '민팃 보상금은 즉시 입금되며, 추가 보상금은 개통 14일 유지 후 지급됩니다.',
  '개통 후 불량으로 인하여 제품을 교환하시는 경우 교환 후 14일 유지 확인 후 추가 보상금이 지급됩니다.',
  '개통 후 14일 이내 휴대폰 번호를 변경한 경우 추가보상 대상자 여부 확인이 불가하오니 갤럭시 퀀텀3, 갤럭시 점프2 구매처로 문의 바랍니다.',
  '갤럭시 퀀텀3, 갤럭시 점프2 개통 고객이 미성년자 또는 본인 명의의 계좌가 없는 경우, 가족 관계 증명서에 명시되어 있는 가족 명의 계좌로 변경은 가능합니다. (가족 관계 증명서 증빙 필요)',
];

export const BannerData = [
  { label: '이벤트 기간', value: '22.04.22(금) - 05.31(화)' },
  {
    label: '이벤트 대상',
    value: '갤럭시 퀀텀3 / 갤럭시 점프2를 개통한 고객 누구나',
  },
  {
    label: '고객 혜택',
    value: `민팃 보상금에 무조건 <span>2만원까지${
      window.innerWidth < 720 ? '<br />' : ' '
    }추가 보상</span>받는 혜택`,
  },
  {
    label: '반납 기한',
    value: '제품 개통일로부터 14일 이내 기존 휴대폰 반납',
  },
];

export const AdditionalPartnerData = [
  {
    imgs: [mainIcon.SKLogo, mainIcon.KTLogo],
    text: 'SKT, KT',
  },
];
